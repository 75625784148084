import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import FilterLayout from './FilterLayout'
import SortAndSearch from './SortAndSearch'

const FilterAndSearchArea = ({
    filterCount,
    handleSearch,
    setInputValue,
    updateQueryStringAndNavigate,
    sortName,
    isGridView,
    setIsGridView,
    inputValue,
    setSelectOption,
}) => {
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const isSurvey = pathname.includes('survey')

    // 기간 설정 필터
    const [startDateRange, setStartDateRange] = useState(null)
    const [endDateRange, setEndDateRange] = useState(null)

    // 지난달, 이번달 버튼 필터
    const [isActiveLastMonth, setIsActiveLastMonth] = useState(false)
    const [isActiveThisMonth, setIsActiveThisMonth] = useState(false)

    /**
     * 필터 리셋 버튼
     */
    const handleResetQuerystring = () => {
        navigate(pathname)

        if (startDateRange) {
            setStartDateRange(null)
        }
        if (endDateRange) {
            setEndDateRange(null)
        }
        if (isActiveLastMonth) {
            setIsActiveLastMonth(false)
        }
        if (isActiveThisMonth) {
            setIsActiveThisMonth(false)
        }
        if (!isSurvey && inputValue !== '') {
            setInputValue('')
        }
    }

    return (
        <>
            <FilterLayout
                filterCount={filterCount}
                updateQueryStringAndNavigate={updateQueryStringAndNavigate}
                handleResetQuerystring={handleResetQuerystring}
                startDateRange={startDateRange}
                setStartDateRange={setStartDateRange}
                endDateRange={endDateRange}
                setEndDateRange={setEndDateRange}
                isActiveLastMonth={isActiveLastMonth}
                setIsActiveLastMonth={setIsActiveLastMonth}
                isActiveThisMonth={isActiveThisMonth}
                setIsActiveThisMonth={setIsActiveThisMonth}
            />
            {!isSurvey && (
                <SortAndSearch
                    updateQueryStringAndNavigate={updateQueryStringAndNavigate}
                    sortName={sortName}
                    isGridView={isGridView}
                    setIsGridView={setIsGridView}
                    setInputValue={setInputValue}
                    handleSearch={handleSearch}
                    inputValue={inputValue}
                    setSelectOption={setSelectOption}
                />
            )}
        </>
    )
}

export default FilterAndSearchArea
