import styles from './Spin.module.scss'

const Spinner = () => {
    return (
        <div className={styles.center_body}>
            <div className={styles.loader_square}>
                <div className={styles.first} />
                <div className={styles.second} />
                <div className={styles.third} />
                <div className={styles.fourth} />
            </div>
        </div>
    )
}

export default Spinner
