import { useNavigate } from 'react-router-dom'

import PageBanner from 'layout/PageBanner'
import { App } from 'antd'

import RegistrationAndEditFormTutors from 'components/RegistrationAndEditForm/Tutors'
import { convertTutorCamelToSnakeCase } from 'utils/utilCommon'
import { postTutors } from 'api/tutors/tutors.api'

import { useSelector } from 'react-redux'
import { getAllUserInfo } from 'store/Slices/user'

// 강사 단건 등록
const TutorRegisterOne = () => {
    const { message } = App.useApp()
    const navigate = useNavigate()
    const { userInfo } = useSelector(getAllUserInfo)
    const onFinish = value => {
        const formData = convertTutorCamelToSnakeCase(value)
        const postTutor = async () => {
            const response = await postTutors({ ...formData, region_id: userInfo.regionId, is_delete: false })

            if (response.error) {
                message.error('등록 중 오류가 발생했습니다')
                return
            }
            if (response.data) {
                message.success('성공적으로 등록되었습니다')
                const tutorId = response.data[0].tutor_id
                navigate(`/tutors/${tutorId}`)
            }
        }

        postTutor()
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo)
        message.error('사용자 정보를 모두 입력해주세요.')
    }

    return (
        <PageBanner title="강사정보 등록">
            <RegistrationAndEditFormTutors onFinish={onFinish} onFinishFailed={onFinishFailed} />
        </PageBanner>
    )
}

export default TutorRegisterOne
