import { useState } from 'react'

import PageBanner from 'layout/PageBanner'
import { BannerIconTools, ColorDocIcon, ColorLocationIcon } from 'assets/Icons'
import { Row, Col, Space, Flex } from 'antd'
import styles from 'assets/scss/detail.module.scss'
import Title from 'components/Ui/Title'
import Label from 'components/Ui/Label'
import SelectUi from 'components/Ui/Select/SelectUi'
import IconCard from 'components/AntD/Card/IconCard'
import ColorButton from 'components/AntD/Button/ColorButton'

// import Value from 'components/Ui/Value'
// import Spinner from 'components/AntD/Spin'
import { useNavigate, useParams } from 'react-router-dom'

const ToolsRentDetail = () => {
    // const [loading, setLoading] = useState(true)
    const [toolsState] = useState({
        name: '교구',
    })
    const { id } = useParams()
    const navigate = useNavigate()

    return (
        <PageBanner title="교구재 관리" bannerIcon={<BannerIconTools />}>
            <div className={styles.detail_padding}>
                {
                    toolsState && (
                        <>
                            <Row className={styles.mb_lg}>
                                <Col span={16}>
                                    <Space direction="vertical" size={60}>
                                        <div>
                                            <Title title="대여자 정보" />
                                            <div className={`${styles.box_md} ${styles.border_style}`}>
                                                <Row className={styles.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="성명 (대여자)" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>김미래</span>
                                                                {/* <Value value="010-AD6-610" /> */}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="공동사용자" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>박미래</span>
                                                                {/* <Value value="010-AD6-610" /> */}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="연락처" />
                                                    </Col>
                                                    <Col span={19} className={styles.padding_y_medium}>
                                                        {/* <Value value="코딩교육" /> */}
                                                        010-0000-0000
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div>
                                            <Title title="대여 및 사용정보" />
                                            <div className={`${styles.box_md} ${styles.border_style}`}>
                                                <Row className={styles.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="수량" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>4개</span>
                                                                {/* <Value value="010-AD6-610" /> */}
                                                            </Col>
                                                        </Row>
                                                        {/* <Value value={toolsState?.program_detail} /> */}
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="사용기관" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>미래채움</span>
                                                                {/* <Value value="010-AD6-610" /> */}
                                                            </Col>
                                                        </Row>
                                                        {/* <Value value={toolsState?.program_detail} /> */}
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="대여상태" />
                                                    </Col>
                                                    <Col span={19}>
                                                        <SelectUi
                                                            size="lg"
                                                            disabled
                                                            value="반납완료"
                                                            options={[
                                                                '반납완료',
                                                                '신청취소',
                                                                '대기중',
                                                                '대여확정',
                                                                '대여불가능',
                                                            ]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="대여신청일자" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>2023-01-01</span>
                                                                {/* <Value value="010-AD6-610" /> */}
                                                            </Col>
                                                        </Row>
                                                        {/* <Value value={toolsState?.program_detail} /> */}
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="대여일자" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>2023-01-10</span>
                                                                {/* <Value value="010-AD6-610" /> */}
                                                            </Col>
                                                        </Row>
                                                        {/* <Value value={toolsState?.program_detail} /> */}
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="반납일자" />
                                                        {/* <Value value={toolsState?.program_detail} /> */}
                                                    </Col>
                                                    <Col span={19} className={styles.padding_y_medium}>
                                                        <span>2023-01-20</span>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="대여/사용목적" />
                                                        {/* <Value value={toolsState?.program_detail} /> */}
                                                    </Col>
                                                    <Col span={19} className={styles.padding_y_medium}>
                                                        <span>
                                                            상반기 지역아동센터 교육4조(전남), 연구개발, 대안학교
                                                            SW교육(전북)
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div>
                                            <Title title="교구 정보" />
                                            <div className={`${styles.box_md} ${styles.border_style}`}>
                                                <Row className={styles.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="품목 (교구명)" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>엔트리</span>
                                                                {/* <Value value="010-AD6-610" /> */}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="교구번호" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>02020305</span>
                                                                {/* <Value value="010-AD6-610" /> */}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="비고" />
                                                    </Col>
                                                    <Col span={19} className={styles.padding_y_medium}>
                                                        {/* <Value value={toolsState?.name} /> */}
                                                        <span />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>

                                        <div>
                                            <Title title="비고" />
                                            <div className={`${styles.box_md} ${styles.border_style}`}>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="구분" />
                                                    </Col>
                                                    <Col span={19}>
                                                        <SelectUi
                                                            disabled
                                                            value="지역아동센터"
                                                            options={['지역아동센터', '섬마을']}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="소속권역" />
                                                    </Col>
                                                    <Col span={19} className={styles.padding_y_medium}>
                                                        1권역
                                                        {/* <SelectUi value="1권역" options={['1권역', '2권역']} /> */}
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="규격" />
                                                    </Col>
                                                    <Col span={19} className={styles.padding_y_medium}>
                                                        532X532X788mm , 240W , 60W , Window 10 Pro 형태
                                                        {/* <SelectUi value="1권역" options={['1권역', '2권역']} /> */}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div>
                                            <Title title="선택항목" />
                                            <div className={`${styles.box_md} ${styles.border_style}`}>
                                                <Row className={styles.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="위치코드" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>0007</span>
                                                                {/* <Value value="010-AD6-610" /> */}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="관리번호" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>123</span>
                                                                {/* <Value value="010-AD6-610" /> */}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="입고순번" />
                                                    </Col>
                                                    <Col span={19} className={styles.padding_y_medium}>
                                                        <span>20번</span>

                                                        {/* <SelectUi option={['수리완료', '수리불가', '주문예정']} /> */}
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="지역구분" />
                                                    </Col>
                                                    <Col span={19} className={styles.padding_y_medium}>
                                                        경남센터
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="건물구분" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>2동</span>
                                                                {/* <Value value="010-AD6-610" /> */}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="이동일자" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>2023-02-02</span>
                                                                {/* <Value value="010-AD6-610" /> */}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Space>
                                </Col>
                                <Col span={8} className={styles.padding_left_large}>
                                    <Space direction="horizontal" className={styles.mt}>
                                        <IconCard icon={<ColorDocIcon />} label="자산번호" val="UN001" />
                                        <IconCard icon={<ColorLocationIcon />} label="재고위치" val="경남" />
                                    </Space>
                                </Col>
                            </Row>
                            <Flex justify="center" align="center" gap={8}>
                                <ColorButton
                                    size="large"
                                    type="primary"
                                    onClick={() => navigate(`/tools/rental/edit/${id}`)}
                                >
                                    수정
                                </ColorButton>
                                <ColorButton size="large" onClick={() => navigate('/tools/rental')}>
                                    목록
                                </ColorButton>
                            </Flex>
                        </>
                    )
                    // : (
                    //     <div className={styles.spin_wrap}>
                    //         <Spinner />
                    //     </div>
                    // )
                }
            </div>
        </PageBanner>
    )
}

export default ToolsRentDetail
