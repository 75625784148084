import React, { useState } from 'react'
import style from 'assets/scss/detail.module.scss'
import { Button, Upload } from 'antd'
import { useSelector } from 'react-redux'
import { tempS3Bucket } from 'utils/utilS3Bucket'
import { GrayTrashIcon24 } from '../../assets/Icons/index'
import '../../pages/Contact/contact.scss'

const UploadBtn = ({ attachment = [], contactId, onChange = () => {} }) => {
    const userId = useSelector(state => state.user?.userInfo?.userId)
    const [fileList, setFileList] = useState(attachment || [])
    const { Dragger } = Upload

    const handleUploadStatus = info => {
        const file_name = info.file.name

        if (fileList.some(file => file.file_name === file_name)) {
            return
        }

        tempS3Bucket(info.file, file_name, userId || 'JJ01')

        const updatedFileList = {
            file_name,
            contact_id: contactId,
            file_path: `/file/upload/attachment/${userId || 'JJ01'}`,
        }

        setFileList(prevFileList => {
            const newFileList = [...prevFileList, updatedFileList]
            onChange(newFileList)
            return newFileList
        })
    }

    const handleRemove = index => {
        const newFileList = fileList.filter((_, i) => i !== index)
        setFileList(newFileList)
        onChange(newFileList)
    }

    return (
        <div className="upload_wrap">
            <div className="upload_box">
                <Dragger
                    showUploadList={false}
                    multiple
                    onChange={handleUploadStatus}
                    accept=".pdf, .xlsx, .jpg, .jpeg, .png"
                >
                    <div className={style.upload_icon_wrap}>
                        <span className={style.upload_icon} />
                    </div>
                    <p className="ant-upload-text">클릭하거나 드래그해서 파일을 추가하세요. Excel(.xlsx, .xls)형식</p>
                    <p className="ant-upload-text red">*강사/교육 대량 등록 실패 시 엑셀파일도 함께 첨부해주세요.</p>
                    {/* <p className="ant-upload-hint">xlsx, pdf, jpg, png</p> */}
                </Dragger>
            </div>

            <div className="upload_list">
                {fileList.map((file, index) => (
                    <div key={file.uid} className="file-item">
                        <span className="body2 gray">{file.file_name}</span>
                        <Button className="button-reset-type" onClick={() => handleRemove(index)}>
                            <GrayTrashIcon24 />
                        </Button>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default UploadBtn
