import CheckBox from 'components/AntD/CheckBox/type1'
import { useState } from 'react'
import Title from 'components/Ui/Title'
import Label from 'components/Ui/Label'
import { Col, Form, Row } from 'antd'
import TextField from 'components/AntD/TextField'
import style from 'assets/scss/detail.module.scss'
import Value from '../../Ui/Value'

const EtcInfo = ({ etc_info, isEditOrRegisterPage }) => {
    const [etcInfoState] = useState(etc_info)

    return (
        <div>
            <Title title="기타" />
            <div className={`${style.border_style} ${style.box_md}`}>
                <Row className={style.row_item}>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="권역" />
                            </Col>
                            {isEditOrRegisterPage ? (
                                <Col span={14} className={style.padding_y_xs}>
                                    <Form.Item name="areaName" initialValue={etcInfoState?.areaName}>
                                        <TextField name="areaName" size="sm" val={etcInfoState.areaName} />
                                    </Form.Item>
                                </Col>
                            ) : (
                                <Col span={14} className={style.padding_y_medium}>
                                    <Value value={etc_info?.areaName} />
                                </Col>
                            )}
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="기수" />
                            </Col>
                            {isEditOrRegisterPage ? (
                                <Col span={14} className={style.padding_y_xs}>
                                    <Form.Item name="cohortStartYear" initialValue={etcInfoState?.cohortStartYear}>
                                        <TextField
                                            name="cohortStartYear"
                                            size="sm"
                                            val={etcInfoState.cohortStartYear}
                                        />
                                    </Form.Item>
                                </Col>
                            ) : (
                                <Col span={14} className={style.padding_y_medium}>
                                    <Value value={etc_info?.cohortStartYear} />
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
                <Row className={style.row_item}>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="자차 여부" />
                            </Col>

                            <Col span={14} className={style.padding_y_xs}>
                                {isEditOrRegisterPage ? (
                                    <Form.Item name="ownCarYn" initialValue={etcInfoState?.ownCarYn}>
                                        <CheckBox
                                            name="ownCarYn"
                                            checked={etcInfoState?.ownCarYn}
                                            width="48"
                                            height="48"
                                        />
                                    </Form.Item>
                                ) : (
                                    <CheckBox disabled checked={etc_info?.ownCarYn} width="48" height="48" />
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="고유번호" />
                            </Col>
                            {isEditOrRegisterPage ? (
                                <Col span={14} className={style.padding_y_xs}>
                                    <Form.Item name="uniqueNum" initialValue={etcInfoState?.uniqueNum}>
                                        <TextField name="uniqueNum" size="sm" val={etcInfoState.uniqueNum} />
                                    </Form.Item>
                                </Col>
                            ) : (
                                <Col span={14} className={style.padding_y_medium}>
                                    <Value value={etc_info?.uniqueNum} />
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>

                <Row className={style.row_item}>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="지역인재" />
                            </Col>
                            <Col span={14} className={style.padding_y_xs}>
                                {isEditOrRegisterPage ? (
                                    <Form.Item name="localTalent" initialValue={etcInfoState?.localTalent}>
                                        <CheckBox
                                            name="localTalent"
                                            checked={etcInfoState?.localTalent}
                                            width="48"
                                            height="48"
                                        />
                                    </Form.Item>
                                ) : (
                                    <CheckBox disabled checked={etc_info?.localTalent} width="48" height="48" />
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="연속배치" />
                            </Col>
                            <Col span={14} className={style.padding_y_xs}>
                                {isEditOrRegisterPage ? (
                                    <Form.Item name="continuPlacement" initialValue={etcInfoState?.continuPlacement}>
                                        <CheckBox
                                            name="continuPlacement"
                                            checked={etcInfoState?.continuPlacement}
                                            width="48"
                                            height="48"
                                        />
                                    </Form.Item>
                                ) : (
                                    <CheckBox
                                        disabled
                                        name="continuPlacement"
                                        checked={etc_info?.continuPlacement}
                                        width="48"
                                        height="48"
                                    />
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className={style.row_item}>
                    <Col span={5}>
                        <Label label="기타활동" />
                    </Col>
                    {isEditOrRegisterPage ? (
                        <Col span={19} className={style.padding_y_xs}>
                            <Form.Item name="remarks" initialValue={etcInfoState.remarks}>
                                <TextField name="remarks" val={etcInfoState.remarks} size="xl" />
                            </Form.Item>
                        </Col>
                    ) : (
                        <Col span={19} className={style.padding_y_medium}>
                            <Value value={etc_info?.remarks} />
                        </Col>
                    )}
                </Row>
                <Row className={isEditOrRegisterPage ? style.row_item : ''}>
                    <Col span={5}>
                        <Label label="강사평가" />
                    </Col>
                    {isEditOrRegisterPage ? (
                        <Col span={19} className={style.padding_y_xs}>
                            <Form.Item name="evaluation" initialValue={etcInfoState?.evaluation}>
                                <TextField name="evaluation" val={etcInfoState.evaluation} size="xl" />
                            </Form.Item>
                        </Col>
                    ) : (
                        <Col span={19} className={style.padding_y_medium}>
                            <Value value={etc_info?.evaluation} />
                        </Col>
                    )}
                </Row>
            </div>
        </div>
    )
}
export default EtcInfo
