import { useState, useEffect } from 'react'
import { Flex, Input, Form, App } from 'antd'
import { BannerIconContact } from 'assets/Icons'
import PageBanner from 'layout/PageBanner'
import ColorButton from 'components/AntD/Button/ColorButton'
import SelectBox from 'components/AntD/Select'
import SelectUi from 'components/Ui/Select/SelectUi'
import { supabase } from 'api/axios.instance'
import UploadBtn from 'components/UploadBtn/UploadBtn'
import TextArea from 'antd/es/input/TextArea'
import { useNavigate } from 'react-router-dom'
import { postContact } from 'api/contact/contact.api'
import { useSelector } from 'react-redux'
import { getAllUserInfo } from 'store/Slices/user'
import { copyBucketFolderList, deleteBucketFolder } from 'utils/utilS3Bucket'
import '../contact.scss'

const ContactWrite = () => {
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const { message } = App.useApp()
    const userId = useSelector(state => state.user?.userInfo?.userId)
    const { userInfo } = useSelector(getAllUserInfo)
    const [text, setText] = useState('')
    const [wordCount, setWordCount] = useState(0)
    const [charCount, setCharCount] = useState(0)
    const [emailUser, setEmailUser] = useState('')
    const [emailSecond, setEmailSecond] = useState('')
    const [emailDomain, setEmailDomain] = useState('')

    useEffect(() => {
        const words = text.trim().split(/\s+/).filter(Boolean)
        setWordCount(words.length)
        setCharCount(text.length)
    }, [text])

    const onFinish = async values => {
        const matchingFilter = (arr, contactId) => {
            const newArray = arr.map(item => {
                const { id, attachment_no, ...rest } = item
                return {
                    ...rest,
                    contact_id: contactId,
                }
            })

            return newArray
        }

        const fullEmail = `${emailUser}@${emailDomain || emailSecond}`
        const sendEmail = async () => {
            try {
                const res = await fetch('https://yopdbpykqvrhrmnnciax.supabase.co/functions/v1/resend', {
                    method: 'POST',
                    body: JSON.stringify({
                        from: 'onboarding@resend.dev',
                        to: fullEmail,
                        subject: values.title,
                        text: values.contents,
                    }),
                })

                if (!res.ok) {
                    throw new Error('Failed to send email')
                }

                const data = await res.json()
                console.log('Email sent successfully:', data)
            } catch (error) {
                console.error('Error sending email:', error)
            }
        }

        await sendEmail()

        const getContact = async () => {
            const response = await postContact({
                title: values.title,
                contents: values.contents,
                status: 'time',
                regionId: userInfo.regionId,
                category: values.category,
                email: fullEmail,
            })

            if (response.error) {
                message.error('등록 중 오류가 발생했습니다')
                return null
            }

            if (response.data) {
                message.success('성공적으로 등록되었습니다')
                const contactId = response.data[0].contact_id
                navigate(`/contact/view/${contactId}`)
                return contactId
            }
            return null
        }

        const updateProgramAttachment = async ({ contactId }) => {
            if (values.contactAttachmentList) {
                console.log(values.contactAttachmentList)
                return supabase
                    .from('contact_attachment')
                    .upsert(matchingFilter(values.contactAttachmentList, contactId))
            }
            return null
        }

        const contactId = await getContact()
        if (contactId) {
            const [attachmentResponse] = await Promise.all([updateProgramAttachment({ contactId })])
            console.log('attachmentResponse : ', attachmentResponse)
        }
        if (values.contactAttachmentList) {
            await copyBucketFolderList(values.contactAttachmentList, userId)
            await deleteBucketFolder(values.contactAttachmentList, userId)
        }
    }

    const emailValidator = async () => {
        const fullEmail = `${emailUser}@${emailDomain || emailSecond}`
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (!emailRegex.test(fullEmail)) {
            return Promise.reject(new Error('유효한 이메일을 입력해주세요.'))
        }
        return Promise.resolve()
    }

    const handleEmailDomainChange = value => {
        setEmailDomain(value)
        setEmailSecond('')
    }
    const handleEmailUserChange = e => {
        setEmailUser(e.target.value)
    }
    const handleEmailSecondChange = e => {
        setEmailSecond(e.target.value)
    }

    return (
        <PageBanner title="문의하기" bannerIcon={<BannerIconContact />}>
            <Form form={form} onFinish={onFinish}>
                <div className="contact_container">
                    <div className="write_outer">
                        <div className="contact_card">
                            <div className="title_box">
                                <span className="title_label">
                                    제목 <span className="red">*</span>
                                </span>
                                <div className="form_title">
                                    <Form.Item name="title" rules={[{ required: true }]}>
                                        <Input placeholder="제목을 입력해주세요." className="ant_input body2" />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="title_box">
                                <span className="title_label">
                                    유형 <span className="red">*</span>
                                </span>
                                <div className="form_select">
                                    <Form.Item name="category" initialValue="기능 문의">
                                        <SelectUi name="category" size="lg" options={['기능 문의', '오류 제보']} />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="title_box">
                                <span className="title_label">
                                    이메일 <span className="red">*</span>
                                </span>
                                <div className="form_email">
                                    <Form.Item name="email" rules={[{ validator: emailValidator }]}>
                                        <Input
                                            name="email"
                                            placeholder="이메일"
                                            value={emailUser}
                                            className="ant_input body2"
                                            onChange={handleEmailUserChange}
                                            style={{ width: 'calc(100% - 200px)', marginRight: '8px' }}
                                        />
                                        @
                                        <Input
                                            name="email"
                                            placeholder=""
                                            className="ant_input body2"
                                            disabled={emailDomain !== ''}
                                            value={emailSecond}
                                            onChange={handleEmailSecondChange}
                                            style={{ width: 'calc(100% - 120px)' }}
                                        />
                                        <SelectBox
                                            placeholder="직접입력"
                                            options={[
                                                { value: '', label: '직접입력' },
                                                { value: 'naver.com', label: 'naver.com' },
                                                { value: 'gmail.com', label: 'gmail.com' },
                                            ]}
                                            defaultValue=""
                                            onChange={handleEmailDomainChange}
                                            style={{ width: 'calc(100% - 120px)' }}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>

                        <Form.Item name="contents" rules={[{ required: true }]}>
                            <TextArea
                                placeholder="내용을 입력해주세요."
                                rows={20}
                                className="ant_textarea body2"
                                style={{ resize: 'none' }}
                                onChange={e => setText(e.target.value)}
                            />
                        </Form.Item>
                        <div className="textarea-count">
                            <span className="body2 gray">Word : {wordCount}</span>
                            <span className="body2 gray">Characters : {charCount}</span>
                        </div>

                        {/* <div className="title_box">
                            <Form.Item name="contactAttachmentList">
                                <UploadBtn form={form} />
                            </Form.Item>
                        </div> */}

                        <Form.Item name="contactAttachmentList">
                            <UploadBtn form={form} />
                        </Form.Item>
                    </div>

                    <Flex justify="end" gap={8}>
                        <ColorButton type="primary" size="large" htmlType="submit">
                            확인
                        </ColorButton>
                        <ColorButton size="large" onClick={() => navigate('/contact')}>
                            취소
                        </ColorButton>
                    </Flex>
                </div>
            </Form>
        </PageBanner>
    )
}

export default ContactWrite
