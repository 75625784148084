import styles from './icons.module.scss'

export const AlertIcon = () => {
    return (
        <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg" id="alert_icon">
            <g>
                <path
                    id="Vector"
                    d="M9.00044 13C5.1011 13 1.84435 15.681 1.03564 19.269C0.835033 20.1545 1.50582 21 2.4305 21H15.5704C16.4951 21 17.1627 20.1545 16.9652 19.269C16.1597 15.6841 12.8998 13 9.00044 13Z"
                    stroke="#00A886"
                    strokeWidth="1.5"
                    strokeLinecap="square"
                />
                <path
                    id="Vector_2"
                    d="M9 10.3333C11.5773 10.3333 13.6667 8.244 13.6667 5.66667C13.6667 3.08934 11.5773 1 9 1C6.42267 1 4.33334 3.08934 4.33334 5.66667C4.33334 8.244 6.42267 10.3333 9 10.3333Z"
                    stroke="#00A886"
                    strokeWidth="1.5"
                    strokeLinecap="square"
                />
            </g>
        </svg>
    )
}

export const CopyIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.181 16L21 16V5.819C21 4.26389 19.7381 3 18.181 3H10.819C9.26389 3 8 4.26195 8 5.819"
                stroke="#024383"
                strokeWidth="1.5"
                strokeLinecap="square"
            />
            <path
                d="M5.25269 7H13.7473C15.5439 7 17 8.45833 17 10.2527V22H5.25269C3.45609 22 2 20.5417 2 18.7473V10.2527C2 8.45609 3.45833 7 5.25269 7Z"
                stroke="#024383"
                strokeWidth="1.5"
                strokeLinecap="square"
            />
            <path d="M6 13H13" stroke="#024383" strokeWidth="1.5" strokeLinecap="square" />
            <path d="M6 17H9" stroke="#252525" strokeWidth="1.5" strokeLinecap="square" />
        </svg>
    )
}

export const BtnClose = ({ onClick }) => {
    return (
        <svg
            onClick={onClick}
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            id="btn_close_icon"
        >
            <path
                d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
                fill="#C2C2C2"
            />
            <path d="M14.4447 5.55522L5.55554 14.4443" stroke="white" strokeWidth="1.6" />
            <path d="M14.4447 14.4448L5.55554 5.55566" stroke="white" strokeWidth="1.6" />
        </svg>
    )
}

export const AvatarIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="40" viewBox="0 0 32 40" fill="none">
            <path
                d="M24.7254 20.8681C24.2964 20.4254 23.743 20.1343 23.1443 20.0364C22.5457 19.9386 21.9324 20.039 21.3923 20.3234C19.717 21.1831 17.8721 21.6305 16.0018 21.6305C14.1315 21.6305 12.2865 21.1831 10.6112 20.3234C10.071 20.0391 9.45773 19.9386 8.85902 20.0365C8.26031 20.1343 7.70685 20.4254 7.27779 20.8681C4.10555 24.2015 1.57032 29.2955 0.0846144 35.4114C-0.0642237 36.0288 -0.0157892 36.6794 0.222674 37.266C0.461138 37.8526 0.876815 38.3436 1.40755 38.6656C3.60802 40.4433 9.5773 39.9316 16 39.9316C22.4226 39.9316 28.6637 40.236 30.5924 38.6656C31.1232 38.3437 31.5389 37.8527 31.7774 37.2661C32.0158 36.6795 32.0642 36.0288 31.9153 35.4114C30.4332 29.2955 27.898 24.2015 24.7254 20.8681Z"
                fill="white"
            />
            <path
                d="M16.3478 19.2857C21.5345 19.2857 25.7391 14.9684 25.7391 9.64283C25.7391 4.31724 21.5345 0 16.3478 0C11.1611 0 6.95648 4.31724 6.95648 9.64283C6.95648 14.9684 11.1611 19.2857 16.3478 19.2857Z"
                fill="white"
            />
        </svg>
    )
}

export const ArrowBtmIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 8L12 16L3.99999 8" stroke="#7A7A7A" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}
export const ArrowTopIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 16L12 8L20 16" stroke="#7A7A7A" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}

export const PreviousPageIcon = ({ width = 30, height = 30 }) => {
    return (
        <svg
            id="previous_page_icon"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 40 40"
            fill="none"
        >
            <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" fill="white" stroke="#E5E5E5" />
            <path d="M24 28L16 20L24 12" fill="white" />
            <path d="M24 28L16 20L24 12" stroke="#C2C2C2" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}

export const NextPageIcon = ({ width = 30, height = 30 }) => {
    return (
        <svg
            id="next_page_icon"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 40 40"
            fill="none"
        >
            <rect
                x="39.5"
                y="39.5"
                width="39"
                height="39"
                rx="3.5"
                transform="rotate(180 39.5 39.5)"
                fill="white"
                stroke="#E5E5E5"
            />
            <path d="M16 12L24 20L16 28" stroke="#C2C2C2" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}

export const FirstPageIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 40 40" fill="none">
            <rect id="first_page_icon" x="0.5" y="0.5" width="39" height="39" rx="3.5" fill="white" stroke="#E5E5E5" />
            <path d="M20 28L12 20L20 12" stroke="#C2C2C2" strokeWidth="2" strokeLinecap="square" />
            <path d="M28 28L20 20L28 12" stroke="#C2C2C2" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}

export const LastPageIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 40 40" fill="none">
            <rect
                id="last_page_icon"
                x="39.5"
                y="39.5"
                width="39"
                height="39"
                rx="3.5"
                transform="rotate(180 39.5 39.5)"
                fill="white"
                stroke="#E5E5E5"
            />
            <path d="M12 12L20 20L12 28" stroke="#C2C2C2" strokeWidth="2" strokeLinecap="square" />
            <path d="M20 12L28 20L20 28" stroke="#C2C2C2" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}

export const UploadIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
            <path
                d="M6.57471 10.2612L9.45615 13.151C9.7577 13.4535 10.2448 13.4535 10.5464 13.151L13.4278 10.2612"
                stroke="#024383"
                strokeWidth="1.5"
                strokeLinecap="square"
            />
            <path d="M10 12.4286V1" stroke="#024383" strokeWidth="1.5" strokeLinecap="square" />
            <path
                d="M19 12.3293V14.7487C19 15.992 17.9948 17.0001 16.7552 17.0001H3.24485C2.00515 17.0001 1 15.992 1 14.7487V12.3293"
                stroke="#024383"
                strokeWidth="1.5"
                strokeLinecap="square"
            />
        </svg>
    )
}

export const AttendanceIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none">
            <path
                d="M18.0703 10.0588L11.3403 16.5552L11.2354 18.9999L13.768 18.8986L20.498 12.4022C21.1673 11.7561 21.1673 10.7049 20.498 10.0588C19.8287 9.4127 18.7396 9.4127 18.0703 10.0588Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="square"
            />
            <path
                d="M6.81395 18.9995H3.1072C1.94441 18.9995 1 18.0878 1 16.9654V5.12782C1 4.00539 1.94441 3.09375 3.1072 3.09375H17.211C18.3738 3.09375 19.3182 4.00539 19.3182 5.12782V6.57056"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="square"
            />
            <path d="M1 6.57129H19.321" stroke="white" strokeWidth="1.5" strokeLinecap="square" />
            <path d="M13.5071 3.0943V1" stroke="white" strokeWidth="1.5" strokeLinecap="square" />
            <path d="M6.81396 3.0943V1" stroke="white" strokeWidth="1.5" strokeLinecap="square" />
            <path d="M4.69824 11.2964H10.9319" stroke="white" strokeWidth="1.5" strokeLinecap="square" />
            <path d="M4.69824 15.0083H7.81509" stroke="white" strokeWidth="1.5" strokeLinecap="square" />
        </svg>
    )
}

export const TopArrowIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12" viewBox="0 0 20 12" fill="none">
            <path d="M2 10L10 2L18 10" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}
export const FloatingOnIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="2" viewBox="0 0 18 2" fill="none">
            <path d="M1 1H17" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}

export const TopBigArrowIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="24"
            viewBox="0 0 20 24"
            fill="none"
            style={{
                width: '16px',
                height: '20.5px',
                flexShrink: 0,
            }}
        >
            <path d="M10 22.5V2.5" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M2 10L10 2L18 10" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}

export const FloatingArrowIcon = () => {
    return (
        <>
            <FloatingOnIcon />
            <TopBigArrowIcon />
        </>
    )
}

export const HamburgerIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="24" viewBox="0 0 28 24" fill="none">
            <rect width="28" height="4" fill="#252525" />
            <rect y="10" width="28" height="4" fill="#252525" />
            <rect y="20" width="28" height="4" fill="#252525" />
        </svg>
    )
}

export const CheckBoxIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path
                d="M14.6001 5.15015L6.90015 12.8501L3.40015 9.35015"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="square"
            />
        </svg>
    )
}

export const PhoneIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M38.5907 32.8798C40.7034 34.3908 40.3753 37.6214 38.009 38.69C32.5258 41.1602 25.8544 40.1363 21.3505 35.6234L12.3674 26.6422C7.86352 22.1391 6.83945 15.474 9.3002 9.99186C10.364 7.62106 13.6003 7.29303 15.1116 9.40538L17.6171 12.9144C18.3627 13.9631 18.4025 15.32 17.8259 16.4681C16.6526 18.8091 17.0454 21.7316 18.9991 23.6799L24.3034 28.9831C26.2571 30.9364 29.1801 31.3241 31.5166 30.1561C32.665 29.5796 34.0271 29.6193 35.071 30.3649L38.5857 32.8748L38.5907 32.8798Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
            <path
                d="M24.0001 17.1936C27.7584 17.1936 30.8057 20.2404 30.8057 23.9979"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
            <path
                d="M24.0001 10.7671C31.3078 10.7671 37.2335 16.6916 37.2335 23.9979"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export const ContactIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M25.9548 26.4121C22.8089 26.4121 20.1789 28.5435 19.5315 31.3935C19.3697 32.0974 19.9109 32.772 20.6543 32.772H31.2553C32.0038 32.772 32.5399 32.0974 32.3781 31.3935C31.7256 28.5435 29.0956 26.4121 25.9548 26.4121Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path
                d="M25.9548 22.4946C27.7537 22.4946 29.212 21.0851 29.212 19.3464C29.212 17.6077 27.7537 16.1982 25.9548 16.1982C24.1559 16.1982 22.6977 17.6077 22.6977 19.3464C22.6977 21.0851 24.1559 22.4946 25.9548 22.4946Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <rect x="13" y="9" width="26" height="30" stroke="#252525" strokeWidth="2" />
            <path d="M13 14.228H9" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M13 33.7725H9" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M13 27.2559H9" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M13 20.7441H9" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}
export const SearchIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.8762 33.7526C27.9875 33.7526 33.7524 27.9877 33.7524 20.8763C33.7524 13.7649 27.9875 8 20.8762 8C13.7649 8 8 13.7649 8 20.8763C8 27.9877 13.7649 33.7526 20.8762 33.7526Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path d="M29.9768 29.9768L40 40.0001" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}
export const SearchIcon2 = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.2732 39.9999H13.6275C11.6258 39.9999 10 38.404 10 36.4391V11.5609C10 9.59592 11.6258 8 13.6275 8H32.5269C34.5287 8 36.1545 9.59592 36.1545 11.5609V22.0949"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path
                d="M30.2274 38.5911C33.7246 38.5911 36.5597 35.8081 36.5597 32.3751C36.5597 28.9422 33.7246 26.1592 30.2274 26.1592C26.7301 26.1592 23.8951 28.9422 23.8951 32.3751C23.8951 35.8081 26.7301 38.5911 30.2274 38.5911Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path d="M34.7045 36.77L38 40.0002" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M17.5627 16.4829H28.5966" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}
export const MobileIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M32.2965 8H15.7035C14.2104 8 13 9.20813 13 10.6984V37.3016C13 38.7919 14.2104 40 15.7035 40H32.2965C33.7896 40 35 38.7919 35 37.3016V10.6984C35 9.20813 33.7896 8 32.2965 8Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M13 32.7725H35" stroke="#252525" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <circle cx="24.5" cy="36.5" r="1.5" fill="#252525" />
        </svg>
    )
}
export const LayerIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.8123 9.49233L8.58401 15.7825C7.80533 16.1557 7.80533 17.2348 8.58401 17.6035L21.8123 23.8892C23.1935 24.5456 24.8065 24.5456 26.1877 23.8892L39.416 17.6035C40.1947 17.2303 40.1947 16.1512 39.416 15.7825L26.1877 9.49233C24.8065 8.83589 23.1935 8.83589 21.8123 9.49233Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path
                d="M8.12515 32.0005L21.8123 38.511C23.1889 39.1629 24.8019 39.1629 26.1924 38.511L39.8795 32.0005"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path
                d="M8.12515 24.6899L21.8123 31.2004C23.1889 31.8524 24.8019 31.8524 26.1924 31.2004L39.8795 24.6899"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
        </svg>
    )
}

export const PaperIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.9391 8H33.0609C36.8937 8 40 11.1111 40 14.9391V40H14.9391C11.1063 40 8 36.8889 8 33.0609V14.9391C8 11.1063 11.1111 8 14.9391 8Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path d="M18.1458 18.3132H23.5747" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M18.1458 24H29.859" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M18.1458 29.6921H29.859" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}
export const DocumentIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M28.8466 8H11.8307C9.71523 8 8 9.71594 8 11.8235V36.1677C8 38.2885 9.71523 40 11.8307 40H26.7664C27.6987 40 28.6135 39.7976 29.4447 39.3928C30.1045 39.098 30.7114 38.6712 31.2391 38.1433L38.144 31.2443C38.6718 30.7163 39.0984 30.1004 39.4019 29.4404C39.7977 28.6088 40 27.6937 40 26.7741V19.1448"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path
                d="M39.3975 29.4404C39.094 30.1004 38.6674 30.7164 38.1397 31.2444L31.2348 38.1433C30.707 38.6713 30.1001 39.0981 29.4404 39.3929V29.4448H39.3975V29.4404Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path
                d="M32.9148 10.5871L16.9896 26.5189L16.8268 30.448L20.7543 30.2852L36.6795 14.3534C37.7174 13.315 37.7174 11.6255 36.6795 10.5871C35.6416 9.54876 33.9527 9.54876 32.9148 10.5871Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
        </svg>
    )
}
export const GridViewIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.7012 8H9.2988C8.58149 8 8 8.58149 8 9.2988V20.7012C8 21.4185 8.58149 22 9.2988 22H20.7012C21.4185 22 22 21.4185 22 20.7012V9.2988C22 8.58149 21.4185 8 20.7012 8Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M38.7012 8H27.2988C26.5815 8 26 8.58149 26 9.2988V20.7012C26 21.4185 26.5815 22 27.2988 22H38.7012C39.4185 22 40 21.4185 40 20.7012V9.2988C40 8.58149 39.4185 8 38.7012 8Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M22 38.7012V27.2988C22 26.5815 21.4185 26 20.7012 26H9.2988C8.58149 26 8 26.5815 8 27.2988V38.7012C8 39.4185 8.58149 40 9.2988 40H20.7012C21.4185 40 22 39.4185 22 38.7012Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M40 38.7012V27.2988C40 26.5815 39.4185 26 38.7012 26H27.2988C26.5815 26 26 26.5815 26 27.2988V38.7012C26 39.4185 26.5815 40 27.2988 40H38.7012C39.4185 40 40 39.4185 40 38.7012Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    )
}
export const ListViewIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="9" y="9" width="6" height="6" stroke="#252525" strokeWidth="2" />
            <rect x="9" y="21" width="6" height="6" stroke="#252525" strokeWidth="2" />
            <rect x="9" y="33" width="6" height="6" stroke="#252525" strokeWidth="2" />
            <rect x="20" y="11" width="20" height="2" fill="#252525" />
            <rect x="20" y="23" width="20" height="2" fill="#252525" />
            <rect x="20" y="35" width="20" height="2" fill="#252525" />
        </svg>
    )
}
export const ListIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.7149 15.5H30.7757" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M17.5 15.5H18.5" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M17.5 23.5H18.5" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M22.7149 23.5H30.7757" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M17.5 31.5H18.5" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M22.7149 31.5H30.7757" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path
                d="M34.6168 8H13.3832C12.067 8 11 9.09529 11 10.4464V37.5536C11 38.9047 12.067 40 13.3832 40H34.6168C35.933 40 37 38.9047 37 37.5536V10.4464C37 9.09529 35.933 8 34.6168 8Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
        </svg>
    )
}
export const MemberPlusIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.0502 28.0315C15.177 28.0315 10.2717 32.0424 9.05368 37.4103C8.75153 38.735 9.76186 39.9999 11.1546 39.9999H30.9457C32.3384 39.9999 33.3441 38.735 33.0466 37.4103C31.8333 32.047 26.9233 28.0315 21.0502 28.0315Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path
                d="M21.0501 22.6178C25.1933 22.6178 28.5521 19.3455 28.5521 15.3089C28.5521 11.2723 25.1933 8 21.0501 8C16.9069 8 13.5482 11.2723 13.5482 15.3089C13.5482 19.3455 16.9069 22.6178 21.0501 22.6178Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path d="M39 24.5679H30.771" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M34.8832 20.5571V28.5744" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}
export const CalenderIcon1 = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M38.1908 12.0422H9.80915C8.80999 12.0422 8 12.8049 8 13.7457V38.2965C8 39.2373 8.80999 39.9999 9.80915 39.9999H38.1908C39.19 39.9999 40 39.2373 40 38.2965V13.7457C40 12.8049 39.19 12.0422 38.1908 12.0422Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path d="M8 18.7522H40" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M29.4562 12.0422V8" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M18.5438 12.0422V8" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path
                d="M30.0258 24.9893L21.9946 32.5555L17.9743 28.7746"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
        </svg>
    )
}
export const CalenderIcon2 = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.9888 36.0693H11.1446C9.40934 36.0693 8 34.6361 8 32.8714V16.4906C8 14.7259 9.40934 13.2927 11.1446 13.2927H34.1468C35.882 13.2927 37.2914 14.7259 37.2914 16.4906V18.7588"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path d="M8 18.7588H37.2914" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M27.6376 13.2925V10" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M17.6537 13.2925V10" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path
                d="M32.1492 38.9997C36.485 38.9997 40 35.4252 40 31.0158C40 26.6065 36.485 23.032 32.1492 23.032C27.8133 23.032 24.2983 26.6065 24.2983 31.0158C24.2983 35.4252 27.8133 38.9997 32.1492 38.9997Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path d="M32 26.9998V30.9998L34.5 33.4998" stroke="#252525" strokeWidth="2" />
        </svg>
    )
}
export const CalenderIcon3 = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M35.3126 24.0979L24.5446 34.9253L24.3767 38.9998L28.4289 38.831L39.1969 28.0036C40.2678 26.9268 40.2678 25.1747 39.1969 24.0979C38.126 23.0211 36.3835 23.0211 35.3126 24.0979Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path
                d="M17.3023 38.9998H11.3715C9.51106 38.9998 8 37.4804 8 35.6097V15.8804C8 14.0096 9.51106 12.4902 11.3715 12.4902H33.9376C35.7981 12.4902 37.3091 14.0096 37.3091 15.8804V18.2849"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path d="M8 18.2854H37.3137" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M28.0114 12.4905V9" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M17.3024 12.4905V9" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M13.9172 26.1606H23.8911" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M13.9172 32.3474H18.9042" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}
export const AttatchmentIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M39 23.4173L24.8139 37.4812C21.4264 40.8396 15.9282 40.8396 12.5406 37.4812C9.15312 34.1229 9.15312 28.672 12.5406 25.3137L28.3145 9.671C30.5618 7.443 34.2132 7.443 36.4605 9.671C38.7079 11.899 38.7079 15.5142 36.4605 17.7469L20.96 33.114C19.8528 34.2116 18.053 34.2116 16.9458 33.114C15.8386 32.0163 15.8386 30.2321 16.9458 29.1344L30.9529 15.248"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
        </svg>
    )
}
export const FilterIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M37.9446 14.7485L27.4843 26.2164C27.2996 26.4188 27.1976 26.6804 27.1976 26.9519V38.9135C27.1976 39.8515 26.1088 40.3452 25.4185 39.7231L21.1654 35.8775C20.937 35.6701 20.8057 35.3789 20.8057 35.0679V26.9569C20.8057 26.6854 20.7036 26.4188 20.5189 26.2213L10.0635 14.7485C7.71579 12.1764 9.50939 8 12.9605 8H35.0379C38.489 8 40.2826 12.1764 37.9398 14.7485H37.9446Z"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    )
}
export const FilterIcon2 = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24.0053 16.8639C26.375 16.8639 28.2961 14.8796 28.2961 12.4319C28.2961 9.98425 26.375 8 24.0053 8C21.6356 8 19.7146 9.98425 19.7146 12.4319C19.7146 14.8796 21.6356 16.8639 24.0053 16.8639Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path
                d="M31.5354 28.4317C33.9051 28.4317 35.8261 26.4475 35.8261 23.9998C35.8261 21.5521 33.9051 19.5679 31.5354 19.5679C29.1657 19.5679 27.2446 21.5521 27.2446 23.9998C27.2446 26.4475 29.1657 28.4317 31.5354 28.4317Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path d="M40 12.4373H28.296" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M19.7146 12.4373H8" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M40 24.0056H35.826" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M27.2446 24.0056H8" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path
                d="M16.4753 39.9999C18.845 39.9999 20.766 38.0156 20.766 35.5679C20.766 33.1202 18.845 31.136 16.4753 31.136C14.1056 31.136 12.1846 33.1202 12.1846 35.5679C12.1846 38.0156 14.1056 39.9999 16.4753 39.9999Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path d="M12.1845 35.5627H8" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M40 35.5627H20.766" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}
export const FilterTimeIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.28735 24C9.28735 15.7157 16.1626 9 24.6437 9C33.1247 9 40 15.7157 40 24C40 32.2842 33.1247 39 24.6437 39"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path d="M24.6437 14.5393V23.9999L31 29.5127" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M8 39.0002H24.6437" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M8 33.853H18.2391" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M8 28.7058H14.5707" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}
export const PrintIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.0141 31.3936H30.9859C31.4803 31.3936 31.8806 31.6436 31.8806 31.9524V38.0736C31.8806 38.5854 31.2166 39.0001 30.3972 39.0001H17.6027C16.7834 39.0001 16.1194 38.5854 16.1194 38.0736V31.9524C16.1194 31.6436 16.5197 31.3936 17.0141 31.3936Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path d="M31.9809 23.6633H28.0597" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path
                d="M32.1194 35.6721H38C39.1046 35.6721 40 34.7767 40 33.6721V19.1311C40 18.0265 39.1046 17.1311 38 17.1311H10C8.89543 17.1311 8 18.0265 8 19.1311V33.6721C8 34.7767 8.89543 35.6721 10 35.6721H16.1194"
                stroke="#252525"
                strokeWidth="2"
            />
            <path
                d="M32.3582 17.6066V12C32.3582 10.8954 31.4628 10 30.3582 10H18.1194C17.0148 10 16.1194 10.8954 16.1194 12V17.6066"
                stroke="#252525"
                strokeWidth="2"
            />
        </svg>
    )
}
export const MonitorIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M37.6355 10H10.3645C9.05864 10 8 11.0419 8 12.3272V29.339C8 30.6243 9.05864 31.6662 10.3645 31.6662H37.6355C38.9414 31.6662 40 30.6243 40 29.339V12.3272C40 11.0419 38.9414 10 37.6355 10Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path d="M8 26.3044H40" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M17.1753 38H30.8247" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M24 32.2822V37.9999" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}
export const LockIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M36.2097 17.4873H11.7903C10.2493 17.4873 9 18.7373 9 20.2792V37.2081C9 38.75 10.2493 40 11.7903 40H36.2097C37.7507 40 39 38.75 39 37.2081V20.2792C39 18.7373 37.7507 17.4873 36.2097 17.4873Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path
                d="M18.6332 8H29.372C31.6408 8 33.4871 9.84213 33.4871 12.1174V17.4873H14.5181V12.1174C14.5181 9.84735 16.3592 8 18.6332 8Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path d="M24 26.061V31.4204" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}
export const UnLockIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.1328 40H35.8672C37.5974 40 39 38.832 39 37.3912V21.2565C39 19.8157 37.5974 18.6478 35.8672 18.6478L12.1328 18.6478C10.4026 18.6478 9 19.8157 9 21.2565L9 37.3912C9 38.832 10.4026 40 12.1328 40Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path
                d="M32.8026 11.4595C30.7084 9.34692 27.5416 8 24.0001 8C17.7005 8 12.5927 12.2534 12.5927 17.4993"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path d="M24.0001 27.3154V31.3326" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}
export const RewindIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M40 24C40 32.8365 32.8365 40 24 40C18.6827 40 13.9712 37.4038 11.0625 33.4135"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="bevel"
            />
            <path
                d="M8 24C8 15.1635 15.1635 8 24 8C29.3173 8 34.0288 10.5962 36.9375 14.5865"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="bevel"
            />
            <path
                d="M17.0817 32.5H10.5V39.0865"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="bevel"
            />
            <path
                d="M31.5 16.0865H38.0865V9.5"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="bevel"
            />
        </svg>
    )
}
export const RewindIcon2 = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.6971 32.4136H10.1106V39.0001"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="bevel"
            />
            <path
                d="M8 24C8 15.1635 15.1635 8 24 8C32.8365 8 40 15.1635 40 24C40 32.8365 32.8365 40 24 40H24.0481C18.7308 40 14.0192 37.4038 11.1106 33.4135"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="bevel"
            />
        </svg>
    )
}
export const ShareIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.5086 28.487C14.9986 28.487 17.0172 26.4792 17.0172 24.0025C17.0172 21.5258 14.9986 19.5181 12.5086 19.5181C10.0186 19.5181 8 21.5258 8 24.0025C8 26.4792 10.0186 28.487 12.5086 28.487Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M35.4914 16.9689C37.9814 16.9689 40 14.9612 40 12.4845C40 10.0078 37.9814 8 35.4914 8C33.0014 8 30.9828 10.0078 30.9828 12.4845C30.9828 14.9612 33.0014 16.9689 35.4914 16.9689Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M35.4914 39.9997C37.9814 39.9997 40 37.9919 40 35.5152C40 33.0385 37.9814 31.0308 35.4914 31.0308C33.0014 31.0308 30.9828 33.0385 30.9828 35.5152C30.9828 37.9919 33.0014 39.9997 35.4914 39.9997Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M31.4657 14.5007L16.5343 21.9859"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M31.4657 33.4992L16.5343 26.0188"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
export const DownloadIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.9106 26.2075L23.0332 31.2647C23.5693 31.7939 24.4353 31.7939 24.9714 31.2647L30.0939 26.2075"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path d="M24 31.6627V10" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path
                d="M40 29.8262V34.0601C40 36.2359 38.2131 38 36.0092 38H11.9908C9.78694 38 8 36.2359 8 34.0601V29.8262"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
        </svg>
    )
}
//Delete
export const TrashIcon48 = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 12H36V38.0785C36 39.142 35.0185 40 33.815 40H14.185C12.9757 40 12 39.1369 12 38.0785V12Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path
                d="M9 11.0088H8V13.0088H9V11.0088ZM39 13.0088H40V11.0088H39V13.0088ZM16 8V7H15V8H16ZM32 8H33V7H32V8ZM9 13.0088H39V11.0088H9V13.0088ZM17 11.5V8H15V11.5H17ZM16 9H32V7H16V9ZM31 8V11.5H33V8H31Z"
                fill="#252525"
            />
            <path d="M28 19V31" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M20 31V19" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}
//Delete2
export const DeleteIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24 40C32.8366 40 40 32.8366 40 24C40 15.1634 32.8366 8 24 8C15.1634 8 8 15.1634 8 24C8 32.8366 15.1634 40 24 40Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path d="M27.9087 20.0867L20.0865 27.9088" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M20.0865 20.0867L27.9087 27.9088" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}
// 원형에 + 들어간 모양
export const PlusCircleIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 8V40" stroke="#252525" strokeWidth="2" />
            <path d="M8 24L40 24" stroke="#252525" strokeWidth="2" />
        </svg>
    )
}
export const GraphIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M40 40H13.9192C10.651 40 8 37.3259 8 34.0293V8"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
            <path
                d="M16.8758 21.9692V33.4186"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
            <path
                d="M25.7516 24.9338V33.4186"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
            <path
                d="M34.6216 14.3325V33.4187"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
        </svg>
    )
}
export const GraphIcon2 = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.7067 12.4389C12.906 13.5018 7.73875 19.5157 8.01022 26.6753C8.2817 33.8119 14.1851 39.7153 21.3217 39.9868C28.4859 40.2583 34.4952 35.0956 35.5581 28.2903C35.733 27.163 34.8955 26.1369 33.7544 26.1369H23.6868C22.6791 26.1369 21.8601 25.3179 21.8601 24.3102V14.2426C21.8601 13.1015 20.8294 12.2641 19.7067 12.4389Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M39.7821 20.2197H27.7819V8.21952C27.7819 8.07228 27.9338 7.96185 28.0718 8.01246C33.6486 9.9266 38.075 14.3484 39.9891 19.9298C40.0351 20.0724 39.9293 20.2197 39.7821 20.2197Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    )
}
export const GraphIcon3 = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M37.4877 10H10.5123C9.1248 10 8 11.1287 8 12.521V30.6015C8 31.9938 9.1248 33.1225 10.5123 33.1225H37.4877C38.8752 33.1225 40 31.9938 40 30.6015V12.521C40 11.1287 38.8752 10 37.4877 10Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path
                d="M13.8529 21.6186H17.2026L20.0244 18.7871L26.0003 24.7881L29.0086 21.7693H34.147"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path d="M23.9977 33.1223V37.9998" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M15.3367 38H32.6634" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}
export const GraphIcon4 = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.9087 26.3345H9V40H14.9087V26.3345Z" stroke="#252525" strokeWidth="2" strokeLinecap="round" />
            <path
                d="M26.9544 19.5635H21.0457V40H26.9544V19.5635Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path d="M39 8H33.0913V40H39V8Z" stroke="#252525" strokeWidth="2" strokeLinecap="round" />
        </svg>
    )
}

export const LocationIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24 24.267C26.3388 24.267 28.2348 22.3673 28.2348 20.0239C28.2348 17.6805 26.3388 15.7808 24 15.7808C21.6612 15.7808 19.7652 17.6805 19.7652 20.0239C19.7652 22.3673 21.6612 24.267 24 24.267Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M24 40C26.9979 40 36 30.0979 36 20.0237C36 13.3835 30.6271 8 24 8C17.3729 8 12 13.3835 12 20.0237C12 30.1022 20.9979 40 24 40Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    )
}
export const LocationIcon2 = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24.8747 20.0382C26.6712 20.0382 28.1276 18.6327 28.1276 16.899C28.1276 15.1652 26.6712 13.7598 24.8747 13.7598C23.0782 13.7598 21.6219 15.1652 21.6219 16.899C21.6219 18.6327 23.0782 20.0382 24.8747 20.0382Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path
                d="M24.8748 31.6848C27.18 31.6848 34.0957 24.3556 34.0957 16.8988C34.0957 11.9834 29.9682 8 24.8748 8C19.7814 8 15.6538 11.9834 15.6538 16.8988C15.6538 24.3556 22.5695 31.6848 24.8748 31.6848Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path
                d="M32 25.6438L34.7699 24.3951C37.2574 23.3927 40 25.1557 40 27.7498V34.0106C40 35.4791 39.0843 36.8069 37.6811 37.3741L32.8064 39.3306C30.5786 40.2231 28.082 40.2231 25.8542 39.3306L22.1367 37.8401C19.9226 36.9476 17.4123 36.9476 15.1845 37.8401L13.221 38.6315C10.7335 39.6252 8 37.8621 8 35.2681V29.0072C8 27.5387 8.91572 26.2109 10.3189 25.6438L15.18 23.6873"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="bevel"
            />
        </svg>
    )
}
export const DocumentPlusIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M36 21.2259C36 20.3019 35.7949 19.3912 35.3938 18.5596C35.0862 17.8996 34.6538 17.2837 34.1189 16.7557L27.1204 9.85673C26.5854 9.32875 25.9703 8.90197 25.3016 8.60718C24.4591 8.20239 23.5319 8 22.5869 8H15.8826C13.7385 8 12 9.71594 12 11.8323V36.1765C12 38.2841 13.7385 40 15.8826 40H32.1129C34.2571 40 35.9955 38.2841 35.9955 36.1677V21.2259H36Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path
                d="M35.3938 18.5594C35.0862 17.8994 34.6538 17.2834 34.1189 16.7554L27.1204 9.85649C26.5854 9.32851 25.9703 8.90172 25.3016 8.60693V18.555H35.3938V18.5594Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path d="M27.1783 27.7109H19.6538" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M23.416 24.0024V31.425" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}
export const DocumentCheckIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M36 21.2259C36 20.3019 35.7949 19.3912 35.3938 18.5596C35.0862 17.8996 34.6538 17.2837 34.1189 16.7557L27.1204 9.85673C26.5854 9.32875 25.9703 8.90197 25.3016 8.60718C24.4591 8.20239 23.5319 8 22.5869 8H15.8826C13.7385 8 12 9.71594 12 11.8323V36.1765C12 38.2841 13.7385 40 15.8826 40H32.1129C34.2571 40 35.9955 38.2841 35.9955 36.1677V21.2259H36Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path
                d="M35.3938 18.5594C35.0862 17.8994 34.6538 17.2834 34.1189 16.7554L27.1204 9.85649C26.5854 9.32851 25.9703 8.90172 25.3016 8.60693V18.555H35.3938V18.5594Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path
                d="M29.6122 25.1504L22.1278 32.5377L18.3878 28.8463"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
        </svg>
    )
}

export const LicenseIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.6157 26.1802H22.2167" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M16.6157 20.3579H28.6896" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M16.6157 14.541H28.6896" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path
                d="M23.2266 40H17.2759C13.8128 40 11 37.2136 11 33.7721V14.2279C11 10.7913 13.8079 8 17.2759 8H28.0345C31.4975 8 34.3104 10.7864 34.3104 14.2279V21.9957"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path
                d="M32.4483 36.3972C34.9622 36.3972 37.0001 34.3749 37.0001 31.8802C37.0001 29.3856 34.9622 27.3633 32.4483 27.3633C29.9345 27.3633 27.8966 29.3856 27.8966 31.8802C27.8966 34.3749 29.9345 36.3972 32.4483 36.3972Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path
                d="M28.8226 39.9998L32.4482 36.397L36.0788 39.9998"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="bevel"
            />
        </svg>
    )
}

export const LicenseIcon2 = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23.3952 28.9564C29.2766 28.9564 34.0445 24.2651 34.0445 18.4782C34.0445 12.6912 29.2766 8 23.3952 8C17.5138 8 12.746 12.6912 12.746 18.4782C12.746 24.2651 17.5138 28.9564 23.3952 28.9564Z"
                stroke="#252525"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M23.3952 23.804C26.3847 23.804 28.8082 21.4195 28.8082 18.4779C28.8082 15.5364 26.3847 13.1519 23.3952 13.1519C20.4057 13.1519 17.9822 15.5364 17.9822 18.4779C17.9822 21.4195 20.4057 23.804 23.3952 23.804Z"
                stroke="#252525"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M24.7816 29.2726L19.1435 39.9999L15.6956 33.1239L8 34.3298L13.6381 23.6025"
                stroke="#252525"
                strokeWidth="2"
                strokeLinejoin="round"
            />
            <path
                d="M33.526 22.8354L39.9999 33.0962L32.232 32.4873L29.3386 39.6045L24.0502 31.222"
                stroke="#252525"
                strokeWidth="2"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export const TalkIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.0349 15.6831H23.498" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M17.0349 21.3716H30.9793" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M17.0349 27.0652H30.9793" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path
                d="M8 13.1637V29.5796C8 31.879 9.86436 33.7434 12.1637 33.7434H15.7968C16.9011 33.7434 17.9624 34.1831 18.7416 34.9624L21.0553 37.2761C22.6806 38.9014 25.3194 38.9014 26.9447 37.2761L29.2584 34.9624C30.0376 34.1831 31.0989 33.7434 32.2032 33.7434H35.8363C38.1356 33.7434 40 31.879 40 29.5796V13.1637C40 10.8644 38.1356 9 35.8363 9H12.1637C9.86436 9 8 10.8644 8 13.1637Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
        </svg>
    )
}

export const GraduationIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.1623 10.4204L8.88591 16.8273C7.7047 17.3977 7.7047 19.0892 8.88591 19.6595L22.1623 26.0664C23.324 26.627 24.676 26.627 25.8377 26.0664L39.1141 19.6595C40.2953 19.0892 40.2953 17.3977 39.1141 16.8273L25.8377 10.4204C24.676 9.85986 23.324 9.85986 22.1623 10.4204Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path
                d="M34.505 22.2781V32.4118C34.505 35.4953 31.9946 38.0001 28.893 38.0001H19.117C16.0204 38.0001 13.505 35.5003 13.505 32.4118V22.2781"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path d="M39.505 20.3127V34.6312" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}

export const GroupIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Group">
                <g id="Group_2">
                    <path
                        id="Path"
                        d="M14.6001 5.15015L6.90015 12.8501L3.40015 9.35015"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="square"
                    />
                </g>
            </g>
        </svg>
    )
}

export const PartnerIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.1472 29.0771C12.6898 29.0771 8.95966 32.0678 8.03996 36.069C7.81324 37.059 8.57895 38 9.63554 38H24.663C25.7196 38 26.4853 37.059 26.2586 36.069C25.3346 32.0719 21.6088 29.0771 17.1514 29.0771H17.1472Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
            <path
                d="M17.1471 23.6724C19.5876 23.6724 21.566 21.7802 21.566 19.4462C21.566 17.1121 19.5876 15.22 17.1471 15.22C14.7067 15.22 12.7283 17.1121 12.7283 19.4462C12.7283 21.7802 14.7067 23.6724 17.1471 23.6724Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
            <path
                d="M30.4508 32.78H38.3645C39.4211 32.78 40.1868 31.8391 39.9601 30.849C39.0361 26.8519 35.3103 23.8572 30.8529 23.8572C28.2692 23.8572 25.9336 24.8595 24.2439 26.4837"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
            <path
                d="M30.8485 18.4524C33.289 18.4524 35.2674 16.5603 35.2674 14.2262C35.2674 11.8921 33.289 10 30.8485 10C28.4081 10 26.4297 11.8921 26.4297 14.2262C26.4297 16.5603 28.4081 18.4524 30.8485 18.4524Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export const FileIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M37.9428 10H10.0572C8.92106 10 8 10.9067 8 12.0252V17.3082C8 18.4266 8.92106 19.3334 10.0572 19.3334H37.9428C39.0789 19.3334 40 18.4266 40 17.3082V12.0252C40 10.9067 39.0789 10 37.9428 10Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M37.9428 19.3333H10.0572C8.92106 19.3333 8 20.24 8 21.3585V26.6414C8 27.7599 8.92106 28.6666 10.0572 28.6666H37.9428C39.0789 28.6666 40 27.7599 40 26.6414V21.3585C40 20.24 39.0789 19.3333 37.9428 19.3333Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <rect x="13.5" y="22.5" width="2" height="2" fill="#252525" />
            <rect x="13.5" y="32.5" width="2" height="2" fill="#252525" />
            <path
                d="M37.9428 28.6667H10.0572C8.92106 28.6667 8 29.5735 8 30.6919V35.9749C8 37.0934 8.92106 38.0001 10.0572 38.0001H37.9428C39.0789 38.0001 40 37.0934 40 35.9749V30.6919C40 29.5735 39.0789 28.6667 37.9428 28.6667Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <rect x="13.5" y="13.5" width="2" height="2" fill="#252525" />
        </svg>
    )
}

export const BookmarkIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M25.945 10.1809L29.2101 16.6563C29.5243 17.2819 30.1369 17.7146 30.8399 17.8189L38.1424 18.8564C39.9214 19.1067 40.6299 21.2495 39.3409 22.4747L34.0571 27.5163C33.5457 28.0012 33.3114 28.705 33.4339 29.3932L34.6803 36.5151C34.9839 38.246 33.125 39.5703 31.5377 38.7517L25.0075 35.3889C24.3737 35.0657 23.6227 35.0657 22.9888 35.3889L16.4587 38.7517C14.8714 39.5703 13.0125 38.246 13.3161 36.5151L14.5625 29.3932C14.685 28.705 14.4506 28.0012 13.9393 27.5163L8.6555 22.4747C7.37184 21.2495 8.08025 19.1119 9.85394 18.8564L17.1564 17.8189C17.8648 17.7198 18.472 17.2871 18.7863 16.6563L22.0514 10.1809C22.845 8.60637 25.1407 8.60637 25.9397 10.1809H25.945Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    )
}
export const SettingIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24.0124 30.6035C27.378 30.6035 30.1064 27.8708 30.1064 24.4999C30.1064 21.1289 27.378 18.3962 24.0124 18.3962C20.6467 18.3962 17.9183 21.1289 17.9183 24.4999C17.9183 27.8708 20.6467 30.6035 24.0124 30.6035Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M37.418 24.5C37.418 23.881 37.3686 23.2805 37.2882 22.6862C37.2326 22.2777 37.3191 21.8567 37.5664 21.5225L39.6987 18.6006C40.0633 18.0992 40.1004 17.4307 39.7914 16.8983L38.4687 14.6079C38.1597 14.0693 37.5664 13.7722 36.9483 13.8341L33.345 14.2241C32.9371 14.2674 32.5354 14.1374 32.214 13.8898C31.2622 13.147 30.2053 12.5403 29.0743 12.0761C28.6972 11.9213 28.382 11.6365 28.2152 11.2589L26.7504 7.93474C26.5031 7.37142 25.9407 7 25.3226 7H22.6774C22.0593 7 21.4969 7.36523 21.2496 7.93474L19.7848 11.2589C19.618 11.6365 19.3089 11.9213 18.9257 12.0761C17.7947 12.5403 16.7378 13.1532 15.786 13.8898C15.4646 14.1436 15.0629 14.2674 14.655 14.2241L11.0517 13.8341C10.4398 13.766 9.84029 14.0693 9.53126 14.6079L8.20862 16.8983C7.89959 17.4369 7.93667 18.1054 8.30133 18.6006L10.4336 21.5225C10.6809 21.8567 10.7674 22.2777 10.7118 22.6862C10.6314 23.2805 10.582 23.881 10.582 24.5C10.582 25.119 10.6314 25.7195 10.7118 26.3138C10.7674 26.7223 10.6809 27.1433 10.4336 27.4775L8.30133 30.3994C7.93667 30.9008 7.89959 31.5693 8.20862 32.1017L9.53126 34.3921C9.84029 34.9307 10.4336 35.2278 11.0517 35.1659L14.655 34.7759C15.0629 34.7326 15.4646 34.8626 15.786 35.1102C16.7378 35.853 17.7947 36.4597 18.9257 36.924C19.3028 37.0787 19.618 37.3635 19.7848 37.7411L21.2496 41.0653C21.4969 41.6286 22.0593 42 22.6774 42H25.3226C25.9407 42 26.5031 41.6348 26.7504 41.0653L28.2152 37.7411C28.382 37.3635 28.6911 37.0787 29.0743 36.924C30.2053 36.4597 31.2622 35.8468 32.214 35.1102C32.5354 34.8564 32.9371 34.7326 33.345 34.7759L36.9483 35.1659C37.5602 35.234 38.1597 34.9307 38.4687 34.3921L39.7914 32.1017C40.1004 31.5631 40.0633 30.8946 39.6987 30.3994L37.5664 27.4775C37.3191 27.1433 37.2326 26.7223 37.2882 26.3138C37.3686 25.7195 37.418 25.119 37.418 24.5Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    )
}
//Arrow Top ( ^ ) 모양
export const CloseIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 32L24 16L40 32" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}
// Arrow Bottom
export const OpenIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M40 16L24 32L7.99997 16" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}
// X close btn
export const CloseIcon2 = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M38 10L10 38" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M10 10L38 38" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}
export const PlusIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 8V40" stroke="#252525" strokeWidth="2" />
            <path d="M8 24L40 24" stroke="#252525" strokeWidth="2" />
        </svg>
    )
}
export const TimeIcon48 = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24 40C32.8366 40 40 32.8366 40 24C40 15.1634 32.8366 8 24 8C15.1634 8 8 15.1634 8 24C8 32.8366 15.1634 40 24 40Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path d="M24 24V13.9087" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M24 24H29.7596" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}
export const InfoIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24 40C32.8366 40 40 32.8366 40 24C40 15.1634 32.8366 8 24 8C15.1634 8 8 15.1634 8 24C8 32.8366 15.1634 40 24 40Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path d="M24.0001 26.0053V16.9861" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M24.0001 31V30" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}

export const CommunicationIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.5103 8C25.8638 8 31.0206 13.244 31.0206 19.7048C31.0206 26.1656 25.8638 31.4096 19.5103 31.4096H8V19.7048C8 13.244 13.1568 8 19.5103 8Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path
                d="M36.4956 19.8979C38.6514 22.0226 40 25.0067 40 28.2999V39.9999H28.4849C25.4839 39.9999 22.7583 38.8314 20.7117 36.9288"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
        </svg>
    )
}

export const MoreIcon = () => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18 8C16.8954 8 16 8.89543 16 10C16 11.1046 16.8954 12 18 12C19.1046 12 20 11.1046 20 10C20 8.89543 19.1046 8 18 8Z"
                fill="#252525"
            />
            <path
                d="M18 16C16.8954 16 16 16.8954 16 18C16 19.1046 16.8954 20 18 20C19.1046 20 20 19.1046 20 18C20 16.8954 19.1046 16 18 16Z"
                fill="#252525"
            />
            <path
                d="M18 24C16.8954 24 16 24.8954 16 26C16 27.1046 16.8954 28 18 28C19.1046 28 20 27.1046 20 26C20 24.8954 19.1046 24 18 24Z"
                fill="#252525"
            />
        </svg>
    )
}

export const TimeIcon36 = () => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18 33C26.2843 33 33 26.2843 33 18C33 9.71573 26.2843 3 18 3C9.71573 3 3 9.71573 3 18C3 26.2843 9.71573 33 18 33Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path d="M18 18V8" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M18 18H24" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}

export const MenuHamburgerIcon = () => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="4" y="6" width="28" height="4" fill="#252525" />
            <rect x="4" y="16" width="28" height="4" fill="#252525" />
            <rect x="4" y="26" width="28" height="4" fill="#252525" />
        </svg>
    )
}

// Delete
export const TrashIcon24 = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5 5H19V19.902C19 20.5097 18.4275 21 17.7254 21H6.27459C5.56913 21 5 20.5068 5 19.902V5Z"
                stroke="#252525"
                strokeWidth="1.5"
                strokeLinecap="square"
            />
            <path
                d="M4 4.25H3.25V5.75H4V4.25ZM20 5.75H20.75V4.25H20V5.75ZM8.73333 3V2.25H7.98333V3H8.73333ZM15.2667 3H16.0167V2.25H15.2667V3ZM4 5.75H20V4.25H4V5.75ZM9.48333 4.74616V3H7.98333V4.74616H9.48333ZM8.73333 3.75H15.2667V2.25H8.73333V3.75ZM14.5167 3V4.74616H16.0167V3H14.5167Z"
                fill="#252525"
            />
            <path d="M14 9V17" stroke="#252525" strokeWidth="1.5" strokeLinecap="square" />
            <path d="M10 17V9" stroke="#252525" strokeWidth="1.5" strokeLinecap="square" />
        </svg>
    )
}

// Delete
export const GrayTrashIcon24 = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5 5H19V19.902C19 20.5097 18.4275 21 17.7254 21H6.27459C5.56913 21 5 20.5068 5 19.902V5Z"
                stroke="#C3C3C3"
                strokeWidth="1.5"
                strokeLinecap="square"
            />
            <path
                d="M4 4.25H3.25V5.75H4V4.25ZM20 5.75H20.75V4.25H20V5.75ZM8.73333 3V2.25H7.98333V3H8.73333ZM15.2667 3H16.0167V2.25H15.2667V3ZM4 5.75H20V4.25H4V5.75ZM9.48333 4.74616V3H7.98333V4.74616H9.48333ZM8.73333 3.75H15.2667V2.25H8.73333V3.75ZM14.5167 3V4.74616H16.0167V3H14.5167Z"
                fill="#C3C3C3"
            />
            <path d="M14 9V17" stroke="#C3C3C3" strokeWidth="1.5" strokeLinecap="square" />
            <path d="M10 17V9" stroke="#C3C3C3" strokeWidth="1.5" strokeLinecap="square" />
        </svg>
    )
}
export const ModifyIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="4" fill="white" />
            <path
                d="M18.201 4.4796L4.10025 18.5803L4 21L6.41967 20.8997L20.5204 6.79901C21.1599 6.15955 21.1599 5.11906 20.5204 4.4796C19.8809 3.84013 18.8405 3.84013 18.201 4.4796Z"
                stroke="#252525"
                strokeWidth="1.5"
                strokeLinecap="square"
            />
            <path d="M16.75 6.125L18.875 8.25" stroke="#252525" strokeWidth="1.5" strokeLinecap="square" />
            <path d="M3.5 21H21.5" stroke="#252525" strokeWidth="1.5" />
        </svg>
    )
}
export const AttachmentClipIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20 12.154L12.1732 20.5045C10.3042 22.4985 7.2707 22.4985 5.40173 20.5045C3.53276 18.5105 3.53276 15.274 5.40173 13.28L14.1045 3.99215C15.3445 2.66928 17.359 2.66928 18.5989 3.99215C19.8388 5.31503 19.8388 7.46157 18.5989 8.78722L10.0469 17.9114C9.43601 18.5632 8.44304 18.5632 7.83218 17.9114C7.22132 17.2597 7.22132 16.2003 7.83218 15.5486L15.5602 7.3035"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
        </svg>
    )
}
export const MemberIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.0004 14C8.1011 14 4.84435 16.681 4.03564 20.269C3.83503 21.1545 4.50582 22 5.4305 22H18.5704C19.4951 22 20.1627 21.1545 19.9652 20.269C19.1597 16.6841 15.8998 14 12.0004 14Z"
                stroke="#252525"
                strokeWidth="1.5"
                strokeLinecap="square"
            />
            <path
                d="M12 11.3333C14.5773 11.3333 16.6667 9.244 16.6667 6.66667C16.6667 4.08934 14.5773 2 12 2C9.42267 2 7.33333 4.08934 7.33333 6.66667C7.33333 9.244 9.42267 11.3333 12 11.3333Z"
                stroke="#252525"
                strokeWidth="1.5"
                strokeLinecap="square"
            />
        </svg>
    )
}
// Arrow btm
export const OpenIcon24 = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 8L12 16L3.99999 8" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}
export const DownloadIcon24 = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.57474 13.2612L11.4562 16.151C11.7577 16.4535 12.2448 16.4535 12.5464 16.151L15.4278 13.2612"
                stroke="#252525"
                strokeWidth="1.5"
                strokeLinecap="square"
            />
            <path d="M12 15.4286V4" stroke="#252525" strokeWidth="1.5" strokeLinecap="square" />
            <path
                d="M21 15.3291V17.7485C21 18.9918 19.9948 19.9999 18.7552 19.9999H5.24485C4.00515 19.9999 3 18.9918 3 17.7485V15.3291"
                stroke="#252525"
                strokeWidth="1.5"
                strokeLinecap="square"
            />
        </svg>
    )
}
export const BlueDownloadIcon24 = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.57422 13.2612L11.4557 16.151C11.7572 16.4535 12.2443 16.4535 12.5459 16.151L15.4273 13.2612"
                stroke="#024383"
                strokeWidth="1.5"
                strokeLinecap="square"
            />
            <path d="M12 15.4286V4" stroke="#024383" strokeWidth="1.5" strokeLinecap="square" />
            <path
                d="M21 15.3292V17.7486C21 18.9919 19.9948 20 18.7552 20H5.24485C4.00515 20 3 18.9919 3 17.7486V15.3292"
                stroke="#024383"
                strokeWidth="1.5"
                strokeLinecap="square"
            />
        </svg>
    )
}
export const CalenderIcon24 = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.8693 4.52637H3.13072C2.50624 4.52637 2 5.00302 2 5.591V20.9353C2 21.5233 2.50624 21.9999 3.13072 21.9999H20.8693C21.4938 21.9999 22 21.5233 22 20.9353V5.591C22 5.00302 21.4938 4.52637 20.8693 4.52637Z"
                stroke="#252525"
                strokeWidth="1.5"
                strokeLinecap="square"
            />
            <path d="M2 8.71973H22" stroke="#252525" strokeWidth="1.5" strokeLinecap="square" />
            <path d="M17 4.5264V2" stroke="#252525" strokeWidth="1.5" strokeLinecap="square" />
            <path d="M7 4.5264V2" stroke="#252525" strokeWidth="1.5" strokeLinecap="square" />
            <rect x="5.75" y="12" width="2.5" height="2.5" fill="#252525" />
            <rect x="5.75" y="15.75" width="2.5" height="2.5" fill="#252525" />
            <rect x="10.75" y="12" width="2.5" height="2.5" fill="#252525" />
            <rect x="10.75" y="15.75" width="2.5" height="2.5" fill="#252525" />
            <rect x="15.75" y="12" width="2.5" height="2.5" fill="#252525" />
            <rect x="15.75" y="15.75" width="2.5" height="2.5" fill="#252525" />
        </svg>
    )
}
export const AttendanceIcon24 = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.0703 12.0588L12.3403 18.5552L12.2354 20.9999L14.768 20.8986L21.498 14.4022C22.1673 13.7561 22.1673 12.7049 21.498 12.0588C20.8287 11.4127 19.7397 11.4127 19.0703 12.0588Z"
                stroke="#252525"
                strokeWidth="1.5"
                strokeLinecap="square"
            />
            <path
                d="M7.81395 20.9995H4.1072C2.94441 20.9995 2 20.0878 2 18.9654V7.12782C2 6.00539 2.94441 5.09375 4.1072 5.09375H18.211C19.3738 5.09375 20.3182 6.00539 20.3182 7.12782V8.57056"
                stroke="#252525"
                strokeWidth="1.5"
                strokeLinecap="square"
            />
            <path d="M2 8.57129H20.321" stroke="#252525" strokeWidth="1.5" strokeLinecap="square" />
            <path d="M14.507 5.0943V3" stroke="#252525" strokeWidth="1.5" strokeLinecap="square" />
            <path d="M7.81393 5.0943V3" stroke="#252525" strokeWidth="1.5" strokeLinecap="square" />
            <path d="M5.69821 13.2964H11.9319" stroke="#252525" strokeWidth="1.5" strokeLinecap="square" />
            <path d="M5.69821 17.0083H8.81506" stroke="#252525" strokeWidth="1.5" strokeLinecap="square" />
        </svg>
    )
}

// Icon/24/X
export const CloseIcon24 = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 4L4 20" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M4 4L20 20" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}
//Icon/12/Arrow1
export const ArrowBtmIcon12 = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_554_2779)">
                <g clipPath="url(#clip1_554_2779)">
                    <path
                        d="M1 6.11133L5.20451 10.6443C5.64475 11.1189 6.35804 11.1189 6.79549 10.6443L11 6.11133"
                        stroke="#252525"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                    />
                    <path d="M5.99999 11L5.99998 0" stroke="#252525" strokeWidth="1.5" strokeMiterlimit="10" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_554_2779">
                    <rect width="12" height="12" fill="white" transform="translate(0 12) rotate(-90)" />
                </clipPath>
                <clipPath id="clip1_554_2779">
                    <rect width="12" height="12" fill="white" transform="translate(12 12) rotate(180)" />
                </clipPath>
            </defs>
        </svg>
    )
}
//Icon/12/Arrow2
export const ArrowTopIcon12 = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_554_2770)">
                <g clipPath="url(#clip1_554_2770)">
                    <path
                        d="M11 5.88867L6.79549 1.35575C6.35525 0.881127 5.64196 0.881127 5.20451 1.35575L1 5.88867"
                        stroke="#252525"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                    />
                    <path d="M6.00001 1L6.00001 12" stroke="#252525" strokeWidth="1.5" strokeMiterlimit="10" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_554_2770">
                    <rect width="12" height="12" fill="white" transform="translate(0 12) rotate(-90)" />
                </clipPath>
                <clipPath id="clip1_554_2770">
                    <rect width="12" height="12" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
// Filter/Icon/All
export const ColorDocsIcon = () => {
    return (
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="3" y="6" width="18" height="48" rx="3" fill="#F9A71A" stroke="#252525" strokeWidth="2" />
            <rect x="21" y="6" width="18" height="48" rx="3" fill="#499DD8" stroke="#252525" strokeWidth="2" />
            <rect x="39" y="6" width="18" height="48" rx="3" fill="#C2C2C2" stroke="#252525" strokeWidth="2" />
            <path d="M9 39.1665H15.6667" stroke="#252525" strokeWidth="2" strokeLinecap="round" />
            <path d="M27.0001 39.1665H33.6668" stroke="#252525" strokeWidth="2" strokeLinecap="round" />
            <path d="M44.9999 39.1665H51.6665" stroke="#252525" strokeWidth="2" strokeLinecap="round" />
        </svg>
    )
}
// Filter/Icon/Re
export const ColorDocIcon = () => {
    return (
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.1666 10H52.8271C55.3972 10 57.5 12.4016 57.5 15.3369V50" fill="#F9A71A" />
            <path
                d="M29.1666 10H52.8271C55.3972 10 57.5 12.4016 57.5 15.3369V50"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M52.3341 55H7.64019C4.81308 55 2.5 52.5702 2.5 49.6004V10.3996C2.5 7.42981 4.81308 5 7.64019 5H25.3995L36.7593 20.8207H52.3598C55.1869 20.8207 57.5 23.2505 57.5 26.2203V49.6004C57.5 52.5702 55.1869 55 52.3598 55H52.3341Z"
                fill="#FCD82C"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
// Filter/Icon/Ing
export const ColorDocIngIcon = () => {
    return (
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.1666 10H52.8271C55.3972 10 57.5 12.4016 57.5 15.3369V50" fill="#2480C5" />
            <path
                d="M29.1666 10H52.8271C55.3972 10 57.5 12.4016 57.5 15.3369V50"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M52.3341 55H7.64019C4.81308 55 2.5 52.5702 2.5 49.6004V10.3996C2.5 7.42981 4.81308 5 7.64019 5H25.3995L36.7593 20.8207H52.3598C55.1869 20.8207 57.5 23.2505 57.5 26.2203V49.6004C57.5 52.5702 55.1869 55 52.3598 55H52.3341Z"
                fill="#67ADDD"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M15 43.3335H45" stroke="#252525" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}
// Filter/Icon/Finish
export const ColorDocFinishIcon = () => {
    return (
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.1666 10H52.8271C55.3972 10 57.5 12.4016 57.5 15.3369V50" fill="#7A7A7A" />
            <path
                d="M29.1666 10H52.8271C55.3972 10 57.5 12.4016 57.5 15.3369V50"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M52.3341 55H7.64019C4.81308 55 2.5 52.5702 2.5 49.6004V10.3996C2.5 7.42981 4.81308 5 7.64019 5H25.3995L36.7593 20.8207H52.3598C55.1869 20.8207 57.5 23.2505 57.5 26.2203V49.6004C57.5 52.5702 55.1869 55 52.3598 55H52.3341Z"
                fill="#C2C2C2"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.6666 36.9715L28.1359 43.3335L38.3333 33.3335"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
// Icon/60/Info
export const ColorInfoIcon = () => {
    return (
        <svg width="58" height="60" viewBox="0 0 58 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M42.4729 49.7556L41.8724 49.5102L41.326 49.86C37.48 52.3223 32.9086 53.75 28 53.75C14.331 53.75 3.25 42.669 3.25 29C3.25 15.331 14.331 4.25 28 4.25C41.669 4.25 52.75 15.331 52.75 29C52.75 34.3197 51.0731 39.244 48.2189 43.278L47.8871 43.747L48.027 44.3043L50.1863 52.9083L42.4729 49.7556Z"
                fill="#499DD8"
                stroke="#252525"
                strokeWidth="2.5"
            />
            <path d="M28.0001 32.4465V17" stroke="white" strokeWidth="4" strokeLinecap="square" />
            <path d="M28.0001 40.9997V39.2871" stroke="white" strokeWidth="4" strokeLinecap="square" />
        </svg>
    )
}
// Icon/60/Upload
export const ColorUploadIcon = () => {
    return (
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 23.5449L13.2246 10H46.7753L54 23.5449" fill="#F9A71A" />
            <path d="M6 23.5449L13.2246 10H46.7753L54 23.5449V31.0856H6V23.5449Z" fill="#F9A71A" />
            <path
                d="M6 23.5449L13.2246 10H46.7754L54 23.5449V31.0856H6V23.5449Z"
                stroke="#252525"
                strokeWidth="2.5"
                strokeMiterlimit="10"
            />
            <path
                d="M50.9191 21.4087H37.2246C37.2246 25.418 33.9897 28.6688 30 28.6688C26.0103 28.6688 22.7754 25.418 22.7754 21.4087H9.08087C7.38639 21.4087 6 22.8019 6 24.5047V46.9041C6 48.6069 7.38639 50.0001 9.08087 50.0001H50.9191C52.6136 50.0001 54 48.6069 54 46.9041V24.5047C54 22.8019 52.6136 21.4087 50.9191 21.4087Z"
                fill="#F9E62C"
                stroke="#252525"
                strokeWidth="2.5"
                strokeMiterlimit="10"
            />
            <path d="M20.1885 37.353H39.8136" stroke="#252525" strokeWidth="2.5" strokeMiterlimit="10" />
        </svg>
    )
}
// Icon/60/Calender03
export const ColorCalenderIcon = () => {
    return (
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M28.338 55.0002H9.47872C6.45547 55.0002 4 52.4678 4 49.3499V16.4676C4 13.3497 6.45547 10.8174 9.47872 10.8174H46.1485C49.1718 10.8174 51.6272 13.3497 51.6272 16.4676V27.1593"
                fill="#7BCCB7"
            />
            <path
                d="M28.338 55.0002H9.47872C6.45547 55.0002 4 52.4678 4 49.3499V16.4676C4 13.3497 6.45547 10.8174 9.47872 10.8174H46.1485C49.1718 10.8174 51.6272 13.3497 51.6272 16.4676V27.1593"
                stroke="#252525"
                strokeWidth="2.5"
                strokeLinecap="square"
            />
            <path
                d="M4 16.4676V20.3411H51.6272V16.4676C51.6272 13.3497 49.1718 10.8174 46.1485 10.8174H9.47872C6.45547 10.8174 4 13.3497 4 16.4676Z"
                fill="white"
                stroke="#252525"
                strokeWidth="2.5"
                strokeLinecap="square"
            />
            <path
                d="M48.3829 30.1634L30.8849 48.2091L30.6121 55L37.1968 54.7187L54.6948 36.6729C56.435 34.8783 56.435 31.9581 54.6948 30.1634C52.9546 28.3687 50.1231 28.3687 48.3829 30.1634Z"
                fill="#00A886"
                stroke="#252525"
                strokeWidth="2.5"
                strokeLinecap="square"
            />
            <path d="M4 20.4756H51.6346" stroke="#252525" strokeWidth="2.5" strokeLinecap="square" />
            <path d="M36.5183 10.8175V5" stroke="#252525" strokeWidth="2.5" strokeLinecap="square" />
            <path d="M19.1162 10.8175V5" stroke="#252525" strokeWidth="2.5" strokeLinecap="square" />
            <path d="M13.6155 33.6006H29.823" stroke="#252525" strokeWidth="2.5" strokeLinecap="square" />
            <path d="M13.6155 43.9126H21.7193" stroke="#252525" strokeWidth="2.5" strokeLinecap="square" />
        </svg>
    )
}
// Icon/60/contact
export const ColorContactIcon = () => {
    return (
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect
                x="10.8351"
                y="4.8252"
                width="44.1649"
                height="50.0834"
                fill="#499DD8"
                stroke="#252525"
                strokeWidth="2"
            />
            <path
                d="M33.6664 32.4141C27.1654 32.4141 21.7305 36.8251 20.3927 42.7234C20.0582 44.1803 21.1766 45.5764 22.713 45.5764H44.6198C46.1667 45.5764 47.2746 44.1803 46.9401 42.7234C45.5918 36.8251 40.1569 32.4141 33.6664 32.4141Z"
                fill="white"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path
                d="M33.6667 28.0266C37.3486 28.0266 40.3333 25.0801 40.3333 21.4454C40.3333 17.8108 37.3486 14.8643 33.6667 14.8643C29.9848 14.8643 27 17.8108 27 21.4454C27 25.0801 29.9848 28.0266 33.6667 28.0266Z"
                fill="white"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path d="M10.4444 13.7681H6" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M10.4444 45.5767H6" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M10.4444 35.7056H6" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path d="M10.4444 24.7358H6" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}
// Icon/60/licence
export const ColorLicenseIcon = () => {
    return (
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 55V5H47V44.9263L37.5664 55H8Z" fill="#FF6F4B" stroke="#252525" strokeWidth="2" />
            <path
                d="M43.7029 48.2447C47.9571 48.2447 51.4058 44.8321 51.4058 40.6224C51.4058 36.4126 47.9571 33 43.7029 33C39.4487 33 36 36.4126 36 40.6224C36 44.8321 39.4487 48.2447 43.7029 48.2447Z"
                fill="#FEF59B"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path
                d="M37.5671 54.3239L43.7028 48.2441L49.8468 54.3239"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="bevel"
            />
            <path d="M17.5037 34.7759H26.9822" stroke="white" strokeWidth="3" strokeLinecap="square" />
            <path d="M17.5037 24.9512H37.9364" stroke="white" strokeWidth="3" strokeLinecap="square" />
            <path d="M17.5037 15.1348H37.9364" stroke="white" strokeWidth="3" strokeLinecap="square" />
        </svg>
    )
}
// Icon/60/people
export const ColorPeopleIcon = () => {
    return (
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M29.9999 24.6C35.7576 24.6 40.425 19.7647 40.425 13.8C40.425 7.83534 35.7576 3 29.9999 3C24.2423 3 19.5748 7.83534 19.5748 13.8C19.5748 19.7647 24.2423 24.6 29.9999 24.6Z"
                fill="#499DD8"
                stroke="#252525"
                strokeWidth="2"
                strokeLinejoin="round"
            />
            <path
                d="M17.3306 5.69727C12.2912 5.69727 8.20728 9.92809 8.20728 15.1486C8.20728 20.3692 12.2912 24.6 17.3306 24.6"
                stroke="#161616"
                strokeWidth="2"
                strokeLinejoin="round"
            />
            <path
                d="M42.6681 24.6C47.7074 24.6 51.7914 20.3692 51.7914 15.1486C51.7914 9.92809 47.7074 5.69727 42.6681 5.69727"
                stroke="#161616"
                strokeWidth="2"
                strokeLinejoin="round"
            />
            <path
                d="M45.643 57V43.5027C45.643 37.5425 40.9711 32.7026 35.2179 32.7026H24.7928C19.0396 32.7026 14.3677 37.5425 14.3677 43.5027V57H45.643Z"
                fill="#499DD8"
                stroke="#252525"
                strokeWidth="2"
                strokeLinejoin="round"
            />
            <path
                d="M46.575 32.7026C52.3282 32.7026 57.0001 37.5425 57.0001 43.5027V51.6054"
                stroke="#161616"
                strokeWidth="2"
                strokeLinejoin="round"
            />
            <path
                d="M13.4251 32.7026C7.67188 32.7026 3 37.5425 3 43.5027V51.6054"
                stroke="#161616"
                strokeWidth="2"
                strokeLinejoin="round"
            />
        </svg>
    )
}
// Icon/60/location2
export const ColorLocationIcon = () => {
    return (
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M47.5011 30.642L41 34L18.3 28.7002L7.76822 32.6711C5.48804 33.5927 4 35.7504 4 38.1367V48.3106C4 52.5259 8.44191 55.3908 12.4841 53.7762L15.6748 52.4901C19.295 51.0398 23.3741 51.0398 26.9721 52.4901L33.0131 54.9122C36.6333 56.3625 40.6902 56.3625 44.3104 54.9122L52.2318 51.7328C54.512 50.8112 56 48.6535 56 46.2672V36.0933C56 31.878 51.5433 29.0131 47.5011 30.642Z"
                fill="#8FC4E7"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="bevel"
            />
            <path
                d="M31.4214 42.4878C35.1674 42.4878 46.4055 30.5778 46.4055 18.4606C46.4055 10.473 39.6982 4 31.4214 4C23.1447 4 16.4374 10.473 16.4374 18.4606C16.4374 30.5778 27.6754 42.4878 31.4214 42.4878Z"
                fill="#2D8DD2"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path
                d="M31.4215 23.5618C34.3408 23.5618 36.7074 21.2779 36.7074 18.4606C36.7074 15.6433 34.3408 13.3594 31.4215 13.3594C28.5022 13.3594 26.1356 15.6433 26.1356 18.4606C26.1356 21.2779 28.5022 23.5618 31.4215 23.5618Z"
                fill="white"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
        </svg>
    )
}
// Icon/48/Graduation
export const ColorGraduationIcon = () => {
    return (
        <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M47.5554 26.0479V43.4198C47.5554 48.7058 43.2407 52.9997 37.9097 52.9997H21.1073C15.785 52.9997 11.4617 48.7144 11.4617 43.4198V26.0479"
                fill="#F9A71A"
            />
            <path
                d="M47.5554 26.0479V43.4198C47.5554 48.7058 43.2407 52.9997 37.9097 52.9997H21.1073C15.785 52.9997 11.4617 48.7144 11.4617 43.4198V26.0479"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path d="M56.1492 22.6792V47.2249" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
            <path
                d="M26.3414 5.72069L3.52265 16.7039C1.49245 17.6817 1.49245 20.5813 3.52265 21.5591L26.3414 32.5423C28.3381 33.5032 30.6619 33.5032 32.6585 32.5423L55.4773 21.5591C57.5075 20.5813 57.5075 17.6817 55.4773 16.7039L32.6585 5.72069C30.6619 4.75977 28.3381 4.75977 26.3414 5.72069Z"
                fill="#FCD82C"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
        </svg>
    )
}

// Icon/24/Expect/Off
export const OffFilterIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M20.9336 22H3.05607C1.92523 22 1 21.0281 1 19.8402V4.15983C1 2.97192 1.92523 2 3.05607 2H10.1598L14.7037 8.32829H20.9439C22.0748 8.32829 23 9.30022 23 10.4881V19.8402C23 21.0281 22.0748 22 20.9439 22H20.9336Z"
                stroke="#F9A71A"
                strokeWidth="1.5"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
            <path
                d="M12 4H21.1308C22.1589 4 23 4.96064 23 6.13476V20"
                stroke="#F9A71A"
                strokeWidth="1.5"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
        </svg>
    )
}

// Icon/24/Ongoing/Off
export const OngoingFilterIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M20.9336 22H3.05607C1.92523 22 1 21.0281 1 19.8402V4.15983C1 2.97192 1.92523 2 3.05607 2H10.1598L14.7037 8.32829H20.9439C22.0748 8.32829 23 9.30022 23 10.4881V19.8402C23 21.0281 22.0748 22 20.9439 22H20.9336Z"
                stroke="#2D8DD2"
                strokeWidth="1.5"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
            <path
                d="M12 4H21.1308C22.1589 4 23 4.96064 23 6.13476V20"
                stroke="#2D8DD2"
                strokeWidth="1.5"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
            <path d="M7 16H17" stroke="#2D8DD2" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
        </svg>
    )
}

// Icon/24/Cancel/Off
export const CancelFilterIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect x="5" y="4" width="20.7821" height="1.5" transform="rotate(45 5 4)" fill="#E5E5E5" />
            <circle cx="12" cy="12" r="10.25" stroke="#E5E5E5" strokeWidth="1.5" />
        </svg>
    )
}

// Icon/24/Short/Off
export const ShortFilterIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0_992_2070)">
                <path
                    d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
                    stroke="#00A886"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                />
                <path opacity="0.4" d="M19 12C19 8.13333 15.8667 5 12 5V12H19Z" fill="#00A886" />
                <path d="M12 5V12H19" stroke="#00A886" strokeWidth="1.5" strokeLinecap="round" />
            </g>
            <defs>
                <clipPath id="clip0_992_2070">
                    <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 24 0)" />
                </clipPath>
            </defs>
        </svg>
    )
}

// Icon/24/Middle/Off
export const MiddleFilterIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0_992_4766)">
                <path
                    d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
                    stroke="#00A886"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                />
                <path
                    opacity="0.4"
                    d="M12 19C15.8667 19 19 15.8667 19 12C19 8.13333 15.8667 5 12 5V18.9926V19Z"
                    fill="#00A886"
                />
                <path d="M12 5V19" stroke="#00A886" strokeWidth="1.5" strokeLinecap="round" />
            </g>
            <defs>
                <clipPath id="clip0_992_4766">
                    <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 24 0)" />
                </clipPath>
            </defs>
        </svg>
    )
}

// Icon/24/Long/Off
export const LongFilterIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0_992_1749)">
                <path
                    d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
                    stroke="#00A886"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                />
                <path
                    opacity="0.4"
                    d="M5 12C5 15.8667 8.13333 19 12 19C15.8667 19 19 15.8667 19 12C19 8.13333 15.8667 5 12 5V12H5Z"
                    fill="#00A886"
                />
                <path d="M12 5V12H5" stroke="#00A886" strokeWidth="1.5" strokeLinecap="round" />
            </g>
            <defs>
                <clipPath id="clip0_992_1749">
                    <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 24 0)" />
                </clipPath>
            </defs>
        </svg>
    )
}

// Icon/24/contract/Off
export const ContractIcon = () => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="36" height="36" rx="4" fill="none" />
            <path
                d="M29 16.7659V25.4487C29 27.4108 27.4108 29 25.4487 29H10.5513C8.58918 29 7 27.4108 7 25.4487V10.5513C7 8.58918 8.58918 7 10.5513 7H19.2341"
                stroke="#F9A71A"
                strokeWidth="1.5"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
            <path
                d="M14 22L14.7273 17.4373L24.4501 7.71443C25.4458 6.71877 27.1167 6.77072 28.173 7.82699C29.2293 8.88325 29.2812 10.5542 28.2856 11.5499L18.5627 21.2727L14 22Z"
                stroke="#F9A71A"
                strokeWidth="1.5"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
        </svg>
    )
}

// Icon/24/expired/Off
export const ExpiredIcon = () => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="36" height="36" rx="4" fill="none" />
            <g clipPath="url(#clip0_1054_32393)">
                <path
                    d="M14.8552 17.9953L9.96631 23.9787V29.0533H17.9953H26.0339V23.9787L21.1354 17.9953L26.0339 12.0119V6.94678H17.9953H9.96631V12.0119L14.8552 17.9953Z"
                    stroke="#7A7A7A"
                    strokeWidth="1.5"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.1064 25.4839H20.8939"
                    stroke="#7A7A7A"
                    strokeWidth="1.5"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_1054_32393">
                    <rect width="18" height="24" fill="white" transform="translate(9 6)" />
                </clipPath>
            </defs>
        </svg>
    )
}

// Icon/24/class/Off
export const ClassIcon = () => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="36" height="36" rx="4" fill="none" />
            <g clipPath="url(#clip0_1054_32390)">
                <path
                    d="M18.0003 20.1997C15.3195 20.1997 13.0805 22.0429 12.5245 24.5097C12.3866 25.1184 12.8478 25.6997 13.4835 25.6997H22.5171C23.1528 25.6997 23.6119 25.1184 23.4761 24.5097C22.9223 22.045 20.6811 20.1997 18.0003 20.1997Z"
                    stroke="#2D8DD2"
                    strokeWidth="1.5"
                    strokeLinecap="square"
                />
                <path
                    d="M18.0002 17.9999C19.8227 17.9999 21.3002 16.5224 21.3002 14.6999C21.3002 12.8774 19.8227 11.3999 18.0002 11.3999C16.1777 11.3999 14.7002 12.8774 14.7002 14.6999C14.7002 16.5224 16.1777 17.9999 18.0002 17.9999Z"
                    stroke="#2D8DD2"
                    strokeWidth="1.5"
                    strokeLinecap="square"
                />
                <path
                    d="M11.7706 7H24.2294C26.8644 7 29 9.13889 29 11.7706V29H11.7706C9.1356 29 7 26.8611 7 24.2294V11.7706C7 9.1356 9.13889 7 11.7706 7Z"
                    stroke="#2D8DD2"
                    strokeWidth="1.5"
                    strokeLinecap="square"
                />
            </g>
            <defs>
                <clipPath id="clip0_1054_32390">
                    <rect width="24" height="24" fill="white" transform="translate(6 6)" />
                </clipPath>
            </defs>
        </svg>
    )
}

// Frame 20175 (Icon/24/Finish/Off)
export const FinishFilterBottomIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M20.9336 22H3.05607C1.92523 22 1 21.0281 1 19.8402V4.15983C1 2.97192 1.92523 2 3.05607 2H10.1598L14.7037 8.32829H20.9439C22.0748 8.32829 23 9.30022 23 10.4881V19.8402C23 21.0281 22.0748 22 20.9439 22H20.9336Z"
                stroke="#7A7A7A"
                strokeWidth="1.5"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
            <path
                d="M12 4H21.1308C22.1589 4 23 4.96064 23 6.13476V20"
                stroke="#7A7A7A"
                strokeWidth="1.5"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
            <path
                d="M8 13.819L11.1053 17L16 12"
                stroke="#7A7A7A"
                strokeWidth="1.5"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export const CheckFilterIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M5.62069 8.2971L13.4828 16.3243L10.8621 19L3 10.9728L5.62069 8.2971Z" fill="#E5E5E5" />
            <path d="M8.28793 16.3243L19.3793 5L22 7.67572L10.9086 19L8.28793 16.3243Z" fill="#E5E5E5" />
        </svg>
    )
}

// Group 20196 (Icon/48/Open)
export const OpenFilterIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="20" viewBox="0 0 36 20" fill="none">
            <path d="M34 2L18 18L2 2" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}

// Group 20174 (Icon/48/Re2)
export const FilterResetIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
            <path
                d="M9.69689 25.4136H3.11035V32.0001"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="bevel"
            />
            <path
                d="M1 17C1 8.16346 8.16346 1 17 1C25.8365 1 33 8.16346 33 17C33 25.8365 25.8365 33 17 33H17.0481C11.7308 33 7.01923 30.4038 4.11058 26.4135"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="bevel"
            />
        </svg>
    )
}

// Group 20152 (Icon/48/Search)
export const SearchFilterIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
            <path
                d="M13.8762 26.7526C20.9875 26.7526 26.7524 20.9877 26.7524 13.8763C26.7524 6.76492 20.9875 1 13.8762 1C6.76488 1 1 6.76492 1 13.8763C1 20.9877 6.76488 26.7526 13.8762 26.7526Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path d="M22.9766 22.9771L32.9998 33.0003" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}

// Icon/48/GridView
export const GridViewFilterIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
            <path
                d="M13.7012 1H2.2988C1.58149 1 1 1.58149 1 2.2988V13.7012C1 14.4185 1.58149 15 2.2988 15H13.7012C14.4185 15 15 14.4185 15 13.7012V2.2988C15 1.58149 14.4185 1 13.7012 1Z"
                stroke="#C2C2C2"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M31.7012 1H20.2988C19.5815 1 19 1.58149 19 2.2988V13.7012C19 14.4185 19.5815 15 20.2988 15H31.7012C32.4185 15 33 14.4185 33 13.7012V2.2988C33 1.58149 32.4185 1 31.7012 1Z"
                stroke="#C2C2C2"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M15 31.7012V20.2988C15 19.5815 14.4185 19 13.7012 19H2.2988C1.58149 19 1 19.5815 1 20.2988V31.7012C1 32.4185 1.58149 33 2.2988 33H13.7012C14.4185 33 15 32.4185 15 31.7012Z"
                stroke="#C2C2C2"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M33 31.7012V20.2988C33 19.5815 32.4185 19 31.7012 19H20.2988C19.5815 19 19 19.5815 19 20.2988V31.7012C19 32.4185 19.5815 33 20.2988 33H31.7012C32.4185 33 33 32.4185 33 31.7012Z"
                stroke="#C2C2C2"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    )
}

// Group 20123 (Icon/48/ListView)
export const ListViewFilterIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <rect x="1" y="1" width="6" height="6" stroke="#C2C2C2" strokeWidth="2" />
            <rect x="1" y="13" width="6" height="6" stroke="#C2C2C2" strokeWidth="2" />
            <rect x="1" y="25" width="6" height="6" stroke="#C2C2C2" strokeWidth="2" />
            <rect x="12" y="3" width="20" height="2" fill="#C2C2C2" />
            <rect x="12" y="15" width="20" height="2" fill="#C2C2C2" />
            <rect x="12" y="27" width="20" height="2" fill="#C2C2C2" />
        </svg>
    )
}

export const LoginRightSideIcon = () => {
    return (
        <svg width="280" height="396" viewBox="0 0 280 396" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_843_4889)">
                <path
                    d="M47.0137 51.3851C55.862 51.3851 64.0738 53.8582 70.8469 58.5365C75.4429 53.31 81.7577 49.5113 88.9255 48.3257C88.6327 46.3116 88.4671 44.1956 88.4671 42.0922C88.4799 18.8663 107.297 0 130.494 0C153.69 0 172.507 18.8153 172.507 42.0412C172.507 45.4703 172.087 48.7847 171.31 51.9715C174.417 51.2322 177.651 51.3851 180.986 51.3851C204.183 51.3851 223 68.6962 223 93.9873H5C5 68.6835 23.8043 51.3724 47.0137 51.3724V51.3851Z"
                    fill="#18AFFE"
                />
            </g>
            <g clipPath="url(#clip1_843_4889)">
                <path
                    d="M117.917 396V287.752H214.392V288.603V316.458L280 262.094L214.567 208L214.554 237.333H72.3595V345.582H0V396H117.917Z"
                    fill="#F1F1F1"
                />
                <path d="M146.056 366.652H164.811V371.957H146.056V366.652Z" fill="#252525" />
                <path d="M172.858 366.652H237.17V371.957H172.858V366.652Z" fill="#252525" />
                <path d="M146.056 353.308H164.811V358.612H146.056V353.308Z" fill="#252525" />
                <path d="M172.858 353.308H237.17V358.612H172.858V353.308Z" fill="#252525" />
                <path d="M146.056 339.963H164.811V345.268H146.056V339.963Z" fill="#252525" />
                <path d="M172.858 339.963H237.17V345.268H172.858V339.963Z" fill="#252525" />
                <path d="M146.056 326.619H164.811V331.924H146.056V326.619Z" fill="#252525" />
                <path d="M172.858 326.619H237.17V331.924H172.858V326.619Z" fill="#252525" />
                <path d="M146.055 286.272H237.17V317.414H146.055V286.272Z" fill="#C2C2C2" />
            </g>
            <defs>
                <clipPath id="clip0_843_4889">
                    <rect width="218" height="94" fill="white" transform="translate(5)" />
                </clipPath>
                <clipPath id="clip1_843_4889">
                    <rect width="280" height="188" fill="white" transform="matrix(-1 0 0 1 280 208)" />
                </clipPath>
            </defs>
        </svg>
    )
}
export const LoginLeftSideIcon = () => {
    return (
        <svg width="452" height="698" viewBox="0 0 452 698" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_843_4888)">
                <path
                    d="M179.449 478V369.752H72.6378V370.603V398.458L0 344.094L72.4435 290L72.4584 319.333H229.888V427.582H310V478H179.449Z"
                    fill="#F1F1F1"
                />
                <path d="M148.296 448.652H127.531V453.957H148.296V448.652Z" fill="#252525" />
                <path d="M118.621 448.652H47.418V453.957H118.621V448.652Z" fill="#252525" />
                <path d="M148.296 435.308H127.531V440.612H148.296V435.308Z" fill="#252525" />
                <path d="M118.621 435.308H47.418V440.612H118.621V435.308Z" fill="#252525" />
                <path d="M148.296 421.963H127.531V427.268H148.296V421.963Z" fill="#252525" />
                <path d="M118.621 421.963H47.418V427.268H118.621V421.963Z" fill="#252525" />
                <path d="M148.296 408.619H127.531V413.924H148.296V408.619Z" fill="#252525" />
                <path d="M118.621 408.619H47.418V413.924H118.621V408.619Z" fill="#252525" />
                <path d="M148.295 368.272H47.418V399.414H148.295V368.272Z" fill="#C2C2C2" />
            </g>
            <rect x="11" y="695" width="273" height="3" fill="#252525" />
            <g clipPath="url(#clip1_843_4888)">
                <path d="M149.019 628.85H111.779V696.12H149.019V628.85Z" fill="white" />
                <path
                    d="M149.02 697.9H111.78C110.8 697.9 110 697.1 110 696.12V628.85C110 627.87 110.8 627.07 111.78 627.07H149.02C150 627.07 150.8 627.87 150.8 628.85V696.12C150.8 697.1 150 697.9 149.02 697.9ZM113.56 694.34H147.24V630.62H113.56V694.34Z"
                    fill="#252525"
                />
                <path d="M186.26 597.62H149.02V696.13H186.26V597.62Z" fill="#F9A71A" />
                <path
                    d="M186.26 697.9H149.02C148.04 697.9 147.24 697.1 147.24 696.12V597.62C147.24 596.64 148.04 595.84 149.02 595.84H186.26C187.24 595.84 188.04 596.64 188.04 597.62V696.13C188.04 697.11 187.24 697.91 186.26 697.91V697.9ZM150.8 694.34H184.49V599.39H150.8V694.34Z"
                    fill="#024383"
                />
                <path d="M223.5 562.78H186.26V696.12H223.5V562.78Z" fill="#18AFFE" />
                <path
                    d="M223.5 697.9H186.26C185.28 697.9 184.48 697.1 184.48 696.12V562.78C184.48 561.8 185.28 561 186.26 561H223.5C224.48 561 225.28 561.8 225.28 562.78V696.12C225.28 697.1 224.48 697.9 223.5 697.9ZM188.04 694.34H221.72V564.56H188.04V694.35V694.34Z"
                    fill="#252525"
                />
                <path
                    d="M206.06 684.11H202.51V682.66H206.06V684.11ZM206.06 673.77H202.51V664.88H206.06V673.77ZM206.06 655.99H202.51V647.1H206.06V655.99ZM206.06 638.21H202.51V629.32H206.06V638.21ZM206.06 620.43H202.51V611.54H206.06V620.43ZM206.06 602.65H202.51V593.76H206.06V602.65ZM206.06 584.87H202.51V575.98H206.06V584.87Z"
                    fill="#252525"
                />
                <path
                    d="M168.82 675.45H165.26V666.56H168.82V675.45ZM168.82 657.67H165.26V648.78H168.82V657.67ZM168.82 639.89H165.26V631H168.82V639.89ZM168.82 622.11H165.26V613.22H168.82V622.11Z"
                    fill="#252525"
                />
                <path
                    d="M131.58 678.34H128.02V669.45H131.58V678.34ZM131.58 660.56H128.02V651.67H131.58V660.56Z"
                    fill="#252525"
                />
            </g>
            <g clipPath="url(#clip2_843_4888)">
                <path
                    d="M374.136 94.1255C357.735 94.1255 342.532 99.218 329.972 107.895C321.456 98.2123 309.741 91.4509 296.453 89.2363C296.999 85.5132 297.288 81.7152 297.288 77.8424C297.298 34.8451 262.442 0 219.445 0C176.447 0 141.591 34.8558 141.591 77.8424C141.591 84.1866 142.372 90.3382 143.806 96.2438C138.05 94.8851 132.037 94.1255 125.864 94.1255C82.856 94.1255 48 128.072 48 171.957H452C452 128.072 417.144 94.1362 374.136 94.1362V94.1255Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_843_4888">
                    <rect width="310" height="188" fill="white" transform="translate(0 290)" />
                </clipPath>
                <clipPath id="clip1_843_4888">
                    <rect width="115.28" height="136.9" fill="white" transform="translate(110 561)" />
                </clipPath>
                <clipPath id="clip2_843_4888">
                    <rect width="404" height="172" fill="white" transform="translate(48)" />
                </clipPath>
            </defs>
        </svg>
    )
}

//frame 20177 icon
export const UsrIcon = () => {
    return (
        <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.0006 20.8C13.3953 20.8 8.71375 24.5534 7.55124 29.5766C7.26286 30.8163 8.22711 32 9.55634 32H28.4449C29.7741 32 30.7339 30.8163 30.45 29.5766C29.292 24.5577 24.6059 20.8 19.0006 20.8Z"
                stroke="#7A7A7A"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path
                d="M18.9998 17.0667C22.7047 17.0667 25.7082 14.1416 25.7082 10.5333C25.7082 6.92507 22.7047 4 18.9998 4C15.2949 4 12.2915 6.92507 12.2915 10.5333C12.2915 14.1416 15.2949 17.0667 18.9998 17.0667Z"
                stroke="#7A7A7A"
                strokeWidth="2"
                strokeLinecap="square"
            />
        </svg>
    )
}

export const PeopleIcon = () => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.5006 20.7999C12.8953 20.7999 8.21375 24.5533 7.05124 29.5765C6.76286 30.8162 7.72711 31.9999 9.05634 31.9999H27.9449C29.2741 31.9999 30.2339 30.8162 29.95 29.5765C28.792 24.5576 24.1059 20.7999 18.5006 20.7999Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path
                d="M18.4993 17.0665C22.2043 17.0665 25.2077 14.1415 25.2077 10.5332C25.2077 6.92495 22.2043 3.99988 18.4993 3.99988C14.7944 3.99988 11.791 6.92495 11.791 10.5332C11.791 14.1415 14.7944 17.0665 18.4993 17.0665Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
        </svg>
    )
}

export const GraduationIcon36 = () => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.9494 6.61338L4.74748 11.9857C3.75084 12.4639 3.75084 13.8822 4.74748 14.3605L15.9494 19.7328C16.9296 20.2028 18.0704 20.2028 19.0506 19.7328L30.2525 14.3605C31.2492 13.8822 31.2492 12.4639 30.2525 11.9857L19.0506 6.61338C18.0704 6.14336 16.9296 6.14336 15.9494 6.61338Z"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path
                d="M26.3633 16.5561V25.0533C26.3633 27.6389 24.2451 29.7392 21.6281 29.7392H13.3797C10.7669 29.7392 8.64453 27.6431 8.64453 25.0533V16.5561"
                stroke="#252525"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path d="M30.582 14.9088V26.9149" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}
////frame 20178 icon
export const TelPhoneIcon = () => {
    return (
        <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M29.4894 24.8824C31.0079 25.9684 30.7721 28.2904 29.0713 29.0584C25.1302 30.8339 20.3352 30.098 17.098 26.8543L10.6414 20.399C7.40423 17.1625 6.66818 12.372 8.43685 8.43165C9.20148 6.72764 11.5275 6.49186 12.6138 8.01011L14.4146 10.5322C14.9505 11.286 14.9791 12.2612 14.5647 13.0864C13.7214 14.769 14.0037 16.8696 15.4079 18.2699L19.2204 22.0816C20.6246 23.4856 22.7255 23.7642 24.4049 22.9247C25.2303 22.5103 26.2093 22.5389 26.9596 23.0747L29.4858 24.8788L29.4894 24.8824Z"
                stroke="#7A7A7A"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
            <path
                d="M19.0024 13.6078C21.7037 13.6078 23.894 15.7977 23.894 18.4984"
                stroke="#7A7A7A"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
            <path
                d="M19.0024 8.98907C24.2549 8.98907 28.5139 13.2473 28.5139 18.4987"
                stroke="#7A7A7A"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
        </svg>
    )
}

////frame 20179 icon
export const MailInfoIcon = () => {
    return (
        <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M29.4285 9H8.57148C7.97972 9 7.5 9.47056 7.5 10.051V25.199C7.5 25.7794 7.97972 26.25 8.57148 26.25H29.4285C30.0203 26.25 30.5 25.7794 30.5 25.199V10.051C30.5 9.47056 30.0203 9 29.4285 9Z"
                stroke="#7A7A7A"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path
                d="M8.29346 9.65619L17.384 17.3414C18.311 18.131 19.6829 18.131 20.6127 17.347L29.7117 9.66175"
                stroke="#7A7A7A"
                strokeWidth="2"
                strokeLinecap="square"
            />
        </svg>
    )
}

////frame 20180 icon
export const AddressIcon = () => {
    return (
        <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.5002 17.8639C20.4492 17.8639 22.0292 16.2448 22.0292 14.2476C22.0292 12.2504 20.4492 10.6313 18.5002 10.6313C16.5512 10.6313 14.9712 12.2504 14.9712 14.2476C14.9712 16.2448 16.5512 17.8639 18.5002 17.8639Z"
                stroke="#7A7A7A"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M18.5 31.2727C20.9982 31.2727 28.5 22.8334 28.5 14.2474C28.5 8.58824 24.0225 4 18.5 4C12.9775 4 8.5 8.58824 8.5 14.2474C8.5 22.8371 15.9982 31.2727 18.5 31.2727Z"
                stroke="#7A7A7A"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    )
}

// Icon/48/Close
export const FilterCloseIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="20" viewBox="0 0 36 20" fill="none">
            <path d="M2 18L18 2L34 18" stroke="#252525" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}

export const BannerIconSurvey = () => {
    return (
        <svg width="403" height="240" viewBox="0 0 403 240" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1689_20177)">
                <path
                    d="M278.086 236.21V187.077H363V97.9807C363 48.2379 322.236 8 272.466 8C222.695 8 184.235 46.378 182 95.511V236.21"
                    fill="white"
                />
                <path
                    d="M278.086 236.21V187.077H363V97.9807C363 48.2379 322.236 8 272.466 8C222.695 8 184.235 46.378 182 95.511V236.21"
                    stroke="#231F20"
                    strokeWidth="3"
                    strokeLinejoin="round"
                />
                <path d="M278.086 187.077H256.854" stroke="#231F20" strokeWidth="3" strokeLinejoin="round" />
                <path d="M335.064 115.611V147.998H321.656" stroke="#231F20" strokeWidth="3" strokeLinejoin="round" />
                <path
                    d="M234.504 109.044C234.504 86.8725 252.482 68.9043 274.666 68.9043C296.849 68.9043 314.828 86.8725 314.828 109.044C314.828 131.215 296.849 149.183 274.666 149.183"
                    stroke="#231F20"
                    strokeWidth="3"
                    strokeLinejoin="round"
                />
                <path
                    d="M254.762 109.518C254.762 98.2731 263.889 89.1509 275.141 89.1509C286.392 89.1509 295.519 98.2731 295.519 109.518C295.519 120.764 286.392 129.886 275.141 129.886"
                    stroke="#231F20"
                    strokeWidth="3"
                    strokeLinejoin="round"
                />
                <path d="M216.804 8H92.8301V92.7062H154.813V122.843L187.609 92.7062H216.804V8Z" fill="#F1F2F2" />
                <path d="M106.742 24.4321H132.043" stroke="#231F20" strokeWidth="3" strokeLinejoin="round" />
                <path d="M106.742 44.6616H201.625" stroke="#231F20" strokeWidth="3" strokeLinejoin="round" />
                <path d="M106.742 62.3623H201.625" stroke="#231F20" strokeWidth="3" strokeLinejoin="round" />
                <path d="M106.742 77.5342H126.983" stroke="#231F20" strokeWidth="3" strokeLinejoin="round" />
                <path d="M140.902 77.5342H167.464" stroke="#231F20" strokeWidth="3" strokeLinejoin="round" />
                <path d="M176.322 77.5342H201.623" stroke="#231F20" strokeWidth="3" strokeLinejoin="round" />
                <path d="M142.162 24.4321H201.623" stroke="#231F20" strokeWidth="3" strokeLinejoin="round" />
                <path d="M65 47.1899H142.163V97.7634H104.212V117.19L82.4429 97.7634H65V47.1899Z" fill="#FFCB31" />
                <path d="M100.422 58.5732H128.253" stroke="#231F20" strokeWidth="3" strokeLinejoin="round" />
                <path d="M128.252 78.8027H76.3809" stroke="#231F20" strokeWidth="3" strokeLinejoin="round" />
                <path
                    d="M285.52 110.209C285.52 115.913 280.892 120.53 275.193 120.53C269.494 120.53 264.865 115.905 264.865 110.209C264.865 104.513 269.494 99.8867 275.193 99.8867C280.892 99.8867 285.52 104.513 285.52 110.209Z"
                    fill="#E6E7E8"
                />
                <path d="M164.223 205.625L150 219.84L162.726 232.559L176.949 218.344L164.223 205.625Z" fill="#D1D3D4" />
                <path d="M164.229 205.615L239.101 130.785L251.829 143.506L176.957 218.336" fill="#FF8A6B" />
                <path
                    d="M164.229 205.615L239.101 130.785L251.829 143.506L176.957 218.336"
                    stroke="#231F20"
                    strokeWidth="3"
                    strokeLinejoin="round"
                />
                <path d="M158.236 211.604L170.964 224.324" stroke="#231F20" strokeWidth="3" strokeLinejoin="round" />
                <path
                    d="M262.221 120L239.1 130.785L251.828 143.506L262.221 120Z"
                    fill="#D1D3D4"
                    stroke="#231F20"
                    strokeWidth="3"
                    strokeLinejoin="round"
                />
                <path d="M245.085 136.773L177.701 204.12" stroke="#231F20" strokeWidth="3" strokeLinejoin="round" />
                <g clipPath="url(#clip1_1689_20177)">
                    <path
                        d="M125.97 216.77H43.9297V237.28H125.97V216.77Z"
                        fill="#E6E7E8"
                        stroke="#231F20"
                        strokeWidth="3"
                        strokeLinejoin="round"
                    />
                    <path d="M84.9492 218.23V186" stroke="#231F20" strokeWidth="3" strokeLinejoin="round" />
                    <path
                        d="M51.3292 152.25C51.3292 152.25 49.8192 188.45 86.2092 187.13C86.2192 187.13 90.1192 152.25 51.3292 152.25Z"
                        fill="#009873"
                    />
                    <path d="M63.5195 164.54L86.3095 187.33" stroke="#231F20" strokeWidth="3" strokeLinejoin="round" />
                    <path
                        d="M120.06 152C120.06 152 83.8598 150.49 85.1798 186.88C85.1798 186.88 120.06 190.78 120.06 152Z"
                        fill="#009873"
                    />
                    <path d="M107.761 164.2L84.9707 186.99" stroke="#231F20" strokeWidth="3" strokeLinejoin="round" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1689_20177">
                    <rect width="403" height="240" fill="white" />
                </clipPath>
                <clipPath id="clip1_1689_20177">
                    <rect width="87.9" height="88.21" fill="white" transform="translate(41 152)" />
                </clipPath>
            </defs>
        </svg>
    )
}
export const RegisterCompleteIcon = () => {
    return (
        <svg width="393" height="258" viewBox="0 0 393 258" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1575_11077)">
                <path
                    d="M278.722 34.0625H248.359C245.832 34.0625 243.783 36.1144 243.783 38.6455V202.463C243.783 204.995 245.832 207.046 248.359 207.046H278.722C281.249 207.046 283.298 204.995 283.298 202.463V38.6455C283.298 36.1144 281.249 34.0625 278.722 34.0625Z"
                    fill="white"
                />
                <path
                    d="M278.722 34.0625H248.359C245.832 34.0625 243.783 36.1144 243.783 38.6455V202.463C243.783 204.995 245.832 207.046 248.359 207.046H278.722C281.249 207.046 283.298 204.995 283.298 202.463V38.6455C283.298 36.1144 281.249 34.0625 278.722 34.0625Z"
                    stroke="#231F20"
                    strokeWidth="3"
                    strokeLinejoin="round"
                />
                <path
                    d="M263.515 65.6035C257.97 65.6035 253.484 61.1005 253.484 55.5569C253.484 50.0132 257.98 45.5103 263.515 45.5103C269.051 45.5103 273.546 50.0132 273.546 55.5569C273.546 61.1005 269.051 65.6035 263.515 65.6035Z"
                    fill="#FFCB31"
                />
                <path d="M270.129 73.6484H255.492V160.135H270.129V73.6484Z" fill="#E6E7E8" />
                <path d="M270.129 171.863H255.492" stroke="#231F20" strokeWidth="3" strokeLinejoin="round" />
                <path d="M270.129 186.523H255.492" stroke="#231F20" strokeWidth="3" strokeLinejoin="round" />
                <path
                    d="M287.875 15.0098H318.248C320.775 15.0098 322.824 17.0611 322.824 19.5928V183.411C322.824 185.942 320.775 187.994 318.248 187.994H287.875C285.347 187.994 283.299 185.942 283.299 183.411V19.5928C283.299 17.0611 285.347 15.0098 287.875 15.0098Z"
                    fill="#FC6A6B"
                />
                <path
                    d="M287.875 15.0098H318.248C320.775 15.0098 322.824 17.0611 322.824 19.5928V183.411C322.824 185.942 320.775 187.994 318.248 187.994H287.875C285.347 187.994 283.299 185.942 283.299 183.411V19.5928C283.299 17.0611 285.347 15.0098 287.875 15.0098Z"
                    fill="#BCBEC0"
                    stroke="#231F20"
                    strokeWidth="3"
                    strokeLinejoin="round"
                />
                <path d="M312.572 171.863H293.549" stroke="#231F20" strokeWidth="3" strokeLinejoin="round" />
                <path d="M312.572 25.2666H292.08V67.7746H312.572V25.2666Z" fill="white" />
                <path d="M312.572 76.5806H292.08V160.136H312.572V76.5806Z" fill="white" />
                <path d="M305.26 82.4443V102.968" stroke="#231F20" strokeWidth="3" strokeLinejoin="round" />
                <path d="M297.936 82.4443V139.612" stroke="#231F20" strokeWidth="3" strokeLinejoin="round" />
                <path
                    d="M358.87 202.653C361.997 202.653 363.806 200.922 363.806 198.601V33.8122C363.806 31.4906 361.997 29.6694 358.87 29.6694H329.177C326.059 29.6694 322.822 31.4906 322.822 33.8122V198.601C322.822 200.922 326.059 202.653 329.177 202.653H358.87Z"
                    fill="#FFCB31"
                />
                <path
                    d="M358.87 202.653C361.997 202.653 363.806 200.922 363.806 198.601V33.8122C363.806 31.4906 361.997 29.6694 358.87 29.6694H329.177C326.059 29.6694 322.822 31.4906 322.822 33.8122V198.601C322.822 200.922 326.059 202.653 329.177 202.653H358.87Z"
                    fill="#FFCB31"
                    stroke="#231F20"
                    strokeWidth="3"
                    strokeLinejoin="round"
                />
                <path d="M355.013 44.3291H335.99V101.497H355.013V44.3291Z" fill="white" />
                <path d="M350.627 107.361V130.816" stroke="#231F20" strokeWidth="3" strokeLinejoin="round" />
                <path d="M338.918 107.361V182.13" stroke="#231F20" strokeWidth="3" strokeLinejoin="round" />
                <path
                    d="M304.569 138.301C289.252 138.301 275.055 143.064 263.326 151.18C255.373 142.124 244.432 135.8 232.023 133.728C232.533 130.246 232.803 126.694 232.803 123.071C232.803 82.8547 200.252 50.2632 160.097 50.2632C119.943 50.2632 87.3922 82.8647 87.3922 123.081C87.3922 129.015 88.1215 134.769 89.4603 140.293C84.0851 139.022 78.4701 138.311 72.7052 138.311C32.551 138.301 0 170.052 0 211.099H377.284C377.284 170.052 344.733 138.311 304.569 138.311V138.301Z"
                    fill="#7BCCB7"
                />
                <path
                    d="M89.4609 140.283C89.4609 140.283 98.343 147.417 101.25 148.938L89.4609 140.283Z"
                    fill="#D1D3D4"
                />
                <path
                    d="M89.4609 140.283C89.4609 140.283 98.343 147.417 101.25 148.938"
                    stroke="#231F20"
                    strokeWidth="3"
                    strokeLinejoin="round"
                />
                <path
                    d="M263.325 151.189C263.325 151.189 259.309 158.064 259.139 163.347L263.325 151.189Z"
                    fill="#D1D3D4"
                />
                <path
                    d="M263.325 151.189C263.325 151.189 259.309 158.064 259.139 163.347"
                    stroke="#231F20"
                    strokeWidth="3"
                    strokeLinejoin="round"
                />
                <path
                    d="M244 199.461L196.494 136.09L149 199.461H173.475V279.184H216.144V199.461H244Z"
                    fill="#D1D3D4"
                    stroke="#231F20"
                    strokeWidth="3"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_1575_11077">
                    <rect width="393" height="258" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export const AllDocIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3082_12528)">
                <path d="M10.9609 5.79858H16.4387" stroke="#024383" strokeWidth="1.5" strokeLinecap="square" />
                <path d="M7.41797 5.79846H8.09753" stroke="#024383" strokeWidth="1.5" strokeLinecap="square" />
                <path d="M7.41797 11.2354H8.09753" stroke="#024383" strokeWidth="1.5" strokeLinecap="square" />
                <path d="M10.9609 11.2354H16.4387" stroke="#024383" strokeWidth="1.5" strokeLinecap="square" />
                <path d="M7.41797 16.6713H8.09753" stroke="#024383" strokeWidth="1.5" strokeLinecap="square" />
                <path d="M10.9609 16.6711H16.4387" stroke="#024383" strokeWidth="1.5" strokeLinecap="square" />
                <path
                    d="M19.0491 0.70166H4.61952C3.72508 0.70166 3 1.44598 3 2.36415V20.7852C3 21.7033 3.72508 22.4476 4.61952 22.4476H19.0491C19.9435 22.4476 20.6686 21.7033 20.6686 20.7852V2.36415C20.6686 1.44598 19.9435 0.70166 19.0491 0.70166Z"
                    stroke="#024383"
                    strokeWidth="1.5"
                    strokeLinecap="square"
                />
            </g>
            <defs>
                <clipPath id="clip0_3082_12528">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export const BannerIconTutor = () => {
    return <span className={`${styles.banner} ${styles.tutor_img}`} />
}
export const BannerIconSystem = () => {
    return <span className={`${styles.banner} ${styles.statistics_img}`} />
}
export const BannerIconReport = () => {
    return <span className={`${styles.banner} ${styles.report_img}`} />
}

export const BannerIconProgram = () => {
    return <span className={`${styles.banner} ${styles.program_img}`} />
}

export const BannerIconAccount = () => {
    return <span className={`${styles.banner} ${styles.account_img}`} />
}

export const BannerIconContact = () => {
    return <span className={`${styles.banner} ${styles.contact_img}`} />
}

// Filter/Icon/All
export const AllFilterIcon = () => {
    return <span className={`${styles.filter_icon} ${styles.all}`} />
}

// Filter/Icon/Re
export const ReFilterIcon = () => {
    return <span className={`${styles.filter_icon} ${styles.folder_b}`} />
}

// Filter/Icon/Ing
export const IngFilterIcon = () => {
    return <span className={`${styles.filter_icon} ${styles.folder_g}`} />
}

// Filter/Icon/Finish
export const FinishFilterIcon = () => {
    return <span className={`${styles.filter_icon} ${styles.folder_y}`} />
}

// Icon/24/Member
export const HeaderMemberIcon = () => {
    return <span className={`${styles.avatar}`} />
}

export const LogoutIcon = () => {
    return <span className={`${styles.logout}`} />
}

export const FooterIcon = () => {
    return <span className={`${styles.footer_logo}`} />
}

export const MailIcon = () => {
    return <span className={`${styles.mail}`} />
}

export const HomeIcon = () => {
    return <span className={`${styles.home}`} />
}

// Icon/24/Attachment
export const AttachmentIcon = () => {
    return <span className={`${styles.attachment}`} />
}

export const LoginMainIcon = () => {
    return <span className={`${styles.login_element}`} />
}

// Icon/48/Close2
export const RightArrowIcon = () => {
    return <span className={`${styles.arrow_icon}`} />
}

// 그룹 12379 (Icon/36/More)
export const ListItemMoreIcon = () => {
    return <span className={`${styles.more}`} />
}

// 필터

// Icon/24/Ongoing/Off
export const OnGoing = () => {
    return <span className={`${styles.going_icon}`} />
}
// Icon/24/Finish/Off
export const OnFinish = () => {
    return <span className={`${styles.finish_icon}`} />
}

// Icon/24/Expect/Off
export const OnExpect = () => {
    return <span className={`${styles.expect_icon}`} />
}

// Icon/24/Cancel/Off
export const OnCancel = () => {
    return <span className={`${styles.cancel_icon}`} />
}
// Icon/24/Short/Off
export const ShortPeriod = () => {
    return <span className={`${styles.short_period_icon}`} />
}
// Icon/24/Middle/Off
export const MiddlePeriod = () => {
    return <span className={`${styles.middle_period_icon}`} />
}
// Icon/24/Long/Off
export const LongPeriod = () => {
    return <span className={`${styles.long_period_icon}`} />
}

// Icon/24/Contract/On
export const ContractBadgeIcon = () => {
    return <span className={`${styles.contract_badge_icon}`} />
}

//Icon/24/Expire/On
export const ExpireBadgeIcon = () => {
    return <span className={`${styles.expire_badge_icon}`} />
}

//Icon/48/Right
export const RightArrow48Icon = () => {
    return <span className={`${styles.right_arrow_48_icon}`} />
}

//Icon/48/Left
export const LeftArrow48Icon = () => {
    return <span className={`${styles.left_arrow_48_icon}`} />
}

//Visual/Menu03
export const BannerIconTools = () => {
    return <span className={`${styles.banner}  ${styles.tools_img}`} />
}

export const BannerSurveyIcon = () => {
    return <span className={`${styles.banner}  ${styles.survey_img}`} />
}

export const BannerSurveySuccessIcon = () => {
    return <div className={`${styles.survey_success_img}`} />
}

export const OnWorkingIcon = () => {
    return <span className={styles.working_icon} />
}

// Icon/24/T_G/Off
export const NormalTutorFilterIcon = () => {
    // return <span className={`${styles.normal_tutor_icon}`} />
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2157_8857)">
                <path
                    d="M5.77061 1H18.2294C20.8644 1 23 3.13889 23 5.77061V23H5.77061C3.1356 23 1 20.8611 1 18.2294V5.77061C1 3.1356 3.13889 1 5.77061 1Z"
                    stroke="#EB4824"
                    strokeWidth="1.5"
                    strokeLinecap="square"
                />
                <path d="M8 12L16 12" stroke="#EB4824" strokeWidth="1.5" strokeLinecap="square" />
            </g>
            <defs>
                <clipPath id="clip0_2157_8857">
                    <rect width="24" height="24" fill="#EB4824" />
                </clipPath>
            </defs>
        </svg>
    )
}

// Icon/24/T_G/Off
export const NormalTutorChipsIcon = () => {
    // return <span className={`${styles.normal_tutor_icon}`} />
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1707_10668)">
                <path
                    d="M5.77061 1H18.2294C20.8644 1 23 3.13889 23 5.77061V23H5.77061C3.1356 23 1 20.8611 1 18.2294V5.77061C1 3.1356 3.13889 1 5.77061 1Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="square"
                />
                <path d="M8 12L16 12" stroke="white" strokeWidth="1.5" strokeLinecap="square" />
            </g>
            <defs>
                <clipPath id="clip0_1707_10668">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

// Icon/24/T_H/Off
export const AdvancedTutorFilterIcon = () => {
    // return <span className={`${styles.advanced_tutor_icon}`} />
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2157_2258)">
                <path
                    d="M5.77061 1H18.2294C20.8644 1 23 3.13889 23 5.77061V23H5.77061C3.1356 23 1 20.8611 1 18.2294V5.77061C1 3.1356 3.13889 1 5.77061 1Z"
                    stroke="#00A886"
                    strokeWidth="1.5"
                    strokeLinecap="square"
                />
                <path
                    d="M12 14C14.2091 14 16 12.2091 16 10C16 7.79086 14.2091 6 12 6C9.79086 6 8 7.79086 8 10C8 12.2091 9.79086 14 12 14Z"
                    stroke="#00A886"
                    strokeWidth="2"
                    strokeLinecap="square"
                />
                <path
                    d="M9 18L11.998 14L15 18"
                    stroke="#00A886"
                    strokeWidth="2"
                    strokeLinecap="square"
                    strokeLinejoin="bevel"
                />
            </g>
            <defs>
                <clipPath id="clip0_2157_2258">
                    <rect width="24" height="24" fill="#00A886" />
                </clipPath>
            </defs>
        </svg>
    )
}

export const AdvancedTutorChipsIcon = () => {
    // return <span className={`${styles.advanced_tutor_icon}`} />
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1707_10671)">
                <path
                    d="M5.77061 1H18.2294C20.8644 1 23 3.13889 23 5.77061V23H5.77061C3.1356 23 1 20.8611 1 18.2294V5.77061C1 3.1356 3.13889 1 5.77061 1Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="square"
                />
                <path
                    d="M12 14C14.2091 14 16 12.2091 16 10C16 7.79086 14.2091 6 12 6C9.79086 6 8 7.79086 8 10C8 12.2091 9.79086 14 12 14Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="square"
                />
                <path
                    d="M9 18L11.998 14L15 18"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="square"
                    strokeLinejoin="bevel"
                />
            </g>
            <defs>
                <clipPath id="clip0_1707_10671">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
