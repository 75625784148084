import { ConfigProvider } from 'antd'
import locale from 'antd/lib/locale/ko_KR'

import Routers from 'routers/router'

import AppLayout from 'layout/AppLayout'
import TokenConfig from 'utils/tokenConfig'

import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import 'dayjs/locale/ko' // 한국어 로캘을 불러옵니다
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import 'assets/scss/ui.scss' // 공통 scss

dayjs.locale('ko') // antd datepicker 한글화
dayjs.extend(utc) // UTC 플러그인 적용하기
dayjs.extend(timezone) // 한국 타임존 적용
dayjs.extend(isBetween) // 현재 날짜가 중간에 위치하는지 확인하는 플러그인

dayjs.tz.setDefault('Asia/Seoul')

const App = () => {
    return (
        <ConfigProvider
            locale={locale} // antd datepicker 한글화
            theme={{
                token: TokenConfig(), // AntD Global 테마 설정
                hashed: false, // css-dev-only-do-not-override 제거
            }}
        >
            <AppLayout>
                <Routers />
            </AppLayout>
        </ConfigProvider>
    )
}

export default App
