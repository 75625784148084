import React, { useCallback, useEffect, useState } from 'react'
import { Table } from 'antd'
import { useSelector } from 'react-redux'

const AttendanceUI = ({ onChange }) => {
    const program = useSelector(state => state.program.program)
    const { programOperationInfo } = program
    const { classHours, numberOfApplicants } = programOperationInfo

    const [col, setCol] = useState([])
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    const toggleAttendance = (record, colNm) => {
        setData(prevData => {
            const newData = prevData.map(item => {
                if (record.key === item.key) {
                    return { ...item, [colNm]: !item[colNm] }
                }
                return item
            })

            if (onChange) onChange(newData)
            return newData
        })
    }

    const attendanceButton = (record, colNm) => {
        const isClicked = record[colNm]

        const buttonStyle = {
            width: '100%',
            height: '100%',
            background: isClicked ? '#499DD8' : 'initial',
            color: 'white',
            border: 'none',
            fontSize: '20px',
            display: 'flex',
            alignItems: 'middle',
            justifyContent: 'center',
        }

        return (
            <button
                type="button"
                style={buttonStyle}
                onClick={() => toggleAttendance(record, colNm)}
                aria-label={`Toggle attendance for ${record.name} in step ${colNm}`}
            >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 4L4 20" stroke="white" strokeWidth="2" strokeLinecap="square" />
                    <path d="M4 4L20 20" stroke="white" strokeWidth="2" strokeLinecap="square" />
                </svg>
            </button>
        )
    }

    const setColumns = () => {
        const newData = []

        for (let i = 1; i <= classHours; i += 1) {
            if (i === 1) {
                newData.push({
                    dataIndex: 'name',
                    key: 'name',
                    width: 100,
                })
            }
            const col = {
                title: `STEP${i}`,
                dataIndex: `step${i}`,
                key: `step${i}`,
                width: 90,
                render: (text, record) => attendanceButton(record, `step${i}`),
            }
            newData.push(col)
        }
        setCol(newData)
    }

    const setInitialDataRow = () => {
        const newData = []

        for (let i = 1; i <= numberOfApplicants; i += 1) {
            const student = {
                key: i,
                name: `수강생 ${i}`,
            }

            for (let j = 1; j <= classHours; j += 1) {
                student[`step${j}`] = false
            }

            newData.push(student)
        }

        setData(newData)
    }

    const init = useCallback(async () => {
        setColumns()
        setInitialDataRow()
        setLoading(false)
        await onChange(data)
    }, [classHours, numberOfApplicants])

    useEffect(() => {
        init()
    }, [init, classHours, numberOfApplicants])

    useEffect(() => {
        onChange(data)
    }, [col, data])

    return (
        data &&
        col && (
            <Table
                dataSource={data}
                bordered
                scroll={{ x: 500, y: 500 }}
                columns={col}
                pagination={false}
                loading={loading}
            />
        )
    )
}

export default AttendanceUI
