import { DatePicker } from 'antd'

import FilterButton from 'components/AntD/Button/FilterButton'
import { CheckFilterIcon } from 'assets/Icons'
import { getCurrentMonthDates, getLastMonthDates } from 'utils/utilCommon'

import styles from '../filter.module.scss'

const SurveyFilter = ({
    queryParams,
    updateQueryStringAndNavigate,
    startDateRange,
    setStartDateRange,
    endDateRange,
    setEndDateRange,
    isActiveLastMonth,
    setIsActiveLastMonth,
    isActiveThisMonth,
    setIsActiveThisMonth,
    handleStartDateChange,
    handleEndDateChange,
    startDate,
    endDate,
}) => {
    // query string key
    const isMeet = 'isMeet' // 신청기관 유형 = 오프라인(true) || 온라인(false)
    const organization = 'organizationType' // 교육유형 = 일반학교 || 도서벽지 || 특수학교 || 지역아동센터 || 기타
    const isNew = 'isNew' // 신규여부 = 신규(true) || 재등록(false)

    // get query params
    const currentMeet = queryParams.get(isMeet) || ''
    const currentOrganization = queryParams.get(organization) || ''
    const currentNew = queryParams.get(isNew) || ''

    const [lastMonthStartDate, lastMonthEndDate] = getLastMonthDates()
    const [currentMonthStartDate, currentMonthEndDate] = getCurrentMonthDates()

    // 지난달
    const lastMonthStart = lastMonthStartDate.format('YYYYMMDD')
    const lastMonthEnd = lastMonthEndDate.format('YYYYMMDD')

    // 이번달
    const currentMonthStart = currentMonthStartDate.format('YYYYMMDD')
    const currentMonthEnd = currentMonthEndDate.format('YYYYMMDD')

    // if click 지난달
    const handleLastMonth = () => {
        if (isActiveLastMonth) {
            updateQueryStringAndNavigate(startDate, lastMonthStart)
            updateQueryStringAndNavigate(endDate, lastMonthEnd)

            queryParams.delete('startDate')
            queryParams.delete('endDate')

            setStartDateRange(null)
            setEndDateRange(null)
            setIsActiveLastMonth(false)
        } else {
            updateQueryStringAndNavigate(startDate, lastMonthStart)
            updateQueryStringAndNavigate(endDate, lastMonthEnd)

            setStartDateRange(lastMonthStartDate)
            setEndDateRange(lastMonthEndDate)
            setIsActiveLastMonth(true)
            setIsActiveThisMonth(false)
        }
    }

    // if click 이번달
    const handleThisMonth = () => {
        if (isActiveThisMonth) {
            updateQueryStringAndNavigate(startDate, currentMonthStart)
            updateQueryStringAndNavigate(endDate, currentMonthEnd)

            queryParams.delete('startDate')
            queryParams.delete('endDate')

            setStartDateRange(null)
            setEndDateRange(null)
            setIsActiveThisMonth(false)
        } else {
            updateQueryStringAndNavigate(startDate, currentMonthStart)
            updateQueryStringAndNavigate(endDate, currentMonthEnd)

            setStartDateRange(currentMonthStartDate)
            setEndDateRange(currentMonthEndDate)
            setIsActiveThisMonth(true)
            setIsActiveLastMonth(false)
        }
    }

    return (
        <>
            <div className={styles.item_wrap}>
                <span className={`body2 ${styles.title}`}>신청기관 유형</span>
                <div className={styles.btn_wrap}>
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="오프라인"
                        currentParamValue={currentMeet}
                        onClick={() => updateQueryStringAndNavigate(isMeet, '오프라인')}
                        filterColor="black"
                    />
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="온라인"
                        currentParamValue={currentMeet}
                        onClick={() => updateQueryStringAndNavigate(isMeet, '온라인')}
                        filterColor="black"
                    />
                </div>
            </div>
            <div className={styles.item_wrap}>
                <span className={`body2 ${styles.title}`}>교육유형</span>
                <div className={styles.btn_wrap}>
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="일반학교"
                        currentParamValue={currentOrganization}
                        onClick={() => updateQueryStringAndNavigate(organization, '일반학교')}
                        filterColor="black"
                    />
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="도서벽지"
                        currentParamValue={currentOrganization}
                        onClick={() => updateQueryStringAndNavigate(organization, '도서벽지')}
                        filterColor="black"
                    />
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="특수학교"
                        currentParamValue={currentOrganization}
                        onClick={() => updateQueryStringAndNavigate(organization, '특수학교')}
                        filterColor="black"
                    />
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="지역아동센터"
                        currentParamValue={currentOrganization}
                        onClick={() => updateQueryStringAndNavigate(organization, '지역아동센터')}
                        filterColor="black"
                    />
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="기타"
                        currentParamValue={currentOrganization}
                        onClick={() => updateQueryStringAndNavigate(organization, '기타')}
                        filterColor="black"
                    />
                </div>
            </div>
            <div className={styles.item_wrap}>
                <span className={`body2 ${styles.title}`}>신규여부</span>
                <div className={styles.btn_wrap}>
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="신규"
                        currentParamValue={currentNew}
                        onClick={() => updateQueryStringAndNavigate(isNew, '신규')}
                        filterColor="black"
                    />
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="재등록"
                        currentParamValue={currentNew}
                        onClick={() => updateQueryStringAndNavigate(isNew, '재등록')}
                        filterColor="black"
                    />
                </div>
            </div>
            <div className={styles.item_wrap}>
                <span className={`body2 ${styles.title}`}>교육기간</span>
                <div className={styles.btn_wrap}>
                    <DatePicker
                        format="YYYY-MM-DD"
                        value={startDateRange}
                        className={styles.h100}
                        onChange={handleStartDateChange}
                        placeholder="시작일"
                    />
                    <div className={styles.tilde}>~</div>
                    <DatePicker
                        format="YYYY-MM-DD"
                        value={endDateRange}
                        className={styles.h100}
                        onChange={handleEndDateChange}
                        placeholder="종료일"
                    />
                </div>
                <div className={styles.btn_wrap}>
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="지난달"
                        currentParamValue={isActiveLastMonth}
                        filterColor="black"
                        onClick={handleLastMonth}
                    />
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="이번달"
                        currentParamValue={isActiveThisMonth}
                        filterColor="black"
                        onClick={handleThisMonth}
                    />
                </div>
            </div>
        </>
    )
}

export default SurveyFilter
