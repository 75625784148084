import React, { useState } from 'react'
import { postAccount, putAccount } from 'api/accounts/accouint.api'
// import { supabase } from 'api/axios.instance'

import { Col, Divider, Form, Input, message, Row } from 'antd'
import ColorButton from 'components/AntD/Button/ColorButton'
import InputSection from 'components/AntD/InputSection'
// import SelectUi from 'components/Ui/Select/SelectUi'

import styles from './dialog.module.scss'

/**
 * Modal
 * 계정추가
 * @param setModalOpen
 * @param fetchList
 * @param type  1: 계정추가, 2: 계정수정
 * @param userId 계정수정할 떄 필요
 * @param regionId  계정수정할 떄 필요
 * @returns {JSX.Element}
 * @constructor
 */
const Account = ({ form, setModalOpen, fetchList, type = 1, accountInfo }) => {
    const { userId, regionId } = accountInfo
    const [messageApi, contextHolder] = message.useMessage()
    const success = 'success'
    const error = 'error'
    const existence = 'existence' // 존재

    const alertMessage = (status, option) => {
        if (status === success) {
            messageApi.open({
                type: success,
                content: `계정 ${option === 1 ? '추가가' : option === 2 ? '수정이' : ''} 완료되었습니다.`,
                duration: 5,
            })
        }
        if (status === error) {
            messageApi.open({
                type: error,
                content: `계정 ${option === 1 ? '추가' : option === 2 ? '수정' : ''} 실패하었습니다.`,
                duration: 5,
            })
        }
        if (status === existence) {
            messageApi.open({
                type: error,
                content: `사용자가 이미 존재합니다.`,
                duration: 5,
            })
        }
    }
    const [activeOption, setActiveOption] = useState('all')

    const options = [
        { value: '강원', label: '강원' },
        { value: '광주', label: '광주' },
        { value: '경기', label: '경기' },
        { value: '경남', label: '경남' },
        { value: '경북', label: '경북' },
        { value: '대구', label: '대구' },
        { value: '울산', label: '울산' },
        { value: '인천', label: '인천' },
        { value: '전남', label: '전남' },
        { value: '전북', label: '전북' },
        { value: '제주', label: '제주' },
        { value: '충남', label: '충남' },
        { value: '충북', label: '충북' },
    ]

    const handleAccountAction = async (type, regionId, userId, passwd, isServiceManager) => {
        let response
        if (type === 1) {
            // 과거 : 수퍼베이스 Auth 이메일 등록
            // 현재 : 인증 여부값 추가하여 개발자가 직접 슈퍼베이스 대시보드에서 계정등록
            // const signupResponse = await supabase.auth.signUp({ email: `${userId}@swedulink`, password: passwd })
            // console.log('signupResponse : ', signupResponse)

            // if (signupResponse.data.user) {
            // console.log('가입 진행')
            // const userUid = signupResponse.data.user.id

            response = await postAccount({ regionId, userId, passwd, isServiceManager })
            // 등록 완료
            if (response.status === 201) {
                form.resetFields()
                alertMessage(success, 1)
                setModalOpen(false)
                fetchList()
            } else if (response.status === 409) {
                // 사용자가 존재할 때
                alertMessage(existence)
            } else {
                alertMessage(error, 1)
            }
            // }
            // if (signupResponse.error.status === 400 && signupResponse.error.message === 'User already registered') {
            //     alertMessage('existence', 1)
            //     return false
            // }
        } else if (type === 2) {
            response = await putAccount({ regionId, userId, passwd })
            if (response.status === 200) {
                // 수정 완료
                form.resetFields()
                alertMessage(success, 2)
                setModalOpen(false)
                fetchList()
            } else if (response.status === 409) {
                // 사용자가 존재할 때
                alertMessage(existence)
            } else {
                alertMessage(error, 2)
            }
        } else {
            return false
        }
        return false
    }

    /**
     * submit 제출하여 성공 후 처리
     * 모든 value 확인 가능
     * @param values
     */
    const onFinish = async values => {
        try {
            const { regionId, userId, passwd, isServiceManager } = values
            if (type === 1) {
                await handleAccountAction(1, regionId, userId, passwd, isServiceManager) // 계정 추가
            } else if (type === 2) {
                await handleAccountAction(2, regionId, userId, passwd) // 계정 수정
            } else {
                return false
            }

            return false
        } catch (e) {
            console.error(e)
            return false
        }
    }

    /**
     * submit 제출하여 실패 후 처리
     * 모든 value 확인 가능
     * @param errorInfo
     */
    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo)
    }

    const handleChange = value => {
        console.log(`selected ${value}`)
        setActiveOption(value)
    }

    const handleCancel = e => {
        e.preventDefault()
        form.resetFields()
        setModalOpen(false)
    }

    return (
        <>
            {contextHolder}
            <Form
                name="basic"
                form={form}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className={styles.input_section}>
                    <div className={styles.title}>
                        <span className="h6">{type === 1 ? '계정 추가' : type === 2 ? '계정 수정' : ''}</span>
                    </div>
                    <Divider style={{ margin: 0, backgroundColor: 'black' }} />
                    {type === 1 ? (
                        <>
                            <InputSection
                                label="지역"
                                name="regionId"
                                options={options}
                                handleChange={handleChange}
                                value={activeOption}
                                rules={[{ required: true, message: '지역를 선택해주세요' }]}
                                // noStyle
                            />
                            {/*<Row className={styles.bb}>*/}
                            {/*    <Col span={7}>*/}
                            {/*        <div className={styles.label}>*/}
                            {/*            <span>사용처</span>*/}
                            {/*        </div>*/}
                            {/*    </Col>*/}
                            {/*    <Col span={17}>*/}
                            {/*        <Form.Item*/}
                            {/*            name="isServiceManager"*/}
                            {/*            rules={[{ required: true }]}*/}
                            {/*            initialValue="지역사용자"*/}
                            {/*        >*/}
                            {/*            <SelectUi*/}
                            {/*                name="isServiceManager"*/}
                            {/*                size="sm"*/}
                            {/*                options={['지역사용자', '용역사용자']}*/}
                            {/*                isEssential*/}
                            {/*            />*/}
                            {/*        </Form.Item>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            <InputSection
                                label="아이디"
                                name="userId"
                                rules={[
                                    { required: true, message: '아이디를 입력해주세요' },
                                    {
                                        type: 'string',
                                        min: 4,
                                        message: '4글자 이상 입력해주세요.',
                                    },
                                ]}
                                // noStyle
                            />
                        </>
                    ) : type === 2 ? (
                        /* TODO: 리팩토링 필요 */
                        <>
                            <Row align="middle" className={styles.bb}>
                                <Col span={7}>
                                    <div className={styles.label}>
                                        <span>지역</span>
                                    </div>
                                </Col>
                                <Col span={17}>
                                    <Form.Item
                                        name="regionId"
                                        noStyle
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                        initialValue={regionId}
                                    >
                                        <Input noStyle disabled className={styles.none} />
                                    </Form.Item>
                                    <div className={styles.edit_disable}>{regionId}</div>
                                </Col>
                            </Row>
                            <Row align="middle" className={styles.bb}>
                                <Col span={7}>
                                    <div className={styles.label}>
                                        <span>아이디</span>
                                    </div>
                                </Col>
                                <Col span={17}>
                                    <Form.Item
                                        name="userId"
                                        noStyle
                                        initialValue={userId}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input noStyle disabled className={styles.none} />
                                    </Form.Item>
                                    <div className={styles.edit_disable}>{userId}</div>
                                </Col>
                            </Row>
                        </>
                    ) : (
                        ''
                    )}
                    <InputSection
                        label="패스워드"
                        name="passwd"
                        rules={[
                            { required: true, message: '패스워드를 입력해주세요' },
                            { type: 'string', min: 6 },
                        ]}
                        // noStyle
                        password
                    />
                </div>
                <Form.Item className={styles.item}>
                    <ColorButton type="primary" key="back" htmlType="submit">
                        확인
                    </ColorButton>
                    <ColorButton key="back" onClick={e => handleCancel(e)}>
                        취소
                    </ColorButton>
                </Form.Item>
            </Form>
        </>
    )
}

export default Account
