import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Button, Col, Flex, Form, Row, Select } from 'antd'
import { v4 as uuidv4 } from 'uuid'

import Title from 'components/Ui/Title'
import SelectBox from 'components/AntD/Select'
import TextField from 'components/AntD/TextField'

import { fetchMatchTutorList } from 'api/tutors/tutors.api'

import { useSelector } from 'react-redux'
import { getAllUserInfo } from 'store/Slices/user'

import { GrayTrashIcon24, PlusIcon, ArrowBtmIcon } from 'assets/Icons'
import TextArea from 'antd/es/input/TextArea'

import styles from 'assets/scss/detail.module.scss'

const TutorInfoList = ({
    programId,
    programStartDate,
    programEndDate,
    instructor_matching = [],
    decription = '해당 교육과정에 강사를 배정해주세요',
    onChange,
    classHours,
}) => {
    const location = useLocation()
    const { userInfo } = useSelector(getAllUserInfo)

    const defaultTutorList = [
        {
            id: uuidv4(),
            tutor_id: '',
            tutor_name: '',
            tutor_type: '주강사',
            class_hours: classHours,
            program_id: programId,
            start_date: programStartDate,
            end_date: programEndDate,
            is_delete: false,
        },
        {
            id: uuidv4(),
            tutor_id: '',
            tutor_name: '',
            tutor_type: '보조강사',
            class_hours: classHours,
            program_id: programId,
            start_date: programStartDate,
            end_date: programEndDate,
            is_delete: false,
        },
        {
            id: uuidv4(),
            tutor_id: '',
            tutor_name: '',
            tutor_type: '보조강사',
            class_hours: classHours,
            program_id: programId,
            start_date: programStartDate,
            end_date: programEndDate,
            is_delete: false,
        },
    ]

    const initialTutorList =
        instructor_matching && instructor_matching.length > 0
            ? instructor_matching.map(tutor => ({ ...tutor, id: uuidv4() }))
            : defaultTutorList
    const [tutorList, setTutorList] = useState(initialTutorList)
    const [optionTutor, setOptionTutor] = useState([])

    useEffect(() => {
        const fetchAndSetTutors = async search => {
            try {
                const response = await fetchMatchTutorList(search, userInfo.regionId)
                if (response.status === 200) {
                    const tutors = response?.data?.tutorInfoList
                    if (tutors && Array.isArray(tutors)) {
                        const options = tutors.map(tutor => ({
                            key: uuidv4(),
                            tutor_id: tutor?.tutorId,
                            value: tutor?.tutorName,
                            label: tutor?.tutorName,
                        }))
                        setOptionTutor(options)
                    } else {
                        console.error('Invalid tutor data structure:', tutors)
                    }
                } else {
                    console.error('Failed to fetch tutor list. Status:', response.status)
                }
            } catch (error) {
                console.error('Error while fetching tutor list:', error)
            }
        }

        const { search } = location
        const searchPath = search.split('?')[1] || ''
        fetchAndSetTutors(searchPath)
    }, [])

    const handleAddListItem = () => {
        setTutorList(prevList => [
            ...prevList,
            {
                id: uuidv4(),
                tutor_id: '',
                tutor_name: '',
                tutor_type: '',
                class_hours: null,
                program_id: programId,
                start_date: programStartDate,
                end_date: programEndDate,
                is_delete: false,
            },
        ])
    }

    const handleChange = (field, id, newValue) => {
        let updatedList = []
        // 비고란 분기처리
        if (field === 'remark') {
            const remarkValue = newValue.target.value
            updatedList = tutorList.map(tutor => (tutor.id === id ? { ...tutor, [field]: remarkValue } : tutor))
        } else {
            updatedList = tutorList.map(tutor => (tutor.id === id ? { ...tutor, [field]: newValue } : tutor))
        }
        setTutorList(updatedList)
        onChange(updatedList)
    }

    const handleTutorChange = (id, newValue, option) => {
        const updatedList = tutorList.map(tutor =>
            tutor.id === id ? { ...tutor, tutor_name: newValue, tutor_id: option.tutor_id } : tutor,
        )
        setTutorList(updatedList)
        onChange(updatedList)
    }

    const handleRemoveListItem = id => {
        const newTutorList = tutorList.filter(tutor => tutor.id !== id)
        setTutorList(newTutorList)
        onChange(newTutorList)
    }

    return (
        <div className={styles.box_lg}>
            <Title size="lg" title="강사 매칭 등록" />
            <div className={`${styles.basic_font} ${styles.mb}`}>{decription}</div>
            <div className={styles.border_style}>
                {tutorList && tutorList.length > 0 ? (
                    <div>
                        {tutorList.map(tutor => (
                            <div key={tutor.id} className={styles.row_item}>
                                <Row
                                    justify="space-between"
                                    className={`${styles.padding_y_small} ${styles.padding_x_medium} ${styles.box_lg}`}
                                >
                                    <Col span={7}>
                                        <Flex gap={20}>
                                            <Form.Item name={`tutor_type-${tutor.id}`} initialValue={tutor?.tutor_type}>
                                                <SelectBox
                                                    name={`tutor_type-${tutor.id}`}
                                                    value={tutor?.tutor_type}
                                                    placeholder="강사유형"
                                                    style={{ height: '50px' }}
                                                    onChange={value => handleChange('tutor_type', tutor.id, value)}
                                                    options={[
                                                        { value: '주강사', label: '주강사' },
                                                        { value: '보조강사', label: '보조강사' },
                                                    ]}
                                                />
                                            </Form.Item>
                                            <Form.Item name={`tutor_name-${tutor.id}`} initialValue={tutor?.tutor_name}>
                                                <Select
                                                    className={`${styles.ant_select_selector} `}
                                                    showSearch
                                                    placeholder="강사선택"
                                                    options={optionTutor}
                                                    onChange={(value, option) =>
                                                        handleTutorChange(tutor.id, value, option)
                                                    }
                                                    style={{ height: '50px' }}
                                                    value={tutor?.tutor_name}
                                                    suffixIcon={<ArrowBtmIcon />}
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? '').includes(input)
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? '')
                                                            .toLowerCase()
                                                            .localeCompare((optionB?.label ?? '').toLowerCase())
                                                    }
                                                />
                                            </Form.Item>
                                        </Flex>
                                    </Col>
                                    <Col span={5}>
                                        <Flex align="center" justify="space-around" className={styles.basic_font}>
                                            <span>
                                                강의시간 <span className={styles.asterisk}>*</span>
                                            </span>
                                            <TextField
                                                size="xs"
                                                name={`class_hours-${tutor.id}`}
                                                onChange={value => handleChange('class_hours', tutor.id, value)}
                                                val={tutor?.class_hours}
                                                addonAfter="20"
                                                addonBefore="강의시간"
                                            />
                                            시간
                                        </Flex>
                                    </Col>
                                    <Col span={5}>
                                        <Form.Item name={`remark-${tutor.id}`} initialValue={tutor?.remark}>
                                            <Flex
                                                gap={1}
                                                align="center"
                                                justify="space-evenly"
                                                className={styles.basic_font}
                                            >
                                                <span className={styles.remark}>비고</span>
                                                <TextArea
                                                    placeholder=""
                                                    className={styles.remark_text}
                                                    value={tutor?.remark}
                                                    onChange={value => handleChange('remark', tutor.id, value)}
                                                    rows={2}
                                                    style={{ resize: 'none' }}
                                                />
                                            </Flex>
                                        </Form.Item>
                                    </Col>
                                    <Col span={2} className={styles.padding_top}>
                                        <Button className={styles.btn} onClick={() => handleRemoveListItem(tutor.id)}>
                                            <GrayTrashIcon24 />
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className={styles.not_found_page_wrap}>
                        <div className={styles.not_found_page_top}>
                            <span className={styles.not_found_page_icon} />
                            <div className={styles.not_found_page_title}>
                                <span className="sub_title1">강사를 추가해주세요.</span>
                            </div>
                        </div>
                    </div>
                )}
                <Button className={styles.btn_add} onClick={handleAddListItem}>
                    <PlusIcon />
                </Button>
            </div>
        </div>
    )
}

export default TutorInfoList
