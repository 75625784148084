import CheckBox from 'components/AntD/CheckBox/type1'
import { useState } from 'react'
import Title from 'components/Ui/Title'
import Label from 'components/Ui/Label'
import { Col, Form, Row } from 'antd'
import TextField from 'components/AntD/TextField'
import style from 'assets/scss/detail.module.scss'
import Value from '../../Ui/Value'

const EtcInfo = ({ etc_info, editOrRegisterPage }) => {
    const [etcInfoState] = useState(etc_info)

    return (
        <div>
            <Title title="기타" />
            <div className={`${style.border_style} ${style.box_md}`}>
                <Row className={style.row_item}>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="컴퓨터 구비여부" />
                            </Col>

                            <Col span={14} className={style.padding_y_xs}>
                                {editOrRegisterPage ? (
                                    <Form.Item name="computerYn" initialValue={etcInfoState?.computerYn}>
                                        <CheckBox
                                            name="computerYn"
                                            checked={etcInfoState?.computerYn}
                                            width="48"
                                            height="48"
                                        />
                                    </Form.Item>
                                ) : (
                                    <CheckBox disabled checked={etcInfoState?.computerYn} width="48" height="48" />
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="배정번호" />
                            </Col>
                            {editOrRegisterPage ? (
                                <Col span={14} className={style.padding_y_xs}>
                                    <Form.Item
                                        name="numberOfAttendance"
                                        initialValue={etcInfoState?.numberOfAttendance}
                                    >
                                        <TextField
                                            name="numberOfAttendance"
                                            size="sm"
                                            val={etcInfoState?.numberOfAttendance}
                                        />
                                    </Form.Item>
                                </Col>
                            ) : (
                                <Col span={14} className={style.padding_y_medium}>
                                    <Value value={etcInfoState?.numberOfAttendance} />
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
                <Row className={style.row_item}>
                    <Col span={5}>
                        <Label label="교보재" />
                    </Col>
                    {editOrRegisterPage ? (
                        <Col span={19} className={style.padding_y_xs}>
                            <Form.Item name="teachingMaterials" initialValue={etcInfoState?.teachingMaterials}>
                                <TextField name="teachingMaterials" val={etcInfoState?.teachingMaterials} size="xl" />
                            </Form.Item>
                        </Col>
                    ) : (
                        <Col span={14} className={style.padding_y_medium}>
                            <Value value={etc_info?.teachingMaterials} />
                        </Col>
                    )}
                </Row>
            </div>
        </div>
    )
}
export default EtcInfo
