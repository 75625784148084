import dayjs from 'dayjs'
import React from 'react'

import { Col, DatePicker, Form, Row } from 'antd'

import styles from 'components/Report/report.module.scss'
import { useLocation } from 'react-router-dom'

const DateSection = ({
    formattedString,
    monthFormat,
    onChangeDatePicker,
    sysCreatDate,
    sysModDtime,
    sysModDate,
    today,
}) => {
    const { pathname } = useLocation()
    const paths = pathname.split('/').filter(path => path !== '')
    return (
        <div className={`${styles.card} ${styles.section_lg}`}>
            <Row>
                <Col span={3}>
                    <span className={`${styles.label} ${styles.height_100}`}>
                        작성월{' '}
                        {(paths[1] === 'register' || paths[1] === 'edit') && <span className={styles.red}>*</span>}
                    </span>
                </Col>
                <Col span={5} className={`${styles.padding_12} ${styles.align_center}`}>
                    {paths[1] === 'register' || paths[1] === 'edit' ? (
                        <Form.Item
                            name="monthOfReport"
                            initialValue={dayjs(formattedString, 'YYYY-MM')}
                            rules={[{ required: true, message: '' }]}
                            noStyle
                        >
                            <DatePicker
                                format={monthFormat}
                                picker="month"
                                onChange={onChangeDatePicker}
                                placeholder="날짜를 선택해주세요"
                                className="report_date_picker"
                            />
                        </Form.Item>
                    ) : (
                        <span className={styles.text}>{formattedString === 'Invalid Date' ? '' : formattedString}</span>
                    )}
                </Col>
                <Col span={4}>
                    <span className={`${styles.label} ${styles.height_100}`}>작성일자 </span>
                </Col>
                <Col span={5} className={`${styles.value} ${styles.padding_10}`}>
                    <span>{sysCreatDate || today} </span>
                </Col>
                {sysModDtime && (
                    <>
                        <Col span={3}>
                            <span className={`${styles.label} ${styles.height_100}`}>수정일자</span>
                        </Col>
                        <Col span={3} className={`${styles.value} ${styles.padding_10}`}>
                            <span>{sysModDate}</span>
                        </Col>
                    </>
                )}
            </Row>
        </div>
    )
}

export default DateSection
