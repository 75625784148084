import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { getSurveyByUuId } from 'api/survey/survey.api'

import surveyData from 'mocks/data/survey/survey.json'
import { BannerSurveyIcon, BannerSurveySuccessIcon } from 'assets/Icons'

import Spinner from 'components/AntD/Spin'
import SanitizedHTML from 'components/sanitizeHTML'
import { SurveyWrapper } from 'components/Survey/SurveyWrapper'

import styles from 'assets/scss/detail.module.scss'
// import survey from './survey.module.scss'

const SurveySubmit = () => {
    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)
    const [isExisting, setIsExisting] = useState(false) // 수요조사 존재 여부
    const [isSuccess, setIsSuccess] = useState(false) // 설문 응답 완료 여부
    const [surveyDetail, setSurveyDetail] = useState(null)
    const { surveyContent, surveyName } = surveyDetail || {}

    const { id } = useParams()

    useEffect(() => {
        const fetchSurvey = async () => {
            try {
                const response = await getSurveyByUuId(id)
                if (response && response.length > 0) {
                    setSurveyDetail(response[0])
                    setIsExisting(true)
                } else {
                    setIsExisting(false)
                    navigate('/')
                }
            } catch (error) {
                console.error('Error fetching survey:', error)
            } finally {
                setLoading(false)
            }
        }

        fetchSurvey()
    }, [id])

    useEffect(() => {
        if (isSuccess) {
            window.scrollTo({ top: 0, behavior: 'auto' })
        }
    }, [isSuccess])

    return (
        <div>
            {loading ? (
                <div className={`${styles.m_auto} ${styles.box_md} ${styles.area}`}>
                    <div className={styles.spin_wrap}>
                        <Spinner />
                    </div>
                </div>
            ) : isExisting ? (
                <>
                    <div className={styles.banner}>
                        <div className={`${styles.title} h4`}>{surveyName}</div>
                        <div className={styles.icon}>
                            <BannerSurveyIcon />
                        </div>
                    </div>
                    <div className={`${styles.m_auto} ${styles.box_md} ${styles.area} ${styles.padding_y_xlarge}`}>
                        <div className={styles.field}>
                            <div className={styles.content}>
                                <SanitizedHTML html={surveyContent} />
                            </div>
                        </div>
                        {isSuccess ? (
                            <div className={`${styles.success}`}>
                                <div className={styles.icon}>
                                    <BannerSurveySuccessIcon />
                                </div>
                                <div className="h5">수요조사 등록이 완료되었습니다.</div>
                            </div>
                        ) : (
                            <SurveyWrapper
                                json={{
                                    type: 'panel',
                                    name: '수요조사',
                                    questions: surveyData[0]?.questions,
                                }}
                                setIsSuccess={setIsSuccess}
                            />
                        )}
                    </div>
                </>
            ) : (
                <>없음</>
            )}
        </div>
    )
}

export default SurveySubmit
