import { useLocation } from 'react-router-dom'

import { Col, Row, Form, InputNumber } from 'antd'
import { v4 as uuidv4 } from 'uuid'

import { formatCurrency, formatNumberToThousands, numberParser } from 'utils/utilCommon'

import styles from './report.module.scss'

const SectionRow = ({ title, rows, total, name, length }) => {
    const commonStyles = `${styles.right} ${styles.padding_10} ${styles.align_center_inline}`
    const { pathname } = useLocation()
    const paths = pathname.split('/').filter(path => path !== '')

    return (
        <div className={styles.card}>
            <div className={`${styles.head} ${styles.bottom_line}`}>
                <span className="h7">{title}</span>
            </div>
            <Row
                className={`${styles.bottom_line} ${styles.section_table} ${styles.space_between} ${
                    (paths[1] === 'register' || paths[1] === 'edit') && length !== 3 ? styles.h245 : ''
                } ${(paths[1] !== 'register' || paths[1] !== 'edit') && length !== 3 ? styles.h176 : ''}`}
            >
                {rows.slice(0, length).map(row => (
                    <>
                        <Col
                            span={9}
                            className={`${styles.padding_10} ${styles.label} ${styles.align_center}`}
                            key={uuidv4()}
                        >
                            {row.label}{' '}
                            {(paths[1] === 'register' || paths[1] === 'edit') && title !== '누적 집행 금액' && (
                                <span className={styles.red}>*</span>
                            )}
                        </Col>
                        <Col span={15} className={`${commonStyles} ${styles.ellipsis}`} key={uuidv4()}>
                            {(paths[1] === 'register' || paths[1] === 'edit') && name !== 'accumulatedTotal' ? (
                                <Form.Item name={row.name} rules={[{ required: true }]} noStyle>
                                    <InputNumber
                                        name={row.name}
                                        placeholder={row.placeholder}
                                        className={`${styles.ant_input} ${styles.w150} ${styles.no_number_handler}`}
                                        defaultValue={row.value || ''}
                                        formatter={formatNumberToThousands}
                                        parser={numberParser}
                                        onKeyDown={e => (e.keyCode === 13 ? e.preventDefault() : '')}
                                    />
                                </Form.Item>
                            ) : (
                                <span className="sub_title2">{formatCurrency(row.value)}</span>
                            )}
                        </Col>
                    </>
                ))}
            </Row>
            <Row className={`${styles.right} ${styles.section_total}`}>
                <Col span={24} className={styles.margin_b10}>
                    <span className="h7">Total</span>
                </Col>
                <Col span={24}>
                    <span className={`sub_title1 ${styles.red}`}>
                        {name !== 'accumulatedTotal' ? (
                            <Form.Item name={name} noStyle>
                                <InputNumber
                                    className={`${styles.right} ${styles.total} ${styles.no_number_handler}`}
                                    style={{ width: '200px' }}
                                    size="large"
                                    formatter={formatNumberToThousands}
                                    parser={numberParser}
                                    defaultValue={total || 0}
                                    onKeyDown={e => (e.keyCode === 13 ? e.preventDefault() : '')}
                                    disabled
                                />
                            </Form.Item>
                        ) : (
                            formatCurrency(total)
                        )}
                    </span>
                </Col>
            </Row>
        </div>
    )
}

export default SectionRow
