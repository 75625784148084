/* eslint-disable react/jsx-props-no-spreading */
import styles from './checkBox.module.scss'

const CheckBox = ({ width, height, id, name, ...props }) => {
    return (
        <label className={styles['checkbox-label']} htmlFor={id}>
            <span className="checkbox-span">
                <input
                    className={styles['checkbox-input']}
                    type="checkbox"
                    id={id}
                    style={{ width: `${width}px` || 'auto', height: `${height}px` || 'auto' }}
                    {...props}
                />
            </span>
            <span className="checkbox-name">{name}</span>
        </label>
    )
}

export default CheckBox
