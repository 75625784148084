import { useLocation } from 'react-router-dom'

import styles from './register.module.scss'

const SubContent = () => {
    const { pathname } = useLocation()
    const path = pathname.split('/')[1]

    let instruction = ''
    let additionalInstruction = ''

    if (path === 'program') {
        instruction = '교육 프로그램 목록'
        additionalInstruction = '교육 프로그램 등록은 미리 작성된 프로그램 목록 파일을 이용하여 실행하는 기능입니다.'
    } else if (path === 'tutors') {
        instruction = '강사정보 목록'
        additionalInstruction = '강사 정보 등록은 미리 작성된 프로그램 목록 파일을 이용하여 실행하는 기능입니다.'
    } else if (path === 'tools') {
        instruction = '교구재 목록'
    }

    return (
        <div className={styles.sub_content}>
            <div>
                <span className={styles.exclamation_mark} />
            </div>
            <ul>
                <li>입력하신 교육 정보는 &quot;{instruction}&quot; 단계에서 개별 수정하실 수 있습니다.</li>
                {additionalInstruction && <li>{additionalInstruction}</li>}
                <li>양식의 구조에 맞춰서 작성하신 파일을 등록하시면 됩니다.</li>
                <li>{instruction && `${instruction} 등록은 파일당 100건까지 가능합니다.`}</li>
            </ul>
        </div>
    )
}

export default SubContent
