import { Flex } from 'antd'
import styles from './label.module.scss'

const Label = ({ label, addonAfter, addonBefore, required }) => {
    return (
        <Flex className={styles.label}>
            {addonBefore && (
                <span className={styles.addon} style={{ marginRight: 20 }}>
                    {addonBefore}
                </span>
            )}
            <span>
                {label} {required && <span className={styles.asterisk}>*</span>}
            </span>
            {addonAfter && (
                <span style={{ marginLeft: 20 }} className={styles.addon}>
                    {addonAfter}
                </span>
            )}
        </Flex>
    )
}

export default Label
