import { Modal, Table } from 'antd'
import ColorButton from 'components/AntD/Button/ColorButton'
import { AllDocIcon } from 'assets/Icons'
import * as XLSX from 'xlsx'
import { useState, useEffect } from 'react'
import { convertTutorExcelColumnToKor } from 'utils/utilCommon'

import styles from 'pages/Statistics/statistics.module.scss'

const StatisticsTutorTableDialog = ({ selectedYear, dataSource, modalOpen, setModalOpen, closable = false }) => {
    const [data, setData] = useState([])

    useEffect(() => {
        setData(dataSource || [])
    }, [dataSource])

    const handleCancel = e => {
        e.preventDefault()
        setModalOpen(false)
    }

    const handleDownloadXlsx = () => {
        const convertedData = convertTutorExcelColumnToKor(data)
        const ws = XLSX.utils.json_to_sheet(convertedData)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

        const fileName = `강사통계_${selectedYear}`
        XLSX.writeFile(wb, `${fileName}.xlsx`)
    }

    const columnsFull = [
        {
            title: '지역',
            dataIndex: 'region',
            key: 'region',
            fixed: 'left',
        },
        {
            title: '강사채용수',
            dataIndex: 'hired_count',
            key: 'hired_count',
        },
        {
            title: '채용율',
            dataIndex: 'hired_ratio',
            key: 'hired_ratio',
        },
        {
            title: '이탈인원 포함 합계',
            dataIndex: 'total_with_fail',
            key: 'total_with_fail',
        },
        {
            title: '신규강사',
            dataIndex: 'new_employee',
            key: 'new_employee',
        },

        {
            title: '재고용강사',
            dataIndex: 'reemployee',
            key: 'reemployee',
        },
        {
            title: '청년',
            dataIndex: 'youth',
            key: 'youth',
        },
        {
            title: '경력단절여성',
            dataIndex: 'career_stop_woman',
            key: 'career_stop_woman',
        },
        {
            title: '전문퇴직자',
            dataIndex: 'retired_expert',
            key: 'retired_expert',
        },
        {
            title: '20~29세',
            dataIndex: 'age_20s',
            key: 'age_20s',
        },
        {
            title: '30~39세',
            dataIndex: 'age_30s',
            key: 'age_30s',
        },
        {
            title: '40~49세',
            dataIndex: 'age_40s',
            key: 'age_40s',
        },
        {
            title: '50대이상',
            dataIndex: 'age_over_50',
            key: 'age_over_50',
        },
        {
            title: '남성',
            dataIndex: 'male',
            key: 'male',
        },
        {
            title: '여성',
            dataIndex: 'female',
            key: 'female',
        },
        {
            title: '창업(사업자등록완료)',
            dataIndex: 'own_bussiness',
            key: 'own_bussiness',
        },
        {
            title: '정규직',
            dataIndex: 'full_time_contract',
            key: 'full_time_contract',
        },
        {
            title: '계약직',
            dataIndex: 'contract_worker',
            key: 'contract_worker',
        },
        {
            title: '주 40시간 이상',
            dataIndex: 'over_40_week',
            key: 'over_40_week',
        },
        {
            title: '주 15 ~ 39시간',
            dataIndex: 'work_15to39_week',
            key: 'work_15to39_week',
        },
        {
            title: '주 15시간 미만',
            dataIndex: 'under_15_week',
            key: 'under_15_week',
        },
        {
            title: '4대보험 전부가입',
            dataIndex: 'full_insurance',
            key: 'full_insurance',
        },
        {
            title: '4대보험 일부가입',
            dataIndex: 'partial_insurance',
            key: 'partial_insurance',
        },
        {
            title: '4대보험 미가입',
            dataIndex: 'no_insurance',
            key: 'no_insurance',
        },
        {
            title: '정규직 (무기계약직)',
            dataIndex: 'indefine_contract',
            key: 'indefine_contract',
        },
        {
            title: '21개월~30개월(2차 재고용)',
            dataIndex: 'secondary_recontract',
            key: 'secondary_recontract',
        },
        {
            title: '11개월~20개월(1차 재고용)',
            dataIndex: 'first_recontract',
            key: 'first_recontract',
        },
        {
            title: '10개월 이내(최초고용)',
            dataIndex: 'first_contract',
            key: 'first_contract',
        },
        {
            title: 'ICTSW관련전공',
            dataIndex: 'ictswY',
            key: 'ictswY',
        },
        {
            title: 'ICTSW관련없음',
            dataIndex: 'ictswN',
            key: 'ictswN',
        },
        {
            title: '인문과학',
            dataIndex: 'humanity_major',
            key: 'humanity_major',
        },
        {
            title: '사회과학',
            dataIndex: 'social_science_major',
            key: 'social_science_major',
        },
        {
            title: '자연과학',
            dataIndex: 'natural_science_major',
            key: 'natural_science_major',
        },
        {
            title: '공학',
            dataIndex: 'tech_major',
            key: 'tech_major',
        },
        {
            title: '예체능',
            dataIndex: 'art_major',
            key: 'art_major',
        },
        {
            title: '기타전공',
            dataIndex: 'etc_major',
            key: 'etc_major',
        },
        {
            title: 'SW강의경험 없음',
            dataIndex: 'no_sw_experience',
            key: 'no_sw_experience',
        },
        {
            title: '1년 미만',
            dataIndex: 'sw_experience_under_year',
            key: 'sw_experience_under_year',
        },
        {
            title: '1년이상 3년미만',
            dataIndex: 'sw_experience_one_to_three',
            key: 'sw_experience_one_to_three',
        },
        {
            title: '3년이상',
            dataIndex: 'sw_experience_over_three',
            key: 'sw_experience_over_three',
        },
        {
            title: '기타 강의경험 없음',
            dataIndex: 'no_lec_experience',
            key: 'no_lec_experience',
        },
        {
            title: '1년 미만',
            dataIndex: 'lec_experience_under_year',
            key: 'lec_experience_under_year',
        },
        {
            title: '1년이상 3년미만',
            dataIndex: 'lec_experience_one_to_three',
            key: 'lec_experience_one_to_three',
        },
        {
            title: '3년이상',
            dataIndex: 'lec_experience_over_three',
            key: 'lec_experience_over_three',
        },
        {
            title: '강사 평균임금',
            dataIndex: 'average_wage',
            key: 'average_wage',
        },
    ]
    const columns = [
        {
            title: '지역',
            dataIndex: 'region',
            key: 'region',
            fixed: 'left',
        },
        {
            title: '강사채용수',
            dataIndex: 'hired_count',
            key: 'hired_count',
        },
        // {
        //     title: '채용율',
        //     dataIndex: 'hired_ratio',
        //     key: 'hired_ratio',
        // },
        // {
        //     title: '이탈인원 포함 합계',
        //     dataIndex: 'total_with_fail',
        //     key: 'total_with_fail',
        // },
        {
            title: '신규강사',
            dataIndex: 'new_employee',
            key: 'new_employee',
        },

        {
            title: '재고용강사',
            dataIndex: 'reemployee',
            key: 'reemployee',
        },
        {
            title: '청년',
            dataIndex: 'youth',
            key: 'youth',
        },
        {
            title: '경력단절여성',
            dataIndex: 'career_stop_woman',
            key: 'career_stop_woman',
        },
        {
            title: '전문퇴직자',
            dataIndex: 'retired_expert',
            key: 'retired_expert',
        },
        {
            title: '20~29세',
            dataIndex: 'age_20s',
            key: 'age_20s',
        },
        {
            title: '30~39세',
            dataIndex: 'age_30s',
            key: 'age_30s',
        },
        {
            title: '40~49세',
            dataIndex: 'age_40s',
            key: 'age_40s',
        },
        {
            title: '50대이상',
            dataIndex: 'age_over_50',
            key: 'age_over_50',
        },
        {
            title: '남성',
            dataIndex: 'male',
            key: 'male',
        },
        {
            title: '여성',
            dataIndex: 'female',
            key: 'female',
        },
        // {
        //     title: '창업(사업자등록완료)',
        //     dataIndex: 'own_bussiness',
        //     key: 'own_bussiness',
        // },
        // {
        //     title: '정규직',
        //     dataIndex: 'full_time_contract',
        //     key: 'full_time_contract',
        // },
        // {
        //     title: '계약직',
        //     dataIndex: 'contract_worker',
        //     key: 'contract_worker',
        // },
        // {
        //     title: '주 40시간 이상',
        //     dataIndex: 'over_40_week',
        //     key: 'over_40_week',
        // },
        // {
        //     title: '주 15 ~ 39시간',
        //     dataIndex: 'work_15to39_week',
        //     key: 'work_15to39_week',
        // },
        // {
        //     title: '주 15시간 미만',
        //     dataIndex: 'under_15_week',
        //     key: 'under_15_week',
        // },
        // {
        //     title: '4대보험 전부가입',
        //     dataIndex: 'full_insurance',
        //     key: 'full_insurance',
        // },
        // {
        //     title: '4대보험 일부가입',
        //     dataIndex: 'partial_insurance',
        //     key: 'partial_insurance',
        // },
        // {
        //     title: '4대보험 미가입',
        //     dataIndex: 'no_insurance',
        //     key: 'no_insurance',
        // },
        // {
        //     title: '정규직 (무기계약직)',
        //     dataIndex: 'indefine_contract',
        //     key: 'indefine_contract',
        // },
        // {
        //     title: '21개월~30개월(2차 재고용)',
        //     dataIndex: 'secondary_recontract',
        //     key: 'secondary_recontract',
        // },
        // {
        //     title: '11개월~20개월(1차 재고용)',
        //     dataIndex: 'first_recontract',
        //     key: 'first_recontract',
        // },
        // {
        //     title: '10개월 이내(최초고용)',
        //     dataIndex: 'first_contract',
        //     key: 'first_contract',
        // },
        // {
        //     title: 'ICTSW관련전공',
        //     dataIndex: 'ictswY',
        //     key: 'ictswY',
        // },
        // {
        //     title: 'ICTSW관련없음',
        //     dataIndex: 'ictswN',
        //     key: 'ictswN',
        // },
        // {
        //     title: '인문과학',
        //     dataIndex: 'humanity_major',
        //     key: 'humanity_major',
        // },
        // {
        //     title: '사회과학',
        //     dataIndex: 'social_science_major',
        //     key: 'social_science_major',
        // },
        // {
        //     title: '자연과학',
        //     dataIndex: 'natural_science_major',
        //     key: 'natural_science_major',
        // },
        // {
        //     title: '공학',
        //     dataIndex: 'tech_major',
        //     key: 'tech_major',
        // },
        // {
        //     title: '예체능',
        //     dataIndex: 'art_major',
        //     key: 'art_major',
        // },
        // {
        //     title: '기타전공',
        //     dataIndex: 'etc_major',
        //     key: 'etc_major',
        // },
        {
            title: 'SW강의경험 없음',
            dataIndex: 'no_sw_experience',
            key: 'no_sw_experience',
        },
        // {
        //     title: '1년 미만',
        //     dataIndex: 'sw_experience_under_year',
        //     key: 'sw_experience_under_year',
        // },
        // {
        //     title: '1년이상 3년미만',
        //     dataIndex: 'sw_experience_one_to_three',
        //     key: 'sw_experience_one_to_three',
        // },
        // {
        //     title: '3년이상',
        //     dataIndex: 'sw_experience_over_three',
        //     key: 'sw_experience_over_three',
        // },
        {
            title: '기타 강의경험 없음',
            dataIndex: 'no_lec_experience',
            key: 'no_lec_experience',
        },
        // {
        //     title: '1년 미만',
        //     dataIndex: 'lec_experience_under_year',
        //     key: 'lec_experience_under_year',
        // },
        // {
        //     title: '1년이상 3년미만',
        //     dataIndex: 'lec_experience_one_to_three',
        //     key: 'lec_experience_one_to_three',
        // },
        // {
        //     title: '3년이상',
        //     dataIndex: 'lec_experience_over_three',
        //     key: 'lec_experience_over_three',
        // },
        // {
        //     title: '강사 평균임금',
        //     dataIndex: 'average_wage',
        //     key: 'average_wage',
        // },
    ]
    return (
        <Modal
            title={`${selectedYear}년 강사 통계`}
            visible={modalOpen}
            onOk={() => setModalOpen(false)}
            onCancel={() => setModalOpen(false)}
            closable={closable}
            width={1200}
            footer={[
                <ColorButton key="download" onClick={handleDownloadXlsx} icon={<AllDocIcon />}>
                    엑셀 다운로드
                </ColorButton>,
                <ColorButton key="cancel" onClick={handleCancel}>
                    취소
                </ColorButton>,
            ]}
        >
            <Table
                className={styles.table}
                dataSource={data}
                columns={selectedYear === '2024' ? columns : columnsFull}
                pagination={false}
                scroll={{ x: selectedYear === '2024' ? 3000 : 6000 }}
            />
        </Modal>
    )
}

export default StatisticsTutorTableDialog
