import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getReportTotal, postReportTotal } from 'api/report/reportTotal.api'
import { getReportAccumulate } from 'api/report/report.api'

/**
 * 총 사업비 get
 * @type {AsyncThunk<unknown, void, AsyncThunkConfig>}
 */
export const getReportTotalAsync = createAsyncThunk(
    'report/total/get',
    async ({ regionId, year }, { rejectWithValue }) => {
        try {
            const response = await getReportTotal({ regionId, year })
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    },
)

/**
 * 총사업비 등록
 * @type {AsyncThunk<unknown, void, AsyncThunkConfig>}
 */
export const postReportTotalAsync = createAsyncThunk(
    'report/total/post',
    async ({ budgetTotal }, { rejectWithValue }) => {
        try {
            console.log('budgetTotal 😁: ', budgetTotal)
            const response = await postReportTotal(budgetTotal)
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    },
)

/**
 * 누적 집행금액 get
 * 기준 날짜의 당해연도 1월부터 작성월 전달까지의 합산 금액 표현
 * ex) 기준날짜 : 2023-12-12
 * ex) 결과값 : 2023년 1월 ~ 2023 11월까지의 누적 집행금액
 * @type {AsyncThunk<unknown, AsyncThunk<?, void, AsyncThunkConfig>, AsyncThunkConfig>}
 */
export const getReportAccumulateAsync = createAsyncThunk(
    'report/accumulate/get',
    async ({ regionId, month }, { rejectWithValue }) => {
        try {
            const response = await getReportAccumulate(regionId, month)
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    },
)

const initialState = {
    isFetching: false,
    reportTotal: null, // 총사업비
    accumulatedAmount: null, // 누적 집행금액
    error: null,
}

const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getReportTotalAsync.pending, state => {
                state.isFetching = true
            })
            .addCase(getReportTotalAsync.fulfilled, (state, { payload }) => {
                const total = payload.data[0]
                state.isFetching = false
                state.reportTotal = total
            })
            .addCase(getReportTotalAsync.rejected, (state, { error }) => {
                state.isFetching = false
                state.error = error.message
                state.reportTotal = null
            })
            .addCase(postReportTotalAsync.pending, state => {
                state.isFetching = true
            })
            .addCase(postReportTotalAsync.fulfilled, (state, { payload }) => {
                const total = payload.data[0]
                state.isFetching = false
                state.reportTotal = total
            })
            .addCase(postReportTotalAsync.rejected, (state, { error }) => {
                state.isFetching = false
                state.error = error.message
            })
            .addCase(getReportAccumulateAsync.pending, state => {
                state.isFetching = true
            })
            .addCase(getReportAccumulateAsync.fulfilled, (state, { payload }) => {
                // const amount = payload.data[0]
                // 각 속성별로 합계를 저장할 변수 초기화
                let sumGovernmentFunds = 0
                let sumLocalCashFunds = 0
                let sumTotal = 0

                // TODO: 배열 길이 확인
                // 배열의 각 요소에 대해 반복하면서 합산 수행
                payload.data.forEach(item => {
                    sumGovernmentFunds += parseFloat(item.monthly_budget_execution_government_funds) || 0
                    sumLocalCashFunds += parseFloat(item.monthly_budget_execution_local_cash_funds) || 0
                    sumTotal += parseFloat(item.monthly_budget_execution_total) || 0
                })
                const accumulatedJson = {
                    executionGovernmentFunds: sumGovernmentFunds,
                    executionLocalCashFunds: sumLocalCashFunds,
                    executionTotal: sumTotal,
                }
                state.accumulatedAmount = accumulatedJson
                state.isFetching = false
            })
            .addCase(getReportAccumulateAsync.rejected, (state, { error }) => {
                state.isFetching = false
                state.accumulatedAmount = null
                state.error = error.message
            })
    },
})

export const getReportInfoAll = state => state.report
export const getReportTotalInfo = state => state.report.reportTotal
export const getReportAccumulatedAmountInfo = state => state.report.accumulatedAmount

export default reportSlice
