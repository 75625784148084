import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { getProgramList } from 'api/program/program.api'

import { BannerIconProgram } from 'assets/Icons'
import PageBanner from 'layout/PageBanner'

import Spinner from 'components/AntD/Spin'
import GridList from 'components/List/GridList'
import ListView from 'components/List/ListView'
import Pagenation from 'components/Pagenation'
import FilterAndSearchArea from 'components/Filter'

import { useDispatch, useSelector } from 'react-redux'
import { getAllUserInfo } from 'store/Slices/user'
import { resetProgram } from 'store/Slices/programs'

import styles from 'assets/scss/detail.module.scss'

import ListNotFound from '../PageNotFound/ListNotFound'

const search = 'search'

const Program = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const path = pathname.split('/')[1]
    const { isLogin, userInfo } = useSelector(getAllUserInfo)
    const queryParams = new URLSearchParams(window.location.search)

    const [sortName, setSortName] = useState('createdDt')
    const [isGridView, setIsGridView] = useState(true)
    const [programs, setProgramList] = useState(null)
    const [isLoading, setLoading] = useState(true)
    const [inputValue, setInputValue] = useState('') // 검색 input
    const [selectOption, setSelectOption] = useState('programName') // 교육관리에서 검색시 셀렉트 박스

    const { filterCountInfo, programList, page } = programs || {}
    const { currentPage, totalPages, totalItems } = page || {}

    /**
     * querystring update
     * @param paramName
     * @param paramValue
     * @param loading
     */
    const updateQueryStringAndNavigate = (paramName, paramValue) => {
        const { pathname } = location
        const previousParams = queryParams.get(paramName)
        // 새로운 파라미터 추가 (단, 값이 비어있지 않을 때)
        if (paramValue) {
            if (previousParams === paramValue && paramName !== 'sort' && paramName !== 'search') {
                queryParams.delete(paramName)
            } else {
                if (paramName === 'search') {
                    queryParams.set('select', selectOption)
                }
                queryParams.set(paramName, paramValue)
            }
        }

        // 정렬 active
        if (paramName === 'sort') {
            setSortName(paramValue)
        }
        if (paramName === 'search' && paramValue === '') {
            queryParams.delete(paramName)
            queryParams.delete('select')
        }

        if (paramName === 'startDate' && paramValue === '') {
            queryParams.delete(paramName)
        }

        if (paramName === 'endDate' && paramValue === '') {
            queryParams.delete(paramName)
        }
        // 필터 선택 시 쿼리스트링에 포함된 페이지 정보 제거
        if (paramName !== 'page') {
            queryParams.delete('page')
        }

        const newQueryString = queryParams.toString()
        const newLocation = newQueryString ? `${pathname}?${newQueryString}` : pathname
        navigate(newLocation)
    }

    // 검색 함수
    const handleSearch = () => {
        setLoading(true)
        updateQueryStringAndNavigate(search, inputValue, setLoading)
    }

    const fetchProgramList = async search => {
        try {
            setLoading(true)
            const data = await getProgramList(search, userInfo.regionId, path)
            if (data.status === 200 || data.status === 206) {
                setProgramList(data.data)
                setLoading(false)
                return true
            }
            console.error('프로그램 목록을 불러오는 데 문제가 발생했습니다.')
            setLoading(false)
            navigate('/')
            return false
        } catch (error) {
            console.error('프로그램 목록을 불러오는 동안 오류가 발생했습니다.', error)
            return null
        }
    }

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (isLogin) {
            const { search } = location
            const searchPath = search.split('?')[1] || ''

            dispatch(resetProgram())

            fetchProgramList(searchPath)
        } else {
            navigate('/')
        }
    }, [location])

    return (
        <PageBanner title="교육계획 관리" bannerIcon={<BannerIconProgram />}>
            <FilterAndSearchArea
                filterCount={filterCountInfo}
                updateQueryStringAndNavigate={updateQueryStringAndNavigate}
                handleSearch={handleSearch}
                sortName={sortName}
                isGridView={isGridView}
                setIsGridView={setIsGridView}
                setInputValue={setInputValue}
                inputValue={inputValue}
                setSelectOption={setSelectOption}
            />
            {isLoading ? (
                <div className={styles.spin_wrap}>
                    <Spinner />
                </div>
            ) : !programList || programList.length === 0 ? (
                <ListNotFound />
            ) : (
                <>
                    {isGridView ? (
                        <GridList list={programList} setLoading={setLoading} fetchList={fetchProgramList} />
                    ) : (
                        <ListView
                            list={programList.slice(0, 10)}
                            setLoading={setLoading}
                            fetchList={fetchProgramList}
                        />
                    )}
                    <Pagenation
                        title="프로그램 대량등록"
                        subTitle="프로그램 단건등록"
                        subNavigateBtn={() => navigate('/program/register/one')}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        totalItems={totalItems}
                        navigateBtn={() => navigate('/program/register/bulk')}
                        updateFtn={updateQueryStringAndNavigate}
                    />
                </>
            )}
        </PageBanner>
    )
}

export default Program
