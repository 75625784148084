import { FilterCloseIcon, FilterResetIcon, OpenFilterIcon } from 'assets/Icons'

import { Tooltip } from 'antd'
import { colorTheme } from 'assets/colorTheme'

import styles from '../filter.module.scss'

const ExtraBtn = ({ handleResetQuerystring, isExpand, handleExpandFilter }) => {
    const { gray } = colorTheme || {}

    return (
        <div className={styles.extra_btn_wrap}>
            <Tooltip title="초기화" color={gray[900]}>
                <button
                    type="button"
                    className={`${styles.reset} button-reset-type`}
                    onClick={() => handleResetQuerystring()}
                >
                    <FilterResetIcon />
                </button>
            </Tooltip>
            <Tooltip title={isExpand ? '접기' : '열기'} color={gray[900]}>
                <button
                    type="button"
                    className={`${styles.expand} button-reset-type`}
                    onClick={() => handleExpandFilter()}
                >
                    {isExpand ? <FilterCloseIcon /> : <OpenFilterIcon />}
                </button>
            </Tooltip>
        </div>
    )
}

export default ExtraBtn
