/* eslint-disable react/jsx-props-no-spreading */
import { ConfigProvider, theme, Button } from 'antd'

import { colorTheme } from 'assets/colorTheme'

const { gray, blue, green } = colorTheme
const { useToken } = theme

/**
 * size가 props로 들어 왔을 때
 * size별 styling
 * @param sizeStyle
 * @param type
 * @param override
 * @param active 필터 버튼
 * @param filterColor 필터 컬러
 * @returns {*|{padding: string, alignItems: string, color: string|string, textAlign: string, display: string, fontStyle: string, justifyContent: string, fontFamily: string, gap: string, fontSize: string, lineHeight: string, fontWeight: number, height: string}}
 */
const getSizeStyle = (sizeStyle, type, override, active, filterColor) => {
    /**
     * 필터버튼에서 사용
     * @param color
     * @param activeStatus
     * @returns {string}
     */
    const getFilterColor = (color, activeStatus) => {
        if (activeStatus) {
            if (color === 'yellow') {
                return '#F9A71A'
            }
            if (color === 'green') {
                return '#00A886'
            }
            if (color === 'blue') {
                return '#2D8DD2'
            }
            if (color === 'navy') {
                return '#024383'
            }
            if (color === 'darkgray') {
                return '#7A7A7A'
            }
        }
        return ''
    }

    // 공통 style
    const textStyle = {
        color: type === 'primary' ? 'var(--gray-white, #FFF)' : `${override}`,
        backgroundColor: getFilterColor(filterColor, active),
        textAlign: 'center',
        fontFamily: 'Noto Sans KR',
        display: 'inline-flex',
        fontStyle: 'normal',
        fontWeight: 700,
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        boxShadow: 'none',
    }
    const sizes = {
        large: {
            ...textStyle,
            height: '60px',
            padding: '19px 64px',
            fontSize: '18px',
            lineHeight: '22px',
        },
        middle: {
            ...textStyle,
            height: '48px',
            padding: '14px 32px',
            fontSize: '16px',
            lineHeight: '20px',
        },
        small: {
            ...textStyle,
            height: '36px',
            padding: '9px 28px',
            fontSize: '14px',
            lineHeight: '18px',
        },
        filter: {
            ...textStyle,
            height: '48px',
            padding: '0px 12px',
            alignItems: 'center',

            border: '1px solid var(--gray-300, #E5E5E5)',
            color: 'rgba(194, 194, 194, 0.88)',
        },
        download: {
            ...textStyle,
            padding: '20px',
            alignItems: 'center',
            fontSize: '16px',
        },
    }

    return sizes[sizeStyle] || sizes.middle // 기본값으로 'middle' 스타일을 사용
}

/**
 * hover 컬러값 1단계 낮추는 함수
 * @param overrideColor
 * @returns {*}
 */
const getHoverColor = overrideColor => {
    const colorMapping = {
        [blue['900']]: blue['800'],
        [green['900']]: green['800'],
        [green['400']]: green['300'],
    }

    return colorMapping[overrideColor] || blue['800']
}

/**
 *
 * @param children
 * @param override
 * @param size
 * @param disabled
 * @param active 필터 버튼 active
 * @param filterColor 필터 버튼 color
 * @param icon
 * @param htmlType
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ColorButton = ({ children, override, size, disabled, active, filterColor, icon, htmlType, ...props }) => {
    const { type, onClick, style } = props
    const { token } = useToken() // AntD token
    const overrideColor = token[override] || override

    const baseColor = getHoverColor(overrideColor)

    // AntD theme token 주입
    const modifiedTheme = {
        token: {
            fontSize: 14,
            borderRadius: 4,
            colorPrimary: overrideColor || token.colorPrimary,
            colorTextBase: overrideColor || token.colorPrimary,
            colorBorder: disabled ? gray['200'] : overrideColor || token.colorPrimary,
            colorBgTextHover: baseColor,
        },
        // algorithm,
    }

    return (
        <ConfigProvider theme={modifiedTheme}>
            <Button
                style={getSizeStyle(size, type, override, active, filterColor) || style}
                disabled={disabled}
                onClick={onClick}
                htmlType={htmlType === 'submit' ? 'submit' : 'button'}
                {...props}
            >
                {children}
                {icon}
            </Button>
        </ConfigProvider>
    )
}

export default ColorButton
