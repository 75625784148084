import dayjs from 'dayjs'
import { buildSupabaseChainFilter } from 'utils/utilCommon'
import { supabase } from '../axios.instance'
import { getCommonPageInfo } from '../rpc/rpc.api'

const table = 'user_info'
const today = dayjs().format('YYYY-MM-DD HH:mm:ss.SSSZ')

export const getAccountList = async (search, regionId) => {
    try {
        // 페이지 정보 response
        const pageSize = 10
        const paginationInfo = await getCommonPageInfo(search, table, regionId, pageSize)

        // query
        let { query } = await buildSupabaseChainFilter(table, search, regionId)
        query = query.eq('is_delete', false)
        query = query.order('user_seq', { ascending: true })

        const response = await query

        if (response.data) {
            const convertedData = response.data.map(item => {
                const result = {
                    userSeq: item.user_seq,
                    userId: item.user_id,
                    regionId: item.region_id,
                    // passwd: item.user_id.substring(item.user_id.length - 2),
                    useDtime: null, // 사용하지 않는 필드
                    isMaster: item.is_master,
                    sysRegDtime: item.sys_reg_dtime,
                    sysRegrId: item.sys_regr_id,
                    sysModDtime: item.sys_mod_dtime || null,
                    sysModrId: item.sys_modr_id || null,
                    isServiceManager: item.is_service_manager,
                    auth_status: item.auth_status,
                    isSubMaster: item.is_sub_master,
                }
                return result
            })
            // 배열 뒤집기
            const reversedData = convertedData.reverse()
            response.data = {
                userList: reversedData,
                page: paginationInfo,
            }
            return response
        }
        return response
    } catch (error) {
        console.error(error)
        return null
    }
}

export const getAccountById = async userId => {
    const { data } = await supabase.from(table).select('user_uid').eq('user_id', userId)
    return data
}

/**
 * auth
 * supabse 이메일 계정 등록
 * @returns {Promise<void>}
 */
export const supaBaseSignIn = async ({ userId, passwd }) => {
    const response = await supabase.auth.signInWithPassword({
        email: `${userId}@email.com`,
        password: passwd,
    })
    return response
}

/**
 * DB 계정 등록
 * @param regionId
 * @param userId
 * @param passwd
 * @param userUid
 * @param isServiceManager 용역사용자 여부 - 현재 사용 안함
 * @returns {Promise<PostgrestResponseSuccess<GetResult<any, any, R | unknown, "*">[]>|PostgrestResponseFailure|null>}
 */
export const postAccount = async ({ regionId, userId, passwd, isServiceManager }) => {
    try {
        const response = await supabase
            .from('user_info')
            .insert({
                region_id: regionId,
                user_id: userId,
                // user_uid: userUid,
                passwd,
                is_master: false,
                is_delete: false,
                email_id: `${userId}@swedulink`,
                is_service_manager: isServiceManager === '용역사용자',
                sys_reg_dtime: today,
                sys_mod_dtime: null,
                sys_regr_id: '관리자',
                sys_modr_id: null,
            })
            .select()
        return response
    } catch (error) {
        console.error(error)
        return null
    }
}

export const putAccount = async ({
    // regionId,
    userId,
    passwd,
}) => {
    try {
        const response = await supabase
            .from(table)
            .update({
                passwd,
                sys_mod_dtime: today,
            })
            .eq('user_id', userId)
            .select()
        return response
    } catch (error) {
        console.error(error)
        return null
    }
}

/**
 * user_info 테이블
 * 수퍼베이스 DB 유저 상태값 변경
 * is_delete = true (삭제된 유저)
 * @param userId
 * @returns {Promise<PostgrestResponseSuccess<null>|PostgrestResponseFailure|null>}
 */
export const deleteAccountById = async userId => {
    try {
        const response = await supabase.from(table).update({ is_delete: true }).eq('user_id', userId).select()
        return response
    } catch (error) {
        console.error(error)
        return null
    }
}

/**
 * 수퍼베이스 Auth User
 * 등록 이메일 삭제
 * TODO: service role 필요
 * @param userUid
 * @returns {Promise<{data: {user: User}, error: null}|{data: {user: null}, error: AuthError}|null>}
 */
export const deleteAccountEmailByIUid = async userUid => {
    try {
        const response = await supabase.auth.admin.deleteUser(userUid)
        return response
    } catch (error) {
        console.error(error)
        return null
    }
}
