import PageBanner from 'layout/PageBanner'

import { Col, Flex, Row } from 'antd'

import inventories from 'mocks/data/tools/transfer/stock_transfer.json'
import rentals from 'mocks/data/tools/transfer/rental_transfer.json'

import { BannerIconTools, LeftArrow48Icon, RightArrow48Icon } from 'assets/Icons'

import ColorButton from 'components/AntD/Button/ColorButton'
import ListView from 'components/List/ListView'

import styles from './transfer.module.scss'

const RentalTransfer = () => {
    const { rentalList } = rentals.result || {}
    const { stockList } = inventories.result || {}

    return (
        <PageBanner title="교구재 반출 리스트" bannerIcon={<BannerIconTools />}>
            <div className={styles.container}>
                <Row>
                    <Col span={11}>
                        <Flex align="center" gap={24} className={styles.margin_b45}>
                            <span className={`${styles.inventory} ${styles.icon}`} />
                            <span className="h5">
                                재고 목록 <span className={styles.count}>20</span>
                            </span>
                        </Flex>
                        <ListView list={stockList.slice(0, 10)} />
                    </Col>
                    <Col span={2}>
                        <Flex align="center" gap={24} justify="center" className={`${styles.height_100}`}>
                            <div>
                                <button
                                    type="button"
                                    className={`${styles.btn} ${styles.margin_b12} button-reset-type`}
                                >
                                    <RightArrow48Icon />
                                </button>
                                <button type="button" className={`${styles.btn} ${styles.disabled} button-reset-type`}>
                                    <LeftArrow48Icon />
                                </button>
                            </div>
                        </Flex>
                    </Col>
                    <Col span={11}>
                        <Flex align="center" gap={24} className={styles.margin_b45}>
                            <span className={`${styles.rental} ${styles.icon}`} />
                            <span className="h5">
                                대출 목록 <span className={styles.count}>20</span>
                            </span>
                        </Flex>
                        <ListView list={rentalList.slice(0, 10)} />
                    </Col>
                </Row>
                <Flex justify="center">
                    <ColorButton type="primary">대출 등록</ColorButton>
                </Flex>
            </div>
        </PageBanner>
    )
}

export default RentalTransfer
