import { Table, ConfigProvider } from 'antd'
import { Link } from 'react-router-dom' // Import Link from react-router-dom
import style from './table.module.scss'

const formatDateTime = dateTimeString => {
    const date = new Date(dateTimeString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')

    return `${year}-${month}-${day}`
}

const InfoTable = ({
    col = [
        {
            title: '강사유형',
            dataIndex: 'tutor_type',
            key: 'tutor_type',
        },
        {
            title: '프로그램 명',
            dataIndex: 'program_name',
            key: 'program_name',
            render: (text, record) => <Link to={`/program/${record.program_id}`}>{text}</Link>,
        },
        {
            title: '교시',
            dataIndex: 'class_hours',
            key: 'class_hours',
        },
        {
            title: '교육기간',
            dataIndex: 'education_period',
            render: (text, record) => `${formatDateTime(record.start_date)} ~ ${formatDateTime(record.end_date)}`,
        },
    ],
    data,
}) => {
    return (
        <ConfigProvider
            theme={{
                components: {
                    Table: {
                        borderRadius: 0,
                        headerSplitColor: 'none',
                    },
                },
            }}
        >
            <Table className={style['ant-table-wrapper']} columns={col} dataSource={data} pagination={false} />
        </ConfigProvider>
    )
}
export default InfoTable
