import { Link } from 'react-router-dom'

import { v4 as uuidv4 } from 'uuid'
import ListNotFound from 'pages/PageNotFound/ListNotFound'

import ListItem from 'components/ListItem/MainListItem'
import styles from './mainList.module.scss'

/**
 * 메인 페이지 리스트
 * @param title
 * @param postList
 * @param link
 * @returns {JSX.Element}
 * @constructor
 */
const MainList = ({ title, postList, link, right }) => {
    const { programList } = postList || {}

    return (
        <div className={styles.container}>
            <div className={styles.card}>
                <div className={styles.title_wrap}>
                    <span className={`h7 ${styles.title}`}>{title}</span>
                    <Link to={link}>more</Link>
                </div>
                {programList && programList.length > 0 ? (
                    programList.slice(0, 7).map(item => {
                        const { programId, programBasicInfo, programOperationInfo } = item
                        const { programName } = programBasicInfo
                        const { status, classHours } = programOperationInfo
                        return (
                            <ListItem
                                key={uuidv4()}
                                programId={programId}
                                status={status}
                                programName={programName}
                                classHours={classHours}
                                right={right}
                            />
                        )
                    })
                ) : (
                    <ListNotFound />
                )}
            </div>
        </div>
    )
}
export default MainList
