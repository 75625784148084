import { Col, Row, Form, Space } from 'antd'
import { useState } from 'react'

import CheckBox from 'components/AntD/CheckBox/type1'
import Title from 'components/Ui/Title'
import Label from 'components/Ui/Label'
import TextField from 'components/AntD/TextField'
import style from 'assets/scss/detail.module.scss'
import CertificationList from './CertificationList'
import Value from '../../Ui/Value'

const TutorCertification = ({ qualifications, isEditOrRegisterPage }) => {
    const [localQualifications] = useState(qualifications)

    return (
        <div>
            <Title title="자격증 정보" />
            <div className={`${style.border_style} ${style.box_md} `}>
                <Row className={style.row_item}>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="교원자격 보유" />
                            </Col>
                            <Col span={14} className={style.padding_y_xs}>
                                {isEditOrRegisterPage ? (
                                    <Form.Item
                                        name="teacherCertification"
                                        initialValue={localQualifications?.teacherCertification}
                                    >
                                        <CheckBox
                                            name="teacherCertification"
                                            width={48}
                                            height={48}
                                            checked={localQualifications?.teacherCertification}
                                        />
                                    </Form.Item>
                                ) : (
                                    <CheckBox
                                        width="48"
                                        height="48"
                                        disabled
                                        checked={qualifications?.teacherCertification}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col span={13}>
                                <Label label="수화가능 여부" />
                            </Col>
                            <Col className={style.padding_y_xs}>
                                {isEditOrRegisterPage ? (
                                    <Form.Item
                                        name="signLanguageYn"
                                        initialValue={localQualifications?.signLanguageYn || false}
                                    >
                                        <CheckBox
                                            name="signLanguageYn"
                                            width={48}
                                            height={48}
                                            checked={localQualifications?.signLanguageYn || false}
                                        />
                                    </Form.Item>
                                ) : (
                                    <CheckBox
                                        width="48"
                                        height="48"
                                        disabled
                                        checked={qualifications?.signLanguageYn}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className={style.border_btm}>
                    <Col span={5}>
                        <Label label="SW 자격증" />
                    </Col>
                    {isEditOrRegisterPage ? (
                        <Col span={19}>
                            <Form.Item name="swCertification" initialValue={localQualifications?.swCertification}>
                                <CertificationList
                                    name="swCertification"
                                    value={localQualifications?.swCertification}
                                />
                            </Form.Item>
                        </Col>
                    ) : (
                        <Col className={style.padding_y_medium}>
                            {qualifications?.swCertification && qualifications?.swCertification.length > 0 ? (
                                <Space direction="vertical">
                                    {qualifications?.swCertification.map(v => (
                                        <Value key={v} value={v} />
                                    ))}
                                </Space>
                            ) : (
                                <Value key="" value="등록된 자격증이 없습니다" />
                            )}
                        </Col>
                    )}
                </Row>
                <Row className={style.row_item}>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="강의경험 유무" required={isEditOrRegisterPage} />
                            </Col>
                            <Col span={14} className={style.padding_y_xs}>
                                {isEditOrRegisterPage ? (
                                    <Form.Item
                                        name="teachingExperienceYn"
                                        initialValue={localQualifications?.teachingExperienceYn}
                                    >
                                        <CheckBox
                                            name="teachingExperienceYn"
                                            width={48}
                                            height={48}
                                            checked={localQualifications?.teachingExperienceYn}
                                        />
                                    </Form.Item>
                                ) : (
                                    <CheckBox
                                        width="48"
                                        height="48"
                                        disabled
                                        checked={qualifications?.teachingExperienceYn}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row>
                            {isEditOrRegisterPage ? (
                                <>
                                    <Col span={13}>
                                        <Label label="SW 미래채움 민간자격증" />
                                    </Col>
                                    <Col span={11} className={`${style.padding_right} ${style.padding_y_xs}`}>
                                        <Form.Item
                                            name="privateCertification"
                                            initialValue={localQualifications?.privateCertification || ''}
                                        >
                                            <TextField
                                                name="privateCertification"
                                                val={localQualifications?.privateCertification || ''}
                                            />
                                        </Form.Item>
                                    </Col>
                                </>
                            ) : (
                                <>
                                    <Col span={14}>
                                        <Label label="SW 미래채움 민간자격증" />
                                    </Col>
                                    <Col className={style.padding_y_medium}>
                                        <Value value={qualifications?.privateCertification} />
                                    </Col>
                                </>
                            )}
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export default TutorCertification
