import { useState } from 'react'
import { Col, Row, Form } from 'antd'

import Label from 'components/Ui/Label'
import SelectUi from 'components/Ui/Select/SelectUi'
import Title from 'components/Ui/Title'
import Value from 'components/Ui/Value'

import TextField from 'components/AntD/TextField'

import styles from 'assets/scss/detail.module.scss'

const DemandAgencyInfo = ({ demand_agency_info, editOrRegisterPage }) => {
    const [localDemandAgencyInfo] = useState(demand_agency_info)

    return (
        <div>
            <Title title="수요기관" />
            <div className={`${styles.box_md} ${styles.border_style}`}>
                <Row className={styles.row_item}>
                    <Col span={5}>
                        <Label label="구분" required={editOrRegisterPage} />
                    </Col>
                    <Col span={19}>
                        {editOrRegisterPage ? (
                            <Form.Item
                                name="category"
                                initialValue={localDemandAgencyInfo?.category}
                                noStyle
                                rules={[{ required: true }]}
                            >
                                <SelectUi
                                    name="category"
                                    size="lg"
                                    value={localDemandAgencyInfo?.category}
                                    options={['일반학교', '도서벽지', '특수학교', '지역아동센터', '기타']}
                                />
                            </Form.Item>
                        ) : (
                            <SelectUi
                                disabled
                                size="lg"
                                value={demand_agency_info?.category}
                                options={['일반학교', '도서벽지', '특수학교', '지역아동센터', '기타']}
                            />
                        )}
                    </Col>
                </Row>
                <Row className={styles.row_item}>
                    <Col span={5}>
                        <Label label="학년" required={editOrRegisterPage} />
                    </Col>
                    <Col span={19}>
                        {editOrRegisterPage ? (
                            <Form.Item
                                name="grade"
                                initialValue={localDemandAgencyInfo?.grade}
                                noStyle
                                rules={[{ required: true }]}
                            >
                                <SelectUi
                                    name="grade"
                                    size="lg"
                                    options={['초등저학년', '초등고학년', '중등', '고등', '혼합']}
                                    value={localDemandAgencyInfo?.grade}
                                />
                            </Form.Item>
                        ) : (
                            <SelectUi
                                disabled
                                size="lg"
                                options={['초등저학년', '초등고학년', '중등', '고등', '혼합']}
                                value={demand_agency_info?.grade}
                            />
                        )}
                    </Col>
                </Row>
                {editOrRegisterPage ? (
                    <Row className={styles.row_item}>
                        <Col span={5}>
                            <Label label="주소" required={editOrRegisterPage} />
                        </Col>
                        <Col span={19} className={`${styles.padding_top} ${styles.padding_right}`}>
                            <Row gutter={8}>
                                <Col span={8}>
                                    <Form.Item
                                        initialValue={localDemandAgencyInfo?.addressSido || ''}
                                        name="addressSido"
                                        noStyle
                                        rules={[{ required: true }]}
                                    >
                                        <TextField
                                            name="addressSido"
                                            val={`${localDemandAgencyInfo?.addressSido || ''} `}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name="addressSigungu"
                                        initialValue={localDemandAgencyInfo?.addressSigungu || ''}
                                        noStyle
                                        rules={[{ required: true }]}
                                    >
                                        <TextField
                                            name="addressSigungu"
                                            val={`${localDemandAgencyInfo?.addressSigungu || ''}`}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name="addressBname"
                                        initialValue={localDemandAgencyInfo?.addressBname || ''}
                                        noStyle
                                        rules={[{ required: true }]}
                                    >
                                        <TextField
                                            name="addressBname"
                                            val={`${localDemandAgencyInfo?.addressBname || ''}`}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                ) : (
                    <Row className={styles.row_item}>
                        <Col span={12}>
                            <Row className={styles.row_item}>
                                <Col span={10}>
                                    <Label label="학급" />
                                </Col>
                                <Col className={styles.padding_y_medium}>
                                    <Value value={demand_agency_info?.classroom} />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row>
                                <Col span={8}>
                                    <Label label="주소" />
                                </Col>
                                <Col span={16} className={styles.padding_y_medium}>
                                    <Value
                                        value={`${demand_agency_info?.addressSido} 
                                ${demand_agency_info?.addressSigungu} 
                                ${demand_agency_info?.addressBname}
                            `}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}

                {editOrRegisterPage ? (
                    <>
                        <Row className={styles.row_item}>
                            <Col span={5}>
                                <Label label="기관명" required={editOrRegisterPage} />
                            </Col>
                            <Col className={`${styles.padding_top} ${styles.padding_right}`}>
                                <Form.Item
                                    initialValue={localDemandAgencyInfo?.organizationName}
                                    name="organizationName"
                                    noStyle
                                    rules={[{ required: true }]}
                                >
                                    <TextField name="organizationName" val={localDemandAgencyInfo?.organizationName} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row className={styles.row_item}>
                            <Col span={12}>
                                <Row>
                                    <Col span={10}>
                                        <Label label="학급" />
                                    </Col>
                                    <Col span={13} className={`${styles.padding_top} ${styles.padding_right}`}>
                                        <Form.Item initialValue={localDemandAgencyInfo?.classroom} name="classroom">
                                            <TextField name="classroom" val={localDemandAgencyInfo?.classroom} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={10}>
                                        <Label label="권역" />
                                    </Col>
                                    <Col span={14} className={`${styles.padding_top} ${styles.padding_right}`}>
                                        <Form.Item initialValue={localDemandAgencyInfo?.areaName} name="areaName">
                                            <TextField name="areaName" val={localDemandAgencyInfo?.areaName} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <Row className={styles.row_item}>
                        <Col span={12}>
                            <Row>
                                <Col span={10}>
                                    <Label label="기관명" />
                                </Col>
                                <Col className={`${styles.padding_y_medium}`}>
                                    <Value value={demand_agency_info?.organizationName} />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row>
                                <Col span={8}>
                                    <Label label="권역" />
                                </Col>
                                <Col className={styles.padding_y_medium}>
                                    <Value value={demand_agency_info?.areaName} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}
            </div>
        </div>
    )
}
export default DemandAgencyInfo
