import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Form, Tooltip } from 'antd'
import { useDispatch } from 'react-redux'
import { LoginMainIcon, MailIcon } from 'assets/Icons'

import ColorButton from 'components/AntD/Button/ColorButton'
import TextField from 'components/AntD/TextField'

import logo from 'assets/images/logo.svg'
import { getUserAsync, loginUserAsync } from 'store/Slices/user'

import styles from './login.module.scss'

const contactUrl = 'https://forms.gle/3Gv7z1nFytSAH72F7'
const environment = process.env.REACT_APP_ENVIRONMENT
const isDev = environment === 'dev'

const Login = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const bodyText = '통합 관리 시스템으로 편리하게 정보를 관리 할 수 있어요.'
    const bottomText1 = '아이디(로그인 전용 아이디) 또는 비밀번호를 잘못 입력했습니다.'
    const bottomText2 = '입력하신 내용을 다시 확인해주세요.'

    const [isErrorId, setIsErrorId] = useState(false)
    const [isErrorPassword, setIsErrorPassword] = useState(false)

    const tooltipTitle = <div>담당자에게 문의 주세요.</div>

    const [form] = Form.useForm()

    const handleChange = () => {
        setIsErrorId(false)
        setIsErrorPassword(false)
    }
    const handleClear = () => {
        console.log('clear')
    }

    /**
     * submit 제출하여 성공 후 처리
     * 모든 value 확인 가능
     * @param values
     */
    const onFinish = async values => {
        const loginResult = await dispatch(loginUserAsync(values))
        if (loginResult.error?.message === 'Rejected') {
            setIsErrorId(true)
            setIsErrorPassword(true)
        }
        if (loginResult.payload.session !== null && loginResult.payload.session !== undefined) {
            // TODO: 미들웨어 방식으로 변경해야 함.
            const data = await dispatch(getUserAsync(values.id))
            if (data && data.type.includes('fulfilled')) {
                navigate('/main')
            } else {
                // TODO : 아이디 패스워드 아닌 클라이언트 오류로 변경해야함.
                setIsErrorId(true)
                setIsErrorPassword(true)
            }
        }
    }

    /**
     * submit 제출하여 실패 후 처리
     * 모든 value 확인 가능
     * @param errorInfo
     */
    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo)
        setIsErrorId(true)
        setIsErrorPassword(true)
    }

    return (
        <div className={styles.login}>
            <div className={styles.header}>
                <h1 className={styles.logo_wrap}>
                    <img src={logo} alt="SW미래채움" />
                    <span>성과 관리 시스템 {isDev && '(개발용)'}</span>
                </h1>
            </div>
            <div className={styles.container}>
                <div className={styles.box}>
                    <div className={`${styles.image} ${isDev ? styles.dev : ''}`}>
                        <LoginMainIcon />
                    </div>
                    <div className={styles.form}>
                        <div className={styles.login_area}>
                            <div>
                                <span className="h4">Hello!</span>
                            </div>
                            <div className={styles.desc}>
                                <span className="body2">{bodyText}</span>
                            </div>
                            <Form
                                name="basic"
                                form={form}
                                labelCol={{ span: 8 }}
                                initialValues={{ remember: true }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <div className={styles.id_input}>
                                    <Form.Item
                                        name="id"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                        validateStatus={isErrorId ? 'error' : 'success'}
                                        noStyle
                                    >
                                        <TextField
                                            onClear={handleClear}
                                            onChange={handleChange}
                                            placeholder="아이디"
                                            setIsError={setIsErrorId}
                                        />
                                    </Form.Item>
                                </div>
                                <div className={styles.password_input}>
                                    <Form.Item
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                        validateStatus={isErrorPassword ? 'error' : 'success'}
                                        noStyle
                                    >
                                        <TextField
                                            onClear={handleClear}
                                            onChange={handleChange}
                                            setIsError={setIsErrorPassword}
                                            placeholder="비밀번호"
                                            password
                                        />
                                    </Form.Item>
                                </div>
                                <div className={styles.login_btn}>
                                    <ColorButton type="primary" size="large" htmlType="submit">
                                        로그인
                                    </ColorButton>
                                </div>
                                {(isErrorId || isErrorPassword) && (
                                    <div className={styles.validation}>
                                        <span className={`body3 ${styles.error}`}>{bottomText1}</span>
                                        <span className={`body3 ${styles.error}`}>{bottomText2}</span>
                                    </div>
                                )}
                            </Form>
                            <div className={styles.admin}>
                                <div className={styles.btn}>
                                    <Tooltip title={tooltipTitle} overlayClassName="contact_admin_btn">
                                        <ColorButton
                                            size="middle"
                                            className="button-reset-type"
                                            onClick={() => window.open(contactUrl, '_blank')}
                                        >
                                            관리자에게 문의 <MailIcon />
                                        </ColorButton>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
