import React from 'react'
import DOMPurify from 'dompurify'

import 'assets/scss/main.scss'
import styles from './sanitize.module.scss'

/**
 * 월간보고 전용
 * @param html
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */
const SanitizedHTML = ({ html, className = styles.text }) => {
    const cleanHTML = DOMPurify.sanitize(html, { ADD_TAGS: ['oembed'], ADD_ATTR: ['url'] }) // 미디어 허용

    const parser = new DOMParser()
    const doc = parser.parseFromString(cleanHTML, 'text/html')
    const oembeds = doc.querySelectorAll('oembed')

    // 미디어 미리보기 표출
    if (oembeds) {
        oembeds.forEach(oembed => {
            const url = oembed.getAttribute('url')
            let iframeSrc = ''

            // 플랫폼 분기처리
            if (url.includes('youtu.be') || url.includes('youtube.com')) {
                // youtube
                iframeSrc = url.replace('youtu.be/', 'www.youtube.com/embed/').replace('watch?v=', 'embed/')
            } else {
                iframeSrc = url
            }

            if (iframeSrc) {
                const iframe = document.createElement('iframe')

                iframe.src = iframeSrc
                iframe.width = '560' // 원하는 너비 설정
                iframe.height = '315' // 원하는 높이 설정
                iframe.frameBorder = '0'
                iframe.allow =
                    'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                iframe.allowFullscreen = true

                oembed.replaceWith(iframe)
            }
        })
    }

    const finalHTML = doc.body.innerHTML

    return <div className={`${className} ${styles.text}`} dangerouslySetInnerHTML={{ __html: finalHTML }} />
}

export default SanitizedHTML
