import axios from 'axios'
import { createClient } from '@supabase/supabase-js'

const MSWApiURL = process.env.REACT_APP_EXAMPLE_API_URL
const apiURL = process.env.REACT_APP_API_URL
const supaBaseURL = process.env.REACT_APP_API_SUPA_BASE_API_URL

export const defaultHeaders = {
    prefer: 'return=representation',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache', // 자체 캐쉬 처리되어 실제 서버를 호출하지 않는 현상 해결
    apikey: process.env.REACT_APP_SUPABASE_KEY,
    Authorization: `Bearer ${process.env.REACT_APP_SUPABASE_KEY}`,
}

export const createCustomInstance = (customURL, config = defaultHeaders) => {
    return axios.create({
        baseURL: customURL,
        headers: config,
        timeout: 5000, // 시간이 오래 걸리는 요청에 대한 timeout 값 재정의, 5초
    })
}
// MSW API
export const mockInstance = createCustomInstance(MSWApiURL)

// backend API
export const instance = createCustomInstance(apiURL)

// 슈퍼베이스 API
export const supaBaseInstance = createCustomInstance(supaBaseURL, {
    ...defaultHeaders,
})

// 슈퍼베이스 API 클라이언트
const supabaseUrl = process.env.REACT_APP_API_SUPA_BASE_API_URL
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY
export const supabase = createClient(supabaseUrl, supabaseKey)

instance.defaults.withCredentials = true // 쿠키값 전달 허용 전역 선언
