import { DatePicker } from 'antd'

import FilterButton from 'components/AntD/Button/FilterButton'
import { FinishFilterBottomIcon, OffFilterIcon, OngoingFilterIcon } from 'assets/Icons'

import styles from '../filter.module.scss'

const RentalFilter = ({ queryParams, updateQueryStringAndNavigate, startDateRange, handleStartDateChange }) => {
    const programOperation = 'program_operation'

    const currentProgramOperation = queryParams.get(programOperation) || ''
    return (
        <>
            <span className={`body2 ${styles.title}`}>대여 현황</span>
            <div className={styles.btn_wrap}>
                <FilterButton
                    icon={<OffFilterIcon />}
                    title="대출신청"
                    currentParamValue={currentProgramOperation}
                    onClick={() => updateQueryStringAndNavigate(programOperation, '대출신청')}
                    filterColor="yellow"
                />
                <FilterButton
                    icon={<OngoingFilterIcon />}
                    title="대여"
                    currentParamValue={currentProgramOperation}
                    onClick={() => updateQueryStringAndNavigate(programOperation, '대여')}
                    filterColor="blue"
                />
                <FilterButton
                    icon={<FinishFilterBottomIcon />}
                    title="반납"
                    currentParamValue={currentProgramOperation}
                    onClick={() => updateQueryStringAndNavigate(programOperation, '반납')}
                    filterColor="darkgray"
                />
            </div>
            <span className={`body2 ${styles.title}`}>취득일</span>
            <div className={styles.btn_wrap}>
                <DatePicker
                    format="YYYY-MM-DD"
                    value={startDateRange}
                    onChange={handleStartDateChange}
                    placeholder="날짜선택"
                />
            </div>
        </>
    )
}

export default RentalFilter
