// import dayjs from 'dayjs'
import { DatePicker } from 'antd'

import FilterButton from 'components/AntD/Button/FilterButton'
import { CheckFilterIcon } from 'assets/Icons'
import { getCurrentMonthDates, getLastMonthDates } from 'utils/utilCommon'

import styles from '../filter.module.scss'

/**
 *
 * @param queryParams
 * @param updateQueryStringAndNavigate
 * @param startDateRange
 * @param setStartDateRange
 * @param endDateRange
 * @param setEndDateRange
 * @param isActiveLastMonth 지난달버튼 active
 * @param setIsActiveLastMonth
 * @param isActiveThisMonth 이번달버튼 active
 * @param setIsActiveThisMonth
 * @param handleEndDateChange
 * @param handleStartDateChange
 * @param endDate
 * @param startDate
 * @returns {JSX.Element}
 * @constructor
 */
const ExpandProgram = ({
    queryParams,
    updateQueryStringAndNavigate,
    startDateRange,
    setStartDateRange,
    endDateRange,
    setEndDateRange,
    isActiveLastMonth,
    setIsActiveLastMonth,
    isActiveThisMonth,
    setIsActiveThisMonth,
    handleEndDateChange,
    handleStartDateChange,
    endDate,
    startDate,
}) => {
    // query string key
    const educationType = 'educationType' // 교육방식: 센터/방문
    const course = 'course' // 교육 유형: 정규과정 / 비정규과정
    const method = 'method' // 교육 방식: 오프라인 / 온라인
    const demandAgency = 'category' // 수요기관 : 일반학교 / 도서벽지 / 특수학교 / 지역아동센터 / 기타
    const grade = 'grade' // 학년: 초등 저학년 / 초등 고학년 / 중등 / 고등 / 혼합
    // const queryParams = new URLSearchParams(window.location.search)

    // get query params
    const currentEducationType = queryParams.get(educationType) || ''
    const currentCourse = queryParams.get(course) || ''
    const currentMethod = queryParams.get(method) || ''
    const currentDemandAgency = queryParams.get(demandAgency) || ''
    const currentGrade = queryParams.get(grade) || ''

    const [lastMonthStartDate, lastMonthEndDate] = getLastMonthDates()
    const [currentMonthStartDate, currentMonthEndDate] = getCurrentMonthDates()

    // 저번달
    const lastMonthStart = lastMonthStartDate.format('YYYYMMDD')
    const lastMonthEnd = lastMonthEndDate.format('YYYYMMDD')

    // 이번달
    const currentMonthStart = currentMonthStartDate.format('YYYYMMDD')
    const currentMonthEnd = currentMonthEndDate.format('YYYYMMDD')

    // const resetPeriodBtn = () => {
    //     if (isActiveThisMonth) {
    //         setIsActiveThisMonth(false)
    //     }
    //     if (isActiveLastMonth) {
    //         setIsActiveLastMonth(false)
    //     }
    // }
    //
    // /**
    //  *  시작일 선택 시
    //  * @param dates
    //  */
    // const handleStartDateChange = dates => {
    //     setStartDateRange(dates)
    //     const startSelect = dayjs(dates).format('YYYY-MM-DD')
    //
    //     if (dates === null) {
    //         updateQueryStringAndNavigate(startDate, '')
    //         resetPeriodBtn()
    //     } else {
    //         updateQueryStringAndNavigate(startDate, startSelect)
    //         resetPeriodBtn()
    //     }
    // }
    //
    // /**
    //  * 종료일 선택 시
    //  * @param dates
    //  */
    // const handleEndDateChange = dates => {
    //     setEndDateRange(dates)
    //     const endSelect = dayjs(dates).format('YYYY-MM-DD')
    //
    //     if (dates === null) {
    //         updateQueryStringAndNavigate(endDate, '')
    //         resetPeriodBtn()
    //     } else {
    //         updateQueryStringAndNavigate(endDate, endSelect)
    //         resetPeriodBtn()
    //     }
    // }

    // 지난달 버튼 클릭 시
    const handleLastMonth = () => {
        if (isActiveLastMonth) {
            updateQueryStringAndNavigate(startDate, lastMonthStart)
            updateQueryStringAndNavigate(endDate, lastMonthEnd)
            queryParams.delete('startDate')
            queryParams.delete('endDate')
            setStartDateRange(null)
            setEndDateRange(null)
            setIsActiveLastMonth(false)
        } else {
            updateQueryStringAndNavigate(startDate, lastMonthStart)
            updateQueryStringAndNavigate(endDate, lastMonthEnd)
            setStartDateRange(lastMonthStartDate)
            setEndDateRange(lastMonthEndDate)
            setIsActiveLastMonth(true)
            setIsActiveThisMonth(false)
        }
    }

    // 이번달 버튼 클릭 시
    const handleThisMonth = () => {
        if (isActiveThisMonth) {
            updateQueryStringAndNavigate(startDate, currentMonthStart)
            updateQueryStringAndNavigate(endDate, currentMonthEnd)
            queryParams.delete('startDate')
            queryParams.delete('endDate')
            setStartDateRange(null)
            setEndDateRange(null)
            setIsActiveThisMonth(false)
        } else {
            updateQueryStringAndNavigate(startDate, currentMonthStart)
            updateQueryStringAndNavigate(endDate, currentMonthEnd)
            setStartDateRange(currentMonthStartDate)
            setEndDateRange(currentMonthEndDate)
            setIsActiveThisMonth(true)
            setIsActiveLastMonth(false)
        }
    }

    return (
        <>
            <div className={styles.item_wrap}>
                <span className={`body2 ${styles.title}`}>교육 유형</span>
                <div className={styles.btn_wrap}>
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="센터"
                        currentParamValue={currentEducationType}
                        onClick={() => updateQueryStringAndNavigate(educationType, '센터교육')}
                        filterColor="black"
                    />
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="방문"
                        currentParamValue={currentEducationType}
                        onClick={() => updateQueryStringAndNavigate(educationType, '방문교육')}
                        filterColor="black"
                    />
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="기타"
                        currentParamValue={currentEducationType}
                        onClick={() => updateQueryStringAndNavigate(educationType, '기타')}
                        filterColor="black"
                    />
                </div>
                <div className={styles.btn_wrap}>
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="정규과정"
                        currentParamValue={currentCourse}
                        onClick={() => updateQueryStringAndNavigate(course, '정규과정')}
                        filterColor="black"
                    />
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="비정규과정"
                        currentParamValue={currentCourse}
                        onClick={() => updateQueryStringAndNavigate(course, '비정규과정')}
                        filterColor="black"
                    />
                </div>
                <div className={styles.btn_wrap}>
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="오프라인"
                        currentParamValue={currentMethod}
                        onClick={() => updateQueryStringAndNavigate(method, '오프라인')}
                        filterColor="black"
                    />
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="온라인"
                        currentParamValue={currentMethod}
                        onClick={() => updateQueryStringAndNavigate(method, '온라인')}
                        filterColor="black"
                    />
                </div>
            </div>
            <div className={styles.item_wrap}>
                <span className={`body2 ${styles.title}`}>수요기관</span>
                <div className={styles.btn_wrap}>
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="일반학교"
                        currentParamValue={currentDemandAgency}
                        onClick={() => updateQueryStringAndNavigate(demandAgency, '일반학교')}
                        filterColor="black"
                    />
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="도서벽지"
                        currentParamValue={currentDemandAgency}
                        onClick={() => updateQueryStringAndNavigate(demandAgency, '도서벽지')}
                        filterColor="black"
                    />
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="특수학교"
                        currentParamValue={currentDemandAgency}
                        onClick={() => updateQueryStringAndNavigate(demandAgency, '특수학교')}
                        filterColor="black"
                    />
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="지역아동센터"
                        currentParamValue={currentDemandAgency}
                        onClick={() => updateQueryStringAndNavigate(demandAgency, '지역아동센터')}
                        filterColor="black"
                    />
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="기타"
                        currentParamValue={currentDemandAgency}
                        onClick={() => updateQueryStringAndNavigate(demandAgency, '기타')}
                        filterColor="black"
                    />
                </div>
            </div>
            <div className={styles.item_wrap}>
                <span className={`body2 ${styles.title}`}>학년</span>
                <div className={styles.btn_wrap}>
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="초등저학년"
                        currentParamValue={currentGrade}
                        onClick={() => updateQueryStringAndNavigate(grade, '초등저학년')}
                        filterColor="black"
                    />
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="초등고학년"
                        currentParamValue={currentGrade}
                        onClick={() => updateQueryStringAndNavigate(grade, '초등고학년')}
                        filterColor="black"
                    />
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="중등"
                        currentParamValue={currentGrade}
                        onClick={() => updateQueryStringAndNavigate(grade, '중등')}
                        filterColor="black"
                    />
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="고등"
                        currentParamValue={currentGrade}
                        onClick={() => updateQueryStringAndNavigate(grade, '고등')}
                        filterColor="black"
                    />
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="혼합"
                        currentParamValue={currentGrade}
                        onClick={() => updateQueryStringAndNavigate(grade, '혼합')}
                        filterColor="black"
                    />
                </div>
            </div>
            <div className={styles.item_wrap}>
                <span className={`body2 ${styles.title}`}>교육기간</span>
                <div className={styles.btn_wrap}>
                    <DatePicker
                        format="YYYY-MM-DD"
                        value={startDateRange}
                        className={styles.h100}
                        onChange={handleStartDateChange}
                        placeholder="시작일"
                    />
                    <div className={styles.tilde}>~</div>
                    <DatePicker
                        format="YYYY-MM-DD"
                        value={endDateRange}
                        className={styles.h100}
                        onChange={handleEndDateChange}
                        placeholder="종료일"
                    />
                </div>
                <div className={styles.btn_wrap}>
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="지난달"
                        currentParamValue={isActiveLastMonth}
                        filterColor="black"
                        onClick={handleLastMonth}
                    />
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="이번달"
                        currentParamValue={isActiveThisMonth}
                        filterColor="black"
                        onClick={handleThisMonth}
                    />
                </div>
            </div>
        </>
    )
}

export default ExpandProgram
