import FilterButton from 'components/AntD/Button/FilterButton'
import { CheckFilterIcon, ClassIcon, ContractIcon, ExpiredIcon } from 'assets/Icons'

import styles from '../filter.module.scss'

const ExpandTutors = ({ queryParams, updateQueryStringAndNavigate }) => {
    const employmentStatus = 'employmentStatus'
    const teacherCertification = 'teacherCertification'
    const teachingExperienceYn = 'teachingExperienceYn'
    const educationLevel = 'educationLevel'
    const employmentType = 'employmentType'

    const currentEmploymentStatus = queryParams.get(employmentStatus) || ''
    const currentTeacherCertification = queryParams.get(teacherCertification) || ''
    const currentTeachingExperienceYn = queryParams.get(teachingExperienceYn) || ''
    const currentEducationLevel = queryParams.get(educationLevel) || ''
    const currentEmploymentType = queryParams.get(employmentType) || ''

    return (
        <>
            <div className={styles.item_wrap}>
                <span className={`body2 ${styles.title}`}>학력 및 자격</span>
                <div className={styles.btn_wrap}>
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="고졸"
                        currentParamValue={currentEducationLevel}
                        onClick={() => updateQueryStringAndNavigate(educationLevel, '고졸')}
                        filterColor="black"
                    />
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="전문대졸"
                        currentParamValue={currentEducationLevel}
                        onClick={() => updateQueryStringAndNavigate(educationLevel, '전문대졸')}
                        filterColor="black"
                    />
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="대졸"
                        currentParamValue={currentEducationLevel}
                        onClick={() => updateQueryStringAndNavigate(educationLevel, '대졸')}
                        filterColor="black"
                    />
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="석사"
                        currentParamValue={currentEducationLevel}
                        onClick={() => updateQueryStringAndNavigate(educationLevel, '석사')}
                        filterColor="black"
                    />
                </div>
                <div className={styles.btn_wrap}>
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="교원자격"
                        currentParamValue={currentTeacherCertification}
                        onClick={() => updateQueryStringAndNavigate(teacherCertification, 'Y')}
                        filterColor="black"
                    />
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="강의경험"
                        currentParamValue={currentTeachingExperienceYn}
                        onClick={() => updateQueryStringAndNavigate(teachingExperienceYn, 'Y')}
                        filterColor="black"
                    />
                </div>
            </div>
            <div className={styles.item_wrap}>
                <span className={`body2 ${styles.title}`}>계약관련</span>
                <div className={styles.btn_wrap}>
                    <FilterButton
                        icon={<ContractIcon />}
                        title="대기"
                        currentParamValue={currentEmploymentStatus}
                        onClick={() => updateQueryStringAndNavigate(employmentStatus, '대기')}
                        filterColor="yellow"
                    />
                    <FilterButton
                        icon={<ClassIcon />}
                        title="재직중"
                        currentParamValue={currentEmploymentStatus}
                        onClick={() => updateQueryStringAndNavigate(employmentStatus, '재직중')}
                        filterColor="blue"
                    />
                    <FilterButton
                        icon={<ExpiredIcon />}
                        title="계약만료"
                        currentParamValue={currentEmploymentStatus}
                        onClick={() => updateQueryStringAndNavigate(employmentStatus, '계약만료')}
                        filterColor="darkgray"
                    />
                </div>
                <div className={styles.btn_wrap}>
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="직접채용"
                        currentParamValue={currentEmploymentType}
                        onClick={() => updateQueryStringAndNavigate(employmentType, '직접채용')}
                        filterColor="black"
                    />
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="협동조합"
                        currentParamValue={currentEmploymentType}
                        onClick={() => updateQueryStringAndNavigate(employmentType, '협동조합')}
                        filterColor="black"
                    />
                </div>
            </div>
        </>
    )
}

export default ExpandTutors
