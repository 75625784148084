/* eslint-disable  */
import { useEffect, useState } from 'react'

import { Pagination } from 'antd'

import { FirstPageIcon, LastPageIcon, NextPageIcon, PreviousPageIcon } from 'assets/Icons'

import styles from './pagination.module.scss'

const itemRender = (a, type, originalElement) => {
    if (type === 'prev') {
        return (
            <a href="#!">
                <PreviousPageIcon />
            </a>
        )
    }
    if (type === 'next') {
        return (
            <a href="#!">
                <NextPageIcon />
            </a>
        )
    }
    return originalElement
}
/**
 * Pagination
 * @param current_page 현재 페이지
 * @param total_pages 전체 페이지
 * @param total_items 전체 데이터 수
 * @param updateQueryStringAndNavigate
 * @param size 페이지당 데이터 수
 * @returns {JSX.Element}
 * @constructor
 */
const PaginationComponent = ({ current_page, total_pages, total_items = 100, updateQueryStringAndNavigate, size }) => {
    const [current, setCurrent] = useState(current_page || 1)

    useEffect(() => {
        setCurrent(current_page)
    }, [current_page])

    const onChange = page => {
        setCurrent(page)
        updateQueryStringAndNavigate('page', page)
    }

    return (
        <div style={{ display: 'flex' }}>
            <a className="button-reset-type" onClick={() => onChange(1)} disabled={current === 1}>
                <FirstPageIcon />
            </a>
            <Pagination
                className={styles['ant-space']}
                total={total_items}
                current={current}
                onChange={onChange}
                defaultCurrent={1}
                itemRender={itemRender}
            />
            <a
                href="#!"
                className={styles['custom-btn']}
                onClick={() => onChange(total_pages)}
                disabled={current === total_items}
            >
                <LastPageIcon />
            </a>
        </div>
    )
}

export default PaginationComponent
