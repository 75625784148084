import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import PageBanner from 'layout/PageBanner'

import { useDispatch, useSelector } from 'react-redux'
import { addTutor } from 'store/Slices/tutors'

import { convertTutorSnakeToCamelCase } from 'utils/utilCommon'

import { handleGetProgram } from 'api/program/program_instructor_matching.api'
import { handleGetTutor } from 'api/tutors/tutors.api'

import Spinner from 'components/AntD/Spin'
import RegistrationAndEditFormTutors from 'components/RegistrationAndEditForm/Tutors'

import styles from 'assets/scss/detail.module.scss'

const TutorDetail = () => {
    const { userId } = useSelector(state => state.user?.userInfo)

    const [loading, setLoading] = useState(true)
    const [tutor, setTutor] = useState(null)
    const { id } = useParams()
    const dispatch = useDispatch()

    const setMatching = useCallback(
        matching => {
            setTutor(prevTutor => ({ ...prevTutor, tutorProgramInstructorMatchingList: matching }))
        },
        [setTutor],
    )

    const getTutorInfo = useCallback(async () => {
        try {
            const tutorResult = await handleGetTutor(id)
            const matchingResult = await handleGetProgram(id)

            const { data: tutor, error: tutorError } = tutorResult
            const { data: matchingList } = matchingResult

            const convertedData = tutor.map(convertTutorSnakeToCamelCase)

            setTutor(convertedData[0])
            setMatching(matchingList || null)
            dispatch(addTutor(tutor))

            setLoading(false)
            if (tutorError) {
                console.error(tutorError)
            }
        } catch (error) {
            console.error(error)
        }
    }, [id])

    useEffect(() => {
        setTimeout(getTutorInfo, 500)
    }, [getTutorInfo])

    return (
        <PageBanner title="강사정보 상세">
            {tutor && !loading ? (
                <RegistrationAndEditFormTutors tutor={tutor} userId={userId} />
            ) : (
                <div className={styles.spin_wrap}>
                    <Spinner />
                </div>
            )}
        </PageBanner>
    )
}

export default TutorDetail
