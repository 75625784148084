import 'survey-core/defaultV2.min.css'
import dayjs from 'dayjs'

import { postSurveyDemander } from 'api/survey/surveyDemander.api'

import { message } from 'antd'

import { Model } from 'survey-core'
import { Survey } from 'survey-react-ui'

import { useParams } from 'react-router-dom'
import { themeJson } from './theme'

/**
 * 커스텀 CSS
 */
const customCss = {}

export const SurveyWrapper = ({ json, setIsSuccess }) => {
    const { id } = useParams()
    /**
     * survey 인스턴스 생성
     */
    const survey = new Model(json)

    /**
     * 테마 및 CSS 적용
     */
    survey.applyTheme(themeJson)
    survey.css = customCss
    survey.onUpdateQuestionCssClasses.add((_, options) => {
        const classes = options.cssClasses
        // classes.root = "custom-root"; 사용자 정의 루트 클래스 할당
        if (options.question.getType() === 'checkbox') {
            classes.root += ' question-root-checkboxes'
        }
    })

    survey.onTextMarkdown.add((sender, options) => {
        if (options.name === 'required') {
            options.text = '필수값을 확인하세요.'
        }
    })

    /**
     * 설문작성 완료 이벤트 핸들러
     */
    const surveyComplete = async (sender, options) => {
        options.allow = false
        options.allowComplete = false

        const result = sender.data
        const hasAgreement = result?.privacy_agreement[0]?.includes('동의합니다')
        const isCurrencyAvailability = result?.currency_availability?.[0]?.includes('예') || null

        const modifiedData = {
            ...result,
            is_delete: false,
            sys_reg_dtime: dayjs(),
            sys_mod_dtime: null,
            survey_uuid: id,
            privacy_agreement: hasAgreement,
            currency_availability: isCurrencyAvailability,
            is_new: result?.is_new === 'Y',
            is_benefit: result?.is_benefit === '예',
            is_internet: result?.is_internet === '가능',
            is_island: result?.is_island === '예',
            is_meet: result?.is_meet === '대면',
            is_pc_owned: result?.is_pc_owned === '보유',
            is_selected: result?.is_selected === '예',
            is_visit: result?.is_visit === '예',
        }

        const response = await postSurveyDemander(modifiedData)

        if (response.status === 201) {
            message.success(`수요조사가 성공적으로 등록되었습니다`)
            options.allow = true
            options.allowComplete = true
            setIsSuccess(true)
        } else {
            message.error('수요조사 등록 시 에러가 발생하였습니다.')
        }
    }

    survey.onCompleting.add(surveyComplete)
    // survey.onComplete.add(surveyComplete)
    // survey.onCompleting.add(surveyComplete)

    return <Survey model={survey} />
}
