import AWS from 'aws-sdk'
import {
    S3Client,
    DeleteObjectCommand,
    ListObjectsV2Command,
    CopyObjectCommand,
    GetObjectCommand,
} from '@aws-sdk/client-s3'
import { message } from 'antd'

import { editSurveyById } from 'api/survey/survey.api'

import { validateFileType } from './utilCommon'
import { errorModalForAntd } from './utilModal'

// 상수
const REGION = process.env.REACT_APP_S3_BUCKET_REGION
const ACESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID
const SECRET_ACESS_KEY_ID = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
const IMG_BUCKET = process.env.REACT_APP_IMG_BUCKET // S3 이미지 저장 버킷명
const USER_ID = 'JJ01' // 임시 사용자 ID
const DESTINATION_FOLDER = 'upload'
const BOARD_NAME = 'attachment'

/**
 * S3 버킷
 * config 설정
 * @type {S3Client}
 */
export const s3Client = new S3Client({
    region: REGION,
    credentials: {
        accessKeyId: ACESS_KEY_ID,
        secretAccessKey: SECRET_ACESS_KEY_ID,
    },
})

const setAWSConfig = () => {
    // AWS 설정 추가
    AWS.config.update({
        region: REGION,
        accessKeyId: ACESS_KEY_ID,
        secretAccessKey: SECRET_ACESS_KEY_ID,
    })
}
/**
 * S3 버킷
 * ManagedUpload config 설정
 * @param bucketName 버킷명
 * @param folderPath 폴더명
 * @param file 파일
 * @returns {ManagedUpload}
 */
export const setUploadS3BucketConfig = (bucketName, folderPath, file) => {
    setAWSConfig()

    // S3 ManagedUpload 매개변수 정의
    const upload = new AWS.S3.ManagedUpload({
        params: {
            ACL: 'public-read', // 접근 제어목록
            Bucket: bucketName, // S3 Bucket 이름
            Key: folderPath, // 폴더 및 파일경로 - temp: 파일 임시저장소, USER_ID: 임시저장한 사용자 폴더 생성, file.name: 파일명
            Body: file, // 실제 파일
        },
    })

    return upload
}

/**
 * S3 버킷
 * Img 임시 업로드 함수 (에디터 Preview 영역)
 * @param formData
 * @param file 실제 파일
 * @param resolve
 * @param reject
 * @returns {Promise<void>}
 */
export const uploadS3Bucket = async (formData, file, resolve, reject) => {
    const folderPath = `temp/${USER_ID}/${file.name}`
    const upload = setUploadS3BucketConfig(IMG_BUCKET, folderPath, file)

    try {
        const res = await upload.promise()
        resolve({ default: `${res.Location}` }) // 이미지 preview
    } catch (err) {
        reject(err)
    }
}

const readFileAsBinary = file => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onload = () => {
            // Ensure that the result is a Uint8Array or Buffer
            const fileData = new Uint8Array(reader.result)
            resolve(fileData)
        }

        reader.onerror = error => {
            reject(error)
        }

        reader.readAsArrayBuffer(file)
    })
}

/**
 * S3 버킷
 * 파일 임시 업로드 함수
 * @param formData
 * @param file 실제 파일
 * @param resolve
 * @param reject
 * @returns {Promise<void>}
 */
export const tempS3Bucket = async (formData, fileName, userId) => {
    const folderPath = `file/temp/${userId}/${fileName}`
    const fileData = await readFileAsBinary(formData.originFileObj)
    const upload = setUploadS3BucketConfig(IMG_BUCKET, folderPath, fileData)

    try {
        const res = await upload.promise()
        Promise.resolve(res)
        // console.log('성공', res)
    } catch (err) {
        Promise.reject(err)
        // console.log('실패', err)
    }
}

// /**
//  * S3 버킷
//  * Img 임시폴더 내 파일 삭제 함수
//  * @param contents S3 버킷 임시폴더 내용
//  * @param bucketName 버킷명
//  * @returns {Promise<void>}
//  */
// export const deleteBucketFolder = async (contents, bucketName) => {
//     try {
//         // 임시 폴더 안 파일 모두 삭제
//         if (contents.length > 0) {
//             await Promise.all(
//                 contents.map(content => {
//                     return s3Client.send(new DeleteObjectCommand({ Bucket: bucketName, Key: content.Key }))
//                 }),
//             )
//         }
//     } catch (err) {
//         console.log('Error', err)
//     }
// }

const deleteFile = async file => {
    try {
        const deleteParam = {
            Bucket: IMG_BUCKET,
            Key: file.Key,
        }
        await s3Client.send(new DeleteObjectCommand(deleteParam))
    } catch (error) {
        console.error(`Error deleting file ${file.fileName}:`, error)
    }
}

export const deleteBucketFolder = async (filesToDelete, userId) => {
    try {
        if (Array.isArray(filesToDelete) && filesToDelete.length > 0) {
            await Promise.all(filesToDelete.map(file => deleteFile(file, userId)))
        } else {
            console.log('No files to delete.')
        }
    } catch (error) {
        console.error('Error deleting files:', error)
    }
}
/**
 * S3 버킷
 * 업로드된 파일 1개 삭제
 * @param bucketName
 * @param objectKey
 * @returns {Promise<void>}
 */
export const deleteBucketObject = async (bucketName, objectKey) => {
    try {
        const params = {
            Bucket: bucketName,
            Key: objectKey,
        }
        const command = new DeleteObjectCommand(params)
        await s3Client.send(command)
        message.info(`${objectKey} file removed successfully`)
    } catch (error) {
        message.error(`${objectKey} File removed failed.`, error)
    }
}

/**
 * S3 버킷
 * 폴더 리스트 확인 함수
 * @param client S3 연결 config
 * @param bucketName 버킷명
 * @param folderPath 폴더경로
 * @returns {Promise<*>}
 */
export const confirmBucketFolderList = async (client, bucketName, folderPath) => {
    try {
        /**
         * S3 폴더 리스트 확인 config
         * @type {ListObjectsV2Command}
         */
        const listCommand = new ListObjectsV2Command({
            Bucket: bucketName,
            Prefix: folderPath,
        })

        const { Contents } = await client.send(listCommand)

        return Contents
    } catch (error) {
        console.error('Error confirming folder list:', error)
        throw error // You may want to handle or log the error differently based on your use case
    }
}

// /**
//  * S3 버킷
//  * 글쓰기 등록 시
//  * temp(임시폴더)를 upload(실제저장) 폴더로
//  * 복사하는 함수
//  * @param bucketList 저장된 이미지 리스트
//  * @param categoryId 카테고리 ID
//  * @param contentId 작성글 ID
//  * @param imgFolderPath 실제 저장될 폴더 경로
//  * @returns {Promise<void>}
//  */
// export const copyBucketFolderList = async (bucketList, categoryId, contentId, imgFolderPath) => {
//     await Promise.all(
//         bucketList.map(async object => {
//             const imgName = object.Key.split('/')[2] // 파일명
//             const imgSavePath = `${imgFolderPath}/${imgName}`
//             const copyParams = {
//                 CopySource: `/${IMG_BUCKET}/${object.Key}`, // Adjusted CopySource without encodeURIComponent
//                 Bucket: IMG_BUCKET,
//                 Key: imgSavePath,
//             }
//             await s3Client.send(new CopyObjectCommand(copyParams))
//         }),
//     )
// }

/**
 * 프로그램 등록 시
 * 버킷 폴더 복사
 * 임시폴더 -> 업로드 폴더
 * Copy files from the temp folder to the upload folder in S3.
 * @param {Array} bucketList - List of files to copy.
 * @param {string} userId - User ID for constructing the destination path.
 * @returns {Promise<void>}
 */
export const copyBucketFolderList = async (bucketList, userId) => {
    const imgSavePath = `file/upload/attachment/${userId}`

    await Promise.all(
        bucketList.map(async ({ file_name }) => {
            try {
                const copyParams = {
                    Bucket: IMG_BUCKET,
                    CopySource: `/${IMG_BUCKET}/file/temp/${userId}/${encodeURIComponent(file_name)}`,
                    Key: `${imgSavePath}/${encodeURIComponent(file_name)}`,
                }

                await s3Client.send(new CopyObjectCommand(copyParams))
            } catch (error) {
                console.error(`Error copying file ${file_name}:`, error)
            }
        }),
    )
}

/**
 * 수요조사 등록 시
 * 버킷 이미지 복사
 * Copy files from the temp folder to the upload folder in S3.
 * @param {Array} bucketList - List of files to copy.
 * @param {string} surveyId - surveyId for constructing the destination path.
 * @returns {Promise<void>}
 */
export const copyBucketFolderListBySurvey = async (bucketList, userId, surveyId) => {
    await Promise.all(
        bucketList.map(async object => {
            const imgSavePath = `file/upload/attachment/${surveyId}`
            const tempURL = `/${IMG_BUCKET}/file/temp/${userId}`
            const imgName = object.Key.split('/')[2] // 파일명
            console.log('tempURL : ', tempURL)

            try {
                const copyParams = {
                    Bucket: IMG_BUCKET,
                    // CopySource: `${tempURL}/${encodeURIComponent(object.Key)}`,
                    // error fix : String contains non ISO-8859-1 code point
                    CopySource: encodeURIComponent(`${IMG_BUCKET}/${object.Key}`),
                    Key: `${imgSavePath}/${imgName}`,
                }

                await s3Client.send(new CopyObjectCommand(copyParams))
            } catch (error) {
                console.error(`Error copying file ${imgName}:`, error)
            }
        }),
    )
}

/** ck editor
 * 이미지
 * 임시폴더 -> 작성후 저장폴더 경로변경
 * @param article 작성글
 * @param imgFolderPath 실제 저장 경로
 * @param surveyId
 * @param s3URL
 * @param cloudfrontURL
 */
export const changeImgPath = (article, imgFolderPath) => {
    // const userRegex = new RegExp(`temp/${userId}`, 'g') // 동적으로 정규식 생성
    const userRegex = /temp\/JJ01/g // 변경할 path
    const imgTagRegex = /<img\s+src="([^"]+)"/g // img 태그 정규식

    // img 태그 찾아서 실제 폴더명으로 변경
    const updatedArticle = article.replace(imgTagRegex, (match, imgSrc) => {
        const result = imgSrc.replace(userRegex, imgFolderPath) // userRegex 경로를 imgFolderPath로 변경
        // result = result.replace(s3URL, cloudfrontURL)

        return match.replace(imgSrc, result)
    })
    return updatedArticle
}

/**
 * S3 버킷
 * 에디터 글쓰기 등록 시
 * Img 파일을
 * 임시폴더(temp) -> 저장폴더(upload)로 복사 후
 * 임시경로 -> 실제경로로 변경
 * 임시폴더(temp) 삭제
 *
 * 수요조사 글쓰기용 ****
 * @param userId
 * @param registeredArticle 임시 작성된 글
 * @param surveyId
 * @param s3URL
 * @param cloudfrontURL
 * @returns {Promise<void>}
 */
// eslint-disable-next-line no-unused-vars
export const handleCopyS3Folder = async (userId, registeredArticle, surveyId, s3URL, cloudfrontURL) => {
    try {
        // const tempFolderPath = `/file/temp/${userId}`
        const tempFolderPath = `temp/JJ01`

        // 임시폴더(temp) 내 파일 리스트 확인
        const bucketList = await confirmBucketFolderList(s3Client, IMG_BUCKET, tempFolderPath)
        // 실제 저장될 경로
        const newFolderPath = `file/${DESTINATION_FOLDER}/${BOARD_NAME}/${surveyId}`

        // 임시폴더(temp) 내 img를 모두 upload 폴더에 복사
        await copyBucketFolderListBySurvey(bucketList, userId, surveyId)

        // 임시경로 -> 실제경로
        const updatedArticle = changeImgPath(registeredArticle, newFolderPath)

        //  복사 완료 후 img 임시폴더(temp)의 객체들을 삭제
        await deleteBucketFolder(bucketList, IMG_BUCKET)

        return updatedArticle
    } catch (err) {
        console.error('오류 발생:', err)
        throw err
    }
}

/**
 * S3 버킷
 * 임시 폴더 확인 후 폴더 삭제
 * @returns {Promise<void>}
 * @param userId
 */
export const clearBucketFolder = async userId => {
    // S3 임시 이미지 폴더 확인
    const bucketList = await confirmBucketFolderList(s3Client, IMG_BUCKET, `/file/temp/${userId}`)
    // S3 임시 이미지 폴더 내 파일 삭제
    if (bucketList) {
        await deleteBucketFolder(bucketList, IMG_BUCKET)
    }
}

/**
 * S3 버킷
 * AntD Form
 * 커스텀 파일 업로드 함수
 * @param file 파일
 * @param onProgress 로딩바
 * @param onError
 * @param onSuccess
 * @returns {Promise<void>}
 */
export const handleFileUploadCustomRequest = async ({ file, onProgress, onError, onSuccess, id }) => {
    const userId = id // 임시 사용자 ID
    const bucketName = 'nipa-sw-file'
    const folderPath = `file/temp/${userId}/${file.name}`
    // S3 업로드 config 설정
    const upload = setUploadS3BucketConfig(bucketName, folderPath, file)

    console.log('upload : ', upload)

    try {
        // 업로드 로딩바 설정
        upload.on('httpUploadProgress', progress => {
            const percent = Math.round((progress.loaded / progress.total) * 100)
            onProgress({ percent })
        })

        const response = await upload.promise()
        console.log('response : ', response)
        onSuccess(response)
    } catch (error) {
        onError(error)
    }
}

/**
 * S3 버킷 업로드 전
 * 이미지 타입 validate 함수
 * @param file
 * @param onProgress
 * @param onError
 * @param onSuccess
 * @returns {Promise<void>}
 */
export const handleUploadBeforeValidateImageType = async ({ file, onProgress, onError, onSuccess }) => {
    const isImgFile = validateFileType(file)
    if (!isImgFile) {
        errorModalForAntd('올바른 이미지 파일을 업로드하세요.')
        onError(new Error('올바른 이미지 파일을 업로드하세요.'))
        return // 이미지 파일이 아닐 경우 함수 종료
    }

    // 이미지 파일일 경우만 업로드 처리
    await handleFileUploadCustomRequest({ file, onProgress, onError, onSuccess })
}

// Helper function to convert a stream to a Blob
// const streamToBlob = async stream => {
//     return new Promise((resolve, reject) => {
//         const chunks = []

//         // Handle data event for the stream
//         stream.on('data', chunk => {
//             chunks.push(chunk instanceof Uint8Array ? chunk : Uint8Array.from(chunk))
//         })

//         // Handle end event for the stream
//         stream.on('end', () => {
//             try {
//                 const blob = new Blob(chunks, { type: 'application/octet-stream' })
//                 resolve(blob)
//             } catch (error) {
//                 reject(error)
//             }
//         })

//         stream.on('error', error => reject(error))
//     })
// }

// Helper function to convert a stream to a Blob
const streamToBlob = async stream => {
    return new Promise((resolve, reject) => {
        const chunks = []

        // Check if the stream is a ReadableStream
        if (stream && typeof stream.getReader === 'function') {
            // Convert the AWS SDK Readable stream to a Node.js Readable stream
            const readableStream = stream.getReader()

            // Handle data event for the stream
            readableStream.read().then(function processChunk({ value, done }) {
                if (done) {
                    try {
                        const blob = new Blob(chunks, { type: 'application/octet-stream' })
                        resolve(blob)
                    } catch (error) {
                        reject(error)
                    }
                    return
                }

                chunks.push(value instanceof Uint8Array ? value : Uint8Array.from(value))

                // Read the next chunk
                readableStream.read().then(processChunk).catch(reject)
            })
        } else {
            // If it's not a ReadableStream, handle it accordingly (you may need to adjust this part)
            console.error('Invalid stream type:', stream)
            reject(new Error('Invalid stream type'))
        }
    })
}

export const downloadFileFromS3 = async (folderPath, file_name) => {
    try {
        console.log(folderPath)
        console.log(file_name)
        const getObjectParams = {
            Bucket: IMG_BUCKET,
            Key: `${folderPath}/${encodeURIComponent(file_name)}`,
        }
        const response = await s3Client.send(new GetObjectCommand(getObjectParams))
        console.log(response)
        if (response.Body) {
            const blob = await streamToBlob(response.Body)
            const downloadLink = document.createElement('a')
            downloadLink.href = window.URL.createObjectURL(blob)
            downloadLink.download = file_name
            downloadLink.click()
            return
        }

        console.error('Invalid Body type in AWS SDK response:', response.Body)
        throw new Error('Invalid Body type in AWS SDK response')
    } catch (error) {
        console.error('Error downloading file from S3:', error)
        throw error
    }
}

/**
 * 글쓰기 등록 시
 * S3 버킷 내 img 복사 및 삭제 처리
 * @returns {Promise<void>}
 */
export const handleRegistrationPost = async (surveyId, s3URL, cloudfrontURL, content, userId) => {
    try {
        const updatedArticle = await handleCopyS3Folder(userId, content, surveyId, s3URL, cloudfrontURL) // 실제 경로
        const surveyData = {
            survey_id: surveyId,
            survey_content: updatedArticle,
        }
        const res = await editSurveyById(surveyData)
        console.log('res : ', res)
    } catch (err) {
        console.error('오류 발생:', err)
    }
}
