import React from 'react'
import { Upload } from 'antd'

import styles from './upload.module.scss'

const { Dragger } = Upload

export const DraggableUpload = ({ name, accept, handleFileUpload, multiple, fileName, handleDeleteFile }) => {
    return (
        <>
            <Dragger
                name={name}
                accept={accept}
                multiple={multiple}
                onChange={info => {
                    const { file } = info
                    handleFileUpload(file.originFileObj)
                }}
                showUploadList={false}
            >
                <div className={styles.upload_icon_wrap}>
                    <span className={styles.upload_icon} />
                </div>
                <p className="ant-upload-text">클릭하거나 드래그해서 파일을 추가하세요</p>
                <p className="ant-upload-hint">Excel(.xlsx, .xls)형식</p>
            </Dragger>
            {fileName !== '' && (
                <div className={styles.added_file_area}>
                    <span className="body2">{fileName}</span>
                    <button type="button" className="button-reset-type" onClick={handleDeleteFile}>
                        <span className={styles.delete_btn} />
                    </button>
                </div>
            )}
        </>
    )
}
