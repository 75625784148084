export const colorTheme = {
    primary: '#024383',
    secondary: {
        info: '#00A886',
        error: '#f94f29',
        warning: '#f9a71a',
    },
    third: {
        example: '#f67d08',
    },
    fourth: {
        example: '#43B89B',
    },
    fontColor: {
        darken: '#252525',
        basic: '#7a7a7a',
        light: '#c2c2c2',
        white: '#FFFFFF',
    },
    bgColor: {
        back: '#fbfbfb',
        object: '#f1f1f1',
    },
    lineColor: {
        light: '#e5e5e5',
        darken: '#252525',
    },
    blue: {
        50: '#E3F1F9',
        100: '#BADBF0',
        200: '#8FC4E7',
        300: '#67ADDD',
        400: '#499dd8',
        500: '#2d8dd2',
        600: '#2480c5',
        700: '#1a6fb4',
        800: '#125fa2',
        900: '#024383',
    },
    green: {
        50: '#dff3ee',
        100: '#AFE0D3',
        200: '#7BCCB7',
        300: '#43B89B',
        400: '#00A886',
        500: '#009873',
        600: '#008B67',
        700: '#007B58',
        800: '#006B4A',
        900: '#004F30',
    },
    red: {
        50: '#fbe9e8',
        100: '#ffccbe',
        200: '#ffab95',
        300: '#ff8a6b',
        400: '#ff6f4b',
        500: '#ff552d',
        600: '#f94f29',
        700: '#eb4824',
        800: '#dd4120',
        900: '#c33517',
    },
    yellow: {
        50: '#fffde6',
        100: '#fff9c3',
        200: '#fef59b',
        300: '#fdf072',
        400: '#fbec50',
        500: '#f9e62c',
        600: '#fcd82c',
        700: '#fac023',
        800: '#f9a71a',
        900: '#f67d08',
    },
    gray: {
        50: '#fbfbfb',
        100: '#f7f7f7',
        200: '#f1f1f1',
        300: '#e5e5e5',
        400: '#c2c2c2',
        500: '#a4a4a4',
        600: '#7a7a7a',
        700: '#666666',
        800: '#474747',
        900: '#252525',
    },
}
