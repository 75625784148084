import { useState } from 'react'

import { Col, Form, Row } from 'antd'

import Title from 'components/Ui/Title'
import Label from 'components/Ui/Label'
import Value from 'components/Ui/Value'

import TextField from 'components/AntD/TextField'

import styles from 'assets/scss/detail.module.scss'

const DemandManagerForm = ({ programDemandRepresentative, editOrRegisterPage }) => {
    const [localValue] = useState(programDemandRepresentative)

    return (
        <div>
            <Title title="수요처 담당자" />
            <div className={`${styles.border_style} ${styles.box_md}`}>
                <Row className={styles.row_item}>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="이름" />
                            </Col>
                            {editOrRegisterPage ? (
                                <Col span={14} className={`${styles.padding_y_xs} ${styles.padding_right}`}>
                                    <Form.Item name="managerName" initialValue={localValue?.managerName}>
                                        <TextField name="managerName" val={localValue?.managerName} size="sm" />
                                    </Form.Item>
                                </Col>
                            ) : (
                                <Col span={10} className={`${styles.padding_y_medium}`}>
                                    <Value value={programDemandRepresentative?.managerName} />
                                </Col>
                            )}
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="직책" />
                            </Col>
                            {editOrRegisterPage ? (
                                <Col span={14} className={`${styles.padding_y_xs} ${styles.padding_right}`}>
                                    <Form.Item name="position" initialValue={localValue?.position}>
                                        <TextField name="position" val={localValue?.position} size="sm" />
                                    </Form.Item>
                                </Col>
                            ) : (
                                <Col span={10} className={`${styles.padding_y_medium}`}>
                                    <Value value={programDemandRepresentative?.position} />
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>

                <Row className={styles.row_item}>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="휴대전화" />
                            </Col>
                            {editOrRegisterPage ? (
                                <Col span={14} className={`${styles.padding_y_xs}`}>
                                    <Form.Item name="managerPhoneNumber" initialValue={localValue?.managerPhoneNumber}>
                                        <TextField
                                            name="managerPhoneNumber"
                                            val={localValue?.managerPhoneNumber}
                                            size="sm"
                                        />
                                    </Form.Item>
                                </Col>
                            ) : (
                                <Col span={10} className={`${styles.padding_y_medium}`}>
                                    <Value value={programDemandRepresentative?.managerPhoneNumber} />
                                </Col>
                            )}
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="대표번호" />
                            </Col>
                            {editOrRegisterPage ? (
                                <Col span={14} className={`${styles.padding_y_xs}`}>
                                    <Form.Item name="managerTelNumber" initialValue={localValue?.managerTelNumber}>
                                        <TextField
                                            name="managerTelNumber"
                                            val={localValue?.managerTelNumber}
                                            size="sm"
                                        />
                                    </Form.Item>
                                </Col>
                            ) : (
                                <Col span={10} className={`${styles.padding_y_medium}`}>
                                    <Value value={programDemandRepresentative?.managerTelNumber} />
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>

                <Row className={styles.row_item}>
                    <Col span={5}>
                        <Label label="이메일" />
                    </Col>
                    {editOrRegisterPage ? (
                        <Col span={19} className={`${styles.padding_y_xs}`}>
                            <Form.Item name="managerEmail" initialValue={localValue?.managerEmail}>
                                <TextField size="xl" name="managerEmail" val={localValue?.managerEmail} />
                            </Form.Item>
                        </Col>
                    ) : (
                        <Col span={19} className={`${styles.padding_y_medium}`}>
                            <Value value={programDemandRepresentative?.managerEmail} />
                        </Col>
                    )}
                </Row>
                <Row className={editOrRegisterPage ? styles.row_item : ''}>
                    <Col span={5}>
                        <Label label="수요처 특이사항" />
                    </Col>
                    {editOrRegisterPage ? (
                        <Col span={19} className={`${styles.padding_y_xs}`}>
                            <Form.Item name="managerRemarks" initialValue={localValue?.managerRemarks}>
                                <TextField size="xl" name="managerRemarks" val={localValue?.managerRemarks} />
                            </Form.Item>
                        </Col>
                    ) : (
                        <Col span={19} className={`${styles.padding_y_medium}`}>
                            <Value value={programDemandRepresentative?.managerRemarks} />
                        </Col>
                    )}
                </Row>
            </div>
        </div>
    )
}
export default DemandManagerForm
