import { Modal, Table } from 'antd'
import ColorButton from 'components/AntD/Button/ColorButton'
import { AllDocIcon } from 'assets/Icons'
import * as XLSX from 'xlsx'
import { useState, useEffect } from 'react'
import { convertExcelColumnToKor } from 'utils/utilCommon'
import styles from 'pages/Statistics/statistics.module.scss'

const StatisticsTableDialog = ({ selectedYear, dataSource, modalOpen, setModalOpen, closable = false }) => {
    const [data, setData] = useState([])

    useEffect(() => {
        setData(dataSource)
    }, [dataSource])

    const handleCancel = e => {
        e.preventDefault()
        setModalOpen(false)
    }

    const handleDownloadXlsx = () => {
        const convertedData = convertExcelColumnToKor(data)
        const ws = XLSX.utils.json_to_sheet(convertedData)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

        const fileName = `수혜학생통계_${selectedYear}`
        XLSX.writeFile(wb, `${fileName}.xlsx`)
    }

    const columnsFull = [
        {
            title: '지역',
            dataIndex: 'region',
            key: 'region',
            fixed: 'left',
        },
        {
            title: '강사채용수',
            dataIndex: 'number_of_trained',
            key: 'number_of_trained',
        },
        {
            title: '교육신청자수',
            dataIndex: 'number_of_applicants',
            key: 'number_of_applicants',
        },
        {
            title: '교육수혜자수',
            dataIndex: 'number_of_benefited',
            key: 'number_of_benefited',
        },
        {
            title: '수혜율',
            dataIndex: 'benefited_ratio',
            key: 'benefited_ratio',
        },

        {
            title: '초등학교',
            dataIndex: 'element_school',
            key: 'element_school',
        },
        {
            title: '중학교',
            dataIndex: 'middle_school',
            key: 'middle_school',
        },
        {
            title: '고등학교',
            dataIndex: 'high_school',
            key: 'high_school',
        },
        {
            title: '특수학교',
            dataIndex: 'special_school',
            key: 'special_school',
        },
        {
            title: '남성',
            dataIndex: 'male',
            key: 'male',
        },
        {
            title: '여성',
            dataIndex: 'female',
            key: 'female',
        },
        {
            title: '미래채움센터 有',
            dataIndex: 'mirae_center_y',
            key: 'mirae_center_y',
        },
        {
            title: '미래채움센터 無',
            dataIndex: 'mirae_center_n',
            key: 'mirae_center_n',
        },
        {
            title: '일반학생',
            dataIndex: 'regular_students',
            key: 'regular_students',
        },
        {
            title: '소외계층',
            dataIndex: 'underprivileged',
            key: 'underprivileged',
        },
        {
            title: '일반학교',
            dataIndex: 'regular_school',
            key: 'regular_school',
        },
        {
            title: '도서벽지학교',
            dataIndex: 'island_school',
            key: 'island_school',
        },
        {
            title: '특수학교 (장애학교, 대안학교 등)',
            dataIndex: 'special_school',
            key: 'special_school',
        },
        {
            title: '기관 (아동복지센터 등)',
            dataIndex: 'welfare_center',
            key: 'welfare_center',
        },
        {
            title: '1~2시간',
            dataIndex: 'hour_1to2',
            key: 'hour_1to2',
        },
        {
            title: '3~8시간 미만',
            dataIndex: 'hour_3to8',
            key: 'hour_3to8',
        },
        {
            title: '8~30시간 미만',
            dataIndex: 'hour_8to30',
            key: 'hour_8to30',
        },
        {
            title: '30~50시간 미만',
            dataIndex: 'hour_30to50',
            key: 'hour_30to50',
        },
        {
            title: '50시간이상',
            dataIndex: 'more50_hours',
            key: 'more50_hours',
        },
        {
            title: '온라인',
            dataIndex: 'online',
            key: 'online',
        },
        {
            title: '오프라인',
            dataIndex: 'offline',
            key: 'offline',
        },
        {
            title: '센터내',
            dataIndex: 'in_center_edu',
            key: 'in_center_edu',
        },
        {
            title: '찾아가는교육',
            dataIndex: 'visiting_edu',
            key: 'visiting_edu',
        },
        {
            title: '기타',
            dataIndex: 'etc',
            key: 'etc',
        },
        {
            title: '찾아가는교육비율',
            dataIndex: 'visiting_ratio',
            key: 'visiting_ratio',
        },
    ]
    const columns = [
        {
            title: '지역',
            dataIndex: 'region',
            key: 'region',
            fixed: 'left',
        },
        {
            title: '강사채용수',
            dataIndex: 'number_of_trained',
            key: 'number_of_trained',
        },
        {
            title: '교육신청자수',
            dataIndex: 'number_of_applicants',
            key: 'number_of_applicants',
        },
        {
            title: '교육수혜자수',
            dataIndex: 'number_of_benefited',
            key: 'number_of_benefited',
        },
        {
            title: '수혜율',
            dataIndex: 'benefited_ratio',
            key: 'benefited_ratio',
        },

        {
            title: '초등학교',
            dataIndex: 'element_school',
            key: 'element_school',
        },
        {
            title: '중학교',
            dataIndex: 'middle_school',
            key: 'middle_school',
        },
        {
            title: '고등학교',
            dataIndex: 'high_school',
            key: 'high_school',
        },
        {
            title: '특수학교',
            dataIndex: 'special_school',
            key: 'special_school',
        },
        // {
        //     title: '남성',
        //     dataIndex: 'male',
        //     key: 'male',
        // },
        // {
        //     title: '여성',
        //     dataIndex: 'female',
        //     key: 'female',
        // },
        // {
        //     title: '미래채움센터 有',
        //     dataIndex: 'mirae_center_y',
        //     key: 'mirae_center_y',
        // },
        // {
        //     title: '미래채움센터 無',
        //     dataIndex: 'mirae_center_n',
        //     key: 'mirae_center_n',
        // },
        {
            title: '일반학생',
            dataIndex: 'regular_students',
            key: 'regular_students',
        },
        {
            title: '소외계층',
            dataIndex: 'underprivileged',
            key: 'underprivileged',
        },
        {
            title: '일반학교',
            dataIndex: 'regular_school',
            key: 'regular_school',
        },
        {
            title: '도서벽지학교',
            dataIndex: 'island_school',
            key: 'island_school',
        },
        {
            title: '특수학교 (장애학교, 대안학교 등)',
            dataIndex: 'special_school',
            key: 'special_school',
        },
        {
            title: '기관 (아동복지센터 등)',
            dataIndex: 'welfare_center',
            key: 'welfare_center',
        },
        {
            title: '1~2시간',
            dataIndex: 'hour_1to2',
            key: 'hour_1to2',
        },
        {
            title: '3~8시간 미만',
            dataIndex: 'hour_3to8',
            key: 'hour_3to8',
        },
        {
            title: '8~30시간 미만',
            dataIndex: 'hour_8to30',
            key: 'hour_8to30',
        },
        {
            title: '30~50시간 미만',
            dataIndex: 'hour_30to50',
            key: 'hour_30to50',
        },
        {
            title: '50시간이상',
            dataIndex: 'more50_hours',
            key: 'more50_hours',
        },
        {
            title: '온라인',
            dataIndex: 'online',
            key: 'online',
        },
        {
            title: '오프라인',
            dataIndex: 'offline',
            key: 'offline',
        },
        {
            title: '센터내',
            dataIndex: 'in_center_edu',
            key: 'in_center_edu',
        },
        {
            title: '찾아가는교육',
            dataIndex: 'visiting_edu',
            key: 'visiting_edu',
        },
        {
            title: '기타',
            dataIndex: 'etc',
            key: 'etc',
        },
        {
            title: '찾아가는교육비율',
            dataIndex: 'visiting_ratio',
            key: 'visiting_ratio',
        },
    ]
    return (
        <Modal
            title={`${selectedYear}년 수혜학생 통계`}
            visible={modalOpen}
            onOk={() => setModalOpen(false)}
            onCancel={() => setModalOpen(false)}
            closable={closable}
            width={1200}
            footer={[
                <ColorButton key="download" onClick={handleDownloadXlsx} icon={<AllDocIcon />}>
                    엑셀 다운로드
                </ColorButton>,
                <ColorButton key="cancel" onClick={handleCancel}>
                    취소
                </ColorButton>,
            ]}
        >
            <Table
                className={styles.table}
                dataSource={data}
                columns={selectedYear === '2024' ? columns : columnsFull}
                pagination={false}
                scroll={{ x: 5000 }}
            />
        </Modal>
    )
}

export default StatisticsTableDialog
