import { Col, Row, Form, Button } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import { useLocation } from 'react-router-dom'
import { PlusIcon } from 'assets/Icons'

import IssueEntry from './IssueEntry'

import styles from './report.module.scss'

const Significant = ({ issue }) => {
    const commonHeadStyles = `${styles.head} ${styles.bottom_line}`
    const worning = '* 협약변경이 필요한 사항, 지역 공통으로 논의가 필요한 사항 등에 대해 작성'

    const { pathname } = useLocation()
    const paths = pathname.split('/').filter(path => path !== '')
    return (
        <div className={`${styles.section_md}`}>
            <div className="h5">사업 수행관련 이슈 및 특이사항</div>
            <div className={`${styles.card} ${styles.section_24}`}>
                <Row>
                    <Col span={9} className={commonHeadStyles}>
                        주요내용
                    </Col>
                    <Col span={6} className={commonHeadStyles}>
                        해결방안
                    </Col>
                    <Col span={2} className={commonHeadStyles}>
                        발생일
                    </Col>
                    <Col span={7} className={commonHeadStyles}>
                        조치사항
                    </Col>
                    <div className={`${styles.width_100}`}>
                        {paths[1] === 'register' || paths[1] === 'edit' ? (
                            <Form.List name="issue">
                                {(fields, { add, remove }) => {
                                    return (
                                        <>
                                            {fields.length === 0 ? (
                                                <div className={styles.not_found_container}>
                                                    <span className={styles.not_found_icon} />
                                                    <div className={styles.not_found_title}>
                                                        <span className="sub_title1">
                                                            사업 수행관련 이슈를 등록해주세요.
                                                        </span>
                                                    </div>
                                                </div>
                                            ) : (
                                                fields.map((field, index) => (
                                                    <IssueEntry
                                                        key={uuidv4()}
                                                        number={index}
                                                        item={field}
                                                        remove={remove}
                                                        length={fields.length}
                                                    />
                                                ))
                                            )}
                                            <Form.Item className={styles.mb0}>
                                                <Button
                                                    className={`${styles.height_100} ${styles.plus}`}
                                                    onClick={() => {
                                                        add()
                                                    }}
                                                    block
                                                >
                                                    <PlusIcon />
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )
                                }}
                            </Form.List>
                        ) : issue && issue.mainPoints && issue.mainPoints.length > 0 ? (
                            [...Array(issue.mainPoints.length)].map((_, index) => (
                                <IssueEntry key={uuidv4()} number={index} item={issue} />
                            ))
                        ) : (
                            <div className={styles.not_found_container}>
                                <span className={styles.not_found_icon} />
                                <div className={styles.not_found_title}>
                                    <span className="sub_title1">사업 수행관련 이슈를 등록해주세요.</span>
                                </div>
                            </div>
                        )}
                    </div>
                </Row>
            </div>
            {(paths[1] === 'register' || paths[1] === 'edit') && <span className={styles.red}>{worning}</span>}
        </div>
    )
}

export default Significant
