import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { supabase } from 'api/axios.instance'
import { postProgram } from 'api/program/program.api'

import { useSelector } from 'react-redux'
import { getAllUserInfo } from 'store/Slices/user'

import { convertProgramCamelToSnakeCase } from 'utils/utilCommon'
import { copyBucketFolderList, deleteBucketFolder } from 'utils/utilS3Bucket'

import { App } from 'antd'

import PageBanner from 'layout/PageBanner'
import RegistrationAndEditFormPrograms from 'components/RegistrationAndEditForm/Programs'

import styles from 'assets/scss/detail.module.scss'

const ProgramRegisterOne = () => {
    const navigate = useNavigate()
    const { userInfo } = useSelector(getAllUserInfo)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const { message } = App.useApp()

    const onFinish = async value => {
        console.log(value)
        const formData = convertProgramCamelToSnakeCase(value)
        const matchingFilter = (arr, programId, date) => {
            const newArray = arr.map(item => {
                const { id, instructor_matching_no, attachment_no, ...rest } = item
                return {
                    ...rest,
                    program_id: programId,
                    ...(date && { start_date: value.startDate, end_date: value.endDate }),
                }
            })

            return newArray
        }
        const registrProgram = async () => {
            // 1. 프로그램 등록
            const registerProgram = async () => {
                return postProgram({
                    ...formData,
                    region_id: userInfo.regionId,
                    sys_regr_id: userInfo.userId,
                    is_delete: false,
                })
            }

            // 2. 프로그램 첨부 파일 업데이트
            const updateProgramAttachment = async ({ programId }) => {
                if (value.programAttachmentList) {
                    return supabase
                        .from('program_attachment')
                        .upsert(matchingFilter(value.programAttachmentList, programId))
                }
                return null
            }

            // 3. 프로그램 강사 매칭 업데이트
            const updateProgramInstructorMatching = async ({ programId }) => {
                if (value.programInstructorMatchingList) {
                    return supabase
                        .from('program_instructor_matching')
                        .upsert(matchingFilter(value.programInstructorMatchingList, programId, true))
                }
                return null
            }

            // Use Promise.all to wait for all async operations to complete
            // const responses = await Promise.all([
            //     postProgram({
            //         ...formData,
            //         region_id: userInfo.regionId,
            //         sys_regr_id: userInfo.userId,
            //         is_delete: false,
            //     }),
            //     value.programAttachmentList &&
            //         supabase.from('program_attachment').upsert(matchingFilter(value.programAttachmentList)),
            //     value.programInstructorMatchingList &&
            //         supabase
            //             .from('program_instructor_matching')
            //             .upsert(matchingFilter(value.programInstructorMatchingList)),
            // ])

            // 1. 프로그램 등록이 먼저 진행됨
            const programResponse = await registerProgram()

            const programId = programResponse.data[0]?.program_id

            // 2. 프로그램 첨부 파일 업데이트와 3. 프로그램 강사 매칭 업데이트는 병렬로 진행
            const [attachmentResponse, instructorMatchingResponse] = await Promise.all([
                updateProgramAttachment({ programId }),
                updateProgramInstructorMatching({ programId }),
            ])

            console.log('attachmentResponse : ', attachmentResponse)
            console.log('instructorMatchingResponse : ', instructorMatchingResponse)

            // const hasError = responses.some(response => response.error)
            //
            // if (hasError) {
            //     message.error('수정 중 오류가 발생했습니다')
            //     return
            // }

            if (value.programAttachmentList) {
                await copyBucketFolderList(value.programAttachmentList, userInfo.userId)
            }
            if (value.programAttachmentList) {
                await deleteBucketFolder(value.programAttachmentList, userInfo.userId)
            }

            message.success('성공적으로 등록되었습니다')

            if (programId) {
                navigate(`/program/${programId}`)
            } else {
                navigate(`/program`)
            }
        }

        await registrProgram()
    }

    const onFinishFailed = errorInfo => {
        message.error('필수값을 확인해주세요')
        console.log('Failed:', errorInfo)
    }

    return (
        <PageBanner title="교육계획 등록">
            <div className={styles.area}>
                <RegistrationAndEditFormPrograms
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    setIsModalOpen={setIsModalOpen}
                    isModalOpen={isModalOpen}
                />
            </div>
        </PageBanner>
    )
}

export default ProgramRegisterOne
