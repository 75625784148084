import dayjs from "dayjs"
import { Col, Row, Form, Input } from 'antd'

import styles from './report.module.scss'

const MainContent = ({ organizationName, projectName, overallResponsiblePerson, author, paths, createMonth }) => {
    const valueType = paths[1] === 'register' || paths[1] === 'edit' ? styles.align_center : styles.value
    const selectedYear = dayjs(createMonth).format('YYYY')

    return (
        <div className={`${styles.section_md}`}>
            <div className="h5">주요내용</div>
            <div className={`${styles.card}`}>
                <Row className={styles.bottom_line}>
                    <Col span={3}>
                        <span className={`${styles.label} ${styles.main_content}`}>
                            기관명{' '}
                            {(paths[1] === 'register' || paths[1] === 'edit') && <span className={styles.red}>*</span>}
                        </span>
                    </Col>
                    <Col span={5} className={valueType}>
                        <Form.Item
                            name="organizationName"
                            initialValue={organizationName || ''}
                            rules={[{ required: true, message: '' }]}
                            noStyle
                        >
                            {paths[1] === 'register' || paths[1] === 'edit' ? (
                                <Input
                                    name="organizationName"
                                    value={organizationName || ''}
                                    placeholder="기관명을 입력해주세요."
                                    className={`${styles.ant_input}`}
                                    onKeyDown={e => (e.keyCode === 13 ? e.preventDefault() : '')}
                                />
                            ) : (
                                <span className={styles.value}>{organizationName}</span>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <span className={styles.label}>
                            사업명
                            {(paths[1] === 'register' || paths[1] === 'edit') && <span className={styles.red}>*</span>}
                        </span>
                    </Col>
                    <Col span={11} className={valueType}>
                        <Form.Item
                            name="projectName"
                            initialValue={projectName || `${selectedYear}년 SW미래채움`}
                            rules={[{ required: true, message: '' }]}
                            noStyle
                        >
                            {/*{paths[1] === 'register' || paths[1] === 'edit' ? (*/}
                            {/*    <Input*/}
                            {/*        name="projectName"*/}
                            {/*        value={'' || ''}*/}
                            {/*        placeholder="사업명을 입력해주세요."*/}
                            {/*        className={`${styles.ant_input}`}*/}
                            {/*        onKeyDown={e => (e.keyCode === 13 ? e.preventDefault() : '')}*/}
                            {/*    />*/}
                            {/*) : (*/}
                            {/*    <span className={styles.value}>{projectName}</span>*/}
                            {/*)}*/}
                            <span className={`${styles.value} ${styles.project_name}`}>{projectName || `${selectedYear}년 SW미래채움`}</span>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={3}>
                        <span className={styles.label}>
                            작성자{' '}
                            {(paths[1] === 'register' || paths[1] === 'edit') && <span className={styles.red}>*</span>}
                        </span>
                    </Col>
                    <Col span={5} className={valueType}>
                        <Form.Item name="author" rules={[{ required: true }]} initialValue={author || ''} noStyle>
                            {paths[1] === 'register' || paths[1] === 'edit' ? (
                                <Input
                                    name="author"
                                    value={author || ''}
                                    placeholder="작성자를 입력해주세요."
                                    className={`${styles.ant_input}`}
                                    onKeyDown={e => (e.keyCode === 13 ? e.preventDefault() : '')}
                                />
                            ) : (
                                <span className={styles.value}>{author}</span>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <span className={styles.label}>
                            총괄책임자
                            {(paths[1] === 'register' || paths[1] === 'edit') && <span className={styles.red}>*</span>}
                        </span>
                    </Col>
                    <Col span={5} className={`${valueType}`}>
                        <Form.Item
                            name="overallResponsiblePerson"
                            initialValue={overallResponsiblePerson || ''}
                            rules={[{ required: true }]}
                            noStyle
                        >
                            {paths[1] === 'register' || paths[1] === 'edit' ? (
                                <Input
                                    name="overallResponsiblePerson"
                                    value={overallResponsiblePerson || ''}
                                    placeholder="총괄책임자를 입력해주세요."
                                    className={`${styles.ant_input}`}
                                    onKeyDown={e => (e.keyCode === 13 ? e.preventDefault() : '')}
                                />
                            ) : (
                                <span className={styles.value}>{overallResponsiblePerson}</span>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default MainContent
