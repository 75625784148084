import { buildSupabaseChainFilter, convertKeysToCamelCase } from 'utils/utilCommon'
import { mockInstance, supabase } from '../axios.instance'
import { getCommonPageInfo } from '../rpc/rpc.api'

const table = 'demand_survey'
// const demanderTable = 'demander_info'

export const submitSurvey = async survey => {
    try {
        const response = await mockInstance.post(`/survey/result`, survey)
        return response
    } catch (error) {
        console.error(error)
        return null
    }
}

/**
 * 수요조사 목록
 * @param search
 * @param regionId
 * @param path
 * @returns {Promise<PostgrestFilterBuilder<*, *, GetResult<*, *, *, "*">[], *>|null>}
 */
export const getSurveyList = async (search, regionId, path) => {
    try {
        //페이지 정보 response
        const pageSize = 10
        const paginationInfo = await getCommonPageInfo(search, table, regionId, pageSize)

        // query
        let { query } = await buildSupabaseChainFilter(table, search, regionId, path)
        if (path === 'survey') {
            query = query.order('survey_id', { ascending: false })
        }
        const response = await query
        if (response.data) {
            const convertedData = convertKeysToCamelCase(response.data)
            response.data = {
                surveys: convertedData,
                page: paginationInfo,
            }
            return response
        }
        return response
    } catch (error) {
        console.error(error)
        return null
    }
}

/**
 * 수요조사 생성
 * @param surveyList
 * @returns {Promise<PostgrestResponseSuccess<GetResult<any, any, R | unknown, "*">[]>|PostgrestResponseFailure|null>}
 */
export const postSurvey = async surveyList => {
    try {
        return await supabase.from(table).insert(surveyList).select()
    } catch (error) {
        console.error(error)
        return null
    }
}

/**
 * 수요조사 수정
 * @returns {Promise<PostgrestResponseSuccess<null>|PostgrestResponseFailure|*>}
 * @param survey
 */
export const editSurveyById = async survey => {
    try {
        const response = await supabase.from(table).update(survey).eq('survey_id', survey.survey_id).select()
        return response
    } catch (error) {
        console.error(error)
        return error
    }
}

/**
 * 수요조사 삭제
 * is_delete(삭제여부) true로 변경
 * @param tutorId
 * @returns {Promise<*|null>}
 */
export const deleteSurveyById = async surveyId => {
    try {
        const response = await supabase.from(table).update({ is_delete: true }).eq('survey_id', surveyId).select()
        return response
    } catch (error) {
        console.error(error)
        return null
    }
}

/**
 * 수요조사 상세
 * surveyId
 * @returns {Promise<*|({error: true} & "Received a generic string")>}
 * @param surveyId
 */
export const getSurveyById = async surveyId => {
    try {
        const response = await supabase.from(table).select().eq('survey_id', surveyId)
        if (response.data && response.data.length > 0) {
            const modifiedCalmelData = convertKeysToCamelCase(response.data)
            return modifiedCalmelData[0]
        }
        return response
    } catch (error) {
        console.error(error)
        return error
    }
}

/**
 * 수요조사 상세
 * surveyUuid
 * @returns {Promise<*|({error: true} & "Received a generic string")>}
 * @param surveyUuid
 */
export const getSurveyByUuId = async surveyUuid => {
    try {
        const response = await supabase.from(table).select().eq('survey_uuid', surveyUuid)
        if (response.data && response.data.length > 0) {
            const modifiedCalmelData = convertKeysToCamelCase(response.data)
            return modifiedCalmelData
        }
        return response
    } catch (error) {
        console.error(error)
        return error
    }
}

/**
 * 수요조사 id 가져오기
 * @param surveyUuid
 * @returns
 */
export const getSurveyIdByUuId = async surveyUuid => {
    const { data, error } = await supabase.from(table).select('survey_id').eq('survey_uuid', surveyUuid)
    const { survey_id } = data[0]

    if (error) {
        console.error(error)
        return error
    }

    return survey_id
}
