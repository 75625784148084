import React, { useState, useEffect, useCallback } from 'react'
import { Flex, Input, Form, App, Button } from 'antd'
import { BannerIconContact } from 'assets/Icons'
import Value from 'components/Ui/Value'
import { v4 as uuidv4 } from 'uuid'
import styles from 'assets/scss/detail.module.scss'
import { downloadFileFromS3 } from 'utils/utilS3Bucket'
import PageBanner from 'layout/PageBanner'
import { useSelector } from 'react-redux'
import ColorButton from 'components/AntD/Button/ColorButton'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { getAllUserInfo } from 'store/Slices/user'
import { handleGetContact, putContact } from 'api/contact/contact.api'
import { ReactComponent as AdminIconBk } from '../../../assets/Icons/svg/Icon/36/icon-admin-bk.svg'
import { DownloadIcon24 } from '../../../assets/Icons'
import '../contact.scss'

import SanitizedHTML from '../../../components/sanitizeHTML'

const ContactView = () => {
    const navigate = useNavigate()
    const { message } = App.useApp()
    const { id } = useParams()
    const [contact, setContact] = useState(null)
    const { TextArea } = Input
    const { userInfo } = useSelector(getAllUserInfo)

    const getContactInfo = useCallback(async () => {
        try {
            const contactResult = await handleGetContact(id)
            setContact(contactResult.data[0])
        } catch (error) {
            console.error(error)
        }
    }, [id])

    const contactDate = dayjs(contact?.created_at).format('YYYY년 MM월 DD일')
    const putContacts = async contactData => {
        const response = await putContact(contactData, contact.contact_id)
        if (response.error) {
            message.error('등록 중 오류가 발생했습니다')
            return
        }
        if (response.data) {
            message.success('성공적으로 등록되었습니다')
            getContactInfo()
        }
    }
    const onFinish = values => {
        const contactData = {
            contact_title: contact.contact_title,
            contact_contents: contact.contact_contents,
            contact_status: 'admin',
            region_id: contact.region_id,
            contact_answer: values.contentAnswer,
        }

        putContacts(contactData)
    }
    const handleDownload = item => {
        const cleanedFilePath = item.file_path.startsWith('/') ? item.file_path.substring(1) : item.file_path
        downloadFileFromS3(cleanedFilePath, item.file_name)
            .then(blob => {
                console.log('File downloaded successfully:', blob)
            })
            .catch(error => {
                console.error('Error:', error)
            })
    }
    useEffect(() => {
        getContactInfo()
    }, [id, getContactInfo])

    const renderBadge = () => {
        if (contact?.contact_category === '기능 문의') {
            return <span className="badge fill_green">기능 문의</span>
        }
        if (contact?.contact_category === '오류 제보') {
            return <span className="badge fill_error">오류 제보</span>
        }
        return null
    }
    return (
        <PageBanner title="문의하기" bannerIcon={<BannerIconContact />}>
            <Form onFinish={onFinish}>
                <div className="contact_container">
                    <div className="view_title">
                        {renderBadge()}
                        <div className="h6">{contact?.contact_title}</div>

                        <span className="contact_label text_right">운영상태</span>
                        {/* 답변대기 : gray, 답변 완료 : blue */}
                        <strong className="contact_label blue">
                            {contact?.contact_answer === null ? '답변 대기' : '답변 완료'}
                        </strong>
                    </div>

                    <div className="contact_card">
                        <div className="title_box">
                            <span className="contact_label">번호</span>
                            <span className="contact_label gray">{contact?.contact_id}</span>
                            <span className="contact_label">지역</span>
                            <span className="contact_label gray">{contact?.region_id}</span>
                            <span className="contact_label">작성일</span>
                            <span className="contact_label gray">{contactDate}</span>
                            <span className="contact_label">이메일</span>
                            <span className="contact_label gray">{contact?.contact_email}</span>
                        </div>
                        {contact?.contact_attachment && contact.contact_attachment.length > 0 && (
                            <div className={styles.border_style}>
                                {contact?.contact_attachment?.map(item => (
                                    <Flex
                                        justify="space-between"
                                        align="center"
                                        key={uuidv4()}
                                        className={`${styles.padding_x_medium} ${styles.row_item}`}
                                    >
                                        <Value value={item?.file_name} />
                                        <Button onClick={() => handleDownload(item)} icon={<DownloadIcon24 />} />
                                    </Flex>
                                ))}
                            </div>
                        )}

                        <div className="title_box">
                            <div className="title_box_inner">
                                <SanitizedHTML className={`${styles.text}`} html={contact?.contact_contents} />
                            </div>
                        </div>
                    </div>
                    {userInfo.regionId === 'all' && contact?.contact_answer === null && (
                        <div className="admin_write">
                            <AdminIconBk />
                            <Form.Item name="contentAnswer" rules={[{ required: true }]} noStyle>
                                <TextArea
                                    placeholder="답변 내용을 입력해주세요."
                                    rows={20}
                                    className="ant_textarea body2"
                                    style={{ resize: 'none' }}
                                />
                            </Form.Item>
                        </div>
                    )}
                    {contact?.contact_answer && (
                        <div className="gray_box">
                            <AdminIconBk />
                            <SanitizedHTML className={`${styles.text}`} html={contact?.contact_answer} />
                        </div>
                    )}

                    <Flex justify="end" gap={8}>
                        {(userInfo.regionId !== 'all' || contact?.contact_answer !== null) && (
                            <ColorButton type="primary" size="large" onClick={() => navigate('/contact')}>
                                목록
                            </ColorButton>
                        )}
                        {userInfo.regionId === 'all' && contact?.contact_answer === null && (
                            <>
                                <ColorButton type="primary" size="large" htmlType="submit">
                                    답변완료
                                </ColorButton>
                                <ColorButton size="large" onClick={() => navigate('/contact')}>
                                    취소
                                </ColorButton>
                            </>
                        )}
                    </Flex>
                </div>
            </Form>
        </PageBanner>
    )
}

export default ContactView
