import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import Chips from 'components/AntD/Chips'
import InfoDialog from 'components/AntD/Dialog'
import {
    AttachmentIcon,
    ListItemMoreIcon,
    LongPeriod,
    MiddlePeriod,
    OnCancel,
    OnExpect,
    OnFinish,
    OnGoing,
    ShortPeriod,
} from 'assets/Icons'

import { deleteProgramById } from 'api/program/program.api'
import { Col, Dropdown, Row, Tooltip } from 'antd'

import DeleteModalContent from 'components/AntD/Dialog/deleteModalContent'

import { colorTheme } from 'assets/colorTheme'

import { useSelector } from 'react-redux'
import { getUser } from 'store/Slices/user'

import styles from './gridListItem.module.scss'

const GridListItem = ({ item, setLoading, fetchList }) => {
    const location = useLocation()
    const { blue, gray, yellow, green } = colorTheme

    const { programBasicInfo, programOperationInfo, programAttachmentList, programDemandAgency, programId } = item
    const { programName, educationType, method, course } = programBasicInfo
    const { status, classHours } = programOperationInfo
    const { grade, areaName, organizationName } = programDemandAgency || ''

    const { userId, isMaster } = useSelector(getUser)
    const [isModalOpen, setIsModalOpen] = useState(false)

    const handleDeleteProgram = e => {
        e.preventDefault()
        setIsModalOpen(true)
    }

    const handleConfirmDelete = async e => {
        const { search } = location
        const searchPath = search.split('?')[1] || ''

        e.preventDefault()
        setLoading(true)
        const deleteProgram = async () => {
            try {
                const response = await deleteProgramById(programId, userId)
                console.log('response : ', response)
                return false
            } catch (error) {
                console.error('프로그램 목록을 불러오는 동안 오류가 발생했습니다.', error)
                return null
            }
        }
        await deleteProgram()
        await fetchList(searchPath)
        setIsModalOpen(false)
        setLoading(false)
    }

    const items = [
        {
            label: (
                <Tooltip title="수정">
                    <Link to={`/program/edit/${programId}`}>
                        <span className={styles.edit_icon} />
                    </Link>
                </Tooltip>
            ),
            key: '1',
        },
        {
            label: (
                <Tooltip title="삭제">
                    <button type="button" className="button-reset-type" onClick={e => handleDeleteProgram(e)}>
                        <span className={styles.delete_icon} />
                    </button>
                </Tooltip>
            ),
            key: '2',
        },
    ]

    return (
        <>
            <div className={styles.badge}>
                {/* 프로그램 운영 뱃지 */}
                {status === '예정' && <Chips color={yellow['800']} icon={<OnExpect />} tooltip={status} />}
                {status === '진행' && <Chips color={blue['600']} icon={<OnGoing />} tooltip={status} />}
                {status === '완료' && <Chips color={gray['500']} icon={<OnFinish />} tooltip={status} />}
                {status === '취소' && <Chips color={gray['300']} icon={<OnCancel />} tooltip={status} />}
                {/* 장/단/중기 뱃지 */}
                {classHours < 8 ? (
                    <Chips color={green['400']} icon={<ShortPeriod />} tooltip="단기" />
                ) : classHours >= 8 && classHours < 50 ? (
                    <Chips color={green['400']} icon={<MiddlePeriod />} tooltip="중기" />
                ) : (
                    <Chips color={green['400']} icon={<LongPeriod />} tooltip="장기" />
                )}
                {!isMaster && (
                    <div className={styles.more}>
                        <Dropdown
                            menu={{
                                items,
                            }}
                        >
                            <button type="button" className="button-reset-type">
                                <ListItemMoreIcon />
                            </button>
                        </Dropdown>
                    </div>
                )}
            </div>
            <div className={styles.title_wrap}>
                <div className={styles.title}>
                    <span className="sub_title1">{programName}</span>
                    {programAttachmentList && programAttachmentList.length > 0 && (
                        <Tooltip title="첨부파일">
                            <button type="button" className="button-reset-type">
                                <AttachmentIcon />
                            </button>
                        </Tooltip>
                    )}
                </div>
            </div>
            <div className={styles.item_wrap}>
                <Row>
                    <Col span={12} order={1}>
                        <ul>
                            <li className={styles.item}>
                                <span className={`body2 ${styles.subject}`}>교육방식</span>
                                <span className={`body2 ${styles.content}`}>{educationType}</span>
                            </li>
                        </ul>
                    </Col>
                    <Col span={12} order={1}>
                        <ul>
                            <li className={styles.item}>
                                <span className={`body2 ${styles.subject}`}>교육그룹</span>
                                <span className={`body2 ${styles.content}`}>{course}</span>
                            </li>
                        </ul>
                    </Col>
                    <Col span={12} order={1}>
                        <ul>
                            <li className={styles.item}>
                                <span className={`body2 ${styles.subject}`}>교육방식</span>
                                <span className={`body2 ${styles.content}`}>{method}</span>
                            </li>
                        </ul>
                    </Col>
                    <Col span={12} order={1}>
                        <ul>
                            <li className={styles.item}>
                                <span className={`body2 ${styles.subject}`}>수요기관</span>
                                <span className={`body2 ${styles.content}`}>{organizationName}</span>
                            </li>
                        </ul>
                    </Col>
                    <Col span={12} order={1}>
                        <ul>
                            <li className={styles.item}>
                                <span className={`body2 ${styles.subject}`}>학년</span>
                                <span className={`body2 ${styles.content}`}>{grade}</span>
                            </li>
                        </ul>
                    </Col>
                    {areaName && (
                        <Col span={12} order={1}>
                            <ul>
                                <li className={styles.item}>
                                    <span className={`body2 ${styles.subject}`}>권역</span>
                                    <span className={`body2 ${styles.content}`}>{areaName}</span>
                                </li>
                            </ul>
                        </Col>
                    )}
                </Row>
            </div>
            <InfoDialog
                content={<DeleteModalContent />}
                centered
                modalOpen={isModalOpen}
                setModalOpen={setIsModalOpen}
                closable={false}
                handleConfirm={handleConfirmDelete}
                isCancel
            />
        </>
    )
}

export default GridListItem
