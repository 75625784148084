import PageBanner from 'layout/PageBanner'

import ReportDetail from 'components/Report'

/**
 * 월간보고
 * 상세,등록,수정 공통
 * @returns {JSX.Element}
 * @constructor
 */
const ReportDetailPage = () => {
    return (
        <PageBanner title="월간보고 상세">
            <ReportDetail />
        </PageBanner>
    )
}

export default ReportDetailPage
