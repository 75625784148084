import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import Breadcrumb from 'components/AntD/Breadcrumb'
import { BannerIconReport } from 'assets/Icons'
import styles from './pageBanner.module.scss'

/**
 * Top layout (title, breadcrumb, icon)
 * @param title
 * @param children
 * @param bannerIcon
 * @returns {JSX.Element}
 * @constructor
 */
const PageBanner = ({ title, children, bannerIcon = <BannerIconReport /> }) => {
    const { pathname } = useLocation()
    const path = pathname.split('/')[1]

    const navigate = useNavigate()
    const [selectedItems] = useState() // 1 depth

    const getLayoutColor = pathName => {
        const pathMap = {
            program: `${styles.container} ${styles.program}`,
            tutors: `${styles.container} ${styles.tutors}`,
            tools: `${styles.container} ${styles.tools}`,
            statistics: `${styles.container} ${styles.statistics}`,
            report: `${styles.container} ${styles.report}`,
            survey: `${styles.container} ${styles.survey}`,
            accounts: `${styles.container} ${styles.accounts}`,
            //문의하기 페이지 추가
            contact: `${styles.container} ${styles.contact}`,
        }

        return pathMap[pathName] || ''
    }

    const getContentWrapColor = pathName => {
        const pathMap = {
            program: `${styles.page_header_section} ${styles.program}`,
            tutors: `${styles.page_header_section} ${styles.tutors}`,
            tools: `${styles.page_header_section} ${styles.tools}`,
            statistics: `${styles.page_header_section} ${styles.statistics}`,
            report: `${styles.page_header_section} ${styles.report}`,
            survey: `${styles.page_header_section} ${styles.survey}`,
            accounts: `${styles.page_header_section} ${styles.accounts}`,
            //문의하기 페이지 추가
            contact: `${styles.page_header_section} ${styles.contact}`,
        }

        return pathMap[pathName] || ''
    }

    // breadcrumb 라우팅
    const handleSelectChange = value => {
        navigate(value)
    }

    return (
        <div>
            <div className={getLayoutColor(path)} color="program">
                <div className={getContentWrapColor(path)}>
                    <div className={styles.page_header}>
                        <div className={styles.title}>
                            <span className="slim_text1">{title}</span>
                            <div className={styles.icon}>{bannerIcon}</div>
                        </div>
                        <Breadcrumb selectedItems={selectedItems} setSelectedItems={handleSelectChange} />
                    </div>
                </div>
            </div>
            <div className={styles.content}>{children}</div>
        </div>
    )
}

export default PageBanner
