import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { BannerIconSurvey } from 'assets/Icons'

// import surveys from 'mocks/data/survey/surveyList.json'
import { getSurveyList } from 'api/survey/survey.api'

import { useSelector } from 'react-redux'
import { getAllUserInfo } from 'store/Slices/user'

import PageBanner from 'layout/PageBanner'

import ListView from 'components/List/ListView'
import Pagenation from 'components/Pagenation'
import Spinner from 'components/AntD/Spin'

import ListNotFound from 'pages/PageNotFound/ListNotFound'
import styles from '../Tutors/tutor.module.scss'

const Survey = () => {
    const queryParams = new URLSearchParams(window.location.search)
    const location = useLocation()
    const navigate = useNavigate()
    const path = location.pathname.split('/')[1]

    const { userInfo } = useSelector(getAllUserInfo)

    const [isLoading, setLoading] = useState(true)
    const [surveyList, setSurveyList] = useState(null)

    const { surveys, page } = surveyList || {}

    // const queryParams = new URLSearchParams(window.location.search)

    const { currentPage, totalPages, totalItems } = page || {}

    const updateQueryStringAndNavigate = (paramName, paramValue, loading) => {
        const { pathname } = location
        const previousParams = queryParams.get(paramName)

        // 새로운 파라미터 추가 (단, 값이 비어있지 않을 때)
        if (paramValue) {
            if (previousParams === paramValue) {
                if (paramName !== 'sort' && paramName !== 'search') {
                    queryParams.delete(paramName)
                }
            } else {
                queryParams.set(paramName, paramValue)
            }
        }

        const newQueryString = queryParams.toString()
        const newLocation = newQueryString ? `${pathname}?${newQueryString}` : pathname

        if (loading) {
            loading(false)
        }
        navigate(newLocation)
    }

    const fetchSurveyList = async search => {
        setLoading(true)

        const result = await getSurveyList(search, userInfo.regionId, path)

        setSurveyList(result.data)
        setLoading(false)
    }

    useEffect(() => {
        const { search } = location
        const searchPath = search.split('?')[1] || ''
        fetchSurveyList(searchPath)
    }, [location])

    return (
        <PageBanner title="수요조사" bannerIcon={<BannerIconSurvey />}>
            {isLoading ? (
                <div className={styles.spin_wrap}>
                    <Spinner />
                </div>
            ) : !surveys || surveys.length === 0 ? (
                <ListNotFound />
            ) : (
                <>
                    <ListView list={surveys.slice(0, 10)} setLoading={setLoading} fetchList={fetchSurveyList} />
                    <Pagenation
                        title="수요조사 등록"
                        currentPage={currentPage}
                        totalPages={totalPages}
                        totalItems={totalItems}
                        navigateBtn={() => navigate('/survey/register')}
                        updateFtn={updateQueryStringAndNavigate}
                    />
                </>
            )}

            {/*<InfoDialog*/}
            {/*    content={<Account setModalOpen={setModalOpen} />}*/}
            {/*    centered*/}
            {/*    modalOpen={modalOpen}*/}
            {/*    setModalOpen={setModalOpen}*/}
            {/*    closable={false}*/}
            {/*    handleConfirm={handleConfirm}*/}
            {/*    isCancel*/}
            {/*    submit*/}
            {/*    hideFooter*/}
            {/*    type={2}*/}
            {/*/>*/}
        </PageBanner>
    )
}

export default Survey
