import { Tooltip, Button, ConfigProvider } from 'antd'
import { colorTheme } from 'assets/colorTheme'

const Chips = ({ icon, color = 'green', tooltip = '툴팁' }) => {
    const { gray } = colorTheme
    // const colorShades = colorTheme[color] || {}

    const buttonStyle = {
        padding: 6,
        minHeight: 36,
        minWidth: 36,
        backgroundColor: color,
        border: 'none',
        // backgroundColor: colorShades[500],
    }

    return (
        <ConfigProvider
            theme={{
                token: {},
                components: {
                    Button: {
                        textHoverBg: color,
                    },
                },
            }}
        >
            <Tooltip title={tooltip} color={gray['900']} key={color}>
                <Button style={buttonStyle} icon={icon} />
            </Tooltip>
        </ConfigProvider>
    )
}

export default Chips
