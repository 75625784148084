import CheckBox from 'components/AntD/CheckBox/type1'
import Title from 'components/Ui/Title'
import Label from 'components/Ui/Label'
import { Col, Form, Row } from 'antd'
import SelectUi from 'components/Ui/Select/SelectUi'

import style from 'assets/scss/detail.module.scss'

const TutorClassification = ({ tutor_sortation, isEditOrRegisterPage }) => {
    return (
        <div>
            <Title title="강사기본정보" />
            <div className={`${style.border_style} ${style.box_md}`}>
                <Row className={style.row_item}>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="청년 여부" required={isEditOrRegisterPage} />
                            </Col>
                            <Col span={14} className={style.padding_y_xs}>
                                {isEditOrRegisterPage ? (
                                    <Form.Item name="isYouth" initialValue={tutor_sortation?.isYouth}>
                                        <CheckBox
                                            name="isYouth"
                                            checked={tutor_sortation?.isYouth}
                                            width="48"
                                            height="48"
                                        />
                                    </Form.Item>
                                ) : (
                                    <CheckBox disabled checked={tutor_sortation?.isYouth} width="48" height="48" />
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="전문퇴직자 여부" required={isEditOrRegisterPage} />
                            </Col>
                            <Col span={14} className={style.padding_y_xs}>
                                {isEditOrRegisterPage ? (
                                    <Form.Item name="isRetiree" initialValue={tutor_sortation?.isRetiree}>
                                        <CheckBox
                                            name="isRetiree"
                                            checked={tutor_sortation?.isRetiree}
                                            width="48"
                                            height="48"
                                        />
                                    </Form.Item>
                                ) : (
                                    <CheckBox disabled checked={tutor_sortation?.isRetiree} width="48" height="48" />
                                )}
                            </Col>
                        </Row>
                    </Col>
                    {/*{isEditOrRegisterPage ? (*/}
                    {/*    <>*/}
                    {/*        <Col span={5}>*/}
                    {/*            <Label label="대상자분류" required />*/}
                    {/*        </Col>*/}
                    {/*        <Col span={19}>*/}
                    {/*            <Form.Item*/}
                    {/*                name="classification"*/}
                    {/*                noStyle*/}
                    {/*                rules={[*/}
                    {/*                    {*/}
                    {/*                        required: true,*/}
                    {/*                    },*/}
                    {/*                ]}*/}
                    {/*                initialValue={tutor_sortation?.classification}*/}
                    {/*            >*/}
                    {/*                <SelectUi*/}
                    {/*                    name="classification"*/}
                    {/*                    options={['경단여', '전문퇴직자', '청년', '해당없음']}*/}
                    {/*                    size="lg"*/}
                    {/*                    value={tutor_sortation?.classification}*/}
                    {/*                />*/}
                    {/*            </Form.Item>*/}
                    {/*        </Col>*/}
                    {/*    </>*/}
                    {/*) : (*/}
                    {/*    <>*/}
                    {/*        <Col align="middle" span={5}>*/}
                    {/*            <Label label="대상자분류" />*/}
                    {/*        </Col>*/}
                    {/*        <Col span={19}>*/}
                    {/*            <SelectUi*/}
                    {/*                disabled*/}
                    {/*                size="lg"*/}
                    {/*                options={['경단여', '전문퇴직자', '청년', '해당없음']}*/}
                    {/*                value={tutor_sortation?.classification}*/}
                    {/*            />*/}
                    {/*        </Col>*/}
                    {/*    </>*/}
                    {/*)}*/}
                </Row>
                <Row className={style.row_item}>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="경단여 여부" required={isEditOrRegisterPage} />
                            </Col>
                            <Col span={14} className={style.padding_y_xs}>
                                {isEditOrRegisterPage ? (
                                    <Form.Item
                                        name="isCareerGapFemale"
                                        initialValue={tutor_sortation?.isCareerGapFemale}
                                    >
                                        <CheckBox
                                            name="isCareerGapFemale"
                                            checked={tutor_sortation?.isCareerGapFemale}
                                            width="48"
                                            height="48"
                                        />
                                    </Form.Item>
                                ) : (
                                    <CheckBox
                                        disabled
                                        checked={tutor_sortation?.isCareerGapFemale}
                                        width="48"
                                        height="48"
                                    />
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="외국인 여부" required={isEditOrRegisterPage} />
                            </Col>
                            <Col span={14} className={style.padding_y_xs}>
                                {isEditOrRegisterPage ? (
                                    <Form.Item name="isForeign" initialValue={tutor_sortation?.isForeign}>
                                        <CheckBox
                                            name="isForeign"
                                            checked={tutor_sortation?.isForeign}
                                            width="48"
                                            height="48"
                                        />
                                    </Form.Item>
                                ) : (
                                    <CheckBox disabled checked={tutor_sortation?.isForeign} width="48" height="48" />
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className={style.row_item}>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="장애여부 " required={isEditOrRegisterPage} />
                            </Col>
                            <Col span={14} className={style.padding_y_xs}>
                                {isEditOrRegisterPage ? (
                                    <Form.Item name="hasDisability" initialValue={tutor_sortation?.hasDisability}>
                                        <CheckBox
                                            name="hasDisability"
                                            checked={tutor_sortation?.hasDisability}
                                            width="48"
                                            height="48"
                                        />
                                    </Form.Item>
                                ) : (
                                    <CheckBox
                                        disabled
                                        checked={tutor_sortation?.hasDisability}
                                        width="48"
                                        height="48"
                                    />
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="보훈 여부" />
                            </Col>
                            <Col span={14} className={style.padding_y_xs}>
                                {isEditOrRegisterPage ? (
                                    <Form.Item name="isVeterans" initialValue={tutor_sortation?.isVeterans}>
                                        <CheckBox
                                            name="isVeterans"
                                            checked={tutor_sortation?.isVeterans}
                                            width="48"
                                            height="48"
                                        />
                                    </Form.Item>
                                ) : (
                                    <CheckBox disabled checked={tutor_sortation?.isVeterans} width="48" height="48" />
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className={style.row_item}>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="신규강사 여부 " required={isEditOrRegisterPage} />
                            </Col>
                            <Col span={14} className={style.padding_y_xs}>
                                {isEditOrRegisterPage ? (
                                    <Form.Item name="isNew" initialValue={tutor_sortation?.isNew}>
                                        <CheckBox
                                            name="isNew"
                                            checked={tutor_sortation?.isNew}
                                            width="48"
                                            height="48"
                                        />
                                    </Form.Item>
                                ) : (
                                    <CheckBox disabled checked={tutor_sortation?.isNew} width="48" height="48" />
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="재고용 여부 " required={isEditOrRegisterPage} />
                            </Col>
                            <Col span={14} className={style.padding_y_xs}>
                                {isEditOrRegisterPage ? (
                                    <Form.Item name="isRehired" initialValue={tutor_sortation?.isRehired}>
                                        <CheckBox
                                            name="isRehired"
                                            checked={tutor_sortation?.isRehired}
                                            width="48"
                                            height="48"
                                        />
                                    </Form.Item>
                                ) : (
                                    <CheckBox disabled checked={tutor_sortation?.isRehired} width="48" height="48" />
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className={style.row_item}>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="고급강사 여부 " required={isEditOrRegisterPage} />
                            </Col>
                            <Col span={14}>
                                {isEditOrRegisterPage ? (
                                    <Form.Item
                                        name="isAdvancedInstructor"
                                        initialValue={tutor_sortation?.isAdvancedInstructor}
                                        noStyle
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <SelectUi
                                            name="isAdvancedInstructor"
                                            options={['일반강사', '고급강사']}
                                            size="sm"
                                            value={tutor_sortation?.isAdvancedInstructor}
                                        />
                                    </Form.Item>
                                ) : (
                                    <SelectUi
                                        disabled
                                        options={['일반강사', '고급강사']}
                                        size="sm"
                                        value={tutor_sortation?.isAdvancedInstructor}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        {/*<Row>*/}
                        {/*    <Col span={10}>*/}
                        {/*        <Label label="보훈 여부" />*/}
                        {/*    </Col>*/}
                        {/*    <Col span={14} className={style.padding_y_xs}>*/}
                        {/*        {isEditOrRegisterPage ? (*/}
                        {/*            <Form.Item name="isVeterans" initialValue={tutor_sortation?.isVeterans}>*/}
                        {/*                <CheckBox*/}
                        {/*                    name="isVeterans"*/}
                        {/*                    checked={tutor_sortation?.isVeterans}*/}
                        {/*                    width="48"*/}
                        {/*                    height="48"*/}
                        {/*                />*/}
                        {/*            </Form.Item>*/}
                        {/*        ) : (*/}
                        {/*            <CheckBox disabled checked={tutor_sortation?.isVeterans} width="48" height="48" />*/}
                        {/*        )}*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export default TutorClassification
