/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react'
import styles from './checkBox.module.scss'

const CheckBox = ({ width, height, id, name, disabled, onChange, checked, ...props }) => {
    const [checkedValue, setCheckedValue] = useState(checked || false)

    const handleInputChange = e => {
        const isYn = e.target.checked
        setCheckedValue(isYn)
        onChange(!!isYn)
    }

    return (
        <label className={styles['checkbox-label']} htmlFor={id}>
            <span className="checkbox-span">
                <input
                    disabled={disabled}
                    className={styles['checkbox-input']}
                    type="checkbox"
                    id={id}
                    name={name}
                    style={{ width: `${width}px` || 'auto', height: `${height}px` || 'auto' }}
                    defaultChecked={checkedValue}
                    onChange={handleInputChange}
                    {...props}
                />
            </span>
            {/* <span className="checkbox-name">{name}</span> */}
        </label>
    )
}

export default CheckBox
