import { createSlice } from '@reduxjs/toolkit'

const programSlice = createSlice({
    name: 'program',
    initialState: {
        program: {
            program_basic_info: {},
            demand_agency: {},
            program_operation_info: {},
            demand_representative: {},
            etc_info: {},
            attachment: [],
            instructor_matching: [],
        },
        programs: [],
        loading: 'idle',
        error: null,
    },

    reducers: {
        setProgramField: (state, action) => {
            const { payload } = action
            const { field, data } = payload
            state.program[field] = data
        },
        addProgram: (state, action) => {
            state.program = action.payload
        },
        resetProgram: state => {
            state.program = {}
        },
    },
})

const { setProgramField, addProgram, resetProgram } = programSlice.actions

export { programSlice, setProgramField, addProgram, resetProgram }
