import { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import dayjs from 'dayjs'

import PageBanner from 'layout/PageBanner'
import { BannerIconSurvey } from 'assets/Icons'

import Spinner from 'components/AntD/Spin'

import { Row, Col, Flex, Form } from 'antd'

import TextField from 'components/AntD/TextField'
import Label from 'components/Ui/Label'

import Title from 'components/Ui/Title'
import ColorButton from 'components/AntD/Button/ColorButton'

import { getSurveyIdByUuId } from 'api/survey/survey.api'
import { getSurveyDemanderById } from 'api/survey/surveyDemander.api'
import ListNotFound from 'pages/PageNotFound/ListNotFound'

import style from 'assets/scss/detail.module.scss'

/**
 * 수요처 응답 상세
 * @returns {JSX.Element}
 * @constructor
 */
const SurveyResponse = () => {
    const dateFormat = 'YYYY년 MM월 DD일'
    const { id } = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const startDate = searchParams.get('startDate')
    const endDate = searchParams.get('endDate')

    const [isLoading, setIsLoading] = useState(true)
    const [surveyInfo, setSurveyInfo] = useState(null)
    const [surveyId, setSurveyId] = useState(null)

    const { surveyUuid } = surveyInfo || {}
    const urlLink = `${process.env.REACT_APP_BASE_URL}/survey/submit/${surveyUuid}`

    const fetchSurveyDemanderById = async () => {
        const res = await getSurveyDemanderById(id)
        const surveyIdByUuid = await getSurveyIdByUuId(res.surveyUuid)

        setSurveyInfo(res)
        setIsLoading(false)
        setSurveyId(surveyIdByUuid)
    }

    // const onFinish = value => {
    //     console.log('success', value)

    //     const formData = {
    //         regionId: tutor.region_id,
    //         tutorInfo: value.tutorInfo,
    //         tutorSortation: value.tutor_sortation,
    //         qualifications: value.qualifications,
    //         contractInfo: value.contract_info,
    //         foundedInfo: value.founded_info,
    //         depositInfo: value.deposit_info,
    //         etcInfo: value.etc_info,
    //     }

    //     const updateTutor = async () => {
    //         const { data } = await editTutor(id, formData)
    //         message.success('수정되었습니다', data)
    //     }

    //     updateTutor()
    // }
    // const onFinishFailed = errorInfo => {
    //     message.error('필수값을 확인해주세요')
    //     console.log('Failed:', errorInfo)
    // }

    useEffect(() => {
        fetchSurveyDemanderById()
    }, [])

    return (
        <PageBanner title="수요조사 응답" bannerIcon={<BannerIconSurvey />}>
            {isLoading ? (
                <div>
                    <Spinner />
                </div>
            ) : !surveyInfo ? (
                <ListNotFound />
            ) : (
                <div className={`${style.area}`}>
                    <Title size="lg" title="수요조사 정보" />
                    <div className={`${style.border_style} ${style.box_lg}  ${style.mb_lg}`}>
                        <Row className={style.row_item}>
                            <Col span={5}>
                                <Label label="수요조사 제목" />
                            </Col>
                            <Col span={19} className={`${style.padding_y_xs} ${style.padding_right}`}>
                                <TextField disabled name="title" val={surveyInfo?.name} size="xl" />
                            </Col>
                        </Row>
                        <Row className={style.row_item}>
                            <Col span={12}>
                                <Row>
                                    <Col span={10}>
                                        <Label label="모집 시작일" />
                                    </Col>
                                    <Col span={14} className={`${style.padding_y_xs} ${style.padding_right}`}>
                                        <Form.Item name="startDate" initialValue={startDate}>
                                            <TextField disabled name="startDate" val={startDate} size="sm" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={10}>
                                        <Label label="모집 마감일" />
                                    </Col>
                                    <Col span={14} className={`${style.padding_y_xs} ${style.padding_right}`}>
                                        <Form.Item name="endDate" initialValue={endDate}>
                                            <TextField disabled name="endDate" val={endDate} size="sm" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className={style.row_item}>
                            <Col span={5}>
                                <Label label="URL 정보" />
                            </Col>
                            <Col span={16} className={style.col}>
                                <TextField disabled name="url" val={urlLink} size="100" />
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Title size="lg" title="수요조사 응답" />
                        <div className={`${style.border_style} ${style.box_lg}  ${style.mb_lg}`}>
                            <Row className={style.row_item}>
                                <Col span={12}>
                                    <Row>
                                        <Col span={10}>
                                            <Label label="신청자/단체명" />
                                        </Col>
                                        <Col span={14} className={`${style.padding_y_xs} ${style.padding_right}`}>
                                            <Form.Item>
                                                <TextField disabled val={surveyInfo.name} size="sm" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row>
                                        <Col span={10}>
                                            <Label label="기관유형" />
                                        </Col>
                                        <Col span={14} className={`${style.padding_y_xs} ${style.padding_right}`}>
                                            <Form.Item>
                                                <TextField disabled val={surveyInfo.organizationType} size="sm" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className={style.row_item}>
                                <Col span={12}>
                                    <Row>
                                        <Col span={10}>
                                            <Label label="전화번호" />
                                        </Col>
                                        <Col span={14} className={`${style.padding_y_xs} ${style.padding_right}`}>
                                            <Form.Item>
                                                <TextField disabled val={surveyInfo.phone} size="sm" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row>
                                        <Col span={10}>
                                            <Label label="통화가능여부" />
                                        </Col>
                                        <Col span={14} className={`${style.padding_y_xs} ${style.padding_right}`}>
                                            <Form.Item>
                                                <TextField
                                                    disabled
                                                    val={surveyInfo?.currencyAvailability ? '예' : '아니오'}
                                                    size="sm"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className={style.row_item}>
                                <Col span={12}>
                                    <Row>
                                        <Col span={10}>
                                            <Label label="이메일" />
                                        </Col>
                                        <Col span={14} className={`${style.padding_y_xs} ${style.padding_right}`}>
                                            <Form.Item>
                                                <TextField disabled val={surveyInfo.email} size="sm" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row>
                                        <Col span={10}>
                                            <Label label="주소" />
                                        </Col>
                                        <Col span={14} className={`${style.padding_y_xs} ${style.padding_right}`}>
                                            <Form.Item>
                                                <TextField disabled val={surveyInfo.address} size="sm" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className={style.row_item}>
                                <Col span={12}>
                                    <Row>
                                        <Col span={10}>
                                            <Label label="직책" />
                                        </Col>
                                        <Col span={14} className={`${style.padding_y_xs} ${style.padding_right}`}>
                                            <Form.Item>
                                                <TextField disabled val={surveyInfo.position} size="sm" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row>
                                        <Col span={10}>
                                            <Label label="신규여부" />
                                        </Col>
                                        <Col span={14} className={`${style.padding_y_xs} ${style.padding_right}`}>
                                            <Form.Item>
                                                <TextField
                                                    disabled
                                                    val={surveyInfo.isNew ? '신규' : '재등록'}
                                                    size="sm"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className={style.row_item}>
                                <Col span={12}>
                                    <Row>
                                        <Col span={10}>
                                            <Label label="교육대상" />
                                        </Col>
                                        <Col span={14} className={`${style.padding_y_xs} ${style.padding_right}`}>
                                            <Form.Item>
                                                <TextField disabled val={surveyInfo.educationTarget} size="sm" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row>
                                        <Col span={10}>
                                            <Label label="신청인원수" />
                                        </Col>
                                        <Col span={14} className={`${style.padding_y_xs} ${style.padding_right}`}>
                                            <Form.Item>
                                                <TextField disabled val={surveyInfo.numberOfApplicants} size="sm" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className={style.row_item}>
                                <Col span={12}>
                                    <Row>
                                        <Col span={10}>
                                            <Label label="전체학생수" />
                                        </Col>
                                        <Col span={14} className={`${style.padding_y_xs} ${style.padding_right}`}>
                                            <Form.Item>
                                                <TextField disabled val={surveyInfo.totalOfStudents} size="sm" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row>
                                        <Col span={10}>
                                            <Label label="프로그램명" />
                                        </Col>
                                        <Col span={14} className={`${style.padding_y_xs} ${style.padding_right}`}>
                                            <Form.Item>
                                                <TextField disabled val={surveyInfo.program} size="sm" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className={style.row_item}>
                                <Col span={12}>
                                    <Row>
                                        <Col span={10}>
                                            <Label label="방학시작일" />
                                        </Col>
                                        <Col span={14} className={`${style.padding_y_xs} ${style.padding_right}`}>
                                            <Form.Item>
                                                <TextField
                                                    disabled
                                                    val={
                                                        surveyInfo?.startDate
                                                            ? dayjs(surveyInfo?.startDate).format(dateFormat)
                                                            : null
                                                    }
                                                    size="sm"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row>
                                        <Col span={10}>
                                            <Label label="방학종료일" />
                                        </Col>
                                        <Col span={14} className={`${style.padding_y_xs} ${style.padding_right}`}>
                                            <Form.Item>
                                                <TextField
                                                    disabled
                                                    val={
                                                        surveyInfo?.endDate
                                                            ? dayjs(surveyInfo?.endDate).format(dateFormat)
                                                            : null
                                                    }
                                                    size="sm"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className={style.row_item}>
                                <Col span={12}>
                                    <Row>
                                        <Col span={10}>
                                            <Label label="교육시작일" />
                                        </Col>
                                        <Col span={14} className={`${style.padding_y_xs} ${style.padding_right}`}>
                                            <Form.Item>
                                                <TextField
                                                    disabled
                                                    val={
                                                        surveyInfo?.startDate
                                                            ? dayjs(surveyInfo?.startDate).format(dateFormat)
                                                            : null
                                                    }
                                                    size="sm"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row>
                                        <Col span={10}>
                                            <Label label="교육종료일" />
                                        </Col>
                                        <Col span={14} className={`${style.padding_y_xs} ${style.padding_right}`}>
                                            <Form.Item>
                                                <TextField
                                                    disabled
                                                    val={
                                                        surveyInfo?.endDate
                                                            ? dayjs(surveyInfo?.endDate).format(dateFormat)
                                                            : null
                                                    }
                                                    size="sm"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className={style.row_item}>
                                <Col span={12}>
                                    <Row>
                                        <Col span={10}>
                                            <Label label="총교시" />
                                        </Col>
                                        <Col span={14} className={`${style.padding_y_xs} ${style.padding_right}`}>
                                            <Form.Item>
                                                <TextField disabled val={surveyInfo.totalClassHours} size="sm" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row>
                                        <Col span={10}>
                                            <Label label="일별교시" />
                                        </Col>
                                        <Col span={14} className={`${style.padding_y_xs} ${style.padding_right}`}>
                                            <Form.Item>
                                                <TextField disabled val={surveyInfo.classHoursPerDay} size="sm" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className={style.row_item}>
                                <Col span={12}>
                                    <Row>
                                        <Col span={10}>
                                            <Label label="대면여부" />
                                        </Col>
                                        <Col span={14} className={`${style.padding_y_xs} ${style.padding_right}`}>
                                            <Form.Item>
                                                <TextField
                                                    disabled
                                                    val={surveyInfo.isMeet ? '대면' : '비대면'}
                                                    size="sm"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row>
                                        <Col span={10}>
                                            <Label label="인터넷 사용여부" />
                                        </Col>
                                        <Col span={14} className={`${style.padding_y_xs} ${style.padding_right}`}>
                                            <Form.Item>
                                                <TextField
                                                    disabled
                                                    val={surveyInfo.isInternet ? '가능' : '불가능'}
                                                    size="sm"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className={style.row_item}>
                                <Col span={12}>
                                    <Row>
                                        <Col span={10}>
                                            <Label label="도서벽지여부" />
                                        </Col>
                                        <Col span={14} className={`${style.padding_y_xs} ${style.padding_right}`}>
                                            <Form.Item>
                                                <TextField
                                                    disabled
                                                    val={surveyInfo.isIsland ? '예' : '아니오'}
                                                    size="sm"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row>
                                        <Col span={10}>
                                            <Label label="PC 보유여부" />
                                        </Col>
                                        <Col span={14} className={`${style.padding_y_xs} ${style.padding_right}`}>
                                            <Form.Item>
                                                <TextField
                                                    disabled
                                                    val={surveyInfo.isPcOwned ? '보유' : '미보유'}
                                                    size="sm"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className={style.row_item}>
                                <Col span={12}>
                                    <Row>
                                        <Col span={10}>
                                            <Label label="수혜기관여부" />
                                        </Col>
                                        <Col span={14} className={`${style.padding_y_xs} ${style.padding_right}`}>
                                            <Form.Item>
                                                <TextField
                                                    disabled
                                                    val={surveyInfo.isBenefit ? '예' : '아니오'}
                                                    size="sm"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row>
                                        <Col span={10}>
                                            <Label label="학생난이도" />
                                        </Col>
                                        <Col span={14} className={`${style.padding_y_xs} ${style.padding_right}`}>
                                            <Form.Item>
                                                <TextField disabled val={surveyInfo.level} size="sm" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className={style.row_item}>
                                <Col span={12}>
                                    <Row>
                                        <Col span={10}>
                                            <Label label="신청경로" />
                                        </Col>
                                        <Col span={14} className={`${style.padding_y_xs} ${style.padding_right}`}>
                                            <Form.Item>
                                                <TextField disabled val={surveyInfo.applicationRoute} size="sm" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div>
                        <Flex justify="center" align="center" gap={8}>
                            <ColorButton size="large" type="primary" onClick={() => navigate(`/survey/${surveyId}`)}>
                                확인
                            </ColorButton>
                            <ColorButton size="large" onClick={() => navigate(`/survey/${surveyId}`)}>
                                취소
                            </ColorButton>
                        </Flex>
                    </div>
                </div>
            )}
        </PageBanner>
    )
}

export default SurveyResponse
