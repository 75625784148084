import dayjs from 'dayjs'
import { buildSupabaseChainFilter } from 'utils/utilCommon'
import { supabase } from '../axios.instance'
import { getCommonPageInfo } from '../rpc/rpc.api'

const table = 'contact_info'
const today = dayjs().format('YYYY-MM-DD HH:mm:ss.SSSZ')

/**
 * 문의하기 목록
 * get
 * @param search
 * @param regionId
 * @param path
 * @returns {Promise<PostgrestFilterBuilder<*, *, GetResult<*, *, *, "*">[], *>|null>}
 */
export const getContactList = async (search, regionId) => {
    try {
        // 페이지 정보 response
        const pageSize = 10
        const paginationInfo = await getCommonPageInfo(search, table, regionId, pageSize)

        // query
        let { query } = await buildSupabaseChainFilter(table, search)
        query = query.eq('is_delete', false)
        query = query.order('contact_id', { ascending: false })
        query = query.select('*, contact_attachment(*)') // Include contact_attachment data

        const response = await query
        if (response.data) {
            const convertedData = response.data.map(item => {
                const result = {
                    contactId: item.contact_id,
                    contactTitle: item.contact_title,
                    contactStatus: item.contact_status,
                    isMaster: item.is_master,
                    createAt: item.created_at,
                    contactAnswer: item.contact_answer,
                    regionId: item.region_id,
                    contactCategory: item.contact_category,
                    contactEmail: item.contact_email,
                    contactAttachment: item.contact_attachment,
                }
                return result
            })
            // 배열 뒤집기
            // const reversedData = convertedData.reverse()
            response.data = {
                contactList: convertedData,
                page: paginationInfo,
            }
            return response
        }
        return response
    } catch (error) {
        console.error(error)
        return null
    }
}

/**
 * 문의하기 등록
 * @param contactId
 * @param createAt
 * @param contactTitle
 ** @param contactContents
 * @param contactStatus
 * @param contactAnswer
 * @returns {Promise<PostgrestResponseSuccess<GetResult<any, any, R | unknown, "*">[]>|PostgrestResponseFailure|null>}
 */
export const postContact = async ({ title, contents, status, regionId, category, email }) => {
    try {
        const response = await supabase
            .from('contact_info')
            .insert({
                contact_title: title,
                contact_contents: contents,
                contact_status: status,
                created_at: today,
                is_delete: false,
                region_id: regionId,
                contact_category: category,
                contact_email: email,
            })
            .select()
        return response
    } catch (error) {
        console.error(error)
        return { error }
    }
}
/**
 * 문의하기
 * 수정
 * @param contactData
 * @param contactId
 * @returns {Promise<*|PostgrestResponseSuccess<GetResult<any, any, R | unknown, "*">[]>|PostgrestResponseFailure>}
 */
export const putContact = async (contactData, contactId) => {
    try {
        const response = await supabase.from(table).update(contactData).eq('contact_id', contactId).select()
        return response
    } catch (error) {
        console.error(error)
        return error
    }
}
/**
 *
 * 문의하기 상세
 * @param id
 * @returns {Promise<*|PostgrestResponseSuccess<GetResult<any, any, R | unknown, "*">[]>|PostgrestResponseFailure>}
 */
export const handleGetContact = async id => {
    try {
        const query = supabase
            .from(table)
            .select('*,contact_attachment(*)', { count: 'exact' })
            .eq('is_delete', false)
            .eq('contact_id', id)
        return await query
    } catch (error) {
        console.error(error)
        return error
    }
}

/**
 * contact_info 테이블
 * 수퍼베이스 DB 유저 상태값 변경
 * is_delete = true (삭제된 유저)
 * @param contactId
 * @returns {Promise<PostgrestResponseSuccess<null>|PostgrestResponseFailure|null>}
 */
export const deleteContactById = async contactId => {
    try {
        const response = await supabase.from(table).update({ is_delete: true }).eq('contact_id', contactId).select()
        return response
    } catch (error) {
        console.error(error)
        return null
    }
}
