import { useState } from 'react'
import { Col, Row, Form, Flex } from 'antd'

import TextField from 'components/AntD/TextField'
import Label from 'components/Ui/Label'
import SelectUi from 'components/Ui/Select/SelectUi'
import Title from 'components/Ui/Title'
import Value from 'components/Ui/Value'

import styles from 'assets/scss/detail.module.scss'

const BasicInfo = ({ programInfo, editOrRegisterPage }) => {
    const [localProgramInfo] = useState(programInfo || {})

    return (
        <div>
            <Title title="교육 기본정보" />
            <div className={`${styles.box_md} ${styles.border_style}`}>
                <Row className={styles.row_item}>
                    <Col span={5}>
                        <Label label="제목" required={editOrRegisterPage} />
                    </Col>
                    {editOrRegisterPage ? (
                        <Col span={19} className={`${styles.padding_top} ${styles.padding_right}`}>
                            <Form.Item
                                initialValue={localProgramInfo?.programName}
                                name="programName"
                                noStyle
                                rules={[{ required: true }]}
                            >
                                <TextField
                                    name="programName"
                                    val={localProgramInfo?.programName}
                                    placeholder="교육명을 입력해주세요."
                                />
                            </Form.Item>
                        </Col>
                    ) : (
                        <Col span={19} className={styles.padding_y_medium}>
                            <Value value={programInfo?.programName} />
                        </Col>
                    )}
                </Row>
                <Row className={styles.row_item}>
                    <Col span={5}>
                        <Label label="상세" required={editOrRegisterPage} />
                    </Col>
                    {editOrRegisterPage ? (
                        <Col span={19} className={`${styles.padding_top} ${styles.padding_right}`}>
                            <Form.Item
                                initialValue={localProgramInfo?.detail}
                                name="detail"
                                noStyle
                                rules={[{ required: true }]}
                            >
                                <TextField
                                    name="detail"
                                    val={localProgramInfo?.detail}
                                    placeholder="상세정보를 입력해주세요."
                                />
                            </Form.Item>
                        </Col>
                    ) : (
                        <Col span={19} className={styles.padding_y_medium}>
                            <Value value={programInfo?.detail} />
                        </Col>
                    )}
                </Row>
                <Row className={styles.row_item}>
                    <Col span={5}>
                        <Label label="유형" required={editOrRegisterPage} />
                    </Col>
                    <Col span={19}>
                        {editOrRegisterPage ? (
                            <Row>
                                <Col span={8}>
                                    <Form.Item
                                        initialValue={localProgramInfo?.educationType}
                                        name="educationType"
                                        noStyle
                                        rules={[{ required: true }]}
                                    >
                                        <SelectUi
                                            name="educationType"
                                            size="sm"
                                            options={['센터교육', '방문교육', '기타']}
                                            value={localProgramInfo?.educationType}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        initialValue={localProgramInfo?.course}
                                        name="course"
                                        noStyle
                                        rules={[{ required: true }]}
                                    >
                                        <SelectUi
                                            name="course"
                                            size="sm"
                                            options={['정규과정', '비정규과정']}
                                            value={localProgramInfo?.course}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        initialValue={localProgramInfo?.method}
                                        name="method"
                                        noStyle
                                        rules={[{ required: true }]}
                                    >
                                        <SelectUi
                                            name="method"
                                            size="sm"
                                            options={['온라인', '오프라인']}
                                            value={localProgramInfo?.method}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        ) : (
                            <Flex justify="start" gap={24}>
                                <SelectUi
                                    size="sm"
                                    disabled
                                    options={['센터교육', '방문교육', '기타']}
                                    value={programInfo?.educationType}
                                />
                                <SelectUi
                                    size="sm"
                                    disabled
                                    options={['정규과정', '비정규과정']}
                                    value={programInfo?.course}
                                />
                                <SelectUi
                                    size="sm"
                                    disabled
                                    options={['온라인', '오프라인']}
                                    value={programInfo?.method}
                                />
                            </Flex>
                        )}
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export default BasicInfo
