/* eslint-disable react/jsx-props-no-spreading */
import { Select, ConfigProvider } from 'antd'
import { ArrowBtmIcon } from 'assets/Icons'

import styles from './select.module.scss'

const SelectBox = ({
    defaultValue = { value: 'all', label: '전체' },
    placeholder,
    options = [
        { value: 'Select 1', label: 'Select 1' },
        { value: 'Select 2', label: 'Select 2' },
        { value: 'Select 3', label: 'Select 3' },
    ],
    onChange,
    value,
    size,
    ...props
}) => {
    const getSize = () => {
        switch (size) {
            case 'sm':
                return styles.sm
            case 'md':
                return styles.md
            case 'lg':
                return styles.lg
            default:
                return ''
        }
    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    Select: {
                        optionActiveBg: '#FFFFFF',
                        optionSelectedBg: '#FBFBFB',
                        optionSelectedColor: '#C2C2C2',
                    },
                },
                token: {
                    controlHeight: 50,
                    borderRadius: 4,
                },
            }}
        >
            <Select
                className={`${styles.ant_select_selector} ${getSize(size)} `}
                defaultValue={defaultValue}
                placeholder={placeholder}
                options={options}
                onChange={onChange}
                value={value}
                suffixIcon={<ArrowBtmIcon />}
                {...props}
            />
        </ConfigProvider>
    )
}

export default SelectBox
