import { uploadS3Bucket } from './utilS3Bucket'

/**
 * CK-Editor5
 * 업로드 플러그인 연결 어댑터 함수
 * @param loader
 * @returns {{upload: (function(): Promise<*>)}}
 */
const uploadAdaptor = loader => {
    return {
        /**
         * 이미지 업로드 함수
         * @returns {Promise<unknown>}
         */
        upload: () => {
            return new Promise((resolve, reject) => {
                const body = new FormData()
                loader.file.then(async file => {
                    body.append('uploadImg', file)
                    // S3 이미지 업로드 함수
                    try {
                        await uploadS3Bucket(body, file, resolve, reject)
                    } catch (err) {
                        reject(err)
                    }
                })
            })
        },
        /**
         * @todo: 이미지 업로드 진행 중 중단 기능
         * @param e
         * @returns {Promise<never>}
         */
        abort(e) {
            console.log(e)
            return Promise.reject()
        },
    }
}

/**
 * CK-Editor5
 * 업로드 플러그인
 * @param editor
 */
export function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
        return uploadAdaptor(loader)
    }
}
