import React from 'react'
import { Flex, Button } from 'antd'
import { BannerIconContact } from 'assets/Icons'
import PageBanner from 'layout/PageBanner'
import ColorButton from 'components/AntD/Button/ColorButton'
import TextArea from 'antd/es/input/TextArea'

import { ReactComponent as AdminIconBk } from '../../../assets/Icons/svg/Icon/36/icon-admin-bk.svg'
import { DownloadIcon24 } from '../../../assets/Icons'
//import '../../../components/Contact/contact.scss'
import '../contact.scss'

const ContactWrite = () => {
    return (
        <PageBanner title="문의하기" bannerIcon={<BannerIconContact />}>
            <div className="contact_container">
                <div className="view_title">
                    {/* 기능문의 : badge + fill_green , 오류제보 : badge + fill_error */}
                    <span className="badge fill_error">기능 문의</span>
                    <div className="h6">교구재에 관해 문의 합니다.</div>
                    <span className="contact_label text_right">운영상태</span>
                    {/* 답변대기 : gray, 답변 완료 : blue */}
                    <strong className="contact_label gray">답변 대기</strong>
                </div>

                <div className="contact_card">
                    <div className="title_box">
                        <span className="contact_label">번호</span>
                        <span className="contact_label gray">230401</span>
                        <span className="contact_label">지역</span>
                        <span className="contact_label gray">경기</span>
                        <span className="contact_label">작성일</span>
                        <span className="contact_label gray">2023년 10월 23일</span>
                        <span className="contact_label">이메일</span>
                        <span className="contact_label gray">asdfdfsdfsdf@domfam.com</span>
                    </div>
                    <div className="attachment_box">
                        <span className="body3">프로그램 완료 보고서.hwp</span>
                        <Button icon={<DownloadIcon24 />} />
                    </div>
                    <div className="title_box">
                        <div className="title_box_inner">
                            <p className="body2">
                                교구재에 관해 문의합니다. 교구재에 관해 문의합니다. 교구재에 관해 문의합니다. 교구재에
                                관해 문의합니다. 교구재에 관해 문의합니다. 교구재에 관해 문의합니다. 교구재에 관해
                                문의합니다. 교구재에 관해 문의합니다. 교구재에 관해 문의합니다. 교구재에 관해
                                문의합니다. 교구재에 관해 문의합니다. 교구재에 관해 문의합니다. 교구재에 관해
                                문의합니다. 교구재에 관해 문의합니다. 교구재에 관해 문의합니다. 교구재에 관해
                                문의합니다. 교구재에 관해 문의합니다. 교구재에 관해 문의합니다. 교구재에 관해
                                문의합니다. 교구재에 관해 문의합니다. 교구재에 관해 문의합니다. 교구재에 관해
                                문의합니다. 교구재에 관해 문의합니다. 교구재에 관해 문의합니다.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="admin_write">
                    <AdminIconBk />
                    <TextArea
                        placeholder="답변 내용을 입력해주세요."
                        rows={20}
                        className="ant_textarea body2"
                        style={{ resize: 'none' }}
                    />
                </div>

                <Flex justify="end" gap={8}>
                    <ColorButton type="primary" size="large">
                        답변완료
                    </ColorButton>
                    <ColorButton size="large">취소</ColorButton>
                </Flex>
            </div>
        </PageBanner>
    )
}

export default ContactWrite
