import { useEffect, useRef } from 'react'
import dayjs from 'dayjs'
import { useLocation } from 'react-router-dom'

import { Col, Row, Form, DatePicker, Button, Divider, Input } from 'antd'

import styles from './report.module.scss'
import SanitizedHTML from '../sanitizeHTML'

const IssueEntry = ({ number, item, remove }) => {
    const { mainPoints, solution, occurrenceDate, actionTaken } = item
    const { TextArea } = Input
    const { pathname } = useLocation()
    const paths = pathname.split('/').filter(path => path !== '')
    const commonStyles = styles.padding_8

    let formattedDateString
    if (occurrenceDate) {
        formattedDateString = dayjs(occurrenceDate[number]).format('YYYY-MM-DD')
    }

    //textarea 길이설정
    const textAreaRefIssueOne = useRef(null)
    const textAreaRefIssueTwo = useRef(null)
    const textAreaRefIssueThree = useRef(null)

    const textAreaAdjust = element => {
        if (element.value === '') {
            //element.style.height = 'inherit' // 초기 높이로 설정
            element.style.height = '48px' // 초기 높이로 설정
            //element.style.height = '100%' // 초기 높이로 설정
        } else {
            element.style.height = '1px'
            element.style.height = `${10 + element.scrollHeight}px`
        }
    }

    const handleTextAreaChange = e => {
        textAreaAdjust(e.target)
    }

    // useEffect(() => {
    //     if (textAreaRef.current) {
    //         textAreaAdjust(textAreaRef.current.resizableTextArea.textArea)
    //     }
    // }, [details])
    useEffect(() => {
        const adjustTextAreaHeight = () => {
            if (textAreaRefIssueOne.current) {
                textAreaAdjust(textAreaRefIssueOne.current.resizableTextArea.textArea)
            }
            if (textAreaRefIssueTwo.current) {
                textAreaAdjust(textAreaRefIssueTwo.current.resizableTextArea.textArea)
            }
            if (textAreaRefIssueThree.current) {
                textAreaAdjust(textAreaRefIssueThree.current.resizableTextArea.textArea)
            }
        }
        adjustTextAreaHeight()
    })

    return (
        <Row key={item.key}>
            <Col span={1} className={styles.padding_2436}>
                <span className="body2">{number + 1}</span>
            </Col>
            <Col span={8} className={commonStyles}>
                {paths[1] === 'register' || paths[1] === 'edit' ? (
                    <Form.Item name={[item.name, 'mainPoints']} initialValue={mainPoints} noStyle>
                        <TextArea
                            placeholder="주요내용을 입력해주세요."
                            textArea
                            rows={2}
                            style={{ resize: 'none' }}
                            onChange={handleTextAreaChange}
                            onInput={handleTextAreaChange}
                            ref={textAreaRefIssueOne}
                        />
                    </Form.Item>
                ) : (
                    <SanitizedHTML className={`${styles.text}`} html={mainPoints[number]} />
                )}
            </Col>
            <Col span={5} className={commonStyles}>
                {paths[1] === 'register' || paths[1] === 'edit' ? (
                    <Form.Item name={[item.name, 'solution']} initialValue={solution} noStyle>
                        <TextArea
                            placeholder="해결방안을 입력해주세요."
                            rows={2}
                            style={{ resize: 'none' }}
                            className={styles.textarea_xmin}
                            onChange={handleTextAreaChange}
                            onInput={handleTextAreaChange}
                            ref={textAreaRefIssueTwo}
                        />
                    </Form.Item>
                ) : (
                    <SanitizedHTML className={`${styles.text}`} html={solution[number]} />
                )}
            </Col>
            <Col span={3} className={`${commonStyles} ${styles.center}`}>
                {paths[1] === 'register' || paths[1] === 'edit' ? (
                    <Form.Item name={[item.name, 'occurrenceDate']} initialValue={occurrenceDate} noStyle>
                        <DatePicker placeholder="날짜 선택" format="YYYY-MM-DD" className={styles.textarea_xmin} />
                    </Form.Item>
                ) : (
                    <span className={`${styles.text}`}>{formattedDateString}</span>
                )}
            </Col>
            <Col span={5} className={commonStyles}>
                {paths[1] === 'register' || paths[1] === 'edit' ? (
                    <Form.Item name={[item.name, 'actionTaken']} initialValue={actionTaken} noStyle>
                        <TextArea
                            placeholder="조치사항을 입력해주세요."
                            rows={2}
                            style={{ resize: 'none' }}
                            className={styles.textarea_xmin}
                            onChange={handleTextAreaChange}
                            onInput={handleTextAreaChange}
                            ref={textAreaRefIssueThree}
                        />
                    </Form.Item>
                ) : (
                    <SanitizedHTML className={`${styles.text}`} html={actionTaken[number]} />
                )}
            </Col>
            {(paths[1] === 'register' || paths[1] === 'edit') && (
                <Col span={1} className={`${styles.padding_12}`}>
                    <Button
                        className={`button-reset-type ${styles.delete} ${styles.no_shadow} ${styles.textarea_xmin}`}
                        onClick={() => {
                            remove(item.name)
                        }}
                        block
                    >
                        <span className={styles.delete_icon} />
                    </Button>
                </Col>
            )}
            <Divider style={{ margin: 0 }} />
        </Row>
    )
}

export default IssueEntry
