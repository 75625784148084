import { BannerIconContact } from 'assets/Icons'
import { useEffect, useState } from 'react'

import PageBanner from 'layout/PageBanner'
import { getContactList } from 'api/contact/contact.api'
import Pagenation from 'components/Pagenation'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ListView from 'components/List/ListView'
import Spinner from 'components/AntD/Spin'
import { getAllUserInfo } from 'store/Slices/user'
import ListNotFound from '../PageNotFound/ListNotFound'
// import '../../components/List/ListView/listView.module.scss'

import './contact.scss'

const Contact = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const queryParams = new URLSearchParams(window.location.search)
    const [isLoading, setLoading] = useState(true)
    const [contacts, setContacts] = useState([])
    const { page, contactList } = contacts || {}
    const { currentPage, totalPages, totalItems } = page || {}
    const { isLogin, userInfo } = useSelector(getAllUserInfo)

    /**
     * querystring update
     * @param paramName
     * @param paramValue
     * @param loading
     */
    const updateQueryStringAndNavigate = (paramName, paramValue, loading) => {
        const { pathname } = location
        const previousParams = queryParams.get(paramName)
        // 새로운 파라미터 추가 (단, 값이 비어있지 않을 때)
        if (paramValue) {
            if (previousParams === paramValue) {
                if (paramName !== 'sort' && paramName !== 'search') {
                    queryParams.delete(paramName)
                }
            } else {
                queryParams.set(paramName, paramValue)
            }
        }

        const newQueryString = queryParams.toString()
        const newLocation = newQueryString ? `${pathname}?${newQueryString}` : pathname

        if (loading) {
            loading(false)
        }
        navigate(newLocation)
    }

    const fetchContactList = async search => {
        try {
            setLoading(true)
            const response = await getContactList(search, userInfo.regionId)
            if (response.status === 200 || response.status === 206) {
                setContacts(response.data)
                setLoading(false)
                return true
            }
            setLoading(false)
            navigate('/')
            return false
        } catch (error) {
            console.error('계정  목록을 불러오는 동안 오류가 발생했습니다.', error)
            return null
        }
    }
    useEffect(() => {
        if (isLogin) {
            const { search } = location
            const searchPath = search.split('?')[1] || ''
            fetchContactList(searchPath)
        } else {
            navigate('/')
        }
    }, [location])

    return (
        <PageBanner title="문의하기" bannerIcon={<BannerIconContact />}>
            {isLoading ? (
                <Spinner />
            ) : contactList.length === 0 ? (
                <ListNotFound />
            ) : (
                <>
                    <ListView list={contactList.slice(0, 10)} setLoading={setLoading} fetchList={fetchContactList} />
                    <Pagenation
                        title="문의하기"
                        currentPage={currentPage}
                        totalPages={totalPages}
                        totalItems={totalItems}
                        navigateBtn={() => navigate('/contact/Write')}
                        updateFtn={updateQueryStringAndNavigate}
                    />
                </>
            )}
        </PageBanner>
    )
}

export default Contact
