import { useLocation, useNavigate, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import { CKEditor } from '@ckeditor/ckeditor5-react'
import CustomEditor from 'ckeditor5-custom-build/build/ckeditor'

import { uploadPlugin } from 'utils/ckeditorPlugin'
import { clearBucketFolder, handleRegistrationPost } from 'utils/utilS3Bucket'

import 'assets/scss/samples/Editor.scss'
import dayjs from 'dayjs'

import { Row, Col, Flex, Form, message } from 'antd'
import { BannerIconSurvey } from 'assets/Icons'

import { editSurveyById, getSurveyById } from 'api/survey/survey.api'

import { useSelector } from 'react-redux'
import { getAllUserInfo } from 'store/Slices/user'

import PageBanner from 'layout/PageBanner'

import ListNotFound from 'pages/PageNotFound/ListNotFound'

import TextField from 'components/AntD/TextField'
import Label from 'components/Ui/Label'
import ColorButton from 'components/AntD/Button/ColorButton'
import Spinner from 'components/AntD/Spin'

import style from 'assets/scss/detail.module.scss'

const s3URL = process.env.REACT_APP_S3_URL
const cloudfrontURL = process.env.REACT_APP_CLOUDFRONT_URL

const success = 'success'
const error = 'error'
const SurveyEdit = () => {
    const [form] = Form.useForm()

    const location = useLocation()
    const navigate = useNavigate()
    const { id } = useParams()

    const { userInfo } = useSelector(getAllUserInfo)
    const { userId } = userInfo

    const [isLoading, setLoading] = useState(true)
    const [surveyInfo, setSurveyDetail] = useState(null)
    const [content, setContent] = useState('') // 수요조사 내용

    const [messageApi, contextHolder] = message.useMessage()

    const { surveyName, startDate, endDate, surveyContent, surveyUuid } = surveyInfo || {}
    const urlLink = `${process.env.REACT_APP_BASE_URL}/survey/submit/${surveyUuid}`

    const alertMessage = status => {
        messageApi.open({
            type: status === success ? success : error,
            content: status === success ? '수요조사가 수정되었습니다.' : `수정 도중 에러가 발생하였습니다.`,
            duration: 5,
        })
    }

    const onFinish = value => {
        const modifiedData = {
            end_date: value.endDate,
            start_date: value.startDate,
            survey_name: value.surveyName,
        }

        const formData = {
            ...modifiedData,
            sys_mod_dtime: dayjs(),
            sys_modr_id: userId,
            survey_content: content,
            survey_id: id,
        }

        const updateSurvey = async () => {
            const response = await editSurveyById(formData)
            if (response.status === 204 || response.status === 200) {
                const { data } = response
                const surveyId = data[0]?.survey_id

                await handleRegistrationPost(surveyId, s3URL, cloudfrontURL, content, userId)
                //TODO: 204 status 분기처리 추가

                alertMessage(success)
                setTimeout(() => {
                    navigate(`/survey/${id}`)
                }, 1000) // 3000 밀리초 = 3초
            } else {
                alertMessage(error)
            }
        }

        updateSurvey()
    }
    const onFinishFailed = errorInfo => {
        message.error('필수값을 확인해주세요')
        console.log('Failed:', errorInfo)
    }

    const fetchSurveyDetail = async () => {
        setLoading(true)
        const survey = await getSurveyById(id)
        setSurveyDetail(survey)
        setLoading(false)
    }

    useEffect(() => {
        fetchSurveyDetail()
    }, [location])

    // component unmount 시(라우팅 변경, 뒤로 가기 등) 임시폴더 내 이미지 파일 모두 삭제
    useEffect(() => {
        return async () => {
            await clearBucketFolder(userId)
        }
    }, [])

    return (
        <>
            {contextHolder}
            <PageBanner title="수요조사 수정" bannerIcon={<BannerIconSurvey />}>
                {contextHolder}
                {isLoading ? (
                    <div className={style.spin_wrap}>
                        <Spinner />
                    </div>
                ) : surveyInfo === null || !surveyInfo || surveyInfo.length === 0 ? (
                    <ListNotFound />
                ) : (
                    <Form
                        name="survey"
                        form={form}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <div className={`${style.area}`}>
                            <div className={`${style.border_style} ${style.box_lg}  ${style.mb_lg}`}>
                                <Row className={style.row_item}>
                                    <Col span={5}>
                                        <Label label="수요조사 제목" />
                                    </Col>
                                    <Col span={19} className={`${style.padding_y_xs} ${style.padding_right}`}>
                                        <Form.Item name="surveyName" initialValue={surveyName}>
                                            <TextField
                                                disabled
                                                name="surveyName"
                                                className={style.ant_input}
                                                placeholder="수요조사 제목을 입력해주세요."
                                                val={surveyName}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className={style.row_item}>
                                    <Col span={12}>
                                        <Row>
                                            <Col span={10}>
                                                <Label label="모집 시작일" />
                                            </Col>
                                            <Col span={9} className={style.col}>
                                                <Form.Item name="startDate" initialValue={dayjs(startDate)}>
                                                    <TextField
                                                        disabled
                                                        name="startDate"
                                                        className={style.date_picker}
                                                        val={dayjs(startDate).format('YYYY-MM-DD')}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={12}>
                                        <Row>
                                            <Col span={10}>
                                                <Label label="모집 마감일" />
                                            </Col>
                                            <Col span={9} className={style.col}>
                                                <Form.Item name="endDate" initialValue={dayjs(endDate)}>
                                                    <TextField
                                                        disabled
                                                        name="endDate"
                                                        className={style.date_picker}
                                                        val={dayjs(endDate).format('YYYY-MM-DD')}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className={style.row_item}>
                                    <Col span={5}>
                                        <Label label="URL 정보" />
                                    </Col>
                                    <Col span={14} className={`${style.padding_y_xs} ${style.padding_right}`}>
                                        <TextField name="url" val={urlLink} size="100" disabled />
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <span className={style.title_sm}>수요조사 공고</span>
                                <Form.Item>
                                    <CKEditor
                                        editor={CustomEditor}
                                        data={surveyContent}
                                        config={{
                                            extraPlugins: [uploadPlugin], // 플러그인 추가
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData()
                                            setContent(data)
                                        }}
                                        placeholder="예시"
                                        onUpload
                                    />
                                </Form.Item>
                                <Flex justify="center" align="center" gap={8}>
                                    <ColorButton size="large" type="primary" htmlType="submit">
                                        확인
                                    </ColorButton>
                                    <ColorButton size="large" onClick={() => navigate('/survey/1')}>
                                        취소
                                    </ColorButton>
                                </Flex>
                            </div>
                        </div>
                    </Form>
                )}
            </PageBanner>
        </>
    )
}

export default SurveyEdit
