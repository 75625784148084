import { useEffect, useState } from 'react'

import { BannerIconReport } from 'assets/Icons'
import PageBanner from 'layout/PageBanner'

import { useLocation, useNavigate } from 'react-router-dom'
import { getReportList } from 'api/report/report.api'

import { useSelector } from 'react-redux'
import { getAllUserInfo } from 'store/Slices/user'

import FilterLayout from 'components/Filter/FilterLayout'
import ListView from 'components/List/ListView'
import Pagenation from 'components/Pagenation'
import Spinner from 'components/AntD/Spin'

import ListNotFound from '../PageNotFound/ListNotFound'

const Report = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const queryParams = new URLSearchParams(window.location.search)
    const { isLogin, userInfo } = useSelector(getAllUserInfo)

    const [isLoading, setLoading] = useState(true)
    const [reports, setReports] = useState([])

    const { page, reportList } = reports || {}
    const { currentPage, totalPages, totalItems } = page || {}

    /**
     * querystring update
     * @param paramName
     * @param paramValue
     * @param loading
     */
    const updateQueryStringAndNavigate = (paramName, paramValue, loading) => {
        const { pathname } = location
        const previousParams = queryParams.get(paramName)

        // 새로운 파라미터 추가 (단, 값이 비어있지 않을 때)
        if (paramValue) {
            if (previousParams === paramValue) {
                if (paramName !== 'sort' && paramName !== 'search') {
                    queryParams.delete(paramName)
                }
            } else {
                queryParams.set(paramName, paramValue)
            }
        }

        // 필터 선택 시 쿼리스트링에 포함된 페이지 정보 제거
        if (paramName !== 'page') {
            queryParams.delete('page')
        }

        const newQueryString = queryParams.toString()
        const newLocation = newQueryString ? `${pathname}?${newQueryString}` : pathname

        if (loading) {
            loading(false)
        }
        navigate(newLocation)
    }

    const fetchReportList = async search => {
        try {
            const response = await getReportList(search, userInfo.regionId)
            if (response.status === 200 || response.status === 206) {
                setReports(response.data)
                setLoading(false)
                return true
            }
            setLoading(false)
            navigate('/')
            return false
        } catch (error) {
            console.error('계정  목록을 불러오는 동안 오류가 발생했습니다.', error)
            return null
        }
    }

    useEffect(() => {
        if (isLogin && !userInfo.isServiceManager) {
            const { search } = location
            const searchPath = search.split('?')[1] || ''

            fetchReportList(searchPath)
        } else {
            navigate('/')
        }
    }, [location])

    return (
        <PageBanner title="월간보고" bannerIcon={<BannerIconReport />}>
            {isLoading ? (
                <Spinner />
            ) : (
                <>
                    {userInfo.regionId === 'all' && (
                        <FilterLayout updateQueryStringAndNavigate={updateQueryStringAndNavigate} />
                    )}
                    {reportList.length === 0 ? (
                        <ListNotFound />
                    ) : (
                        <>
                            <ListView
                                list={reportList.slice(0, 10)}
                                setLoading={setLoading}
                                fetchList={fetchReportList}
                            />
                            <Pagenation
                                title="월간보고 등록"
                                currentPage={currentPage}
                                totalPages={totalPages}
                                totalItems={totalItems}
                                navigateBtn={() => navigate('/report/register')}
                                updateFtn={updateQueryStringAndNavigate}
                            />
                        </>
                    )}
                </>
            )}
            {/*<InfoDialog*/}
            {/*    content={<Account setModalOpen={setModalOpen} />}*/}
            {/*    centered*/}
            {/*    modalOpen={modalOpen}*/}
            {/*    setModalOpen={setModalOpen}*/}
            {/*    closable={false}*/}
            {/*    handleConfirm={handleConfirm}*/}
            {/*    isCancel*/}
            {/*    submit*/}
            {/*    hideFooter*/}
            {/*    type={2}*/}
            {/*/>*/}
        </PageBanner>
    )
}

export default Report
