import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import store from 'store'
import { PersistGate } from 'redux-persist/integration/react'
import { App as AntdApp } from 'antd'

import ScrollToTop from 'components/Scroll/ScrollToTop'

import App from './App'

// 스토어 사용 시 로컬스토리지/세션스토리지 저장 가능
export const persistor = persistStore(store)

function renderApp() {
    const root = ReactDOM.createRoot(document.getElementById('root'))
    root.render(
        <BrowserRouter>
            <AntdApp>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <ScrollToTop />
                        <App />
                    </PersistGate>
                </Provider>
            </AntdApp>
        </BrowserRouter>,
    )
}

// async function startApp() {
//     // 개발모드에서만 MSW  시작
//     // if (process.env.NODE_ENV === 'development') {
//
//     // MSW 항상 시작
//     const { worker } = await import('./mocks/browser')
//     await worker.start()
//     // }
//
//     // MSW 시작 후 렌더링
//     renderApp()
// }
//
// startApp()

renderApp()
