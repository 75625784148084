// import { convertReportSnakeToCamelCase } from 'utils/utilCommon'
import { supabase } from '../axios.instance'

const table = 'report_issue'

/**
 * 월간보고 상세에 해당하는 특이사항 확인
 * @param reportId
 * @returns {Promise<null|PostgrestResponseSuccess<GetResult<any, *, R | unknown, "*">[]>|PostgrestResponseFailure>}
 */
export const getReportIssueById = async reportId => {
    try {
        let query = supabase.from(table).select('report_issue_id')
        // 삭제 여부 추가 (필수)
        query = query.eq('is_delete', false)
        query = query.eq('report_id', reportId)
        const response = await query

        return response
    } catch (error) {
        console.error(error)
        return null
    }
}

/**
 * 월간보고 특이사항 배열 등록
 * @param reportData
 * @returns {Promise<*>}
 */
export const postReportIssue = async issueData => {
    try {
        const response = await supabase.from(table).upsert(issueData).select()
        return response
    } catch (error) {
        console.error(error)
        return error
    }
}

/**
 * 월간보고 삭제 시
 * 특이사항 배열 함꼐 삭제
 * @param reportId
 * @returns {Promise<PostgrestResponseSuccess<GetResult<any, any, R | unknown, "*">[]>|PostgrestResponseFailure|null>}
 */
export const deleteReportIssueById = async reportId => {
    try {
        const response = await supabase.from(table).delete().eq('report_id', reportId)
        return response
    } catch (error) {
        console.error(error)
        return null
    }
}
