import { supabase } from '../axios.instance'

const table = 'report_total'
const yearOfReport = 'year_of_report'

/**
 * 총사업비 추가
 * @param reportData
 * @returns {Promise<axios.AxiosResponse<any>|*>}
 */
export const postReportTotal = async reportTotalData => {
    try {
        const response = await supabase.from(table).upsert(reportTotalData).select()
        return response
    } catch (error) {
        console.error(error)
        return error
    }
}

/**
 * 당해연도 해당 지역 총사업비
 * get
 * @param reportData
 * @returns {Promise<axios.AxiosResponse<any>|*>}
 */
export const getReportTotal = async ({ regionId, year }) => {
    try {
        let query = supabase.from(table).select()
        if (regionId && regionId !== 'all') {
            query = query.eq('region_id', regionId)
        }
        query = query.eq(yearOfReport, year)
        const response = await query
        return response
    } catch (error) {
        console.error(error)
        return error
    }
}

/**
 * 해당 지역 당해 총사업비 id값
 * get
 * @returns {Promise<axios.AxiosResponse<any>|*>}
 * @param regionId
 * @param creatYear
 */
export const getReportTotalById = async (regionId, creatYear) => {
    try {
        const yearTable = 'year_of_report'
        // const { startDate, endDate } = createYearRange()

        let query = supabase.from(table).select('report_total_id')
        if (regionId && regionId !== 'all') {
            query = query.eq('region_id', regionId)
        }
        // query = query.gte(yearTable, startDate).lte(yearTable, endDate)
        query = query.eq(yearTable, creatYear)

        const response = await query
        return response
    } catch (error) {
        console.error(error)
        return error
    }
}
