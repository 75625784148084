import FilterButton from 'components/AntD/Button/FilterButton'
import { CheckFilterIcon } from 'assets/Icons'
import styles from '../filter.module.scss'

//TODO: 동적으로 연도 가져오도록 변경 필요
const years = ['2024', '2023', '2022', '2021', '2020', '2019'];

const StatisticsFilter = ({ queryParams, updateQueryStringAndNavigate }) => {
    const dataOfyear = 'year'
    const currentDataOfyear = queryParams.get(dataOfyear) || ''
    return (
        <div className={styles.item_wrap}>
            <span className={`body2 ${styles.title}`}>년도선택</span>
            <div className={styles.btn_wrap}>
                {years.map(year => (
                    <FilterButton
                        key={year}
                        icon={<CheckFilterIcon />}
                        title={year}
                        currentParamValue={currentDataOfyear}
                        onClick={() => updateQueryStringAndNavigate(dataOfyear, year)}
                        filterColor="black"
                    />
                ))}
            </div>
        </div>
    )
}

export default StatisticsFilter
