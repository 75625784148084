import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getAccountList, postAccount } from 'api/accounts/accouint.api'
import dayjs from 'dayjs'

/**
 * 계정 목록
 * 현재 사용안함
 * @type {AsyncThunk<unknown, AsyncThunk<?, void, AsyncThunkConfig>, AsyncThunkConfig>}
 */
export const accountsAsync = createAsyncThunk('account/get', async (params, { rejectWithValue }) => {
    try {
        const { data } = await getAccountList()
        return data.result
    } catch (error) {
        return rejectWithValue(error)
    }
})

/**
 * 계정 생성
 * 현재 사용안함
 * @type {AsyncThunk<Promise<void>, void, AsyncThunkConfig>}
 */
export const postAccountAsync = createAsyncThunk('account/post', async values => {
    const { region, id, password } = values
    await postAccount({ region, id, password })
    return values
})

const initialState = {
    isFetching: false,
    accountList: {
        page: {},
        userList: [],
    },
    error: null,
}

// 현재 사용안함
const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(accountsAsync.pending, state => {
                state.isFetching = true
            })
            .addCase(accountsAsync.fulfilled, (state, { payload }) => {
                state.isFetching = false
                state.accountList = payload
            })
            .addCase(accountsAsync.rejected, (state, { error }) => {
                state.isFetching = false
                state.error = error.message
            })
            .addCase(postAccountAsync.pending, state => {
                state.isFetching = true
            })
            .addCase(postAccountAsync.fulfilled, (state, { payload }) => {
                state.isFetching = false
                const { region, id, password } = payload
                const today = dayjs().format('YYYY-MM-DD')

                const dataMaps = {
                    id: 112,
                    region_id: region,
                    account: id,
                    creator: '관리자',
                    createdDate: today,
                    password,
                }

                state.accountList.unshift(dataMaps)
            })
            .addCase(postAccountAsync.rejected, (state, { error }) => {
                state.isFetching = false
                state.error = error.message
            })
    },
})

export const getAccounts = state => state.account.accountList
export const getIsFetching = state => state.account.isFetching
export const getError = state => state.account.error
export default accountSlice
