import PageBanner from 'layout/PageBanner'
import { BannerIconTools, ColorDocIcon, ColorLocationIcon } from 'assets/Icons'
import { Row, Col, Space, Flex, Form } from 'antd'
import styles from 'assets/scss/detail.module.scss'
import Title from 'components/Ui/Title'
import Label from 'components/Ui/Label'
// import Value from 'components/Ui/Value'
import { useState } from 'react'
import TextField from 'components/AntD/TextField'

import SelectUi from 'components/Ui/Select/SelectUi'
import IconCard from 'components/AntD/Card/IconCard'
import ColorButton from 'components/AntD/Button/ColorButton'
import DatePickerInput from 'components/AntD/DatePicker'
import { useNavigate } from 'react-router-dom'

// import Spinner from 'components/AntD/Spin'

const ToolsRentEdit = () => {
    // const [loading, setLoading] = useState(true)
    const [toolsState] = useState({
        name: '교구',
    })
    const navigate = useNavigate()

    return (
        <PageBanner title="교구재 대여정보 수정" bannerIcon={<BannerIconTools />}>
            <div className={styles.detail_padding}>
                {
                    toolsState && (
                        <>
                            <Row className={styles.mb_lg}>
                                <Col span={16}>
                                    <Space direction="vertical" size={60}>
                                        <div>
                                            <Title title="대여자 정보" />
                                            <div className={`${styles.box_md} ${styles.border_style}`}>
                                                <Row className={styles.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="성명 (대여자)" />
                                                            </Col>
                                                            <Col
                                                                span={14}
                                                                className={`${styles.padding_y_xs} ${styles.padding_right}`}
                                                            >
                                                                <Form.Item
                                                                    name="account_holder_nm"
                                                                    initialValue={toolsState.account_holder_nm}
                                                                >
                                                                    <TextField
                                                                        name="account_holder_nm"
                                                                        // val={toolsState.account_holder_nm}
                                                                        val="김대여"
                                                                        size="sm"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="공동사용자" />
                                                            </Col>
                                                            <Col
                                                                span={14}
                                                                className={`${styles.padding_y_xs} ${styles.padding_right}`}
                                                            >
                                                                <Form.Item
                                                                    name="bank_nm"
                                                                    initialValue={toolsState.bank_nm}
                                                                >
                                                                    <TextField
                                                                        name="bank_nm"
                                                                        // val={toolsState.bank_nm}
                                                                        val="김공동"
                                                                        size="sm"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="연락처" />
                                                    </Col>
                                                    <Col
                                                        span={19}
                                                        className={`${styles.padding_y_xs} ${styles.padding_right}`}
                                                    >
                                                        {/* <Value value="코딩교육" /> */}
                                                        <TextField
                                                            name="bank_nm"
                                                            // val={toolsState.bank_nm}
                                                            val="010-0000-0000"
                                                            size="sm"
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div>
                                            <Title title="대여 및 사용정보" />
                                            <div className={`${styles.box_md} ${styles.border_style}`}>
                                                <Row className={styles.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="수량" />
                                                            </Col>
                                                            <Col
                                                                span={14}
                                                                className={`${styles.padding_y_xs} ${styles.padding_right}`}
                                                            >
                                                                <Form.Item
                                                                    name="account_holder_nm"
                                                                    initialValue={toolsState.account_holder_nm}
                                                                >
                                                                    <TextField
                                                                        name="account_holder_nm"
                                                                        // val={toolsState.account_holder_nm}
                                                                        val="4개"
                                                                        size="sm"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="사용기관" />
                                                            </Col>
                                                            <Col
                                                                span={14}
                                                                className={`${styles.padding_y_xs} ${styles.padding_right}`}
                                                            >
                                                                <Form.Item
                                                                    name="bank_nm"
                                                                    initialValue={toolsState.bank_nm}
                                                                >
                                                                    <TextField
                                                                        name="bank_nm"
                                                                        // val={toolsState.bank_nm}
                                                                        val="기관"
                                                                        size="sm"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="수리내역 결과" />
                                                    </Col>
                                                    <Col span={19}>
                                                        <SelectUi
                                                            size="xl"
                                                            value="반납완료"
                                                            options={[
                                                                '반납완료',
                                                                '신청취소',
                                                                '대기중',
                                                                '대여확정',
                                                                '대여불가능',
                                                            ]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="대여 신청일자" />
                                                            </Col>
                                                            <Col span={14}>
                                                                <Form.Item
                                                                    // initialValue={localOperationInfo?.start_date}
                                                                    // name="start_date"
                                                                    rules={[{ required: true, message: '' }]}
                                                                >
                                                                    <DatePickerInput
                                                                        // name="startDate"
                                                                        value="2020-01-01"
                                                                        // defaultValue={localOperationInfo.start_date}
                                                                        // onChange={handleStateChange('start_date')}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="대여 일자" />
                                                            </Col>
                                                            <Col span={14}>
                                                                <Form.Item
                                                                    // initialValue={localOperationInfo?.end_date}
                                                                    // name="end_date"
                                                                    rules={[{ required: true, message: '' }]}
                                                                >
                                                                    <DatePickerInput
                                                                        value="2020-01-01"
                                                                        // name="endDate"
                                                                        // defaultValue={localOperationInfo.end_date}
                                                                        // onChange={handleStateChange('end_date')}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="반납일자" />
                                                            </Col>
                                                            <Col span={14}>
                                                                <Form.Item
                                                                    // initialValue={localOperationInfo?.start_date}
                                                                    // name="start_date"
                                                                    rules={[{ required: true, message: '' }]}
                                                                >
                                                                    <DatePickerInput
                                                                        value="2020-01-01"
                                                                        // defaultValue={localOperationInfo.start_date}
                                                                        // onChange={handleStateChange('start_date')}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="연락처" />
                                                    </Col>
                                                    <Col
                                                        span={19}
                                                        className={`${styles.padding_y_xs} ${styles.padding_right}`}
                                                    >
                                                        {/* <Value value="코딩교육" /> */}
                                                        <TextField
                                                            name="bank_nm"
                                                            // val={toolsState.bank_nm}
                                                            val="010-0000-0000"
                                                            size="xl"
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div>
                                            <Title title="교구 정보" />
                                            <div className={`${styles.box_md} ${styles.border_style}`}>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="품목 (교구명)" />
                                                    </Col>
                                                    <Col
                                                        span={19}
                                                        className={`${styles.padding_y_xs} ${styles.padding_right}`}
                                                    >
                                                        {/* <Value value="코딩교육" /> */}
                                                        <TextField
                                                            name="bank_nm"
                                                            // val={toolsState.bank_nm}
                                                            val="010-0000-0000"
                                                            size="sm"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="성명 (대여자)" />
                                                            </Col>
                                                            <Col
                                                                span={14}
                                                                className={`${styles.padding_y_xs} ${styles.padding_right}`}
                                                            >
                                                                <Form.Item
                                                                    name="account_holder_nm"
                                                                    initialValue={toolsState.account_holder_nm}
                                                                >
                                                                    <TextField
                                                                        name="account_holder_nm"
                                                                        // val={toolsState.account_holder_nm}
                                                                        val="김대여"
                                                                        size="sm"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="공동사용자" />
                                                            </Col>
                                                            <Col
                                                                span={14}
                                                                className={`${styles.padding_y_xs} ${styles.padding_right}`}
                                                            >
                                                                <Form.Item
                                                                    name="bank_nm"
                                                                    initialValue={toolsState.bank_nm}
                                                                >
                                                                    <TextField
                                                                        name="bank_nm"
                                                                        // val={toolsState.bank_nm}
                                                                        val="김공동"
                                                                        size="sm"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div>
                                            <Title title="비고" />
                                            <div className={`${styles.border_style} ${styles.box_md}`}>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="구분" />
                                                    </Col>
                                                    <Col span={19}>
                                                        {/* <Form.Item
                                                            // initialValue={localOperationInfo?.operational_status}
                                                            name="operational_status"
                                                            rules={[{ required: true, message: '' }]}
                                                        > */}
                                                        <SelectUi
                                                            // onChange={handleStateChange('operational_status')}
                                                            value="지역아동센터"
                                                            options={['지역아동센터', '섬마을']}
                                                            // value={localOperationInfo?.operational_status}
                                                        />
                                                        {/* </Form.Item> */}
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="권역" />
                                                    </Col>
                                                    <Col span={19} className={`${styles.padding_y_xs}`}>
                                                        <Form.Item
                                                            name="account_num"
                                                            initialValue={toolsState.account_num}
                                                        >
                                                            <TextField
                                                                name="account_num"
                                                                // val={toolsState.account_num}
                                                                val="1권역"
                                                                size="xl"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="규격" />
                                                    </Col>
                                                    <Col span={19} className={`${styles.padding_y_xs}`}>
                                                        <Form.Item
                                                            name="account_num"
                                                            initialValue={toolsState.account_num}
                                                        >
                                                            <TextField
                                                                name="account_num"
                                                                // val={toolsState.account_num}
                                                                val="532X532X788mm , 240W , 60W , Window 10 Pro 형태"
                                                                size="xl"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div>
                                            <Title title="선택 항목" />
                                            <div className={`${styles.box_md} ${styles.border_style}`}>
                                                <Row className={styles.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="위치코드" />
                                                            </Col>
                                                            <Col
                                                                span={14}
                                                                className={`${styles.padding_y_xs} ${styles.padding_right}`}
                                                            >
                                                                <Form.Item
                                                                    name="account_holder_nm"
                                                                    initialValue={toolsState.account_holder_nm}
                                                                >
                                                                    <TextField
                                                                        name="account_holder_nm"
                                                                        // val={toolsState.account_holder_nm}
                                                                        val=""
                                                                        size="sm"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="관리번호" />
                                                            </Col>
                                                            <Col
                                                                span={14}
                                                                className={`${styles.padding_y_xs} ${styles.padding_right}`}
                                                            >
                                                                <Form.Item
                                                                    name="bank_nm"
                                                                    initialValue={toolsState.bank_nm}
                                                                >
                                                                    <TextField
                                                                        name="bank_nm"
                                                                        // val={toolsState.bank_nm}
                                                                        val=""
                                                                        size="sm"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>

                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="입고순번" />
                                                    </Col>
                                                    <Col
                                                        span={19}
                                                        className={`${styles.padding_y_xs} ${styles.padding_right}`}
                                                    >
                                                        {/* <Value value="코딩교육" /> */}
                                                        <TextField
                                                            name="bank_nm"
                                                            // val={toolsState.bank_nm}
                                                            val="50번"
                                                            size="xl"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="지역구분" />
                                                    </Col>
                                                    <Col span={19} className={`${styles.padding_y_xs}`}>
                                                        <Form.Item
                                                        // name="account_num"
                                                        // initialValue={toolsState.account_num}
                                                        >
                                                            <TextField
                                                                name="account_num"
                                                                // val={toolsState.account_num}
                                                                val="경남"
                                                                size="xl"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="건물구분" />
                                                            </Col>
                                                            <Col
                                                                span={14}
                                                                className={`${styles.padding_y_xs} ${styles.padding_right}`}
                                                            >
                                                                <Form.Item
                                                                // name="bank_nm"
                                                                // initialValue={toolsState.bank_nm}
                                                                >
                                                                    <TextField
                                                                        name="bank_nm"
                                                                        // val={toolsState.bank_nm}
                                                                        val="B동"
                                                                        size="sm"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="이동 일자" />
                                                            </Col>
                                                            <Col span={14}>
                                                                <Form.Item
                                                                    // initialValue={localOperationInfo?.end_date}
                                                                    // name="end_date"
                                                                    rules={[{ required: true, message: '' }]}
                                                                >
                                                                    <DatePickerInput
                                                                        // name="endDate"
                                                                        value="2020-01-01"
                                                                        // defaultValue={localOperationInfo.end_date}
                                                                        // onChange={handleStateChange('end_date')}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Space>
                                </Col>
                                <Col span={8} className={styles.padding_left_large}>
                                    <Space direction="horizontal" className={styles.mt}>
                                        <IconCard icon={<ColorDocIcon />} label="자산번호" val="UN001" />
                                        <IconCard icon={<ColorLocationIcon />} label="재고위치" val="경남" />
                                    </Space>
                                </Col>
                            </Row>
                            <Flex justify="center" align="center" gap={8}>
                                <ColorButton size="large" type="primary">
                                    저장
                                </ColorButton>
                                <ColorButton
                                    size="large"
                                    onClick={() => {
                                        navigate('/tools/rental/1')
                                    }}
                                >
                                    취소
                                </ColorButton>
                            </Flex>
                        </>
                    )
                    // : (
                    //     <div className={styles.spin_wrap}>
                    //         <Spinner />
                    //     </div>
                    // )
                }
            </div>
        </PageBanner>
    )
}

export default ToolsRentEdit
