import { Modal } from 'antd'
import ColorButton from 'components/AntD/Button/ColorButton'
import AttendanceUI from 'components/Program/Attendance'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setProgramField } from 'store/Slices/programs'

const AttendanceDialog = ({ title = 'Dialog', modalOpen, setModalOpen, attendanceInfo, closable = false }) => {
    const [data, setData] = useState([])
    const [isDataChanged, setIsDataChanged] = useState(false)
    const dispatch = useDispatch()
    const program = useSelector(state => state.program.program)

    const handleCancel = e => {
        e.preventDefault()
        setModalOpen(false)
    }

    const handleChange = value => {
        setData(value)
        setIsDataChanged(true)
    }

    const calculatePercentage = obj => {
        const trueStepCount = Object.values(obj).filter(value => value === false).length
        const percentage = (trueStepCount / program.programOperationInfo.classHours) * 100
        return percentage
    }

    const handleConfirm = () => {
        if (!isDataChanged) {
            console.log('Data has not changed. Save button is disabled.')
            return
        }

        const percentages = data.map(calculatePercentage)

        const countAbove80 = percentages.filter(percentage => percentage >= 80).length

        const updatedProgramOperationInfo = {
            ...program.programOperationInfo,
            numberOfGraduates: countAbove80,
        }

        dispatch(setProgramField({ field: 'programOperationInfo', data: updatedProgramOperationInfo }))
        setModalOpen(false)
    }

    useEffect(() => {
        setData(attendanceInfo)
        setIsDataChanged(false)
    }, [attendanceInfo])

    return (
        <Modal
            title={title}
            visible={modalOpen}
            onOk={() => setModalOpen(false)}
            onCancel={() => setModalOpen(false)}
            closable={closable}
            width={1200}
            footer={[
                <ColorButton type="primary" key="save" onClick={handleConfirm} disabled={!isDataChanged}>
                    저장
                </ColorButton>,
                <ColorButton key="cancel" onClick={handleCancel}>
                    취소
                </ColorButton>,
            ]}
        >
            <div>
                <AttendanceUI onChange={handleChange} name="attendanceInfo" attendanceInfo={attendanceInfo} />
            </div>
        </Modal>
    )
}

export default AttendanceDialog
