import { useState } from 'react'

import PageBanner from 'layout/PageBanner'
import { BannerIconTools, ColorDocIcon, ColorLocationIcon } from 'assets/Icons'

import { Row, Col, Space, Flex } from 'antd'
import Title from 'components/Ui/Title'
import Label from 'components/Ui/Label'
import SelectUi from 'components/Ui/Select/SelectUi'
import IconCard from 'components/AntD/Card/IconCard'
import ColorButton from 'components/AntD/Button/ColorButton'

// import Value from 'components/Ui/Value'
import { useNavigate, useParams } from 'react-router-dom'
// import Spinner from 'components/AntD/Spin'

import styles from 'assets/scss/detail.module.scss'

const ToolsDetail = () => {
    // const [loading, setLoading] = useState(true)
    const [toolsState] = useState({
        name: '교구',
    })
    const { id } = useParams()
    const navigate = useNavigate()

    return (
        <PageBanner title="교구재 관리 상세" bannerIcon={<BannerIconTools />}>
            <div className={styles.detail_padding}>
                {
                    toolsState && (
                        <>
                            <Row className={styles.mb_lg}>
                                <Col span={16}>
                                    <Space direction="vertical" size={60}>
                                        <div>
                                            <Title title="교구재 정보" />
                                            <div className={`${styles.box_md} ${styles.border_style}`}>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="교구명" />
                                                    </Col>
                                                    <Col span={19} className={styles.padding_y_medium}>
                                                        {/* <Value value="코딩교육" /> */}
                                                        코딩교육
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="품목분류" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>010-AD6-610</span>
                                                                {/* <Value value="010-AD6-610" /> */}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="자산번호" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>UN-100</span>
                                                                {/* <Value value="010-AD6-610" /> */}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div>
                                            <Title title="전체 교구 수량" />
                                            <div className={`${styles.box_md} ${styles.border_style}`}>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="총 수량" />
                                                    </Col>
                                                    <Col span={19} className={styles.padding_y_medium}>
                                                        {/* <Value value={toolsState?.name} /> */}
                                                        <span>20개</span>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="현재 수량" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>20개</span>
                                                                {/* <Value value="010-AD6-610" /> */}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="대여 수량" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>20개</span>
                                                                {/* <Value value="010-AD6-610" /> */}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div>
                                            <Title title="자산 정보" />
                                            <div className={`${styles.box_md} ${styles.border_style}`}>
                                                <Row className={styles.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="취득일" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>2023-09-15</span>
                                                                {/* <Value value="010-AD6-610" /> */}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="취득단가" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>100,000</span>
                                                                {/* <Value value="010-AD6-610" /> */}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="구매처" />
                                                    </Col>
                                                    <Col span={19} className={styles.padding_y_medium}>
                                                        {/* <Value value={toolsState?.name} /> */}
                                                        <span>지역</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div>
                                            <Title title="품목별 교구 상태" />
                                            <div className={`${styles.box_md} ${styles.border_style}`}>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="불량 사유" />
                                                    </Col>
                                                    <Col span={12} className={styles.padding_y_medium}>
                                                        <span>소리가 잘 나지 않음</span>
                                                        {/* <Value value={toolsState?.program_detail} /> */}
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="수리내역 결과" />
                                                    </Col>
                                                    <Col span={19}>
                                                        <SelectUi
                                                            disabled
                                                            value="수리완료"
                                                            options={['수리완료', '수리불가', '주문예정']}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div>
                                            <Title title="전체 교구 수량 및 상태" />
                                            <div className={`${styles.box_md} ${styles.border_style}`}>
                                                <Row className={styles.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="정상 수량" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>24개</span>
                                                                {/* <Value value="010-AD6-610" /> */}
                                                            </Col>
                                                        </Row>
                                                        {/* <Value value={toolsState?.program_detail} /> */}
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="분실 수량" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>24개</span>
                                                                {/* <Value value="010-AD6-610" /> */}
                                                            </Col>
                                                        </Row>{' '}
                                                        {/* <Value value={toolsState?.program_detail} /> */}
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="고장 수량" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>24개</span>
                                                                {/* <Value value="010-AD6-610" /> */}
                                                            </Col>
                                                        </Row>{' '}
                                                        {/* <Value value={toolsState?.program_detail} /> */}
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="수리 AS 수량" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>24개</span>
                                                                {/* <Value value="010-AD6-610" /> */}
                                                            </Col>
                                                        </Row>{' '}
                                                        {/* <Value value={toolsState?.program_detail} /> */}
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="부품 부족 수량" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>24개</span>
                                                                {/* <Value value="010-AD6-610" /> */}
                                                            </Col>
                                                        </Row>{' '}
                                                        {/* <Value value={toolsState?.program_detail} /> */}
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="폐기 수량" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>24개</span>
                                                                {/* <Value value="010-AD6-610" /> */}
                                                            </Col>
                                                        </Row>{' '}
                                                        {/* <Value value={toolsState?.program_detail} /> */}
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="센터별 수량" />
                                                        {/* <Value value={toolsState?.program_detail} /> */}
                                                    </Col>
                                                    <Col span={19} className={styles.padding_y_medium}>
                                                        <span>울산센터 XX개 / 북구센터 XX개</span>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="팀명칭" />
                                                    </Col>
                                                    <Col span={19}>
                                                        <span>새미래행복교육</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div>
                                            <Title title="비고" />
                                            <div className={`${styles.box_md} ${styles.border_style}`}>
                                                <Row className={styles.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="대출가능 수량" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>24개</span>
                                                                {/* <Value value="010-AD6-610" /> */}
                                                            </Col>
                                                        </Row>
                                                        {/* <Value value={toolsState?.program_detail} /> */}
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="재고 위치" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>센터</span>
                                                                {/* <Value value="010-AD6-610" /> */}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="배터리상태" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>70%</span>
                                                                {/* <Value value="010-AD6-610" /> */}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="제조사" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>삼성</span>
                                                                {/* <Value value="010-AD6-610" /> */}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div>
                                            <Title title="추가항목" />
                                            <div className={`${styles.box_md} ${styles.border_style}`}>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="과목" />
                                                    </Col>
                                                    <Col className={styles.padding_y_medium}>
                                                        <span>울산센터 XX개 / 북구센터 XX개</span>
                                                        {/* <Value value={toolsState?.program_detail} /> */}
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="폐기일자" />
                                                    </Col>
                                                    <Col span={19} className={styles.padding_y_medium}>
                                                        <span>2023-01-01</span>

                                                        {/* <SelectUi option={['수리완료', '수리불가', '주문예정']} /> */}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Space>
                                </Col>
                                <Col span={8} className={styles.padding_left_large}>
                                    <Space direction="horizontal" className={styles.mt}>
                                        <IconCard icon={<ColorDocIcon />} label="자산번호" val="UN001" />
                                        <IconCard icon={<ColorLocationIcon />} label="재고위치" val="경남" />
                                    </Space>
                                </Col>
                            </Row>
                            <Flex justify="center" align="center" gap={8}>
                                <ColorButton
                                    size="large"
                                    type="primary"
                                    onClick={() => navigate(`/tools/inventory/edit/${id}`)}
                                >
                                    수정
                                </ColorButton>
                                <ColorButton size="large" onClick={() => navigate('/tools/inventory')}>
                                    목록
                                </ColorButton>
                            </Flex>
                        </>
                    )
                    // : (
                    //     <div className={styles.spin_wrap}>
                    //         <Spinner />
                    //     </div>
                    // )
                }
            </div>
        </PageBanner>
    )
}

export default ToolsDetail
