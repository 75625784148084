import dayjs from 'dayjs'
import { supabase } from '../axios.instance'
import { convertKeysToCamelCase } from '../../utils/utilCommon'
/**
 * 지역통계 쿼리 RPC
 * @param regionId
 * @returns {Promise<null>}
 */
export const getCountRPC = async (regionId, monthOfReport) => {
    try {
        let month
        if (monthOfReport) {
            month = dayjs(monthOfReport).format('M')
        }

        const params = { region_id_param: regionId }
        if (month !== undefined) {
            params.month_of_report_param = Number(month)
        }

        const response = await supabase.rpc('get_count', params)
        let camelCasePayload
        if (regionId) {
            camelCasePayload = convertKeysToCamelCase(response.data[0])
        } else {
            camelCasePayload = convertKeysToCamelCase(response.data)
        }
        return camelCasePayload
    } catch (error) {
        console.error(error)
        return null
    }
}

/**
 * 교육관리 필터영역 카운트 RPC
 * @param regionId
 * @returns {Promise<null>}
 */
export const getCountProgramCountRPC = async params => {
    try {
        const regionId = params.region_id ? params.region_id.replace('eq.', '') : 'all'
        const classHours = params.class_hours ? params.class_hours : null
        const status = params.status ? params.status.replace('eq.', '') : null
        const education = params.education_type ? params.education_type.replace('eq.', '') : null
        const course = params.course ? params.course.replace('eq.', '') : null
        const method = params.method ? params.method.replace('eq.', '') : null
        const category = params.category ? params.category.replace('eq.', '') : null
        const grade = params.grade ? params.grade.replace('eq.', '') : null
        const startDate = params.start_date ? params.start_date.replace('gte.', '') : null
        const endDate = params.end_date ? params.end_date.replace('lte.', '') : null

        const response = await supabase.rpc('get_program_counts', {
            region_id_param: regionId,
            class_hours_param: classHours,
            status_param: status,
            education_type_param: education,
            course_param: course,
            method_param: method,
            category_param: category,
            grade_param: grade,
            start_date_param: startDate,
            end_date_param: endDate,
        })
        return response.data[0]
    } catch (error) {
        console.error(error)
        return null
    }
}
/**
 * 강사 필터영역 카운트 RPC
 * @param regionId
 * @returns {Promise<null>}
 */
export const getTutorCountRPC = async params => {
    try {
        const regionId = params.region_id ? params.region_id.replace('eq.', '') : 'all'
        const isYouth = params.is_youth ? params.is_youth.replace('eq.', '') : null
        const isRetiree = params.is_retiree ? params.is_retiree.replace('eq.', '') : null
        const isCareerGap = params.is_career_gap_female ? params.is_career_gap_female.replace('eq.', '') : null
        const isNew = params.is_new ? params.is_new.replace('eq.', '') : null
        const isRehired = params.is_rehired ? params.is_rehired.replace('eq.', '') : null
        const isAdvanced = params.is_advanced_instructor ? params.is_advanced_instructor.replace('eq.', '') : null
        const isForeign = params.is_foreign ? params.is_foreign.replace('eq.', '') : null
        const hasDisability = params.has_disability ? params.has_disability.replace('eq.', '') : null
        const isVeterans = params.is_veterans ? params.is_veterans.replace('eq.', '') : null
        const educationLevel = params.education_level ? params.education_level.replace('eq.', '') : null
        const teacherCertification = params.teacher_certification
            ? params.teacher_certification.replace('eq.', '')
            : null
        const teachingExperience = params.teaching_experience_yn
            ? params.teaching_experience_yn.replace('eq.', '')
            : null
        const employmentStatus = params.employment_status ? params.employment_status.replace('eq.', '') : null
        const employmentType = params.employment_type ? params.employment_type.replace('eq.', '') : null
        const response = await supabase.rpc('get_tutor_counts', {
            region_id_param: regionId,
            is_youth_param: isYouth,
            is_retiree_param: isRetiree,
            is_career_gap_female_param: isCareerGap,
            is_new_param: isNew,
            is_rehired_param: isRehired,
            is_advanced_instructor_param: isAdvanced,
            is_foreign_param: isForeign,
            has_disability_param: hasDisability,
            is_veterans_param: isVeterans,
            education_level_param: educationLevel,
            teacher_certification_param: teacherCertification,
            teaching_experience_yn_param: teachingExperience,
            employment_status_param: employmentStatus,
            employment_type_param: employmentType,
        })
        return response.data[0]
    } catch (error) {
        console.error(error)
        return null
    }
}
/**
 * 페이지 정보 RPC
 * @param table
 * @param regionId
 * @param pageSize
 * @returns {Promise<null>}
 */
export const getPageInfoRPC = async (table, regionId, pageSize) => {
    try {
        const response = await supabase.rpc('get_pages_info', {
            table_name: table,
            region_id_param: regionId,
            page_size: pageSize,
        })
        return response.data[0]
    } catch (error) {
        console.error(error)
        return null
    }
}

/**
 * 테이블 별 페이지 정보 get 함수
 * @param search
 * @param table
 * @param regionId
 * @param pageSize
 * @returns {Promise<{totalItems: *, totalPages: *, pageSize: *, currentPage: string|number}>}
 */
export const getCommonPageInfo = async (search, table, regionId, pageSize) => {
    const currentPage = Number(new URLSearchParams(search).get('page')) || 1
    let pageInfo = await getPageInfoRPC(table, regionId, pageSize)
    pageInfo = convertKeysToCamelCase(pageInfo)
    const { totalPages, totalItems } = pageInfo

    return {
        currentPage,
        totalPages,
        totalItems,
        pageSize,
    }
}
