import { useLocation, useNavigate } from 'react-router-dom'
import ColorButton from 'components/AntD/Button/ColorButton'

import { useSelector } from 'react-redux'
import { getUser } from 'store/Slices/user'

import styles from './listNotFound.module.scss'

const ListNotFound = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { isMaster } = useSelector(getUser)

    const { pathname } = location
    const paths = pathname.split('/').filter(path => path !== '')
    const firstPath = paths[0]
    const currentPath = window.location.pathname

    const pathMessages = {
        main: '프로그램이',
        program: '프로그램이',
        tutors: '강사가',
        report: '월간보고가',
        survey: '수요조사가',
        contact: '문의가',
    }

    const pahTitle = {
        main: '프로그램',
        program: '프로그램',
        tutors: '강사',
        report: '월간보고',
        survey: '수요조사',
        contact: '문의하기',
    }

    return (
        <div className={styles.not_found_page_wrap}>
            <div className={styles.not_found_page_top}>
                <span className={styles.not_found_page_icon} />
                <div className={styles.not_found_page_title}>
                    <span className="sub_title1">해당하는 {pathMessages[firstPath]} 존재하지 않습니다.</span>
                </div>
            </div>
            {isMaster === false && (
                <>
                    {firstPath === 'report' && currentPath === '/report' && (
                        <div className={styles.not_found_page_bottom}>
                            <ColorButton type="primary" onClick={() => navigate(`/${firstPath}/register`)}>
                                {pahTitle[firstPath]} 등록
                            </ColorButton>
                        </div>
                    )}
                    {firstPath !== 'main' &&
                        firstPath !== 'survey' &&
                        firstPath !== 'report' &&
                        firstPath !== 'contact' && (
                            <div className={styles.not_found_page_bottom}>
                                <ColorButton type="primary" onClick={() => navigate(`/${firstPath}/register/bulk`)}>
                                    {pahTitle[firstPath]} 대량 등록
                                </ColorButton>
                                <ColorButton type="primary" onClick={() => navigate(`/${firstPath}/register/one`)}>
                                    {pahTitle[firstPath]} 단건 등록
                                </ColorButton>
                            </div>
                        )}

                    {firstPath === 'survey' && currentPath === '/survey' && (
                        <div className={styles.not_found_page_bottom}>
                            <ColorButton type="primary" onClick={() => navigate(`/${firstPath}/register`)}>
                                {pahTitle[firstPath]} 등록
                            </ColorButton>
                        </div>
                    )}
                    {firstPath === 'contact' && currentPath === '/contact' && (
                        <div className={styles.not_found_page_bottom}>
                            <ColorButton type="primary" onClick={() => navigate(`/${firstPath}/write`)}>
                                {pahTitle[firstPath]} 등록
                            </ColorButton>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default ListNotFound
