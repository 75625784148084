const TutorName = ({ tutorName, children }) => {
    return (
        <div style={{ display: 'flex', gap: 20, marginBottom: 50 }}>
            <span className="h4" style={{ margin: 0 }}>
                {tutorName}
            </span>
            <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>{children}</div>
        </div>
    )
}

export default TutorName
