import React from 'react'
import styles from './title.module.scss'

const Title = ({ size = 'sm', title, children }) => {
    const headerClass = `${styles.header} ${styles[size] || ''}`

    return (
        <div className={styles.container}>
            <span className={`h5 ${headerClass}`}>{title}</span>
            {children && <div>{children}</div>}
        </div>
    )
}

export default Title
