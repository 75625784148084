import dayjs from 'dayjs'
import { Flex } from 'antd'

import SelectBox from '../../AntD/Select'
import styles from '../filter.module.scss'
import LocalStatisticsFilter from './LocalStatisticsFilter'

const ReportFilter = ({ updateQueryStringAndNavigate, queryParams, type }) => {
    const key = 'monthOfReport'
    const year = dayjs().year()
    const defaultValue = queryParams.get('monthOfReport') || 'all'

    const months = [
        { value: 'all', label: '전체' },
        { value: `${year}0101`, label: '1월' },
        { value: `${year}0201`, label: '2월' },
        { value: `${year}0301`, label: '3월' },
        { value: `${year}0401`, label: '4월' },
        { value: `${year}0501`, label: '5월' },
        { value: `${year}0601`, label: '6월' },
        { value: `${year}0701`, label: '7월' },
        { value: `${year}0801`, label: '8월' },
        { value: `${year}0901`, label: '9월' },
        { value: `${year}1001`, label: '10월' },
        { value: `${year}1101`, label: '11월' },
        { value: `${year}1201`, label: '12월' },
    ]

    return (
        <>
            <LocalStatisticsFilter
                updateQueryStringAndNavigate={updateQueryStringAndNavigate}
                queryParams={queryParams}
                type={type}
            />
            <Flex align="start">
                <span className={`body2 ${styles.title}`}>작성월</span>
                <SelectBox
                    style={{ height: '50px' }}
                    onChange={value => updateQueryStringAndNavigate(key, value)}
                    defaultValue={defaultValue}
                    options={months}
                />
            </Flex>
        </>
    )
}

export default ReportFilter
