import { Col, Row } from 'antd'
import Title from 'components/Ui/Title'
import { Link } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import styles from 'assets/scss/detail.module.scss'
import Value from 'components/Ui/Value'
import SanitizedHTML from '../../sanitizeHTML'

const TutorInfoList = ({ instructor_matching, description = '해당 교육과정에 배정된 강사 목록입니다.' }) => {
    return (
        <div className={styles.box_lg}>
            <Title size="lg" title="강사 정보" />
            <div className={`${styles.basic_font} ${styles.mb}`}>{description}</div>
            {instructor_matching && instructor_matching.length > 0 ? (
                <div className={styles.border_style}>
                    {instructor_matching.map(item => (
                        <Row
                            key={uuidv4()}
                            className={`${styles.row_item_bottom} ${styles.padding_y_medium} ${styles.padding_x_large}`}
                        >
                            <Col span={5}>
                                <Value value={item?.tutor_type} />
                            </Col>
                            <Col span={5}>
                                <Link to={`/tutors/${item.tutor_id}`}>
                                    <Value value={item?.tutor_name} />
                                </Link>
                            </Col>

                            {item?.class_hours === null ? (
                                <Col span={6} />
                            ) : (
                                <>
                                    <Col span={2}>
                                        <span className={styles.basic_font}>강의시간</span>
                                    </Col>
                                    <Col span={1}>
                                        <Value value={`  ${item?.class_hours}`} />
                                    </Col>
                                    <Col span={3}>
                                        <span className={styles.basic_font}>교시</span>
                                    </Col>
                                </>
                            )}

                            {item?.remark === null ? (
                                ''
                            ) : (
                                <>
                                    <Col span={1}>
                                        <span className={styles.basic_font}>비고</span>
                                    </Col>
                                    <Col span={4}>
                                        <SanitizedHTML className="sanitize_text" html={`${item?.remark}`} />
                                    </Col>
                                </>
                            )}
                        </Row>
                    ))}
                </div>
            ) : (
                <div className={` ${styles.border_style}`}>
                    <div className={styles.not_found_page_wrap}>
                        <div className={styles.not_found_page_top}>
                            <span className={styles.not_found_page_icon} />
                            <div className={styles.not_found_page_title}>
                                <span className="sub_title1">수정페이지에서 강사를 추가해주세요.</span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default TutorInfoList
