import { Alert, ConfigProvider } from 'antd'

import { colorTheme } from 'assets/colorTheme'

import {
    BtnClose } from 'assets/Icons'
import styles from './alert.module.scss'

const { fontColor, gray } = colorTheme

const AlertComponent = ({ showIcon, close, message = 'example', onClose, isShow }) => {
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorInfo: fontColor.white,
                    colorInfoBorder: gray['300'],
                },
            }}
        >
            <Alert
                className={`${styles['ant-alert']} ${isShow ? styles.show : ''}`}
                message={message}
                type="error"
                showIcon={!!showIcon}
                closeIcon={close ? <BtnClose /> : false}
                onClose={() => onClose(false)}
            />
        </ConfigProvider>
    )
}

export default AlertComponent
