import dayjs from 'dayjs'

import {
    buildSupabaseChainFilter,
    convertReportSnakeToCamelCase,
    createYearRangeUntilLastDayOfLastMonth,
} from 'utils/utilCommon'
import { supabase } from '../axios.instance'
import { createSupabaseQuery, supabaseEssentialFilter } from '../common'
// import { getCommonPageInfo } from '../rpc/rpc.api'

const table = 'report_info'
const reportIssue = 'report_issue'

/**
 * 해당 지역 당해 월간보고 카운팅
 * @param search
 * @param regionId
 * @returns {Promise<PostgrestFilterBuilder<*, *, GetResult<*, *, *, "*">[], *>|null>}
 */
export const getReportListCount = async regionId => {
    try {
        // query
        const query = supabase.from(table).select('count(*)')
        // 필수 쿼리
        const newQuery = supabaseEssentialFilter(query, regionId)
        const response = await newQuery
        return response
    } catch (error) {
        console.error(error)
        return null
    }
}

/**
 * 월간보고 목록
 * get
 * @param search
 * @param regionId
 * @returns {Promise<PostgrestFilterBuilder<*, *, GetResult<*, *, *, "*">[], *>|null>}
 */
export const getReportList = async (search, regionId) => {
    try {
        const searchParams = new URLSearchParams(search)
        const region = regionId === 'all' ? searchParams.get('regionId') || 'all' : regionId
        const month = searchParams.get('monthOfReport') === 'all' ? null : searchParams.get('monthOfReport')
        const currentPage = Number(searchParams.get('page')) || 1

        // 페이지 정보 response
        const pageSize = 10
        // const paginationInfo = await getCommonPageInfo(search, table, region, pageSize)
        const paginationInfo = {
            currentPage,
            totalPages: 1,
            totalItems: 1,
            pageSize,
        }

        // query
        let { query } = await buildSupabaseChainFilter(table, search, region)
        query = query.order('report_id', { ascending: false })

        if (month) {
            const key = 'month_of_report'
            const startMonth = dayjs(month).format('YYYY-MM-DD 00:00:00')
            const endMonth = dayjs(month).add(1, 'month').format('YYYY-MM-DD 00:00:00')

            query = query.gte(key, startMonth).lt(key, endMonth)
        }

        const response = await query
        const totalList = response.data
        const offset = (currentPage - 1) * pageSize

        if (totalList) {
            // json 데이터 (snake -> camel 변경)
            const convertedData = totalList.map(convertReportSnakeToCamelCase)

            paginationInfo.totalItems = totalList.length
            paginationInfo.totalPages = Math.ceil(totalList.length / pageSize)

            response.data = {
                reportList: convertedData.slice(offset, offset + pageSize),
                page: paginationInfo,
            }

            return response
        }

        return response
    } catch (error) {
        console.error(error)
        return null
    }
}

/**
 * 월간보고 상세
 * @param reportId
 * @param regionId
 * @returns {Promise<*|null>}
 */
export const getReportById = async (reportId, regionId) => {
    try {
        const query = supabase.from(table).select(`*, issue:${reportIssue}(*)`).eq('report_id', reportId)
        const newQuery = supabaseEssentialFilter(query, regionId)
        const response = await newQuery

        // json 데이터 (snake -> camel 변경)
        const list = response.data
        const convertedData = list.map(convertReportSnakeToCamelCase)

        response.data = {
            result: convertedData,
        }
        return response
    } catch (error) {
        console.error(error)
        return null
    }
}

/**
 * 월간보고 등록
 * @param reportData
 * @returns {Promise<axios.AxiosResponse<any>|*>}
 */
export const postReport = async reportData => {
    try {
        const response = await supabase.from(table).insert(reportData).select()
        return response
    } catch (error) {
        console.error(error)
        return error
    }
}

/**
 * 월간보고
 * 수정
 * @param reportData
 * @param reportId
 * @returns {Promise<*|PostgrestResponseSuccess<GetResult<any, any, R | unknown, "*">[]>|PostgrestResponseFailure>}
 */
export const putReport = async (reportData, reportId) => {
    try {
        const response = await supabase.from(table).update(reportData).eq('report_id', reportId).select()
        return response
    } catch (error) {
        console.error(error)
        return error
    }
}

/**
 * 월간보고
 * 삭제된 상태로 변경
 * @param reportId
 * @returns {Promise<PostgrestResponseSuccess<GetResult<any, any, R | unknown, "*">[]>|PostgrestResponseFailure|null>}
 */
export const deleteReportById = async reportId => {
    try {
        const response = await supabase.from(table).update({ is_delete: true }).eq('report_id', reportId).select()
        return response
    } catch (error) {
        console.error(error)
        return null
    }
}

/**
 * 당해 누적 집행 금액
 * get
 * @returns {Promise<PostgrestFilterBuilder<*, *, GetResult<*, *, ?, "*">[], ?>>}
 * @param regionId
 * @param selectedMonth
 */
export const getReportAccumulate = async (regionId, selectedMonth) => {
    try {
        const { startDate, endDate } = createYearRangeUntilLastDayOfLastMonth(selectedMonth)

        let query = createSupabaseQuery(table, regionId)
        query = query.gte('month_of_report', startDate).lte('month_of_report', endDate)
        const response = await query
        return response
    } catch (error) {
        console.error(error)
        return null
    }
}
