import { Col, Row, Form } from 'antd'
import SelectBox from '../Select'

import TextField from '../TextField'

import styles from './input.module.scss'

/**
 *
 * @param label
 * @param options
 * @param handleChange
 * @param name
 * @param rules
 * @param value
 * @param noStyle 하단 validation erorr 제거 및 스타일 제거
 * @param disabled
 * @returns {JSX.Element}
 * @constructor
 */
const InputSection = ({ label, options, handleChange, name, rules, value, noStyle, password, disabled }) => {
    return (
        <Row align="middle" style={{ borderBottom: '1px solid var(--gray-300)' }}>
            <Col span={7}>
                <div className={styles.label}>
                    <span>{label}</span>
                </div>
            </Col>
            <Col span={17}>
                <Form.Item name={name} rules={rules} noStyle={!!noStyle} style={{ minHeight: '50px' }}>
                    {options ? (
                        <SelectBox
                            onChange={handleChange}
                            options={options}
                            size="lg"
                            value={value}
                            defaultValue={{ value: '', label: '지역을 선택해주세요' }}
                        />
                    ) : (
                        <TextField val={value || ''} password={password}  disabled={disabled} />
                    )}
                </Form.Item>
            </Col>
        </Row>
    )
}

export default InputSection
