import { useLocation } from 'react-router-dom'

import { Col, Form, InputNumber, Row } from 'antd'
import { formatCurrency, formatNumberToThousands, numberParser } from 'utils/utilCommon'

import styles from 'components/Report/report.module.scss'

/**
 * 총 집행률
 * @param totalExecutionRate
 * @returns {JSX.Element}
 * @constructor
 */
const TotalExecutionRate = ({ executionData, monthlyBudget, reportTotal }) => {
    const { pathname } = useLocation()
    const paths = pathname.split('/').filter(path => path !== '')

    const cash = Number(monthlyBudget?.governmentFunds) + Number(executionData?.executionGovernmentFunds || 0)
    const funds = Number(monthlyBudget?.localCashFunds) + Number(executionData?.executionLocalCashFunds || 0)
    const total = cash + funds

    const percent = ((total / Number(reportTotal)) * 100).toFixed(2)

    return (
        <div className={`${styles.card} ${styles.seion_md}`}>
            <Row>
                <Col span={7} className={`${styles.blue} ${styles.center} ${styles.padding_34}`}>
                    <span className={`h7 ${styles.text_white}`}>총 집행률</span>
                </Col>
                <Col span={17} className={`${styles.padding_2036} ${styles.right} ${styles.align_center}`}>
                    <Row className={styles.width_100}>
                        <Col span={2} className={`body2 ${styles.label_section}`}>
                            현금
                        </Col>
                        <Col
                            span={5}
                            className={`sub_title1 ${styles.ellipsis} ${styles.flex_center} ${styles.align_center_inline}`}
                        >
                            <Form.Item name="totalExecutionRateCash" rules={[{ required: true }]} noStyle>
                                {paths[1] === 'register' || paths[1] === 'edit' ? (
                                    <InputNumber
                                        name="totalExecutionRateCash"
                                        className={`${styles.ant_input} ${styles.w160} ${styles.no_number_handler}`}
                                        // defaultValue={totalExecutionRate?.cash || ''}
                                        defaultValue={cash || 0}
                                        formatter={formatNumberToThousands}
                                        parser={numberParser}
                                        placeholder="현금을 입력해주세요."
                                        onKeyDown={e => (e.keyCode === 13 ? e.preventDefault() : '')}
                                        readOnly
                                    />
                                ) : (
                                    <span>{formatCurrency(cash || 0)}</span>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={2} className={`body2 ${styles.label_section}`}>
                            현물
                        </Col>
                        <Col
                            span={5}
                            className={`sub_title1 ${styles.right} ${styles.ellipsis} ${styles.flex_center} ${styles.align_center_inline}`}
                        >
                            <Form.Item name="totalExecutionRateInKind" rules={[{ required: true }]} noStyle>
                                {paths[1] === 'register' || paths[1] === 'edit' ? (
                                    <InputNumber
                                        name="totalExecutionRateInKind"
                                        className={`${styles.ant_input} ${styles.w160} ${styles.height_100} ${styles.no_number_handler}`}
                                        // defaultValue={totalExecutionRate?.inKind || ''}
                                        defaultValue={funds || 0}
                                        formatter={formatNumberToThousands}
                                        parser={numberParser}
                                        placeholder="현물을 입력해주세요."
                                        onKeyDown={e => (e.keyCode === 13 ? e.preventDefault() : '')}
                                        readOnly
                                    />
                                ) : (
                                    <span>{formatCurrency(funds || 0)}</span>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={3} className={`${styles.label_section}`}>
                            <span className={`h7 ${styles.section_8} ${styles.mb0}`}>Total</span>
                        </Col>
                        <Col
                            span={4}
                            className={`${styles.ellipsis} ${styles.flex_center} ${styles.height_100} ${styles.align_center_inline}`}
                        >
                            <Form.Item name="totalExecutionRateTotal" noStyle>
                                <InputNumber
                                    className={`${styles.right} ${styles.total} ${styles.no_number_handler}`}
                                    size="large"
                                    style={{ width: '150px' }}
                                    formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/(,*)/g, '')}
                                    // defaultValue={totalExecutionRate?.total || 0}
                                    defaultValue={total || 0}
                                    onKeyDown={e => (e.keyCode === 13 ? e.preventDefault() : '')}
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                        <Col span={1} className={`${styles.label_section}`}>
                            <span className={`h7 ${styles.section_8} ${styles.mb0}`}>(</span>
                        </Col>
                        <Col
                            span={2}
                            className={`sub_title1 ${styles.right} ${styles.flex_center} ${styles.align_center_inline}`}
                        >
                            {percent}%)
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default TotalExecutionRate
