import { Col, Row } from 'antd'
import SectionRow from 'components/Report/SectionRow'
import TotalExecutionRate from 'pages/Report/Detail/TotalExecutionRate'

import styles from 'components/Report/report.module.scss'

const ProjectExpenseSection = ({
    totalBudget,
    reportTotal,
    monthlyBudget,
    monthlyBudgetExecution,
    accumulated,
    executionData,
}) => {
    return (
        <>
            <Row gutter={26} className={styles.section_sm}>
                <Col span={8}>
                    {/* 총 사업비 */}
                    <SectionRow
                        title={totalBudget.title}
                        rows={totalBudget.rows}
                        total={reportTotal?.total}
                        name="totalBudgetTotal"
                        length={3}
                    />
                </Col>
                <Col span={8}>
                    {/* 당월 예산 집행 */}
                    <SectionRow
                        title={monthlyBudget.title}
                        rows={monthlyBudget.rows}
                        total={monthlyBudgetExecution?.total}
                        name="monthlyBudgetTotal"
                        length={2}
                    />
                </Col>
                <Col span={8}>
                    {/* 누적 집행 금액 */}
                    <SectionRow
                        title={accumulated.title}
                        rows={accumulated.rows}
                        total={executionData?.executionTotal || 0}
                        name="accumulatedTotal"
                        length={2}
                    />
                </Col>
            </Row>
            {/* 총 집행률 */}
            <TotalExecutionRate
                // totalExecutionRate={totalExecutionRate}
                monthlyBudget={monthlyBudgetExecution}
                executionData={executionData}
                reportTotal={reportTotal?.total || 1}
            />
        </>
    )
}

export default ProjectExpenseSection
