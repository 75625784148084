import { Form } from 'antd'
import { ColorGraduationIcon } from 'assets/Icons'
import { useLocation } from 'react-router-dom'

import TextField from '../TextField'
import styles from './card.module.scss'

const IconCard = ({ icon = <ColorGraduationIcon />, label, val, name, placeholder }) => {
    const { pathname } = useLocation()
    const paths = pathname.split('/').filter(path => path !== '')

    return (
        <div className={`${styles.card_vertical} ${styles.border_style}`}>
            <div className={styles.icon}>{icon}</div>
            <span className={styles.card_key}>{label}</span>
            {paths[1] === 'register' ? (
                <Form.Item noStyle name={name}>
                    <TextField name={name} size="100" placeholder={placeholder} />
                </Form.Item>
            ) : val ? (
                <span className={styles.card_value}>{val}</span>
            ) : (
                <span className={styles.card_value}>지정안됨</span>
            )}
        </div>
    )
}

export default IconCard
