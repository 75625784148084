import FilterButton from 'components/AntD/Button/FilterButton'
import { AdvancedTutorFilterIcon, CheckFilterIcon, NormalTutorFilterIcon } from 'assets/Icons'

import styles from '../filter.module.scss'

const TutorFilter = ({ queryParams, updateQueryStringAndNavigate }) => {
    // const classification = 'classification' // 대상자 분류*: 청년, 경단여, 전문퇴직자, 해당없음
    const isYouth = 'isYouth' // 청년 여부*
    const isRetiree = 'isRetiree' // 전문퇴직자 여부*
    const isCareerGapFemale = 'isCareerGapFemale' // 경단여 여부*

    const isNew = 'isNew' // 신규강사 여부*
    const isRehired = 'isRehired' // 재고용 여부*
    const isForeign = 'isForeign' // 외국인*
    const hasDisability = 'hasDisability' // 장애여부*
    const isAdvancedInstructor = 'isAdvancedInstructor' // 고급강사 여부*
    const isVeterans = 'isVeterans' // 보훈여부

    // const currentClassification = queryParams.get(classification) || ''
    const currentIsYouth = queryParams.get(isYouth) || ''
    const currentIsRetiree = queryParams.get(isRetiree) || ''
    const currentIsCareerGapFemale = queryParams.get(isCareerGapFemale) || ''

    const currenIsNew = queryParams.get(isNew) || ''
    const currentIsRehired = queryParams.get(isRehired) || ''
    const currentIsForeign = queryParams.get(isForeign) || ''
    const currentHasDisability = queryParams.get(hasDisability) || ''
    const currentIsAdvancedInstructor = queryParams.get(isAdvancedInstructor) || ''
    const currentIsVeterans = queryParams.get(isVeterans) || ''

    return (
        <>
            <span className={`body2 ${styles.title}`}>강사구분</span>
            <div className={styles.btn_wrap}>
                <FilterButton
                    icon={<CheckFilterIcon />}
                    title="신규"
                    currentParamValue={currenIsNew}
                    onClick={() => updateQueryStringAndNavigate(isNew, 'Y')}
                    filterColor="black"
                />
                <FilterButton
                    icon={<CheckFilterIcon />}
                    title="재고용"
                    currentParamValue={currentIsRehired}
                    onClick={() => updateQueryStringAndNavigate(isRehired, 'Y')}
                    filterColor="black"
                />
            </div>
            {/*<span className={`body2 ${styles.title}`}>구분</span>*/}
            <div className={styles.btn_wrap}>
                <FilterButton
                    icon={<CheckFilterIcon />}
                    title="청년"
                    currentParamValue={currentIsYouth}
                    onClick={() => updateQueryStringAndNavigate(isYouth, 'Y')}
                    filterColor="black"
                />
                <FilterButton
                    icon={<CheckFilterIcon />}
                    title="전문퇴직자"
                    currentParamValue={currentIsRetiree}
                    onClick={() => updateQueryStringAndNavigate(isRetiree, 'Y')}
                    filterColor="black"
                />
                <FilterButton
                    icon={<CheckFilterIcon />}
                    title="경력단절여성"
                    currentParamValue={currentIsCareerGapFemale}
                    onClick={() => updateQueryStringAndNavigate(isCareerGapFemale, 'Y')}
                    filterColor="black"
                />
            </div>
            {/*<span className={`body2 ${styles.title}`}>고급강사 여부</span>*/}
            <div className={styles.btn_wrap} style={{ paddingLeft: 130 }}>
                <FilterButton
                    icon={<NormalTutorFilterIcon />}
                    title="일반강사"
                    currentParamValue={currentIsAdvancedInstructor}
                    onClick={() => updateQueryStringAndNavigate(isAdvancedInstructor, 'N')}
                    filterColor="red"
                />
                <FilterButton
                    icon={<AdvancedTutorFilterIcon />}
                    title="고급강사"
                    currentParamValue={currentIsAdvancedInstructor}
                    onClick={() => updateQueryStringAndNavigate(isAdvancedInstructor, 'Y')}
                    filterColor="green"
                />
            </div>
            <div className={styles.btn_wrap}>
                <FilterButton
                    icon={<CheckFilterIcon />}
                    title="외국인"
                    currentParamValue={currentIsForeign}
                    onClick={() => updateQueryStringAndNavigate(isForeign, 'Y')}
                    filterColor="black"
                />
                <FilterButton
                    icon={<CheckFilterIcon />}
                    title="장애인"
                    currentParamValue={currentHasDisability}
                    onClick={() => updateQueryStringAndNavigate(hasDisability, 'Y')}
                    filterColor="black"
                />
                <FilterButton
                    icon={<CheckFilterIcon />}
                    title="보훈대상"
                    currentParamValue={currentIsVeterans}
                    onClick={() => updateQueryStringAndNavigate(isVeterans, 'Y')}
                    filterColor="black"
                />
            </div>
        </>
    )
}

export default TutorFilter
