import { v4 as uuidv4 } from 'uuid'
import { Link, useLocation } from 'react-router-dom'

import { Col, Row } from 'antd'

import ProgramItem from 'components/ListItem/GridListItem/Program'
import TutorItem from 'components/ListItem/GridListItem/Tutor'

import styles from './gridList.module.scss'

const GridList = ({ list, setLoading, fetchList }) => {
    const { pathname } = useLocation()
    const path = pathname.split('/')[1]

    const getItemId = (urlPath, item) => {
        if (urlPath === 'program') {
            return item?.programId
        }
        if (urlPath === 'tutors') {
            return item?.tutorId
        }
        return '/'
    }

    return (
        <div className={styles.container}>
            <Row gutter={[16, 16]}>
                {list.slice(0, 10).map(item => {
                    return (
                        <Col span={12} key={uuidv4()}>
                            <Link to={`/${path}/${getItemId(path, item)}`} key={uuidv4()}>
                                <div className={styles.card}>
                                    {path === 'program' ? (
                                        <ProgramItem item={item} setLoading={setLoading} fetchList={fetchList} />
                                    ) : path === 'tutors' ? (
                                        <TutorItem item={item} setLoading={setLoading} fetchList={fetchList} />
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </Link>
                        </Col>
                    )
                })}
            </Row>
        </div>
    )
}

export default GridList
