import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function ScrollToTop() {
    const { pathname } = useLocation()

    // navigate 이동 시 페이지 맨 위로 이동
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    // 페이지가 이동 시 페이지 맨 위로 이동
    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        const currentPage = params.get('page')

        if (currentPage) {
            window.scrollTo({ top: 0 })
        }
    }, [window.location.search])

    return null
}
