import { useEffect, useState } from 'react'

import { supabase } from 'api/axios.instance'

import InfoTable from 'components/AntD/Table'
import Title from 'components/Ui/Title'

import styles from 'assets/scss/detail.module.scss'

const TutorExperience = ({ matchingList }) => {
    const [programData, setProgramData] = useState(matchingList)

    useEffect(() => {
        const fetchData = async () => {
            if (matchingList) {
                try {
                    /**
                     * 프로그램 정보 호출
                     */
                    const programInfoPromises = matchingList.map(async ({ program_id, ...rest }) => {
                        const { data, error } = await supabase
                            .from('program_info')
                            .select('program_name', 'start_date', 'end_date')
                            .eq('program_id', program_id)
                            .single()

                        if (error) {
                            console.error(`Error fetching program_info for program_id ${program_id}:`, error)
                            return { ...rest, key: program_id }
                        }

                        return {
                            key: program_id,
                            program_id,
                            program_name: data.program_name,
                            ...rest,
                        }
                    })

                    const updatedProgramData = await Promise.all(programInfoPromises)

                    setProgramData([...updatedProgramData])
                } catch (error) {
                    console.error('Error fetching program_info:', error)
                }
            }
        }

        fetchData()
    }, [matchingList])

    return (
        <div className={styles.box_md}>
            <Title title="경력이력" />
            {matchingList && matchingList.length > 0 ? (
                <InfoTable data={programData} />
            ) : (
                <div className={` ${styles.border_style}`}>
                    <div className={styles.not_found_page_wrap}>
                        <div className={styles.not_found_page_top}>
                            <span className={styles.not_found_page_icon} />
                            <div className={styles.not_found_page_title}>
                                <span className="sub_title1">매칭된 교육프로그램이 없습니다</span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
export default TutorExperience
