import { Col, Row } from 'antd'
import ReactECharts from 'echarts-for-react'
// import { useEffect, useState } from 'react'
import styles from './chart.module.scss'

const PieChart = ({
    dataSource,
    dataName,
    // chartTitle = '수혜율',
    title = '강사',
}) => {
    const calculatePercentages = (total, partOf) => {
        let totalPercentage = 0
        let partOfPercentage = 0

        if (total !== 0) {
            totalPercentage = ((total / (total + partOf)) * 100).toFixed(2)
        }
        if (partOf !== 0) {
            partOfPercentage = ((partOf / (total + partOf)) * 100).toFixed(2)
        }

        return { totalPercentage, partOfPercentage }
    }
    const getEChartsOption = data => {
        if (!data || data.length === 0) {
            return {}
        }
        const colors = ['#E5E5E5', '#FF6F4B']
        const { totalPercentage, partOfPercentage } = calculatePercentages(data.totalCount, data.partOfCount)

        return {
            backgroundColor: '#fff',
            color: colors,
            series: [
                {
                    type: 'pie',
                    radius: '40%',
                    center: ['50%', '50%'],
                    data: [
                        { value: totalPercentage, name: dataName[0] },
                        { value: partOfPercentage, name: dataName[1] },
                    ],
                    label: {
                        fontSize: 18,
                        fontWeight: 700,
                        align: 'left',
                        formatter(params) {
                            return `{a|${params.name}}\n {b|${params.percent}%}`
                        },
                        rich: {
                            a: {
                                fontSize: 15,
                                fontWeight: 700,
                                lineHeight: 42,
                                textAlign: 'left',
                                color: 'black',
                            },
                            b: {
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 42,
                                textAlign: 'left',
                                color: '#FF6F4B',
                            },
                        },
                    },
                    emphasis: {
                        scale: true,
                        label: {
                            show: true,
                        },
                    },
                    labelLine: {
                        lineStyle: {
                            color: '#E5E5E5',
                        },
                        smooth: 0,
                        length: 14,
                        length2: 3,
                        minTurnAngle: 70,
                    },
                },
            ],
        }
    }

    const { totalPercentage, partOfPercentage } = calculatePercentages(dataSource.totalCount, dataSource.partOfCount)

    return (
        <>
            <h5 className={styles.title}>{title}</h5>
            <div className={styles.wrapper}>
                <Row className={styles.alignCenter}>
                    <Col span={14}>
                        <ReactECharts style={{ height: 260 }} option={getEChartsOption(dataSource)} />
                    </Col>
                    <Col span={10} className={styles.progressArea}>
                        <div className={styles.relative}>
                            <span className={styles.labelText}>{dataName[0]}</span>
                            <div className={styles.barArea} style={{ width: `${totalPercentage}%` }}>
                                <span className={styles.valueText}>{dataSource.totalCount}</span>
                            </div>
                        </div>
                        <div className={styles.relative}>
                            <span className={styles.labelText}>{dataName[1]}</span>
                            <div
                                className={`${styles.barArea} ${styles.barAreaOrange}`}
                                style={{ width: `${partOfPercentage}%` }}
                            >
                                <span className={styles.valueText}>{dataSource.partOfCount}</span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default PieChart
