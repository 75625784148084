import { Row, Col, Flex, Form, Input } from 'antd'
import ColorButton from 'components/AntD/Button/ColorButton'
import HorizontalIconCard from 'components/AntD/Card/HorizontalIconCard'
import Title from 'components/Ui/Title'

import { AttendanceIcon, ColorCalenderIcon, ColorContactIcon, ColorLicenseIcon } from 'assets/Icons'
import { useDispatch, useSelector } from 'react-redux'
import { setProgramField } from 'store/Slices/programs'

import styles from 'assets/scss/detail.module.scss'

const Attendences = ({ decription = '수업교시, 수강인원을 입력하면 출석부를 등록할 수 있습니다.', onModal }) => {
    const program = useSelector(state => state.program.program)
    const dispatch = useDispatch()
    const { programOperationInfo } = program
    const { numberOfGraduates, numberOfApplicants, classHours } = programOperationInfo || {}

    const handleChange = (key, value) => {
        dispatch(
            setProgramField({
                field: 'programOperationInfo',
                data: { ...programOperationInfo, [key]: value },
            }),
        )
    }

    const renderInput = (name, label, value, onChange) => (
        <Input
            name={name}
            value={value}
            onChange={e => onChange(name, e.target.value)}
            style={{ padding: 12, textAlign: 'right', fontSize: 16 }}
        />
    )

    return (
        <div className={styles.box_lg}>
            <Title size="lg" title="출석부" />
            <Flex justify="space-between" align="center" className={`${styles.basic_font} ${styles.mb}`}>
                {decription}
                <ColorButton
                    type="primary"
                    icon={<AttendanceIcon />}
                    onClick={onModal}
                    disabled={!(classHours && numberOfApplicants)}
                >
                    출석부 계산하기
                </ColorButton>
            </Flex>
            <Row gutter={26}>
                <Col>
                    <Form.Item
                        name="classHours"
                        initialValue={program?.programOperationInfo?.classHours}
                        rules={[{ required: true, message: '교시를 입력해야 출석부를 등록할 수 있습니다.' }]}
                    >
                        <HorizontalIconCard
                            name="classHours"
                            editable
                            icon={<ColorCalenderIcon />}
                            label="수업교시"
                            value={classHours}
                        >
                            {renderInput('classHours', '수업교시', classHours, handleChange)}
                        </HorizontalIconCard>
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item
                        name="numberOfApplicants"
                        initialValue={program?.programOperationInfo?.numberOfApplicants}
                    >
                        <HorizontalIconCard
                            onChange={value => handleChange('numberOfApplicants', value)}
                            name="numberOfApplicants"
                            editable
                            icon={<ColorContactIcon />}
                            label="수강인원"
                            value={numberOfApplicants}
                        >
                            {renderInput('numberOfApplicants', '수강인원', numberOfApplicants, handleChange)}
                        </HorizontalIconCard>
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item name="numberOfGraduates" initialValue={program?.programOperationInfo?.numberOfGraduates}>
                        <HorizontalIconCard
                            icon={<ColorLicenseIcon />}
                            label="수료인원 (80% 이상)"
                            editable
                            addonAfter="명"
                            value={numberOfGraduates}
                        >
                            {renderInput('numberOfGraduates', '수료인원 (80% 이상)', numberOfGraduates, handleChange)}
                        </HorizontalIconCard>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    )
}

export default Attendences
