import React, { useState } from 'react'
import { Button } from 'antd'
import TextField from 'components/AntD/TextField'
import { v4 as uuidv4 } from 'uuid'
import { PlusIcon, TrashIcon24 } from 'assets/Icons'
import styles from './certList.module.scss'

const CertificationList = ({ value = [], onChange }) => {
    const initialCertifications = (value || []).map(cert => ({ id: uuidv4(), value: cert }))
    const [certifications, setCertifications] = useState(initialCertifications)

    const handleAddListItem = () => {
        setCertifications(prevList => [...prevList, { id: uuidv4(), value: '' }])
    }

    const handleChange = (id, newValue) => {
        setCertifications(prevList => {
            const updatedList = prevList.map(cert => (cert.id === id ? { ...cert, value: newValue } : cert))
            onChange(updatedList.map(cert => cert.value))
            return updatedList
        })
    }

    const handleRemoveListItem = id => {
        const newCertifications = certifications.filter(cert => cert.id !== id)
        setCertifications(newCertifications)

        const certificationValues = newCertifications.map(cert => cert.value)
        onChange(certificationValues)
    }

    return (
        <div className={styles.list}>
            {certifications.map(certification => (
                <div key={certification.id}>
                    <TextField
                        name={`certification-${certification.id}`}
                        val={certification.value}
                        onChange={newVal => handleChange(certification.id, newVal)}
                    />
                    <Button className={styles.btn} onClick={() => handleRemoveListItem(certification.id)}>
                        <TrashIcon24 />
                    </Button>
                </div>
            ))}

            <button type="button" className={styles.btn_add} onClick={handleAddListItem}>
                <PlusIcon />
            </button>
        </div>
    )
}

export default CertificationList
