import Title from 'components/Ui/Title'
import { v4 as uuidv4 } from 'uuid'
import { downloadFileFromS3 } from 'utils/utilS3Bucket'
import styles from 'assets/scss/detail.module.scss'
import { Button, Flex } from 'antd'

import { DownloadIcon24 } from 'assets/Icons'
import Value from 'components/Ui/Value'

const Attachment = ({ attachment, decription = '교육프로그램 첨부파일 목록' }) => {
    console.log(attachment)
    const handleDownload = item => {
        console.log(item)
        const cleanedFilePath = item.file_path.startsWith('/') ? item.file_path.substring(1) : item.file_path
        console.log(cleanedFilePath)
        downloadFileFromS3(cleanedFilePath, item.file_name)
            .then(blob => {
                console.log('File downloaded successfully:', blob)
            })
            .catch(error => {
                console.error('Error:', error)
            })
    }
    return (
        <div className={styles.box_lg}>
            <Title size="lg" title="첨부파일" />
            <div className={`${styles.basic_font} ${styles.mb}`}>{decription}</div>
            {attachment?.length > 0 ? (
                <div className={styles.border_style}>
                    {attachment?.map(item => (
                        <Flex
                            justify="space-between"
                            align="center"
                            key={uuidv4()}
                            className={`${styles.padding_x_medium} ${styles.row_item}`}
                        >
                            <Value value={item?.file_name} />
                            <Button onClick={() => handleDownload(item)} icon={<DownloadIcon24 />} />
                        </Flex>
                    ))}
                </div>
            ) : (
                <div
                    className={`${styles.text_gray} ${styles.basic_font}  ${styles.padding_x_medium} ${styles.padding_y_medium} ${styles.border_style}`}
                >
                    등록된 첨부파일이 없습니다.
                </div>
            )}
        </div>
    )
}

export default Attachment
