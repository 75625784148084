import FilterButton from 'components/AntD/Button/FilterButton'
import { CheckFilterIcon, FinishFilterBottomIcon, OffFilterIcon, OngoingFilterIcon } from 'assets/Icons'

import { DatePicker } from 'antd'
import styles from '../filter.module.scss'

const InventoryFilter = ({ queryParams, updateQueryStringAndNavigate, startDateRange, handleStartDateChange }) => {
    const programOperation = 'program_operation'
    const programTime = 'program_time'

    const currentProgramOperation = queryParams.get(programOperation) || ''
    const currentProgramTime = queryParams.get(programTime) || ''
    return (
        <>
            <span className={`body2 ${styles.title}`}>교구재 현황</span>
            <div className={styles.btn_wrap}>
                <FilterButton
                    icon={<OffFilterIcon />}
                    title="재고"
                    currentParamValue={currentProgramOperation}
                    onClick={() => updateQueryStringAndNavigate(programOperation, '재고')}
                    filterColor="yellow"
                />
                <FilterButton
                    icon={<OngoingFilterIcon />}
                    title="대여"
                    currentParamValue={currentProgramOperation}
                    onClick={() => updateQueryStringAndNavigate(programOperation, '대여')}
                    filterColor="blue"
                />
                <FilterButton
                    icon={<FinishFilterBottomIcon />}
                    title="폐기"
                    currentParamValue={currentProgramOperation}
                    onClick={() => updateQueryStringAndNavigate(programOperation, '폐기')}
                    filterColor="darkgray"
                />
            </div>
            <span className={`body2 ${styles.title}`}>교구재 현황</span>
            <div className={styles.btn_wrap}>
                <FilterButton
                    icon={<CheckFilterIcon />}
                    title="정상"
                    currentParamValue={currentProgramTime}
                    onClick={() => updateQueryStringAndNavigate(programTime, '정상')}
                    filterColor="black"
                />
                <FilterButton
                    icon={<CheckFilterIcon />}
                    title="불량"
                    currentParamValue={currentProgramTime}
                    onClick={() => updateQueryStringAndNavigate(programTime, '불량')}
                    filterColor="black"
                />
            </div>
            <span className={`body2 ${styles.title}`}>취득일</span>
            <div className={styles.btn_wrap}>
                <DatePicker
                    format="YYYY-MM-DD"
                    value={startDateRange}
                    onChange={handleStartDateChange}
                    placeholder="날짜선택"
                />
            </div>
        </>
    )
}

export default InventoryFilter
