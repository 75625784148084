import dayjs from 'dayjs'

import { buildSupabaseChainFilter, convertKeysToCamelCase, camelToSnake } from 'utils/utilCommon'
import { supabase } from '../axios.instance'

const table = 'demander_info'

const countSurveyDemander = async data => {
    const now = dayjs().format('YYYY-MM-DD')
    const counter = { totalCount: 0, scheduled: 0, progress: 0, complete: 0 }

    if (Array.isArray(data)) {
        counter.totalCount = data.length

        data.forEach(v => {
            const { start_date, end_date } = v
            const sDate = dayjs(start_date).format('YYYY-MM-DD')
            const eDate = dayjs(end_date).format('YYYY-MM-DD')

            if ((!start_date && !end_date) || (start_date && sDate > now)) {
                counter.scheduled += 1
            } else if (end_date && eDate < now) {
                counter.progress += 1
            } else {
                counter.complete += 1
            }
        })
    }

    return counter
}

/**
 * 수요처 목록
 * 수요조사 참여자
 * @param surveyUuId
 * @param search
 * @param path
 * @param isDetail
 * @returns {Promise<PostgrestFilterBuilder<*, *, GetResult<*, *, *, "*">[], *>|null>}
 */
export const getSurveyDemanderList = async (surveyUuId, search, path) => {
    try {
        // 페이지 정보 response
        const pageSize = 10
        const currentPage = Number(new URLSearchParams(search).get('page')) || 1
        // const paginationInfo = await calculatePaginationInfo(table, search, pageSize, undefined)
        // const paginationInfo = await getCommonPageInfo(search, table, 'all', pageSize)
        const paginationInfo = {
            currentPage,
            totalPages: 1,
            totalItems: 0,
            pageSize,
        }

        // eslint-disable-next-line prefer-const
        let { query, searchParams } = await buildSupabaseChainFilter(table, search, 'all', path)

        query = query.order('demander_id', { ascending: false })
        query = query.select().eq('survey_uuid', surveyUuId).eq('is_delete', false)

        // eslint-disable-next-line no-restricted-syntax
        for (const key of searchParams.keys()) {
            const snakeCaseKey = camelToSnake(key)
            const value = searchParams.get(key)

            switch (snakeCaseKey) {
                case 'is_meet':
                    query = query.eq(snakeCaseKey, value === '오프라인')
                    break
                case 'is_new':
                    query = query.eq(snakeCaseKey, value === '신규')
                    break
                case 'page':
                    break
                default:
                    query = query.eq(snakeCaseKey, value)
                    break
            }
        }

        const response = await query
        const totalList = response.data
        const offset = (currentPage - 1) * pageSize

        if (totalList) {
            const convertedData = convertKeysToCamelCase(totalList)
            const counter = await countSurveyDemander(totalList)
            const surveys = convertedData.slice(offset, offset + pageSize)

            paginationInfo.totalItems = counter.totalCount
            paginationInfo.totalPages = Math.ceil(counter.totalCount / pageSize)

            response.data = {
                surveys,
                page: paginationInfo,
                counter,
            }

            return response
        }

        return response
    } catch (error) {
        console.error(error)
        return null
    }
}

/**
 * 수요처 응답값 생성
 * @returns {Promise<PostgrestResponseSuccess<GetResult<any, any, R | unknown, "*">[]>|PostgrestResponseFailure|null>}
 * @param surveyResponse
 */
export const postSurveyDemander = async surveyResponse => {
    try {
        const response = await supabase.from(table).insert(surveyResponse).select()
        return response
    } catch (error) {
        console.error(error)
        return null
    }
}

/**
 * 수요처 응답 상세
 * surveyId
 * @returns {Promise<*|({error: true} & "Received a generic string")>}
 * @param demanderId
 */
export const getSurveyDemanderById = async demanderId => {
    try {
        const response = await supabase.from(table).select().eq('demander_id', demanderId)
        if (response.data && response.data.length > 0) {
            const modifiedCalmelData = convertKeysToCamelCase(response.data)
            return modifiedCalmelData[0]
        }
        return response
    } catch (error) {
        console.error(error)
        return error
    }
}

/**
 * 수요처 채택유무 수정
 * @param {*} demanderId
 * @returns
 */
export const patchSurveyDemanderById = async (demanderId, isSelected) => {
    const { data, error } = await supabase
        .from(table)
        .update({ is_selected: isSelected })
        .eq('demander_id', demanderId)
        .select()

    if (error) {
        console.error(error)
        // return error
    }

    return data
}
