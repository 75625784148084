import dayjs from 'dayjs'

import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'

import { Flex, Form, message } from 'antd'

import { getReportById, postReport, putReport } from 'api/report/report.api'
import { deleteReportIssueById, getReportIssueById, postReportIssue } from 'api/report/reportIssue.api'
import { getReportTotalById } from 'api/report/reportTotal.api'

import ColorButton from 'components/AntD/Button/ColorButton'
import Spinner from 'components/AntD/Spin'
import { convertUtcToKoreaTime, isPropertyEmptyArray } from 'utils/utilCommon'

import DateSection from 'pages/Report/Detail/DateSection'

import { useDispatch, useSelector } from 'react-redux'
import { getAllUserInfo } from 'store/Slices/user'
// import { convertArrayToObject } from 'utils/utilCommon'
// import { areAllFieldsEmpty } from 'utils/utilCommon'

import {
    getReportAccumulateAsync,
    getReportInfoAll,
    getReportTotalAsync,
    postReportTotalAsync,
} from 'store/Slices/report'

import MainContent from './MainContent'
import TableSection from './TableSection'
import Significant from './Significant'

import styles from './report.module.scss'

const monthFormat = 'YYYY-MM'
// const dateFormat = 'YYYY-MM-DD'

const today = dayjs().format('YYYY-MM-DD')

const ReportDetail = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { id } = useParams()
    const [form] = Form.useForm()
    const paths = location.pathname.split('/').filter(path => path !== '')
    const [messageApi, contextHolder] = message.useMessage()

    const { isLogin, userInfo } = useSelector(getAllUserInfo)
    const userRegionId = userInfo.regionId
    const { reportTotal, accumulatedAmount } = useSelector(getReportInfoAll) // 총사업비, 누적 집행금액

    const [report, setReport] = useState(null)
    const [isLoading, setLoading] = useState(true)

    const {
        organizationName,
        sysRegDtime,
        sysModDtime,
        projectName,
        overallResponsiblePerson,
        author,
        monthOfReport,
        dateOfReport,
        issue,
        monthlyBudgetExecution,
        planAndPerformanceInfo,
        // reportId,
        // totalProjectBudget,
        // accumulatedExpenditure,
        // totalExecutionRate,
    } = report || ''

    const sysCreatDate = dayjs(sysRegDtime).format('YYYY-MM-DD') // 작성일자
    const sysModDate = dayjs(sysModDtime).format('YYYY-MM-DD') // 수정일자

    const [createMonth, setCreateMonth] = useState('') // 작성월

    // 당월
    let convertedMM
    if (createMonth !== '') {
        convertedMM = dayjs(createMonth).format('MM')
    }

    // YYYY-DD로 변경
    const dateObject = dayjs(monthOfReport, { format: 'YYYY-MM' })
    const formattedString = dateObject.format('YYYY-MM')

    const errorMessage = status => {
        messageApi.open({
            type: 'error',
            content:
                status === 'essential'
                    ? '필수값을 입력해주세요.'
                    : status === 'bad'
                    ? '입력값을 확인해주세요.'
                    : status === 'login'
                    ? '로그인 세션이 만료되었습니다.'
                    : '',
            duration: 5,
        })
    }

    const successMessage = status => {
        messageApi.open({
            type: 'success',
            content:
                status === 'register'
                    ? '월간보고 등록이 완료되었습니다.'
                    : status === 'edit'
                    ? '월간보고 수정이 완료되었습니다.'
                    : '',
            duration: 5,
        })
    }

    /**
     * NaN, Infinity 여부 확인
     */
    const handleNaNResult = result => {
        if (Number.isNaN(result) || !Number.isFinite(result)) {
            return 0
        }
        return Number(result.toFixed(1))
    }

    // 숫자 데이터 변경 시 total 계산 함수
    const handleTotal = useMemo(
        () => (changedValues, allValues) => {
            const rowsCopy = { ...allValues }

            const isTotalBudgetChanged =
                changedValues.totalBudgetGovernmentFunds !== undefined ||
                changedValues.totalBudgetLocalCashFunds !== undefined ||
                changedValues.totalBudgetLocalInKindFunds !== undefined

            const isMonthlyBudgetChanged =
                changedValues.monthlyBudgetGovernmentFunds !== undefined ||
                changedValues.monthlyBudgetLocalInKindFunds !== undefined

            // const isExecutionRateChanged =
            //     changedValues.totalExecutionRateCash !== undefined ||
            //     changedValues.totalExecutionRateInKind !== undefined

            // 총 사업비 변경 시
            if (isTotalBudgetChanged) {
                // 총 사업비 total 계산
                rowsCopy.totalBudgetTotal =
                    Number(allValues.totalBudgetGovernmentFunds || 0) +
                    Number(allValues.totalBudgetLocalCashFunds || 0) +
                    Number(allValues.totalBudgetLocalInKindFunds || 0)

                // 하단 비율 영역 모두 수정
                const monthlyPlanRatioResult =
                    (Number(allValues.monthlyPlanExpenditureAmount) / Number(rowsCopy.totalBudgetTotal)) * 100
                const monthlyPerformanceRatioResult =
                    (Number(allValues.monthlyPerformanceExpenditureAmount) / Number(rowsCopy.totalBudgetTotal)) * 100
                const nextMonthPlanRatioResult =
                    (Number(allValues.nextMonthPlanExpenditureAmount) / Number(rowsCopy.totalBudgetTotal)) * 100

                rowsCopy.monthlyPlanRatio = handleNaNResult(monthlyPlanRatioResult)
                rowsCopy.monthlyPerformanceRatio = handleNaNResult(monthlyPerformanceRatioResult)
                rowsCopy.nextMonthPlanRatio = handleNaNResult(nextMonthPlanRatioResult)
                form.setFieldsValue(rowsCopy)
            }

            // 당월 예산 변경 시
            if (isMonthlyBudgetChanged) {
                const combinedCash =
                    Number(allValues.monthlyBudgetGovernmentFunds ?? 0) +
                    Number(accumulatedAmount?.executionGovernmentFunds ?? 0)

                const combinedFunds =
                    Number(allValues.monthlyBudgetLocalInKindFunds ?? 0) +
                    Number(accumulatedAmount?.executionLocalCashFunds ?? 0)
                // 당월 예산집행 total 계산
                rowsCopy.monthlyBudgetTotal =
                    Number(allValues.monthlyBudgetGovernmentFunds ?? 0) +
                    Number(allValues.monthlyBudgetLocalInKindFunds ?? 0)

                // 총집행률 현금 계산
                rowsCopy.totalExecutionRateCash = combinedCash
                // 총집행률 현물 계산
                rowsCopy.totalExecutionRateInKind = combinedFunds
                // 총집행률 total
                rowsCopy.totalExecutionRateTotal = combinedCash + combinedFunds
                form.setFieldsValue(rowsCopy)
            }

            // if (isExecutionRateChanged) {
            //     // 총 집행률 total 계산
            //     rowsCopy.totalExecutionRateTotal =
            //         Number(allValues.totalExecutionRateCash || 0) + Number(allValues.totalExecutionRateInKind || 0)
            //     // 업데이트 필드
            //     form.setFieldsValue(rowsCopy)
            // }

            // 당월 계획 집행금액 변경 시 비율 동적 변경
            if (changedValues.monthlyPlanExpenditureAmount !== undefined) {
                const monthlyPlanRatioResult =
                    (Number(allValues.monthlyPlanExpenditureAmount) / Number(allValues.totalBudgetTotal)) * 100
                rowsCopy.monthlyPlanRatio = handleNaNResult(monthlyPlanRatioResult)
                form.setFieldsValue(rowsCopy)
            }

            // 당월 실적 집행금액 변경 시 비율 동적 변경
            if (changedValues.monthlyPerformanceExpenditureAmount !== undefined) {
                const monthlyPerformanceRatioResult =
                    (Number(allValues.monthlyPerformanceExpenditureAmount) / Number(allValues.totalBudgetTotal)) * 100
                rowsCopy.monthlyPerformanceRatio = handleNaNResult(monthlyPerformanceRatioResult)
                form.setFieldsValue(rowsCopy)
            }

            // 당월 실정 집행금액 변경 시 비율 동적 변경
            if (changedValues.nextMonthPlanExpenditureAmount !== undefined) {
                const nextMonthPlanRatioResult =
                    (Number(allValues.nextMonthPlanExpenditureAmount) / Number(allValues.totalBudgetTotal)) * 100
                rowsCopy.nextMonthPlanRatio = handleNaNResult(nextMonthPlanRatioResult)
                form.setFieldsValue(rowsCopy)
            }
            // 업데이트 form 필드 <--- 주석 풀면 특이사항 입력 시 엄청 끊기게 됨 주의 ****
            // form.setFieldsValue(rowsCopy)
        },
        [form, accumulatedAmount],
    )

    const areAllPropertiesEmpty = obj => {
        return (
            isPropertyEmptyArray(obj, 'issue_main_points') &&
            isPropertyEmptyArray(obj, 'issue_action_taken') &&
            isPropertyEmptyArray(obj, 'issue_occurrence_date') &&
            isPropertyEmptyArray(obj, 'issue_solution')
        )
    }

    /**
     * 특이사항 API 등록 및 수정 호출 값 세팅
     * @param values
     * @param id
     * @param today
     * @param userInfo
     * @returns {null}
     */
    const setModifiedIssue = (values, id, today, userInfo) => {
        let modifiedIssue = null

        if (values.issue && values.issue.length > 0) {
            if (!areAllPropertiesEmpty(values)) {
                modifiedIssue = {
                    report_id: id,
                    is_delete: false,
                    sys_reg_dtime: today,
                    sys_regr_id: userInfo.userId,
                    sys_mod_dtime: null,
                    sys_modr_id: null,
                    issue_main_points: [],
                    issue_action_taken: [],
                    issue_occurrence_date: [],
                    issue_solution: [],
                }

                values.issue.forEach(item => {
                    if (item.mainPoints) {
                        modifiedIssue.issue_main_points.push(item.mainPoints)
                    }
                    if (item.actionTaken) {
                        modifiedIssue.issue_action_taken.push(item.actionTaken)
                    }
                    if (item.occurrenceDate) {
                        modifiedIssue.issue_occurrence_date.push(item.occurrenceDate)
                    }
                    if (item.solution) {
                        modifiedIssue.issue_solution.push(item.solution)
                    }
                })
            }
        }

        return modifiedIssue
    }

    /**
     * submit 제출하여 성공 후 처리
     * 모든 value 확인 가능
     * @param values
     */
    const onFinish = async values => {
        const today = dayjs().format('YYYY-MM-DD HH:mm:ss.SSSZ')
        // // 특이사항 값 모두 비어있는지 확인
        // const isIssueEmpty = areAllFieldsEmpty(values.issues)
        // console.log('isIssueEmpty️: ', isIssueEmpty)

        // 특이사항 API 등록 및 수정 호출 값 세팅
        const modifiedIssue = setModifiedIssue(values, id, today, userInfo)
        // 한국 시간으로 변경
        const createMonthFormatedKorea = convertUtcToKoreaTime(values.monthOfReport)
        const creatYear = Number(dayjs(createMonthFormatedKorea).format('YYYY'))

        const reportData = {
            region_id: userInfo.regionId,
            is_delete: false,
            // sys_reg_dtime: values.createDate,
            // sys_regr_id: userInfo.user_id,
            sys_mod_dtime: null,
            sys_modr_id: null,
            organization_name: values.organizationName,
            // project_name: values.projectName,
            project_name: `${creatYear}년 SW미래채움`,
            month_of_report: createMonthFormatedKorea,
            date_of_report: values.createDate,
            author: values.author,
            overall_responsible_person: values.overallResponsiblePerson,
            total_project_budget_government_funds: values.totalBudgetGovernmentFunds,
            total_project_budget_local_cash_funds: values.totalBudgetLocalCashFunds,
            total_project_budget_localin_kind_funds: values.totalBudgetLocalInKindFunds,
            total_project_budget_total: values.totalBudgetTotal,
            monthly_budget_execution_government_funds: values.monthlyBudgetGovernmentFunds,
            monthly_budget_execution_local_cash_funds: values.monthlyBudgetLocalInKindFunds,
            monthly_budget_execution_total: values.monthlyBudgetTotal,
            accumulated_expenditure_cash: values.accumulatedCash,
            accumulated_expenditure_in_kind: values.accumulatedInkind,
            accumulated_expenditure_total: values.accumulatedTotal,
            total_execution_rate_cash: values.totalExecutionRateCash,
            total_execution_rate_in_kind: values.totalExecutionRateInKind,
            total_execution_rate_total: values.totalExecutionRateTotal,
            other_business: values.nextMonthPlanOtherBusiness || '',
            suspense: values.nextMonthPlanSuspense || '',
            monthly_plan_details: values.monthlyPlanDetails,
            monthly_plan_expenditure_amount: values.monthlyPlanExpenditureAmount,
            monthly_plan_ratio: values.monthlyPlanRatio,
            monthly_performance_details: values.monthlyPerformanceDetails,
            monthly_performance_expenditure_amount: values.monthlyPerformanceExpenditureAmount,
            monthly_performance_ratio: values.monthlyPerformanceRatio,
            next_month_plan_details: values.nextMonthPlanDetails,
            next_month_plan_expenditure_amount: values.nextMonthPlanExpenditureAmount,
            next_month_plan_ratio: values.nextMonthPlanRatio,
            monthly_event_details: values.monthlyPerformanceEventDetails, //행사관리 추가
            cumulative_performance_number: values.monthlyPerformanceCumulativePerformanceNumber, // 실적 누적인원 추가
            cumulative_month_number: values.monthlyPerformanceCumulativeMonthNumber, // 실적 당월인원 추가
            cumulative_performance_details: values.monthlyPerformanceCumulativePerformanceDetails, // 실적 누적내용 추가
            cumulative_month_details: values.monthlyPerformanceCumulativeMonthDetails, // 실적 당월내용 추가
            performance_details: values.monthlyPerformancePerformanceDetails, // 세부 누적내용 추가
            performance_month_details: values.monthlyPerformancePerformanceMonthDetails, // 세부 당월내용 추가
            // issue: convertedIssues,
        }

        const budgetTotal = {
            region_id: userInfo.regionId,
            year_of_report: creatYear,
            government_funds: values.totalBudgetGovernmentFunds,
            local_cash_funds: values.totalBudgetLocalCashFunds,
            localin_kind_funds: values.totalBudgetLocalInKindFunds,
            total: Number(values.totalBudgetTotal),
        }

        try {
            if (paths[1] === 'register') {
                // reportData.sys_reg_dtime = values.createDate // 작성일자 변경가능
                reportData.sys_reg_dtime = today // 작성일자 (현재)
                reportData.sys_regr_id = userInfo.user_id
                const response = await postReport(reportData)
                if (response.status === 201) {
                    const { data } = response
                    const registedReportId = data[0].report_id // 등록한 월간보고 id
                    // 해당 지역 총사업비 Id값 확인
                    const totalByIdResponse = await getReportTotalById(userInfo.regionId, creatYear)
                    // 해당 지역 총사업비 Id 있으면 삽입
                    if (totalByIdResponse.data && totalByIdResponse.data.length > 0) {
                        const totalId = totalByIdResponse.data[0].report_total_id
                        budgetTotal.report_total_id = totalId // report_total_id가 있으면 수정, 없으면 등록 (시퀀스 추가)
                    }
                    budgetTotal.sys_reg_dtime = today
                    budgetTotal.sys_regr_id = userInfo.regionId
                    budgetTotal.sys_mod_dtime = null
                    budgetTotal.sys_modr_id = null

                    // 총사업비 upsert (등록 및 수정)
                    const reportTotalResponse = await dispatch(postReportTotalAsync({ budgetTotal }))
                    console.log('reportTotalResponse : ', reportTotalResponse)

                    // 특이사항 없으면 특이사항 API에 데이터 추가
                    if (modifiedIssue !== null) {
                        modifiedIssue.report_id = response.data[0].report_id
                        const issueResponse = await postReportIssue(modifiedIssue)
                        console.log('issueResponse : ', issueResponse)

                        successMessage('register')
                        navigate(`/report/${registedReportId}`)
                    } else {
                        successMessage('register')
                        navigate(`/report/${registedReportId}`)
                    }
                } else {
                    errorMessage('bad')
                }
                // if (response.error.message === '로그인 해 주세요.') {
                //     errorMessage('login')
                //     navigate('/')
                // } else if (response.code === 'ERR_BAD_REQUEST') {
                //     errorMessage('bad')
                // }
                // return response
            }
            if (paths[1] === 'edit') {
                reportData.sys_mod_dtime = today
                reportData.sys_modr_id = userInfo.userId
                const response = await putReport(reportData, id)
                if (response.status === 200) {
                    // 해당 지역 총사업비 Id값 확인
                    const totalByIdResponse = await getReportTotalById(userInfo.regionId, creatYear)

                    // 해당 지역 총사업비 Id 있으면 삽입
                    if (totalByIdResponse.status === 200) {
                        if (totalByIdResponse.data && totalByIdResponse.data.length > 0) {
                            const totalId = totalByIdResponse.data[0].report_total_id
                            budgetTotal.report_total_id = totalId
                        }
                        budgetTotal.sys_mod_dtime = today
                        budgetTotal.sys_modr_id = userInfo.regionId
                        const reportTotalResponse = await dispatch(postReportTotalAsync({ budgetTotal }))
                        console.log('reportTotalResponse : ', reportTotalResponse)
                    }

                    // 월간보고 상세 id에 포함된 특이사항이 있는지 확인 (report_issue_id)
                    const getReportIssueIdResponse = await getReportIssueById(id)
                    console.log('getReportIssueIdResponse : ', getReportIssueIdResponse)

                    const reportIssueIdArray =
                        getReportIssueIdResponse.status === 200
                            ? getReportIssueIdResponse.data && getReportIssueIdResponse.data.length > 0
                                ? getReportIssueIdResponse.data
                                : null
                            : null

                    const report_issue_id = reportIssueIdArray ? reportIssueIdArray[0].report_issue_id : null

                    if (modifiedIssue) {
                        // report_issue_id 있으면 수정 없으면 등록
                        if (report_issue_id) {
                            modifiedIssue.report_issue_id = report_issue_id
                        }
                        // 특이사항 배열 등록 및 수정
                        const issueResponse = await postReportIssue(modifiedIssue)
                        if (issueResponse.status === 201) {
                            successMessage('edit')
                            navigate(`/report/${id}`)
                        } else if (issueResponse.status === 200) {
                            successMessage('edit')
                            navigate(`/report/${id}`)
                        } else {
                            errorMessage('bad')
                        }
                    } else if (report_issue_id) {
                        // 현재 특이사항이 있는데 특이사항을 모두 삭제하는 경우
                        const deleteResponse = await deleteReportIssueById(id)
                        if (deleteResponse.status === 204) {
                            successMessage('edit')
                            navigate(`/report/${id}`)
                        } else {
                            errorMessage('bad')
                        }
                    } else {
                        successMessage('edit')
                        navigate(`/report/${id}`)
                    }
                } else {
                    errorMessage('bad')
                }
                // // TODO: 에러 분기처리
                // if (response.error.message === '로그인 해 주세요.') {
                //     errorMessage('login')
                //     navigate('/')
                // } else if (response.code === 'ERR_BAD_REQUEST') {
                //     errorMessage('bad')
                // }
                // return response
            }
        } catch (e) {
            console.log('error : ', e)
        }
        return ''
    }

    /**
     * submit 제출하여 실패 후 처리
     * 모든 value 확인 가능
     * @param errorInfo
     */
    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo)
        const { errorFields } = errorInfo
        if (errorFields && errorFields.length > 1) {
            errorMessage('essential')
        }
    }

    /* *
     * 해당 지역 당해년도
     * 누적 사업비
     * @returns {Promise<void>}
     */
    const handlerGetReportAccumulate = async (month, regionId) => {
        await dispatch(getReportAccumulateAsync({ month, regionId }))
    }

    /**
     * 월간보고 총사업비
     * @returns {Promise<void>}
     */
    const handleGetReportTotal = async (year, regionId) => {
        await dispatch(getReportTotalAsync({ year, regionId }))
    }

    // 등록, 수정, 삭제 공통
    const fetchReportDetail = async () => {
        if (paths[1] !== 'register') {
            try {
                setLoading(true)
                const response = await getReportById(id, userInfo.regionId)
                const regionIds = response.data.result[0].regionId
                if (response.status === 200) {
                    const reportData = response.data.result[0]
                    const selectedMonth = reportData.monthOfReport
                    const year = dayjs(selectedMonth).format('YYYY')
                    await handleGetReportTotal(year, regionIds) // 총 사업비
                    await handlerGetReportAccumulate(selectedMonth, regionIds) // 총 누적금액
                    setReport(reportData)
                    setLoading(false)
                    return true
                }
                setLoading(false)
                navigate('/')
                return false
            } catch (error) {
                setLoading(false)
                console.error('월간보고를 불러오는 동안 오류가 발생했습니다.', error)
                return null
            }
        } else {
            const year = dayjs(convertedMM).format('YYYY')
            await handleGetReportTotal(year, userRegionId) // 총 사업비
            await handlerGetReportAccumulate(convertedMM, userRegionId) // 총 누적금액

            setLoading(false)
            return true
        }
    }

    // 작성 월 변경 시
    // 총 사업비 API 변경
    const onChangeDatePicker = async date => {
        if (date) {
            const koreaTime = convertUtcToKoreaTime(date.toISOString())

            setCreateMonth(koreaTime)
            const parsedDate = dayjs(koreaTime).locale('ko')
            const year = parsedDate.format('YYYY')
            // TODO: 당해연도와 동일하면 호출하지 않게 변경
            // // 총사업비 API
            await handleGetReportTotal(year, userRegionId)
            // // 총 누적금액
            await handlerGetReportAccumulate(koreaTime, userRegionId)
        }
    }

    const convertIssue = issueItem => {
        return Array.from({ length: issueItem.mainPoints.length }, (_, index) => ({
            reportIssueId: issueItem.reportIssueId,
            reportId: issueItem.reportId,
            mainPoints: issueItem.mainPoints[index],
            solution: issueItem.solution[index],
            occurrenceDate: dayjs(issueItem.occurrenceDate[index]),
            actionTaken: issueItem.actionTaken[index],
            isDelete: issueItem.isDelete,
        }))
    }

    /**
     * 등록 페이지에서 입력 value 초기값 세팅
     * @param form
     * @param issue
     */

    const setFormValues = issue => {
        const cash =
            Number(monthlyBudgetExecution?.governmentFunds) + Number(accumulatedAmount?.executionGovernmentFunds)
        const funds =
            Number(monthlyBudgetExecution?.localCashFunds) + Number(accumulatedAmount?.executionLocalCashFunds)
        const total = cash + funds

        form.setFieldsValue({
            organizationName,
            projectName,
            overallResponsiblePerson,
            author,
            dateOfReport,
            issue: issue || null,
            // TODO: 사용하지 않는 월간보고 DB 테이블 제거 (supabase)
            // totalBudgetGovernmentFunds: totalProjectBudget?.governmentFunds,
            // totalBudgetLocalCashFunds: totalProjectBudget?.localCashFunds,
            // totalBudgetLocalInKindFunds: totalProjectBudget?.localInKindFunds,
            // totalBudgetTotal: totalProjectBudget?.total,

            // accumulatedCash: accumulatedExpenditure?.cash,
            // accumulatedInkind: accumulatedExpenditure?.inKind,
            // accumulatedTotal: accumulatedExpenditure?.total,

            // totalExecutionRateCash: totalExecutionRate?.cash,
            // totalExecutionRateInKind: totalExecutionRate?.inKind,
            // totalExecutionRateTotal: totalExecutionRate?.total,
            totalExecutionRateCash: cash,
            totalExecutionRateInKind: funds,
            totalExecutionRateTotal: total,
            totalBudgetGovernmentFunds: reportTotal?.government_funds,
            totalBudgetLocalCashFunds: reportTotal?.local_cash_funds,
            totalBudgetLocalInKindFunds: reportTotal?.localin_kind_funds,
            totalBudgetTotal: reportTotal?.total,
            monthlyBudgetGovernmentFunds: monthlyBudgetExecution?.governmentFunds,
            monthlyBudgetLocalInKindFunds: monthlyBudgetExecution?.localCashFunds,
            monthlyPlanDetails: planAndPerformanceInfo?.monthlyPlan?.details,
            monthlyPlanExpenditureAmount: planAndPerformanceInfo?.monthlyPlan?.expenditureAmount,
            monthlyPlanRatio: planAndPerformanceInfo?.monthlyPlan.ratio,
            monthlyBudgetTotal: monthlyBudgetExecution?.total,
            monthlyPerformanceDetails: planAndPerformanceInfo?.monthlyPerformance?.details,
            monthlyPerformanceExpenditureAmount: planAndPerformanceInfo?.monthlyPerformance?.expenditureAmount,
            monthlyPerformanceRatio: planAndPerformanceInfo?.monthlyPerformance?.ratio,
            monthlyPerformanceEventDetails: planAndPerformanceInfo?.monthlyPerformance?.eventDetails,
            monthlyPerformanceCumulativeMonthDetails:
                planAndPerformanceInfo?.monthlyPerformance?.cumulativeMonthDetails,
            monthlyPerformanceCumulativeMonthNumber: planAndPerformanceInfo?.monthlyPerformance?.cumulativeMonthNumber,
            monthlyPerformanceCumulativePerformanceDetails:
                planAndPerformanceInfo?.monthlyPerformance?.cumulativePerformanceDetails,
            monthlyPerformanceCumulativePerformanceNumber:
                planAndPerformanceInfo?.monthlyPerformance?.cumulativePerformanceNumber,
            monthlyPerformancePerformanceDetails: planAndPerformanceInfo?.monthlyPerformance?.performanceDetails,
            monthlyPerformancePerformanceMonthDetails:
                planAndPerformanceInfo?.monthlyPerformance?.performanceMonthDetails,
            nextMonthPlanDetails: planAndPerformanceInfo?.nextMonthPlane.details,
            nextMonthPlanExpenditureAmount: planAndPerformanceInfo?.nextMonthPlane?.expenditureAmount,
            nextMonthPlanRatio: planAndPerformanceInfo?.nextMonthPlane?.ratio,
            nextMonthPlanOtherBusiness: planAndPerformanceInfo?.nextMonthPlane?.otherBusiness,
            nextMonthPlanSuspense: planAndPerformanceInfo?.nextMonthPlane?.suspense,
        })
    }

    // 작성 월 초기 세팅
    useEffect(() => {
        if (monthOfReport) {
            setCreateMonth(monthOfReport)
        } else {
            setCreateMonth(dayjs())
        }
    }, [monthOfReport])

    // 공통 함수 호출
    useEffect(() => {
        if (isLogin && !userInfo.isServiceManager) {
            fetchReportDetail(id)
        } else {
            navigate('/')
        }
    }, [id, location.pathname])

    useEffect(() => {
        // 수정 페이지 이동 시 값 input 값세팅
        if (paths[1] === 'edit') {
            //issue 값 세팅
            const convertedIssue = issue ? convertIssue(issue) : null
            setFormValues(convertedIssue)
        }
        if (paths[1] === 'register') {
            // 총 집행률 default 세팅
            if (accumulatedAmount) {
                form.setFieldsValue({
                    totalExecutionRateCash: accumulatedAmount.executionGovernmentFunds,
                    totalExecutionRateInKind: accumulatedAmount.executionLocalCashFunds,
                    totalExecutionRateTotal: accumulatedAmount.executionTotal,
                })
            }

            // 등록 페이지에 특이사항 1개 default 세팅
            if (!issue) {
                form.setFieldsValue({
                    issue: [
                        {
                            mainPoints: '',
                            solution: '',
                            actionTaken: '',
                        },
                    ],
                })
            }
        }
    }, [location, report, accumulatedAmount])

    // 등록 & 수정 페이지 & 작성월일 변경
    // 총사업비 초기 입력값 세팅
    useEffect(() => {
        if (paths[1] === 'register' || paths[1] === 'edit') {
            if (reportTotal) {
                form.setFieldsValue({
                    totalBudgetGovernmentFunds: reportTotal?.government_funds || 0,
                    totalBudgetLocalCashFunds: reportTotal?.local_cash_funds || 0,
                    totalBudgetLocalInKindFunds: reportTotal?.localin_kind_funds || 0,
                    totalBudgetTotal: reportTotal?.total || 0,
                })
            } else {
                form.setFieldsValue({
                    totalBudgetGovernmentFunds: 0,
                    totalBudgetLocalCashFunds: 0,
                    totalBudgetLocalInKindFunds: 0,
                    totalBudgetTotal: 0,
                })
            }
        }
    }, [reportTotal])

    return (
        <div className={styles.container}>
            {contextHolder}
            <Form
                name="report"
                form={form}
                onValuesChange={handleTotal}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className="h5">월간보고</div>
                {isLoading ? (
                    <Spinner />
                ) : (
                    <>
                        <DateSection
                            formattedString={formattedString}
                            monthFormat={monthFormat}
                            sysModDate={sysModDate}
                            today={today}
                            onChangeDatePicker={onChangeDatePicker}
                            sysCreatDate={sysCreatDate}
                            sysModDtime={sysModDtime}
                        />
                        {/* 주요 내용 */}
                        <MainContent
                            createDate={sysRegDtime}
                            organizationName={organizationName}
                            projectName={projectName}
                            overallResponsiblePerson={overallResponsiblePerson}
                            author={author}
                            paths={paths}
                            dateOfReport={dateOfReport}
                            createMonth={createMonth}
                            // monthFormat={monthFormat}
                            // dateFormat={dateFormat}
                            // dateOfReport={dateOfReport}
                        />
                        <TableSection
                            currentMonth={convertedMM}
                            createMonth={createMonth}
                            report={report}
                            reportTotal={reportTotal}
                            executionData={accumulatedAmount}
                        />
                        {/* 사업 수행관련 이슈 및 특이사항 */}
                        <Significant issue={issue} />
                        <Flex justify="center" align="center" gap={8}>
                            {paths[1] === 'register' ? (
                                <>
                                    <ColorButton size="large" type="primary" htmlType="submit">
                                        등록
                                    </ColorButton>
                                    <ColorButton size="large" onClick={() => navigate(`/report`)}>
                                        취소
                                    </ColorButton>
                                </>
                            ) : paths[1] === 'edit' ? (
                                <>
                                    <ColorButton size="large" type="primary" htmlType="submit">
                                        저장
                                    </ColorButton>
                                    <ColorButton size="large" onClick={() => navigate(`/report/${id}`)}>
                                        취소
                                    </ColorButton>
                                </>
                            ) : (
                                <>
                                    {userInfo && userInfo.isMaster === false && (
                                        <Link to={`/report/edit/${id}`}>
                                            <ColorButton size="large" type="primary">
                                                수정
                                            </ColorButton>
                                        </Link>
                                    )}
                                    <Link to="/report">
                                        <ColorButton size="large">목록</ColorButton>
                                    </Link>
                                </>
                            )}
                        </Flex>
                    </>
                )}
            </Form>
        </div>
    )
}

export default ReportDetail
