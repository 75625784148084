import PageBanner from 'layout/PageBanner'
import Register from 'components/Register/Bulk'

const TutorRegisterBulk = () => {
    return (
        <PageBanner title="강사정보 등록">
            <Register />
        </PageBanner>
    )
}

export default TutorRegisterBulk
