import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { getProgramList } from 'api/program/program.api'
import { getAllUserInfo, logoutUserAsync } from 'store/Slices/user'

import { message } from 'antd'
import Spinner from 'components/AntD/Spin'
import MainList from 'components/List/MainList'

import { getLastMonthDates } from 'utils/utilCommon'

import styles from './main.module.scss'

const Main = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { isLogin, userInfo } = useSelector(getAllUserInfo)
    const { pathname } = useLocation()
    const path = pathname.split('/')[1]

    const [lastMonthProgramList, setLastMonthProgramList] = useState(null)
    const [progressPrograms, setProgressPrograms] = useState(null)

    const [isLoading, setLoading] = useState(true)

    const [lastMonthStartDate, lastMonthEndDate] = getLastMonthDates()

    const completedLastMonth = `startDate=${lastMonthStartDate}&endDate=${lastMonthEndDate}&status=완료`
    const progressProgramList = 'status=진행'

    const [messageApi, contextHolder] = message.useMessage()
    const errorMessage = () => {
        messageApi.open({
            type: 'error',
            content: `로그인 세션이 만료되었습니다.`,
            duration: 5,
        })
    }

    const fetchProgramList = async (search, setList) => {
        try {
            const data = await getProgramList(search, userInfo.regionId, path)
            if (data.status === 200 || data.status === 206) {
                setList(data.data)
                setLoading(false)
                return true
            }
            errorMessage()
            dispatch(logoutUserAsync())
            console.error('프로그램 목록을 불러오는 데 문제가 발생했습니다.')
            return false
        } catch (error) {
            console.error('프로그램 목록을 불러오는 동안 오류가 발생했습니다.', error)
            return null
        }
    }

    useEffect(() => {
        if (isLogin) {
            Promise.all([
                fetchProgramList(completedLastMonth, setLastMonthProgramList),
                fetchProgramList(progressProgramList, setProgressPrograms),
            ])
                .then(() => {
                    setLoading(false)
                })
                .catch(error => {
                    console.error('An error occurred:', error)
                    setLoading(false)
                })
        } else {
            navigate('/login')
        }
    }, [isLogin])

    if (isLoading) {
        return (
            <div className={styles.container}>
                <div className={styles.wrap}>
                    <Spinner />
                </div>
            </div>
        )
    }

    return (
        <div className={styles.container}>
            {contextHolder}
            <div className={styles.banner} />
            <div className={styles.card}>
                <MainList
                    postList={lastMonthProgramList}
                    title="지난달 완료 교육 프로그램 목록"
                    link={`/program?${completedLastMonth}`}
                />
                <MainList
                    postList={progressPrograms}
                    title="진행중 교육 프로그램 목록"
                    link={`/program?${progressProgramList}`}
                    right
                />
            </div>
        </div>
    )
}

export default Main
