import React, { useState } from 'react'
import styles from './selectui.module.scss'

const SIZE_STYLES = {
    sm: styles.width_sm,
    md: styles.width_md,
    lg: styles.width_lg,
    xl: styles.width_xl,
}

/**
 * @param name
 * @param options
 * @param value
 * @param size
 * @param onChange
 * @param disabled
 * @param isEssential 필수값 : value값이 항상 존재해야 하는 경우
 * @returns {JSX.Element}
 * @constructor
 */
const SelectUi = ({ name, options, value, size = 'md', onChange, disabled, isEssential }) => {
    const [selectValue, setSelectValue] = useState(value)
    const handleClick = option => {
        if (!disabled && onChange) {
            if (isEssential) {
                const newSelectValue = selectValue === option ? '' : option
                if (newSelectValue !== '') {
                    setSelectValue(newSelectValue)
                    if (onChange) {
                        onChange(newSelectValue)
                    }
                }
            } else {
                const newSelectValue = selectValue === option ? '' : option
                setSelectValue(newSelectValue)
                if (onChange) {
                    onChange(newSelectValue)
                }
            }
        }
    }

    /**
     * 실제 값과 옵션값이 다를 때 분기처리
     * @param opt
     * @returns {*|string}
     */
    const handleActiveOption = opt => {
        if (selectValue === opt) {
            return styles.selected
        }
        if (selectValue === '경단여' && opt === '경력단절여성') {
            return styles.selected
        }
        if (selectValue === 'Y' && opt === '고급강사') {
            return styles.selected
        }
        if (selectValue === 'N' && opt === '일반강사') {
            return styles.selected
        }
        if (selectValue === '협동조합' && opt === '협동조합채용') {
            return styles.selected
        }
        return ''
    }

    return (
        <div className={`${styles.container} ${disabled ? styles.disabled : ''} ${SIZE_STYLES[size] || ''}`}>
            {!options ? (
                <span className={styles.no_value}>해당없음</span>
            ) : (
                options.map(option => {
                    return (
                        <button
                            name={name}
                            disabled={disabled}
                            type="button"
                            key={option}
                            className={`${styles.option} ${handleActiveOption(option)}`}
                            onClick={() => handleClick(option)}
                        >
                            {option}
                        </button>
                    )
                })
            )}
        </div>
    )
}

export default SelectUi
