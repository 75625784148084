import { supabase } from '../axios.instance'

const table = 'program_attachment' // 교육 첨부파일

/**
 * 실제 첨부파일 삭제
 * @param programId
 * @returns {Promise<PostgrestResponseSuccess<GetResult<any, any, R | unknown, "*">[]>|PostgrestResponseFailure|null>}
 */
export const deleteProgramAttachmentById = async programId => {
    try {
        const response = await supabase.from(table).delete().eq('program_id', programId).select()
        return response
    } catch (error) {
        console.error(error)
        return null
    }
}

/**
 * 첨부파일 수정
 * @param reportData
 * @returns {Promise<axios.AxiosResponse<any>|*>}
 */
export const upsertProgramAttachment = async programAttachmentList => {
    console.log(programAttachmentList)
    try {
        const response = await supabase.from(table).upsert(programAttachmentList).select()
        return response
    } catch (error) {
        console.error(error)
        return error
    }
}
