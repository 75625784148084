import PageBanner from 'layout/PageBanner'

import Register from 'components/Register/Bulk'

const ProgramRegisterBulk = () => {
    return (
        <PageBanner title="교육계획 등록">
            <Register />
        </PageBanner>
    )
}

export default ProgramRegisterBulk
