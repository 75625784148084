import { Row, Col, Flex } from 'antd'
import HorizontalIconCard from 'components/AntD/Card/HorizontalIconCard'
import Title from 'components/Ui/Title'
import { ColorCalenderIcon, ColorContactIcon, ColorLicenseIcon } from 'assets/Icons'

import styles from 'assets/scss/detail.module.scss'

const AttendencesDetail = ({ program_operation_info, decription = '수정페이지에서 출석부를 등록할 수 있습니다.' }) => {
    return (
        <div>
            <Title size="lg" title="출석부" />
            <Flex justify="space-between" align="center" className={`${styles.basic_font} ${styles.mb}`}>
                {decription}
            </Flex>
            <Row gutter={26}>
                <Col>
                    <HorizontalIconCard
                        icon={<ColorCalenderIcon />}
                        value={program_operation_info?.classHours}
                        label="총 교시"
                        addonAfter="교시"
                    />
                </Col>
                <Col>
                    <HorizontalIconCard
                        icon={<ColorContactIcon />}
                        value={program_operation_info?.numberOfApplicants}
                        label="수강인원"
                        addonAfter="명"
                    />
                </Col>
                <Col>
                    <HorizontalIconCard
                        icon={<ColorLicenseIcon />}
                        value={program_operation_info?.numberOfGraduates}
                        label="수료인원 (80% 이상)"
                        addonAfter="명"
                    />
                </Col>
            </Row>
        </div>
    )
}

export default AttendencesDetail
