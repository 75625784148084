import { useLocation } from 'react-router-dom'
import { Flex } from 'antd'
import { useSelector } from 'react-redux'
import { getUser } from 'store/Slices/user'

import PaginationComponent from 'components/AntD/Pagination'
import ColorButton from 'components/AntD/Button/ColorButton'

import styles from './pagenation.module.scss'

const Pagenation = ({
    updateFtn,
    currentPage,
    totalItems,
    totalPages,
    navigateBtn,
    title,
    subTitle,
    subNavigateBtn,
    isSubMaster,
}) => {
    const { pathname } = useLocation()
    const paths = pathname.split('/').filter(path => path !== '')
    const { isMaster } = useSelector(getUser)

    return (
        <div className={`${styles.container} ${paths[1] && paths[0] === 'survey' ? styles.margin_b48 : ''}`}>
            <Flex justify="center">
                <PaginationComponent
                    updateQueryStringAndNavigate={updateFtn}
                    current_page={currentPage}
                    total_items={totalItems}
                    total_pages={totalPages}
                />
            </Flex>
            {!isMaster && title && (
                <div
                    className={`${styles.register_btn} ${
                        paths[0] === 'survey' || paths[0] === 'report' ? styles.margin_r64 : ''
                    }`}
                >
                    <ColorButton type="primary" onClick={navigateBtn}>
                        {title}
                    </ColorButton>
                    {subTitle && (
                        <ColorButton type="primary" onClick={subNavigateBtn}>
                            {subTitle}
                        </ColorButton>
                    )}
                </div>
            )}
            {paths[0] === 'accounts' && isMaster && !isSubMaster && title && (
                <div
                    className={`${styles.register_btn} ${
                        paths[0] === 'survey' || paths[0] === 'report' ? styles.margin_r64 : ''
                    }`}
                >
                    <ColorButton type="primary" onClick={navigateBtn}>
                        {title}
                    </ColorButton>
                </div>
            )}
        </div>
    )
}

export default Pagenation
