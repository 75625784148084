import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import tools from 'mocks/data/tools/Inventory/toolList.json'
import PageBanner from 'layout/PageBanner'

import { BannerIconTools } from 'assets/Icons'

import ListView from 'components/List/ListView'
import Pagenation from 'components/Pagenation'
import FilterLayout from 'components/Filter/FilterLayout'
import SortAndSearch from 'components/Filter/SortAndSearch'

// import FilterLayout from 'components/Filter'

const Tools = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const queryParams = new URLSearchParams(window.location.search)
    // const dispatch = useDispatch()

    // const [isLoading, setLoading] = useState(true)
    // const [modalOpen, setModalOpen] = useState(false)
    // const accounts = useSelector(state => state.account)
    const [sortName, setSortName] = useState('created_dt')

    const { result } = tools
    const { page, toolList, filterCountInfo } = result || {}
    const { currentPage, totalPages, totalItems } = page || {}

    /**
     * querystring update
     * @param paramName
     * @param paramValue
     * @param loading
     */
    const updateQueryStringAndNavigate = (paramName, paramValue, loading) => {
        const { pathname } = location
        const previousParams = queryParams.get(paramName)
        // 새로운 파라미터 추가 (단, 값이 비어있지 않을 때)
        if (paramValue) {
            if (previousParams === paramValue) {
                if (paramName !== 'sort' && paramName !== 'search') {
                    queryParams.delete(paramName)
                }
            } else {
                queryParams.set(paramName, paramValue)
            }
        }
        // 정렬 active
        if (paramName === 'sort') {
            setSortName(paramValue)
        }

        const newQueryString = queryParams.toString()
        const newLocation = newQueryString ? `${pathname}?${newQueryString}` : pathname

        if (loading) {
            loading(false)
        }
        navigate(newLocation)
    }

    // const fetchAccountList = async () => {
    //     try {
    //         const { payload } = await dispatch(accountsAsync())
    //         if (payload) {
    //             setLoading(false)
    //             return true
    //         }
    //         console.error('계정 목록을 불러오는 데 문제가 발생했습니다.')
    //         return false
    //     } catch (error) {
    //         console.error('계정  목록을 불러오는 동안 오류가 발생했습니다.', error)
    //         return null
    //     }
    // }
    //
    // const handleConfirm = () => {}
    //
    // useEffect(() => {
    //     fetchAccountList()
    // }, [location])

    return (
        <PageBanner title="교구재 재고 관리" bannerIcon={<BannerIconTools />}>
            {filterCountInfo && (
                <>
                    <FilterLayout
                        filterCount={filterCountInfo}
                        updateQueryStringAndNavigate={updateQueryStringAndNavigate}
                    />
                    <SortAndSearch
                        updateQueryStringAndNavigate={updateQueryStringAndNavigate}
                        sortName={sortName}
                        // isGridView={isGridView}
                        // setIsGridView={setIsGridView}
                        // setLoading={setLoading}
                    />
                </>
            )}
            {/*{isLoading ? (*/}
            {/*    <Spinner />*/}
            {/*) : accountList.length === 0 ? (*/}
            {/*    <ListNotFound />*/}
            {/*) : (*/}
            {/*    <>*/}
            {/*        <ListView*/}
            {/*            list={accountList.slice(0, 10)}*/}
            {/*            setLoading={setLoading}*/}
            {/*            fetchProgramList={fetchAccountList}*/}
            {/*        />*/}
            {/*        <Pagenation*/}
            {/*            title="등록"*/}
            {/*            currentPage={current_page}*/}
            {/*            totalPages={total_pages}*/}
            {/*            totalItems={total_items}*/}
            {/*            navigateBtn={() => setModalOpen(true)}*/}
            {/*            updateFtn={updateQueryStringAndNavigate}*/}
            {/*        />*/}
            {/*    </>*/}
            {/*)}*/}
            <ListView list={toolList.slice(0, 10)} />
            <Pagenation
                title="교구재 등록"
                currentPage={currentPage}
                totalPages={totalPages}
                totalItems={totalItems}
                navigateBtn={() => navigate('/tools/inventory/register')}
                // updateFtn={updateQueryStringAndNavigate}
            />
            {/*<InfoDialog*/}
            {/*    content={<Account setModalOpen={setModalOpen} />}*/}
            {/*    centered*/}
            {/*    modalOpen={modalOpen}*/}
            {/*    setModalOpen={setModalOpen}*/}
            {/*    closable={false}*/}
            {/*    handleConfirm={handleConfirm}*/}
            {/*    isCancel*/}
            {/*    submit*/}
            {/*    hideFooter*/}
            {/*    type={2}*/}
            {/*/>*/}
        </PageBanner>
    )
}

export default Tools
