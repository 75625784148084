import { useLocation } from 'react-router-dom'
import { Input, Tooltip } from 'antd'
import { GridViewFilterIcon, ListViewFilterIcon, SearchFilterIcon } from 'assets/Icons'
import SelectBox from '../../AntD/Select'

import styles from './sortAndSearch.module.scss'

const SortAndSearch = ({
    updateQueryStringAndNavigate,
    sortName,
    isGridView,
    setIsGridView,
    setInputValue,
    handleSearch,
    inputValue,
    setSelectOption,
}) => {
    const { pathname } = useLocation()
    const path = pathname.split('/')[1]
    const sort = 'sort'

    const handleChange = e => {
        const { value } = e.target
        setInputValue(value)
    }

    /**
     * 교육괸리에서 검색 시 셀렉트 박스
     * @param value
     */
    const changeSelectProgramSearch = value => {
        setSelectOption(value)
    }

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            handleSearch()
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.sort}>
                <button
                    type="button"
                    className={`button-reset-type ${sortName === 'createdDt' ? styles.active : ''}`}
                    onClick={() => updateQueryStringAndNavigate(sort, 'createdDt')}
                >
                    <span
                        className={`${styles.sub_title2} ${styles.item} ${
                            sortName === 'createdDt' ? styles.active : ''
                        }`}
                    >
                        등록순
                    </span>
                </button>
                <button
                    type="button"
                    className={`button-reset-type ${sortName === 'updatedDt' ? styles.active : ''}`}
                    onClick={() => updateQueryStringAndNavigate(sort, 'updatedDt')}
                >
                    <span
                        className={`${styles.sub_title2} ${styles.item} ${
                            sortName === 'updatedDt' ? styles.active : ''
                        }`}
                    >
                        수정순
                    </span>
                </button>
                {/*<button*/}
                {/*    type="button"*/}
                {/*    className="button-reset-type"*/}
                {/*    onClick={() => updateQueryStringAndNavigate(sort, 'date')}*/}
                {/*>*/}
                {/*    <span className={`${styles.sub_title2} ${styles.item} ${sortName === 'date' ? styles.active : ''}`}>*/}
                {/*        날짜순*/}
                {/*    </span>*/}
                {/*</button>*/}
            </div>
            <div className={styles.search}>
                {path === 'program' ? (
                    <div className={styles.search_program}>
                        <SelectBox
                            style={{ height: '50px' }}
                            onChange={value => changeSelectProgramSearch(value)}
                            defaultValue={{ value: 'programName', label: '교육명' }}
                            options={[
                                { value: 'programName', label: '교육명' },
                                { value: 'organizationName', label: '수요기관명' },
                            ]}
                        />
                        <div className={styles.text}>
                            <Input
                                className={`${styles.ant_input} ${styles['ant-input']}}`}
                                value={inputValue}
                                onChange={e => handleChange(e)}
                                placeholder="검색어를 입력해주세요"
                                type="text"
                                onKeyPress={e => handleKeyPress(e)}
                            />
                        </div>
                    </div>
                ) : (
                    <div className={styles.text}>
                        <Input
                            className={`${styles.ant_input} ${styles['ant-input']}}`}
                            value={inputValue}
                            onChange={e => handleChange(e)}
                            placeholder="검색어를 입력해주세요"
                            type="text"
                            onKeyPress={e => handleKeyPress(e)}
                        />
                    </div>
                )}
                <button type="button" className={`${styles.area} button-reset-type`} onClick={() => handleSearch()}>
                    <SearchFilterIcon />
                </button>

                {path !== 'tools' && (
                    <>
                        <Tooltip title="카드형">
                            <button
                                type="button"
                                className={`${styles.grid} button-reset-type ${isGridView ? styles.active : ''}`}
                                onClick={() => setIsGridView(true)}
                            >
                                <GridViewFilterIcon />
                            </button>
                        </Tooltip>
                        <Tooltip title="리스트형">
                            <button
                                type="button"
                                className={`${styles.list} button-reset-type ${!isGridView ? styles.active : ''}`}
                                onClick={() => setIsGridView(false)}
                            >
                                <ListViewFilterIcon />
                            </button>
                        </Tooltip>
                    </>
                )}
            </div>
        </div>
    )
}

export default SortAndSearch
