import React, { useState } from 'react'
import { Upload } from 'antd'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { tempS3Bucket } from 'utils/utilS3Bucket'
import style from 'assets/scss/detail.module.scss'

const UploadAttachment = ({ attachment = [], onChange = () => {} }) => {
    const { Dragger } = Upload
    const userId = useSelector(state => state.user?.userInfo?.userId)
    const programId = useSelector(state => state.program?.program?.programId)
    const [localFileList, setLocalFileList] = useState(attachment || [])

    const handleUploadStatus = info => {
        const file_name = info.file.name

        if (localFileList.some(file => file.file_name === file_name)) {
            return
        }

        tempS3Bucket(info.file, file_name, userId || 'JJ01')

        const updatedFileList = {
            file_name,
            program_id: programId,
            file_path: `/file/upload/attachment/${userId || 'JJ01'}`,
        }

        setLocalFileList(prevFileList => {
            const newFileList = [...prevFileList, updatedFileList]
            onChange(newFileList)
            return newFileList
        })
    }

    const handleRemoveFile = index => {
        const newFileList = localFileList.filter((_, i) => i !== index)
        setLocalFileList(newFileList)
        onChange(newFileList)
    }

    return (
        <div className={style.upload}>
            <Dragger
                showUploadList={false}
                multiple
                onChange={handleUploadStatus}
                accept=".pdf, .xlsx, .jpg, .jpeg, .png"
                // onDrop={e => console.log('Dropped files', e.dataTransfer.files)}
            >
                <div className={style.upload_icon_wrap}>
                    <span className={style.upload_icon} />
                </div>
                <p className="ant-upload-text">클릭하거나 드래그해서 파일을 추가하세요</p>
                <p className="ant-upload-hint">xlsx, pdf, jpg, png</p>
            </Dragger>
            {localFileList.length > 0 && (
                <>
                    {localFileList.map((file, index) => (
                        <div key={uuidv4()} className={style.added_file_area}>
                            <span className="body2">{file.name || file.fileName || file.file_name}</span>
                            <button type="button" className="button-reset-type" onClick={() => handleRemoveFile(index)}>
                                <span className={style.delete_btn} />
                            </button>
                        </div>
                    ))}
                </>
            )}
        </div>
    )
}

export default UploadAttachment
