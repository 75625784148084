import { colorTheme } from 'assets/colorTheme'

const TokenConfig = () => {
    const { primary, secondary, fontColor } = colorTheme

    return {
        fontSize: 14,
        borderRadius: 4,
        colorPrimary: primary,
        colorInfo: secondary.info,
        colorError: secondary.error,
        colorWarning: secondary.warning,
        colorWhite: fontColor.white,
    }
}

export default TokenConfig
