import { Suspense } from 'react'
import { useLocation } from 'react-router-dom'

import { FloatButton } from 'antd'
import Spinner from 'components/AntD/Spin'

import Header from 'layout/Header'

import { FooterIcon } from 'assets/Icons'
import styles from './layout.module.scss'

/**
 * APP 레이아웃
 * @param children ROUTERS
 * @returns {JSX.Element}
 * @constructor
 */
const AppLayout = ({ children }) => {
    const { pathname } = useLocation()
    const path = pathname.split('/')[1]
    const footerFirstInfo =
        '주소 : 서울특별시 마포구 월드컵북로 396, 누리꿈스퀘어 연구개발타워 14F 지역 SW 융합비즈마루'
    const footerSecondInfo = 'COPYRIGHT SW미래채움. ALL RIGHTS RESERVED'

    return (
        <>
            {/*{path !== 'login' && <Header />}*/}
            {path !== 'login' && !pathname.includes('/survey/submit/') && <Header />}
            <main className="main_content">
                <Suspense fallback={<Spinner />}>{children}</Suspense>
            </main>
            <div className={styles.footer}>
                <div className={styles.inner}>
                    <div className={`${styles.info}`}>
                        <p>{footerFirstInfo}</p>
                        <p>{footerSecondInfo}</p>
                    </div>
                    <div className={`${styles.logo}`}>
                        <FooterIcon />
                    </div>
                </div>
            </div>
            <FloatButton.BackTop />
        </>
    )
}

export default AppLayout
