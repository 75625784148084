import {
    buildSupabaseChainFilter,
    convertKeysToCamelCase,
    convertProgramSnakeToCamelCase,
    handlePageCountCommon,
} from 'utils/utilCommon'
import { supabase } from '../axios.instance'
import { getCountProgramCountRPC } from '../rpc/rpc.api'

const table = 'program_info' // 교육 정보
const matchingTable = 'program_instructor_matching' // 강사매칭

export const getProgramList = async (search, regionId, path) => {
    try {
        let filter = null
        let paginationInfo = null

        const pageSize = 10
        // query
        const { query } = await buildSupabaseChainFilter(table, search, regionId, path)
        const response = await query
        const urlSearchParams = new URLSearchParams(query.url.search)
        const params = Object.fromEntries(urlSearchParams.entries())
        // 메인페이지 분기처리 (필터카운트, 페이지 정보 필요 X)
        if (path !== 'main') {
            filter = await getCountProgramCountRPC(params)
            filter = convertKeysToCamelCase(filter)
            paginationInfo = handlePageCountCommon(search, response, pageSize)
        }
        // 현재 JSON 데이터 형태로 컨버팅
        if (response.data) {
            const convertedData = response.data.map(convertProgramSnakeToCamelCase)

            if (path !== 'main') {
                response.data = {
                    filterCountInfo: filter,
                    programList: convertedData,
                    page: paginationInfo,
                }
            } else {
                response.data = {
                    programList: convertedData,
                }
            }
            return response
        }
        return response
    } catch (error) {
        console.error(error)
        return null
    }
}

export const getProgramById = async programId => {
    try {
        const response = await supabase
            .from(table)
            .select(`*,program_instructor_matching(*),program_attachment(*)`)
            .eq('program_id', programId)
            .eq('is_delete', false)
        if (response.data) {
            const convertedData = response.data.map(convertProgramSnakeToCamelCase)
            response.data = convertedData
        }
        return response.data[0]
    } catch (error) {
        console.error(error)
        return error
    }
}

/**
 * 가장 높은 교육 index값 가져오기
 * program_id
 * 사용안함
 * @param programId
 * @returns {Promise<*|null>}
 */
export const getMaxProgramId = async () => {
    try {
        const { data, error } = await supabase
            .from('program_info')
            .select('program_id')
            .order('program_id', { ascending: false })
            .limit(1)

        console.log('error : ', error)

        return data
    } catch (error) {
        console.error(error)
        return null
    }
}

export const postProgram = async programList => {
    try {
        const response = await supabase.from(table).insert(programList).select()
        return response
    } catch (error) {
        console.error(error)
        return null
    }
}

export const editProgram = async program => {
    try {
        const response = await supabase.from(table).update(program).eq('program_id', program.program_id)
        return response
    } catch (error) {
        console.error(error.message)
        return error
    }
}

/**
 * 수퍼베이스 삭제
 * is_delete(삭제여부) true 변경
 * @param programId
 * @returns {Promise<PostgrestResponseSuccess<GetResult<any, any, R | unknown, "*">[]>|PostgrestResponseFailure|null>}
 */
export const deleteProgramById = async programId => {
    try {
        const response = await supabase.from(table).update({ is_delete: true }).eq('program_id', programId).select()
        return response
    } catch (error) {
        console.error(error)
        return null
    }
}

/**
 * 강사매칭 정보 등록
 * @param programList
 * @returns {Promise<PostgrestResponseSuccess<GetResult<any, any, R | unknown, "*">[]>|PostgrestResponseFailure|null>}
 */
export const postProgramInstructorMatching = async matchingList => {
    try {
        const response = await supabase.from(matchingTable).upsert(matchingList).select()
        return response
    } catch (error) {
        console.error(error)
        return null
    }
}
