import styles from './pageNotFound.module.scss'

const PageNotFound = () => {
    return (
        <div className={styles.container}>
            <div className={styles.wrap}>404 not found</div>
        </div>
    )
}

export default PageNotFound
