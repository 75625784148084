import React, { useState, useEffect, useCallback } from 'react'
import { BannerIconProgram } from 'assets/Icons'

import PageBanner from 'layout/PageBanner'
import { useParams } from 'react-router-dom'
import { getProgramById } from 'api/program/program.api'
import { useDispatch } from 'react-redux'
import { addProgram } from 'store/Slices/programs'

import { supabase } from 'api/axios.instance'

import Spinner from 'components/AntD/Spin'
import RegistrationAndEditFormPrograms from 'components/RegistrationAndEditForm/Programs'

import { convertProgramSnakeToCamelCase } from 'utils/utilCommon'

import styles from 'assets/scss/detail.module.scss'

const ProgramDetail = () => {
    const [loading, setLoading] = useState(true)
    const [program, setProgram] = useState(null)
    const { id } = useParams()
    const dispatch = useDispatch()

    const fetchProgram = useCallback(async () => {
        try {
            const { result } = await getProgramById(id)
            dispatch(addProgram(result))
            setLoading(false)
            // setProgram(result)
        } catch (err) {
            setLoading(false)
        }
    }, [id, dispatch])

    const setAttachment = useCallback(
        attachment => {
            setProgram(prevProgram => ({ ...prevProgram, programAttachmentList: attachment }))
        },
        [setProgram],
    )

    const setMatching = useCallback(
        matching => {
            setProgram(prevProgram => ({ ...prevProgram, programInstructorMatchingList: matching }))
        },
        [setProgram],
    )

    const getProgramInfo = useCallback(async () => {
        try {
            setLoading(true)
            const programResult = await supabase.from('program_info').select().eq('program_id', id)
            const attachmentResult = await supabase.from('program_attachment').select().eq('program_id', id)
            const matchingResult = await supabase
                .from('program_instructor_matching')
                .select()
                .eq('program_id', id)
                .eq('is_delete', false)

            const { data: program, error: programError } = programResult
            const { data: programInstructorMatching, error } = matchingResult
            const { data: programAttachment, Attachmenterror } = attachmentResult
            const convertedData = program.map(convertProgramSnakeToCamelCase)

            setProgram(convertedData[0])
            setAttachment(programAttachment || null)
            setMatching(programInstructorMatching || null)
            setLoading(false)
            if (error) {
                console.error(programError)
                console.error(Attachmenterror)
            }
        } catch (error) {
            console.error(error)
        }
    }, [id])

    useEffect(() => {
        // setTimeout(fetchProgram, 500)
        setTimeout(getProgramInfo, 500)
    }, [fetchProgram])

    return (
        <PageBanner title="교육계획 상세" bannerIcon={<BannerIconProgram />}>
            {program && !loading ? (
                <div className={styles.area}>
                    <RegistrationAndEditFormPrograms program={program} />
                </div>
            ) : (
                <div className={styles.spin_wrap}>
                    <Spinner />
                </div>
            )}
        </PageBanner>
    )
}

export default ProgramDetail
