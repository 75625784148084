import { Row, Col, Form } from 'antd'
import Title from 'components/Ui/Title'
import Label from 'components/Ui/Label'
import TextField from 'components/AntD/TextField'
import SelectUi from 'components/Ui/Select/SelectUi'
import { useState } from 'react'
import style from 'assets/scss/detail.module.scss'
import Value from '../../Ui/Value'

const TutorEducation = ({ tutor_info, isEditOrRegisterPage }) => {
    const [educationInfo] = useState(tutor_info)

    return (
        <div>
            <Title title="학력 및 전공" />
            <div className={`${style.border_style} ${style.box_md}`}>
                <Row className={style.row_item}>
                    <Col span={5}>
                        <Label label="전공 (학과명)" required={isEditOrRegisterPage} />
                    </Col>
                    {isEditOrRegisterPage ? (
                        <Col span={19} className={`${style.padding_top} ${style.padding_right} `}>
                            <Form.Item
                                initialValue={educationInfo?.major}
                                name="major"
                                rules={[{ required: true, message: '' }]}
                            >
                                <TextField name="major" val={educationInfo?.major} size="lg" />
                            </Form.Item>
                        </Col>
                    ) : (
                        <Col span={19} className={style.padding_y_medium}>
                            <Value value={tutor_info?.major} />
                        </Col>
                    )}
                </Row>
                <Row className={style.row_item}>
                    <Col span={5}>
                        <Label label="최종학력" required={isEditOrRegisterPage} />
                    </Col>
                    <Col span={19}>
                        {isEditOrRegisterPage ? (
                            <Form.Item
                                initialValue={educationInfo?.educationLevel}
                                name="educationLevel"
                                noStyle
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <SelectUi
                                    name="educationLevel"
                                    size="lg"
                                    options={['고졸', '전문대졸', '초대졸', '대졸', '석사']}
                                    value={educationInfo?.educationLevel}
                                />
                            </Form.Item>
                        ) : (
                            <SelectUi
                                disabled
                                size="lg"
                                options={['고졸', '전문대졸', '초대졸', '대졸', '석사']}
                                value={educationInfo?.educationLevel}
                            />
                        )}
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export default TutorEducation
