import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchTutorList } from 'api/tutors/tutors.api'

// Constants
const LOADING = 'loading'
const SUCCEEDED = 'succeeded'
const FAILED = 'failed'

// Async Thunks
// 파라미터 넣어야됨, 사용하지 않음
export const getTutors = createAsyncThunk('tutors/fetchTutorList', async () => {
    const { data } = await fetchTutorList()
    return data
})

// Slice
const tutorsSlice = createSlice({
    name: 'tutors',
    initialState: {
        tutor: {
            tutor_info: {},
            tutor_education: {},
            tutor_sortation: {},
            qualification: {},
            contract_info: {},
            founded_info: {},
            deposit_info: {},
            etc_info: {},
        },
        tutors: [],
        loading: 'idle',
        error: null,
    },

    reducers: {
        addTutor: (state, action) => {
            state.tutor = action.payload
        },
        addTutors: (state, action) => {
            state.tutors.push(action.payload)
        },
        updateTutor: (state, action) => {
            const index = state.tutors.findIndex(tutor => tutor.id === action.payload.id)
            if (index !== -1) {
                state.tutors[index] = action.payload
            }
        },
        deleteTutor: (state, action) => {
            const index = state.tutors.findIndex(tutor => tutor.id === action.payload.id)
            if (index !== -1) {
                state.tutors.splice(index, 1)
            }
        },
        setTutorField: (state, action) => {
            const { payload } = action
            const { field, data } = payload
            state.tutor[field] = data
        },
    },

    extraReducers: builder => {
        builder
            .addCase(getTutors.pending, state => {
                state.loading = LOADING
            })
            .addCase(getTutors.fulfilled, (state, action) => {
                state.loading = SUCCEEDED
                state.tutors = action.payload
            })
            .addCase(getTutors.rejected, (state, action) => {
                state.loading = FAILED
                state.error = action.error.message
            })
    },
})

const { addTutor, addTutors, updateTutor, deleteTutor, setTutorField } = tutorsSlice.actions

export { tutorsSlice, addTutor, addTutors, updateTutor, deleteTutor, setTutorField }

export default tutorsSlice
