import { useState } from 'react'
import dayjs from 'dayjs'

import CheckBox from 'components/AntD/CheckBox/type1'
import Title from 'components/Ui/Title'
import Label from 'components/Ui/Label'
import { Col, Form, Row } from 'antd'
import SelectUi from 'components/Ui/Select/SelectUi'
import TextField from 'components/AntD/TextField'

import style from 'assets/scss/detail.module.scss'
import DatePickerInput from 'components/AntD/DatePicker'
import Value from '../../Ui/Value'

const FoundedInfo = ({ founded_info, isEditOrRegisterPage }) => {
    const [foundedInfoState, setFoundedInfoState] = useState(founded_info)

    const handleChange = field => value => {
        setFoundedInfoState(prevState => ({
            ...prevState,
            [field]: value,
        }))
    }

    return (
        <div>
            <Title title="창업정보" />
            <div className={`${style.border_style} ${style.box_md}`}>
                <Row className={style.row_item}>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="홈텍스 확인현황" />
                            </Col>
                            <Col span={14} className={style.padding_y_xs}>
                                {isEditOrRegisterPage ? (
                                    <Form.Item name="taxStatus" initialValue={foundedInfoState?.taxStatus}>
                                        <CheckBox
                                            name="taxStatus"
                                            onChange={handleChange('taxStatus')}
                                            checked={foundedInfoState?.taxStatus}
                                            width="48"
                                            height="48"
                                        />
                                    </Form.Item>
                                ) : (
                                    <CheckBox disabled checked={founded_info?.taxStatus} width="48" height="48" />
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="사업장 형태" />
                            </Col>
                            {isEditOrRegisterPage ? (
                                <Col span={14} className={style.padding_y_xs}>
                                    <Form.Item name="businessType" initialValue={foundedInfoState?.businessType}>
                                        <TextField
                                            name="businessType"
                                            onChange={handleChange('businessType')}
                                            val={foundedInfoState?.businessType}
                                            size="sm"
                                        />
                                    </Form.Item>
                                </Col>
                            ) : (
                                <Col span={14} className={style.padding_y_medium}>
                                    <Value value={founded_info?.businessType} />
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
                <Row className={style.row_item}>
                    <Col span={5}>
                        <Label label="사업장 주소" />
                    </Col>
                    {isEditOrRegisterPage ? (
                        <Col span={19} className={style.padding_y_xs}>
                            <Form.Item name="businessAddress" initialValue={foundedInfoState?.businessAddress}>
                                <TextField
                                    name="businessAddress"
                                    onChange={handleChange('businessAddress')}
                                    val={foundedInfoState?.businessAddress}
                                    size="xl"
                                />
                            </Form.Item>
                        </Col>
                    ) : (
                        <Col span={19} className={style.padding_y_medium}>
                            <Value value={founded_info?.businessAddress} />
                        </Col>
                    )}
                </Row>
                <Row className={style.row_item}>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="상호" />
                            </Col>
                            {isEditOrRegisterPage ? (
                                <Col span={14} className={style.padding_y_xs}>
                                    <Form.Item name="businessName" initialValue={foundedInfoState?.businessName}>
                                        <TextField
                                            name="businessName"
                                            onChange={handleChange('businessName')}
                                            val={foundedInfoState?.businessName}
                                            size="sm"
                                        />
                                    </Form.Item>
                                </Col>
                            ) : (
                                <Col span={14} className={style.padding_y_medium}>
                                    <Value value={founded_info?.businessName} />
                                </Col>
                            )}
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="사업자등록번호" />
                            </Col>
                            {isEditOrRegisterPage ? (
                                <Col span={14} className={style.padding_y_xs}>
                                    <Form.Item
                                        name="businessRegistrationNumber"
                                        initialValue={foundedInfoState?.businessRegistrationNumber}
                                    >
                                        <TextField
                                            name="businessRegistrationNumber"
                                            onChange={handleChange('businessRegistrationNumber')}
                                            val={foundedInfoState?.businessRegistrationNumber}
                                            size="sm"
                                        />
                                    </Form.Item>
                                </Col>
                            ) : (
                                <Col span={14} className={style.padding_y_medium}>
                                    <Value value={founded_info?.businessRegistrationNumber} />
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
                <Row className={style.row_item}>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="창업일자(취업일)" />
                            </Col>
                            {isEditOrRegisterPage ? (
                                <Col span={14}>
                                    <Form.Item
                                        name="dateOfEstablish"
                                        initialValue={foundedInfoState?.dateOfEstablish || dayjs()}
                                    >
                                        <DatePickerInput
                                            name="dateOfEstablish"
                                            value={foundedInfoState?.dateOfEstablish || dayjs()}
                                            onChange={date => handleChange('dateOfEstablish', date)}
                                            defaultValue={dayjs()}
                                        />
                                    </Form.Item>
                                </Col>
                            ) : (
                                <Col span={14} className={style.padding_y_medium}>
                                    <Value type="date" value={founded_info?.dateOfEstablish} />
                                </Col>
                            )}
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="창업/취업 구분" />
                            </Col>
                            <Col span={14}>
                                <Col span={19}>
                                    {isEditOrRegisterPage ? (
                                        <Form.Item name="isFounded" initialValue={foundedInfoState?.isFounded}>
                                            <SelectUi
                                                name="isFounded"
                                                size="sm"
                                                options={['창업', '취업']}
                                                onChange={handleChange('isFounded')}
                                                value={foundedInfoState?.isFounded}
                                            />
                                        </Form.Item>
                                    ) : (
                                        <SelectUi
                                            disabled
                                            size="sm"
                                            options={['창업', '취업']}
                                            value={founded_info?.isFounded}
                                        />
                                    )}
                                </Col>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className={isEditOrRegisterPage ? style.row_item : ''}>
                    <Col span={5}>
                        <Label label="위촉번호" />
                    </Col>
                    {isEditOrRegisterPage ? (
                        <Col span={19} className={style.padding_y_xs}>
                            <Form.Item name="appointmentNum" initialValue={foundedInfoState?.appointmentNum}>
                                <TextField
                                    name="appointmentNum"
                                    onChange={handleChange('appointmentNum')}
                                    val={foundedInfoState?.appointmentNum}
                                    size="sm"
                                />
                            </Form.Item>
                        </Col>
                    ) : (
                        <Col span={19} className={style.padding_y_medium}>
                            <Value value={founded_info?.appointmentNum} />
                        </Col>
                    )}
                </Row>
            </div>
        </div>
    )
}
export default FoundedInfo
