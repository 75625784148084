import { createSlice } from '@reduxjs/toolkit'

const attendanceSlice = createSlice({
    name: 'attendance',
    initialState: {
        attendance: {
            numberOfClass: '',
            numberOfStudents: '',
            numberOfGraduates: '',
            data: [],
        },
    },

    reducers: {
        addAttendanceData: (state, { payload }) => {
            state.attendance.data = payload
        },
    },
})

export const { addAttendanceData } = attendanceSlice.actions
export default attendanceSlice
