import { useLocation } from 'react-router-dom'

import styles from './dialog.module.scss'

const DeleteModalContent = () => {
    const { pathname } = useLocation()
    const paths = pathname.split('/').filter(path => path !== '')

    const getTitle = type => {
        if (paths[0] === 'program') {
            if (type === 1) {
                return '프로그램'
            }
            if (type === 2) {
                return '프로그램을'
            }
        }
        if (paths[0] === 'tutors') {
            if (type === 1) {
                return '강사'
            }
            if (type === 2) {
                return '강사를'
            }
        }
        if (paths[0] === 'accounts') {
            if (type === 1) {
                return '계정'
            }
            if (type === 2) {
                return '계정을'
            }
        }
        if (paths[0] === 'report') {
            if (type === 1) {
                return '월간보고'
            }
            if (type === 2) {
                return '월간보고를'
            }
        }

        if (paths[0] === 'tools') {
            if (paths[1] === 'rental') {
                if (type === 1) {
                    return '대여 정보'
                }
                if (type === 2) {
                    return '대여 정보를'
                }
            }
        }
        return ''
    }

    return (
        <>
            <p>등록된 {getTitle(2)} 삭제하시겠습니까?</p>
            <p className={styles.red}>삭제된 {getTitle(1)} 정보는 복구 할 수 없습니다.</p>
        </>
    )
}

export default DeleteModalContent
