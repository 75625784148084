import { message } from 'antd/lib'

import CopyToClipboard from 'react-copy-to-clipboard'
import ColorButton from 'components/AntD/Button/ColorButton'

import { CopyIcon } from 'assets/Icons'

import style from './copyclipboard.module.scss'

const CopyURLButton = ({ url, text, type = 'text' }) => {
    const handleCopy = () => {
        message.success('URL이 복사되었습니다.')
    }

    return (
        <div className={style.copy_url}>
            {type === 'text' ? (
                <CopyToClipboard text={url} onCopy={handleCopy}>
                    <span>{text}</span>
                </CopyToClipboard>
            ) : type === 'button' ? (
                <CopyToClipboard text={url} onCopy={handleCopy}>
                    <ColorButton icon={<CopyIcon />}>{text}</ColorButton>
                </CopyToClipboard>
            ) : null}
        </div>
    )
}

export default CopyURLButton
