import { ColorGraduationIcon } from 'assets/Icons'
import styles from './card.module.scss'

const HorizontalIconCard = ({ icon = <ColorGraduationIcon />, label, value, addonAfter, editable, children }) => {
    const displayValue = value || value === 0 ? value : 0

    return (
        <div className={`${styles.card_horizontal} ${styles.border_style}`}>
            {icon}
            <div>
                {!editable ? (
                    <>
                        <span className={styles.card_key}>{label}</span>
                        <span className={styles.card_value}>
                            {displayValue}
                            {addonAfter}
                        </span>
                    </>
                ) : (
                    <>
                        <span className={styles.card_key}>{label}</span>
                        <div className={styles.card_input}>{children}</div>
                    </>
                )}
            </div>
        </div>
    )
}

export default HorizontalIconCard
