import { createSlice } from '@reduxjs/toolkit'

const surveySlice = createSlice({
    name: 'survey',
    initialState: {
        survey: {
            content: '',
            questions: [],
        },
    },

    reducers: {
        addSurvey: (state, { payload }) => {
            state.survey = payload
        },
    },
})

export const { addSurvey } = surveySlice.actions
export default surveySlice
