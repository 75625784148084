import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { getIsLoggedIn, logoutUserAsync } from 'store/Slices/user'

const RedirectPage = () => {
    const dispatch = useDispatch()
    const isLogin = useSelector(getIsLoggedIn)

    const navigate = useNavigate()

    useEffect(() => {
        const handleLogin = async () => {
            navigate('/main')
        }

        const handleLogout = () => {
            dispatch(logoutUserAsync())
            navigate('/login')
        }

        // 리다이렉트 여부에 따라 페이지 변경
        if (isLogin) {
            handleLogin()
        } else {
            handleLogout()
        }
    }, [navigate, isLogin, dispatch])

    return null
}

export default RedirectPage
