import { supabase } from '../axios.instance'

const table = 'program_instructor_matching' // 강사매칭

/**
 * 강사매칭 정보 등록
 * @returns {Promise<PostgrestResponseSuccess<GetResult<any, any, R | unknown, "*">[]>|PostgrestResponseFailure|null>}
 * @param matchingList
 */
export const postProgramInstructorMatching = async matchingList => {
    try {
        const response = await supabase.from(table).upsert(matchingList).select()
        return response
    } catch (error) {
        console.error(error)
        return null
    }
}

/**
 * 강사매칭 정보 완전 삭제
 * @param programId
 * @returns {Promise<PostgrestResponseSuccess<GetResult<any, any, R | unknown, "*">[]>|PostgrestResponseFailure|null>}
 */
export const deleteProgramInstructorById = async programId => {
    try {
        const response = await supabase.from(table).delete().eq('program_id', programId).select()
        return response
    } catch (error) {
        console.error(error)
        return null
    }
}

/**
 * 강사매칭 정보 수정
 * @param reportData
 * @returns {Promise<axios.AxiosResponse<any>|*>}
 */
export const upsertProgramInstructor = async programInstructorMatchingList => {
    try {
        const response = await supabase.from(table).upsert(programInstructorMatchingList).select()
        return response
    } catch (error) {
        console.error(error)
        return error
    }
}

/**
 * 강사 매칭 상세 임시
 * @param id
 * @returns {Promise<*|PostgrestResponseSuccess<GetResult<any, any, R | unknown, "*">[]>|PostgrestResponseFailure>}
 */
export const handleGetProgram = async id => {
    try {
        let query = supabase.from(table).select('*', { count: 'exact' })
        query = query.eq('is_delete', false)
        query = query.eq('tutor_id', id)
        return await query
    } catch (error) {
        console.error(error)
        return error
    }
}
