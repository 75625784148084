import React, { useState, useEffect, useCallback } from 'react'
import PageBanner from 'layout/PageBanner'
import { useNavigate, useParams } from 'react-router-dom'

import { getTutorsById, editTutor } from 'api/tutors/tutors.api'

import Spinner from 'components/AntD/Spin'

import { useDispatch, useSelector } from 'react-redux'
import { addTutor } from 'store/Slices/tutors'
import RegistrationAndEditFormTutors from 'components/RegistrationAndEditForm/Tutors'

import { App } from 'antd'

import { convertTutorCamelToSnakeCase } from 'utils/utilCommon'

import styles from 'assets/scss/detail.module.scss'

const TutorDetail = () => {
    const [loading, setLoading] = useState(true)
    // const { gray, yellow, blue, green, red } = colorTheme

    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { message } = App.useApp()
    const tutor = useSelector(state => state.tutor.tutor)

    const getTutorInfo = useCallback(async () => {
        try {
            const data = await getTutorsById(id)
            dispatch(addTutor(data))
            setLoading(false)
        } catch (error) {
            console.error(error)
        }
    }, [id])

    useEffect(() => {
        setTimeout(getTutorInfo, 500)
    }, [getTutorInfo])

    const onFinish = value => {
        const formData = convertTutorCamelToSnakeCase(value)
        const updateTutor = async () => {
            const response = await editTutor({ ...formData, region_id: tutor.regionId, tutor_id: tutor.tutorId })

            if (response.error) {
                message.error('수정 중 오류가 발생했습니다')
                return
            }

            message.success('성공적으로 수정되었습니다')
            navigate(`/tutors/${tutor?.tutorId}`)
        }

        updateTutor()
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo)
        message.error('사용자 수정사항을 다시 한번 확인해주세요.')
    }

    return (
        <PageBanner title="강사정보 수정">
            {tutor && !loading ? (
                <RegistrationAndEditFormTutors tutor={tutor} onFinish={onFinish} onFinishFailed={onFinishFailed} />
            ) : (
                <div className={styles.spin_wrap}>
                    <Spinner />
                </div>
            )}
        </PageBanner>
    )
}

export default TutorDetail
