import PageBanner from 'layout/PageBanner'
import { BannerIconTools, ColorDocIcon, ColorLocationIcon } from 'assets/Icons'
import { Row, Col, Space, Flex, Form } from 'antd'
import Title from 'components/Ui/Title'
import Label from 'components/Ui/Label'
import { useState } from 'react'
import SelectUi from 'components/Ui/Select/SelectUi'
import IconCard from 'components/AntD/Card/IconCard'
import ColorButton from 'components/AntD/Button/ColorButton'
import TextField from 'components/AntD/TextField'
import style from 'assets/scss/detail.module.scss'
import DatePickerInput from 'components/AntD/DatePicker'
import { useNavigate } from 'react-router-dom'

// import Spinner from 'components/AntD/Spin'

const ToolsEdit = () => {
    // const [loading, setLoading] = useState(true)
    const [toolsState] = useState({
        name: '교구',
        status: '수리완료',
    })
    const navigate = useNavigate()
    return (
        <PageBanner title="교구재 재고정보 수정" bannerIcon={<BannerIconTools />}>
            <div className={style.detail_padding}>
                {
                    toolsState && (
                        <>
                            <Row className={style.mb_lg}>
                                <Col span={16}>
                                    <Space direction="vertical" size={60}>
                                        <div>
                                            <Title title="교구재 정보" />
                                            <div className={`${style.border_style} ${style.box_md}`}>
                                                <Row className={style.row_item}>
                                                    <Col span={5}>
                                                        <Label label="교구명" />
                                                    </Col>
                                                    <Col span={19} className={`${style.padding_y_xs}`}>
                                                        <Form.Item
                                                            name="account_num"
                                                            initialValue={toolsState.account_num}
                                                        >
                                                            <TextField
                                                                name="account_num"
                                                                // val={toolsState.account_num}
                                                                val="엔트리"
                                                                size="xl"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row className={style.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="품목분류" />
                                                            </Col>
                                                            <Col
                                                                span={14}
                                                                className={`${style.padding_y_xs} ${style.padding_right}`}
                                                            >
                                                                <Form.Item
                                                                    name="account_holder_nm"
                                                                    initialValue={toolsState.account_holder_nm}
                                                                >
                                                                    <TextField
                                                                        name="account_holder_nm"
                                                                        // val={toolsState.account_holder_nm}
                                                                        val="010-AD6-610"
                                                                        size="sm"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="자산번호" />
                                                            </Col>
                                                            <Col
                                                                span={14}
                                                                className={`${style.padding_y_xs} ${style.padding_right}`}
                                                            >
                                                                <Form.Item
                                                                    name="bank_nm"
                                                                    initialValue={toolsState.bank_nm}
                                                                >
                                                                    <TextField
                                                                        name="bank_nm"
                                                                        // val={toolsState.bank_nm}
                                                                        val="UN001"
                                                                        size="sm"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div>
                                            <Title title="전체 교구 수량" />
                                            <div className={`${style.border_style} ${style.box_md}`}>
                                                <Row className={style.row_item}>
                                                    <Col span={5}>
                                                        <Label label="총수량" />
                                                    </Col>
                                                    <Col span={19} className={`${style.padding_y_xs}`}>
                                                        <Form.Item
                                                            name="account_num"
                                                            initialValue={toolsState.account_num}
                                                        >
                                                            <TextField
                                                                name="account_num"
                                                                // val={toolsState.account_num}
                                                                val="26개"
                                                                size="sm"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row className={style.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="현재수량" />
                                                            </Col>
                                                            <Col
                                                                span={14}
                                                                className={`${style.padding_y_xs} ${style.padding_right}`}
                                                            >
                                                                <Form.Item
                                                                    name="account_holder_nm"
                                                                    initialValue={toolsState.account_holder_nm}
                                                                >
                                                                    <TextField
                                                                        name="account_holder_nm"
                                                                        // val={toolsState.account_holder_nm}
                                                                        val="20개"
                                                                        size="sm"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="대여수량" />
                                                            </Col>
                                                            <Col
                                                                span={14}
                                                                className={`${style.padding_y_xs} ${style.padding_right}`}
                                                            >
                                                                <Form.Item
                                                                    name="bank_nm"
                                                                    initialValue={toolsState.bank_nm}
                                                                >
                                                                    <TextField
                                                                        name="bank_nm"
                                                                        // val={toolsState.bank_nm}
                                                                        val="6개"
                                                                        size="sm"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div>
                                            <Title title="자산 정보" />
                                            <div className={`${style.border_style} ${style.box_md}`}>
                                                <Row className={style.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="취득일" />
                                                            </Col>
                                                            <Col span={14}>
                                                                <Form.Item
                                                                    // initialValue={localOperationInfo?.start_date}
                                                                    // name="start_date"
                                                                    rules={[{ required: true, message: '' }]}
                                                                >
                                                                    <DatePickerInput
                                                                        // name="startDate"
                                                                        value="2023-09-15"
                                                                        // onChange={handleStateChange('start_date')}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="취득단가" />
                                                            </Col>
                                                            <Col
                                                                span={14}
                                                                className={`${style.padding_y_xs} ${style.padding_right}`}
                                                            >
                                                                <Form.Item
                                                                    name="account_holder_nm"
                                                                    initialValue={toolsState.account_holder_nm}
                                                                >
                                                                    <TextField
                                                                        name="account_holder_nm"
                                                                        // val={toolsState.account_holder_nm}
                                                                        val="100,000"
                                                                        size="sm"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className={style.row_item}>
                                                    <Col span={5}>
                                                        <Label label="구매처" />
                                                    </Col>
                                                    <Col span={19} className={`${style.padding_y_xs}`}>
                                                        <Form.Item
                                                            name="account_num"
                                                            initialValue={toolsState.account_num}
                                                        >
                                                            <TextField
                                                                name="account_num"
                                                                // val={toolsState.account_num}
                                                                val="지역구매"
                                                                size="xl"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div>
                                            <Title title="품목별 교구 상태" />
                                            <div className={`${style.border_style} ${style.box_md}`}>
                                                <Row className={style.row_item}>
                                                    <Col span={5}>
                                                        <Label label="불량사유" />
                                                    </Col>
                                                    <Col span={19} className={`${style.padding_y_xs}`}>
                                                        <Form.Item
                                                            name="account_num"
                                                            initialValue={toolsState.account_num}
                                                        >
                                                            <TextField
                                                                name="account_num"
                                                                // val={toolsState.account_num}
                                                                val="지역구매"
                                                                size="xl"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                <Row className={style.row_item}>
                                                    <Col span={5}>
                                                        <Label label="수리내역 결과" />
                                                    </Col>
                                                    <Col span={19}>
                                                        {/* <Form.Item
                                                            // initialValue={localOperationInfo?.operational_status}
                                                            name="operational_status"
                                                            rules={[{ required: true, message: '' }]}
                                                        > */}
                                                        <SelectUi
                                                            // onChange={handleStateChange('operational_status')}
                                                            value={toolsState?.status}
                                                            options={['수리완료', '수리불가', '주문예정']}
                                                            // value={localOperationInfo?.operational_status}
                                                        />
                                                        {/* </Form.Item> */}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div>
                                            <Title title="전체 교구 수량 및 상태" />
                                            <div className={`${style.border_style} ${style.box_md}`}>
                                                <Row className={style.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="정상 수량" />
                                                            </Col>
                                                            <Col
                                                                span={14}
                                                                className={`${style.padding_y_xs} ${style.padding_right}`}
                                                            >
                                                                <Form.Item
                                                                    name="account_holder_nm"
                                                                    initialValue={toolsState.account_holder_nm}
                                                                >
                                                                    <TextField
                                                                        name="account_holder_nm"
                                                                        // val={toolsState.account_holder_nm}
                                                                        val="24개"
                                                                        size="sm"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="분실 수량" />
                                                            </Col>
                                                            <Col
                                                                span={14}
                                                                className={`${style.padding_y_xs} ${style.padding_right}`}
                                                            >
                                                                <Form.Item
                                                                    name="bank_nm"
                                                                    initialValue={toolsState.bank_nm}
                                                                >
                                                                    <TextField
                                                                        name="bank_nm"
                                                                        // val={toolsState.bank_nm}
                                                                        val="6개"
                                                                        size="sm"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className={style.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="고장 수량" />
                                                            </Col>
                                                            <Col
                                                                span={14}
                                                                className={`${style.padding_y_xs} ${style.padding_right}`}
                                                            >
                                                                <Form.Item
                                                                    name="account_holder_nm"
                                                                    initialValue={toolsState.account_holder_nm}
                                                                >
                                                                    <TextField
                                                                        name="account_holder_nm"
                                                                        // val={toolsState.account_holder_nm}
                                                                        val="24개"
                                                                        size="sm"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="수리 AS 수량" />
                                                            </Col>
                                                            <Col
                                                                span={14}
                                                                className={`${style.padding_y_xs} ${style.padding_right}`}
                                                            >
                                                                <Form.Item
                                                                    name="bank_nm"
                                                                    initialValue={toolsState.bank_nm}
                                                                >
                                                                    <TextField
                                                                        name="bank_nm"
                                                                        // val={toolsState.bank_nm}
                                                                        val="6개"
                                                                        size="sm"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className={style.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="부품부족 수량" />
                                                            </Col>
                                                            <Col
                                                                span={14}
                                                                className={`${style.padding_y_xs} ${style.padding_right}`}
                                                            >
                                                                <Form.Item
                                                                    name="account_holder_nm"
                                                                    initialValue={toolsState.account_holder_nm}
                                                                >
                                                                    <TextField
                                                                        name="account_holder_nm"
                                                                        // val={toolsState.account_holder_nm}
                                                                        val="24개"
                                                                        size="sm"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="폐기수량" />
                                                            </Col>
                                                            <Col
                                                                span={14}
                                                                className={`${style.padding_y_xs} ${style.padding_right}`}
                                                            >
                                                                <Form.Item
                                                                    name="bank_nm"
                                                                    initialValue={toolsState.bank_nm}
                                                                >
                                                                    <TextField
                                                                        name="bank_nm"
                                                                        // val={toolsState.bank_nm}
                                                                        val="6개"
                                                                        size="sm"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className={style.row_item}>
                                                    <Col span={5}>
                                                        <Label label="센터별 수량" />
                                                    </Col>
                                                    <Col span={19} className={`${style.padding_y_xs}`}>
                                                        <Form.Item
                                                            name="account_num"
                                                            initialValue={toolsState.account_num}
                                                        >
                                                            <TextField
                                                                name="account_num"
                                                                // val={toolsState.account_num}
                                                                val="울산센터 XX개 / 북구센터 XX개"
                                                                size="xl"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row className={style.row_item}>
                                                    <Col span={5}>
                                                        <Label label="팀명칭" />
                                                    </Col>
                                                    <Col span={19} className={`${style.padding_y_xs}`}>
                                                        {/* <Form.Item
                                                            // initialValue={localOperationInfo?.operational_status}
                                                            name="operational_status"
                                                            rules={[{ required: true, message: '' }]}
                                                        > */}
                                                        <TextField
                                                            name="account_num"
                                                            // val={toolsState.account_num}
                                                            val="새미래 행복교육"
                                                            size="xl"
                                                        />
                                                        {/* </Form.Item> */}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div>
                                            <Title title="비고" />
                                            <div className={`${style.box_md} ${style.border_style}`}>
                                                <Row className={style.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="대출가능 수량" />
                                                            </Col>
                                                            <Col
                                                                span={14}
                                                                className={`${style.padding_y_xs} ${style.padding_right}`}
                                                            >
                                                                <Form.Item
                                                                    name="account_holder_nm"
                                                                    initialValue={toolsState.account_holder_nm}
                                                                >
                                                                    <TextField
                                                                        name="account_holder_nm"
                                                                        // val={toolsState.account_holder_nm}
                                                                        val="24개"
                                                                        size="sm"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="재고 위치" />
                                                            </Col>
                                                            <Col
                                                                span={14}
                                                                className={`${style.padding_y_xs} ${style.padding_right}`}
                                                            >
                                                                <Form.Item
                                                                    name="bank_nm"
                                                                    initialValue={toolsState.bank_nm}
                                                                >
                                                                    <TextField
                                                                        name="bank_nm"
                                                                        // val={toolsState.bank_nm}
                                                                        val="센터"
                                                                        size="sm"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className={style.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="배터리 상태" />
                                                            </Col>
                                                            <Col
                                                                span={14}
                                                                className={`${style.padding_y_xs} ${style.padding_right}`}
                                                            >
                                                                <Form.Item
                                                                    name="account_holder_nm"
                                                                    initialValue={toolsState.account_holder_nm}
                                                                >
                                                                    <TextField
                                                                        name="account_holder_nm"
                                                                        // val={toolsState.account_holder_nm}
                                                                        val="70%"
                                                                        size="sm"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="제조사" />
                                                            </Col>
                                                            <Col
                                                                span={14}
                                                                className={`${style.padding_y_xs} ${style.padding_right}`}
                                                            >
                                                                <Form.Item
                                                                    name="bank_nm"
                                                                    initialValue={toolsState.bank_nm}
                                                                >
                                                                    <TextField
                                                                        name="bank_nm"
                                                                        // val={toolsState.bank_nm}
                                                                        val="삼성"
                                                                        size="sm"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div>
                                            <Title title="추가항목" />
                                            <div className={`${style.border_style} ${style.box_md}`}>
                                                <Row className={style.row_item}>
                                                    <Col span={5}>
                                                        <Label label="과목 (활용)" />
                                                    </Col>
                                                    <Col span={19} className={`${style.padding_y_xs}`}>
                                                        <Form.Item
                                                            name="account_num"
                                                            initialValue={toolsState.account_num}
                                                        >
                                                            <TextField
                                                                name="account_num"
                                                                // val={toolsState.account_num}
                                                                val="활용수업"
                                                                size="xl"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row className={style.row_item}>
                                                    <Col span={5}>
                                                        <Label label="폐기일자" />
                                                    </Col>
                                                    <Col span={19}>
                                                        <Form.Item
                                                            // initialValue={localOperationInfo?.start_date}
                                                            // name="start_date"
                                                            rules={[{ required: true, message: '' }]}
                                                        >
                                                            <DatePickerInput
                                                                // name="startDate"
                                                                value="2023-09-15"
                                                                // onChange={handleStateChange('start_date')}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Space>
                                </Col>
                                <Col span={8} className={style.padding_left_large}>
                                    <Space direction="horizontal" className={style.mt}>
                                        <IconCard icon={<ColorDocIcon />} label="자산번호" val="UN001" />
                                        <IconCard icon={<ColorLocationIcon />} label="재고위치" val="경남" />
                                    </Space>
                                </Col>
                            </Row>
                            <Flex justify="center" align="center" gap={8}>
                                <ColorButton size="large" type="primary">
                                    저장
                                </ColorButton>
                                <ColorButton
                                    size="large"
                                    onClick={() => {
                                        navigate('/tools/inventory/1')
                                    }}
                                >
                                    취소
                                </ColorButton>
                            </Flex>
                        </>
                    )
                    // : (
                    //     <div className={styles.spin_wrap}>
                    //         <Spinner />
                    //     </div>
                    // )
                }
            </div>
        </PageBanner>
    )
}

export default ToolsEdit
