import dayjs from 'dayjs'
import styles from './value.module.scss'

const formatDate = dateString => {
    const formattedDate = dayjs(dateString, { format: 'YYYYMMDD' }).format('YYYY-MM-DD')
    return formattedDate
}

const formatPhone = phone => {
    return phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
}

const Value = ({ type, value, addonBefore, addonAfter }) => {
    if (!value) {
        return <span className={styles.no_value}>해당없음</span>
    }

    const renderAddon = (addon, marginRight = 0, marginLeft = 0) => {
        return (
            <span className={styles.addon} style={{ marginRight, marginLeft }}>
                {addon}
            </span>
        )
    }

    let formattedValue = value

    if (type === 'date') {
        formattedValue = formatDate(value)
    } else if (type === 'phone') {
        formattedValue = formatPhone(value)
    }

    return (
        <span className={styles.value}>
            {addonBefore && renderAddon(addonBefore, 20)}
            {formattedValue}
            {addonAfter && renderAddon(addonAfter, 0, 20)}
        </span>
    )
}

export default Value
