import { useState } from 'react'
import Title from 'components/Ui/Title'
import Label from 'components/Ui/Label'
import { Col, Form, Row } from 'antd'
import TextField from 'components/AntD/TextField'
import style from 'assets/scss/detail.module.scss'
import Value from '../../Ui/Value'

const DepositInfo = ({ deposit_info, isEditOrRegisterPage }) => {
    const [depositState] = useState(deposit_info || '')

    return (
        <div>
            <Title title="은행정보" />
            <div className={`${style.border_style} ${style.box_md}`}>
                <Row className={style.row_item}>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="예금주" />
                            </Col>
                            {isEditOrRegisterPage ? (
                                <Col span={14} className={`${style.padding_y_xs} ${style.padding_right}`}>
                                    <Form.Item name="accountHolderNm" initialValue={depositState?.accountHolderNm}>
                                        <TextField
                                            name="accountHolderNm"
                                            val={depositState?.accountHolderNm}
                                            size="sm"
                                        />
                                    </Form.Item>
                                </Col>
                            ) : (
                                <Col span={14} className={style.padding_y_medium}>
                                    <Value value={deposit_info?.accountHolderNm} />
                                </Col>
                            )}
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="입금은행" />
                            </Col>
                            {isEditOrRegisterPage ? (
                                <Col span={14} className={`${style.padding_y_xs} ${style.padding_right}`}>
                                    <Form.Item name="bankNm" initialValue={depositState?.bankNm}>
                                        <TextField name="bankNm" val={depositState?.bankNm} size="sm" />
                                    </Form.Item>
                                </Col>
                            ) : (
                                <Col span={14} className={style.padding_y_medium}>
                                    <Value value={deposit_info?.bankNm} />
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
                <Row className={style.row_item}>
                    <Col span={5}>
                        <Label label="계좌번호" />
                    </Col>
                    {isEditOrRegisterPage ? (
                        <Col span={19} className={`${style.padding_y_xs}`}>
                            <Form.Item name="accountNum" initialValue={depositState?.accountNum}>
                                <TextField name="accountNum" val={depositState?.accountNum} size="sm" />
                            </Form.Item>
                        </Col>
                    ) : (
                        <Col span={19} className={style.padding_y_medium}>
                            <Value value={deposit_info?.accountNum} />
                        </Col>
                    )}
                </Row>
            </div>
        </div>
    )
}
export default DepositInfo
