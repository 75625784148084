import React, { useEffect, useRef } from 'react'

import { Col, Row, Form, InputNumber, Input } from 'antd'
import { useLocation } from 'react-router-dom'
import { formatCurrency, formatNumberToThousands, numberParser } from 'utils/utilCommon'
import styles from './report.module.scss'
import SanitizedHTML from '../sanitizeHTML'

const PlanTable = ({ title, info, name }) => {
    const { TextArea } = Input
    const { pathname } = useLocation()
    const paths = pathname.split('/').filter(path => path !== '')
    const { details, expenditureAmount, ratio } = info || ''

    //textarea 길이설정
    const textAreaRef = useRef(null)
    const textAreaAdjust = element => {
        if (element.value === '') {
            //element.style.height = 'inherit' // 초기 높이로 설정
            //element.style.height = '90px' // 초기 높이로 설정
            element.style.height = '100%' // 초기 높이로 설정
        } else {
            element.style.height = '1px'
            element.style.height = `${10 + element.scrollHeight}px`
        }
    }

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaAdjust(textAreaRef.current.resizableTextArea.textArea)
        }
    }, [details])

    const handleTextAreaChange = e => {
        textAreaAdjust(e.target)
    }
    return (
        <>
            <p className="h7">
                {title}월 계획 (당월)
                {(paths[1] === 'register' || paths[1] === 'edit') && <span className={styles.red}>*</span>}
            </p>
            {/* </div> */}
            <div className={`${styles.card} ${styles.section_md}`}>
                <Row className={styles.padding_2436} gutter={24}>
                    <Col span={18} className={styles.height_100}>
                        <Form.Item name={`${name}Details`} rules={[{ required: true }]} noStyle>
                            {paths[1] === 'register' || paths[1] === 'edit' ? (
                                <TextArea
                                    name={`${name}Details`}
                                    //className={styles.height_100}
                                    className={styles.textarea_min}
                                    placeholder="내용을 입력해주세요"
                                    content={details || ''}
                                    rows={3}
                                    style={{ resize: 'none' }}
                                    onChange={handleTextAreaChange}
                                    onInput={handleTextAreaChange}
                                    ref={textAreaRef}
                                />
                            ) : (
                                <SanitizedHTML className={`${styles.text}`} html={details} />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Row className={styles.right} gutter={16}>
                            <Col span={12} className={`body2 ${styles.margin_b16}`}>
                                집행금액{' '}
                                {(paths[1] === 'register' || paths[1] === 'edit') && (
                                    <span className={styles.red}>*</span>
                                )}
                            </Col>
                            <Col span={12} className={`body2 ${styles.margin_b16}`}>
                                비율 (%)
                            </Col>
                            <Col span={12} className={`body2 ${styles.gray} ${styles.ellipsis}`}>
                                <Form.Item name={`${name}ExpenditureAmount`} rules={[{ required: true }]} noStyle>
                                    {paths[1] === 'register' || paths[1] === 'edit' ? (
                                        <InputNumber
                                            name={`${name}ExpenditureAmount`}
                                            className={`${styles.right} ${styles.height_100} ${styles.ant_input} ${styles.w127} ${styles.no_number_handler}`}
                                            defaultValue={expenditureAmount || ''}
                                            formatter={formatNumberToThousands}
                                            parser={numberParser}
                                            placeholder="집행금액 입력"
                                            onKeyDown={e => (e.keyCode === 13 ? e.preventDefault() : '')}
                                        />
                                    ) : (
                                        <span>{formatCurrency(expenditureAmount)}</span>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12} className={`body2 ${styles.gray} ${styles.ellipsis}`}>
                                <Form.Item name={`${name}Ratio`} rules={[{ required: true }]} noStyle>
                                    {paths[1] === 'register' || paths[1] === 'edit' ? (
                                        <InputNumber
                                            name={`${name}Ratio`}
                                            className={`${styles.right} ${styles.height_100} ${styles.ant_input} ${styles.no_number_handler}`}
                                            step={0.1} // 소수점 자릿수 조절
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} // 천 단위 구분 기호 추가
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')} // 입력 값에서 구분 기호 제거
                                            maxLength={3}
                                            defaultValue={0}
                                            onKeyDown={e => (e.keyCode === 13 ? e.preventDefault() : '')}
                                            readOnly
                                        />
                                    ) : (
                                        <span>{ratio}</span>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default PlanTable
